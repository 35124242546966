import { blockTypeItem, icons, MenuItem, joinUpItem, liftItem } from 'prosemirror-menu'

import { toggleMark } from 'prosemirror-commands'
import { wrapInList } from 'prosemirror-schema-list'

// Helpers to create specific types of items

function cmdItem(cmd, options) {
  let passedOptions = {
    label: options.title,
    run: cmd,
  }
  for (let prop in options) passedOptions[prop] = options[prop]
  if ((!options.enable || options.enable === true) && !options.select)
    passedOptions[options.enable ? 'enable' : 'select'] = (state) => cmd(state)

  return new MenuItem(passedOptions)
}

function markActive(state, type) {
  let { from, $from, to, empty } = state.selection
  if (empty) return type.isInSet(state.storedMarks || $from.marks())
  else return state.doc.rangeHasMark(from, to, type)
}

function markItem(markType, options) {
  let passedOptions = {
    active(state) {
      return markActive(state, markType)
    },
    enable: true,
  }
  for (let prop in options) passedOptions[prop] = options[prop]
  return cmdItem(toggleMark(markType), passedOptions)
}

function wrapListItem(nodeType, options) {
  return cmdItem(wrapInList(nodeType, options.attrs), options)
}

// :: (Schema) → Object
// Given a schema, look for default mark and node types in it and
// return an object with relevant menu items relating to those marks:
//
export default function buildMenuItems(schema) {
  let r = {},
    type
  if ((type = schema.marks.strong))
    r.toggleStrong = markItem(type, { title: 'Toggle strong style', icon: icons.strong })
  if ((type = schema.marks.em)) r.toggleEm = markItem(type, { title: 'Toggle emphasis', icon: icons.em })

  if ((type = schema.nodes.bullet_list))
    r.wrapBulletList = wrapListItem(type, {
      title: 'Wrap in bullet list',
      icon: icons.bulletList,
    })
  if ((type = schema.nodes.ordered_list))
    r.wrapOrderedList = wrapListItem(type, {
      title: 'Wrap in ordered list',
      icon: icons.orderedList,
    })

  if ((type = schema.nodes.paragraph))
    r.makeParagraph = blockTypeItem(type, {
      title: 'Change to paragraph',
      label: 'Plain',
    })

  if ((type = schema.nodes.heading))
    for (let i = 1; i <= 10; i++)
      r['makeHead' + i] = blockTypeItem(type, {
        title: 'Change to heading ' + i,
        label: 'Level ' + i,
        attrs: { level: i },
      })

  let cut = (arr) => arr.filter((x) => x)

  r.inlineMenu = [ cut([ r.toggleStrong, r.toggleEm ]) ]
  r.blockMenu = [ cut([ r.wrapBulletList, r.wrapOrderedList, joinUpItem, liftItem ]) ]
  r.fullMenu = r.inlineMenu.concat(r.blockMenu)

  return r
}
