import { Fragment } from 'react'
import { observer } from 'mobx-react'
import get from 'lodash.get'
import sortBy from 'lodash.sortby'

import RowTr from '../Rows/Row.tr'
import RowLink from '../Rows/Row.link'

import './Categories.scss'

const Categories = (props) => {
  const { list, config } = props
  //-----------------------------------------------------------------------------------------
  const { link, categories } = config

  const { value, sortOrder, sortDirection, otherLabel } = categories
  const cols = {}
  list.forEach((item) => {
    const val = get(item, value)
    cols[val] = cols[val] || { label: val, items: [] }
    cols[val].items.push(item)
  })

  let orderedCols = Object.values(cols)

  if (sortOrder) {
    orderedCols = sortBy(orderedCols, ({ label }) => (sortOrder.includes(label) ? sortOrder.indexOf(label) : 99999))
    orderedCols = sortDirection === 'DESC' ? orderedCols.reverse() : orderedCols
  }

  const getId = ({ _key }, key) => {
    return _key || key
  }

  return (
    <div className="categories table">
      {orderedCols.map(({ items = [], label }, key) => (
        <Fragment key={label || key}>
          <div className="table__row categories__header">
            <div className="table__col">
              <div className="categories__header__cell">
                <div className="categories__extend">{items.length}</div>
                <div>
                  <h3>{label || otherLabel || 'Other...'}</h3>
                </div>
              </div>
            </div>
          </div>

          {items.map((item, key) => {
            const rowProps = {
              ...props,
              key,
              id: getId(item, key),
              item,
            }

            const Row = link ? RowLink : RowTr
            return <Row {...rowProps} />
          })}
        </Fragment>
      ))}
    </div>
  )
}

export default observer(Categories)
