import React, { useState } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'
import get from 'lodash.get'

import _d from 'dictionary'

import Form from 'common/Form/Form'
import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import RightIcon from 'common/Icons/Right'
import MoreIcon from 'common/Icons/More'
import DropDown from 'common/DropDown/DropDown'

import RowExpanded from './Row.expanded'

const RowTr = (props) => {
  const { config, item, id, preLoad } = props
  const { columns, onClick, lastAdded } = config

  let actions = typeof config.actions === 'function' ? config.actions(item) : config.actions
  actions = actions && actions.filter((v) => v)

  const isOpen = typeof config.open === 'function' ? config.open(item) : config.open

  const [ open, setOpen ] = useState(isOpen)
  const [ confirm, setConfirm ] = useState()
  const [ edit, setEdit ] = useState()

  const wrapActions = (item) => {
    if (item.content || item.form) {
      const { content, ...rest } = item
      const body = item.form
        ? (values, self) => {
            const initialValues = !item.noCompare && { ...values }
            return (
              <div>
                <Form
                  config={[
                    ...item.form,
                    {
                      content: (meta, model) => {
                        const { values, stats } = model
                        const { allValid } = stats || {}

                        return (
                          <div className="form-item">
                            <div className="form-item__content">
                              <div className="form-label" />
                              <div className="table__add__actions">
                                <button className="btn cancel" onClick={self.onClose}>
                                  {_d('Cancel')}
                                </button>
                                <ConfirmButton
                                  disabled={!allValid}
                                  data-id="apply"
                                  confirm={_d(item.submitButton || 'Are you sure?')}
                                  onClick={async () => {
                                    item.lastUpdated = await self.onSubmit(values, initialValues)
                                    preLoad.update()
                                    self.onClose()
                                  }}
                                >
                                  {_d(item.submitButton || 'Save')}
                                </ConfirmButton>
                              </div>
                            </div>
                          </div>
                        )
                      },
                    },
                  ]}
                  values={values}
                  // compare={initialValues}
                />
              </div>
            )
          }
        : content

      const editItem = {
        ...rest,
        body,
        onSubmit: async (val) => {
          await item.onSubmit(val)
          preLoad.update()
        },
        onUpdate: () => {
          preLoad.update()
        },
        onClick: () => {
          setEdit(editItem)
          setOpen(true)
        },
        onClose: () => {
          setEdit(false)
          setOpen(open)
        },
      }

      return editItem
    }

    if (item.confirm) {
      const confirmItem = {
        ...item,
        onSubmit: async (val) => {
          await item.onClick(val)
          preLoad.update()
          setOpen(false)
        },
        onClick: () => {
          setConfirm(confirmItem)
          setOpen(true)
        },
        onClose: () => {
          setConfirm(false)
          setOpen(open)
        },
      }

      return confirmItem
    }
    return item
  }

  const expandable = !!config.content

  const selected = config.selected === item
  const added = lastAdded && lastAdded._key === item._key

  return (
    <div className="table__tbody">
      <div
        key="row"
        className={cn('table__row', config.rowClass && config.rowClass(item), {
          clickable: expandable || onClick,
          selected,
          added,
          active: config.active && config.active(item),
        })}
        onClick={async () => {
          if (expandable) {
            setOpen(!open)
            setConfirm(false)
          }
          if (onClick) {
            const result = await onClick(item, config)
            if (result && config.id && config.id(result) === id) {
              Object.assign(item, result)
            }
          }
        }}
      >
        {expandable && (
          <div className={cn('table__col', 'table__expand__btn', { open })} id={config.rowId && config.rowId(item)}>
            <div className="cell">
              <button>
                <RightIcon />
              </button>
            </div>
          </div>
        )}
        {columns.filter((v) => v).map(({ content, name, width, align, desc, cellClass }, colKey) => {
          const body = name ? get(item, name) : content ? content(item, { selected, id, added }) : ''
          const description = desc && get(item, desc)
          cellClass = typeof cellClass === 'function' ? cellClass(item) : cellClass

          return (
            <div key={colKey} className="table__col" style={{ width, textAlign: align }}>
              <div className={cn('cell', cellClass)}>
                {body} {description && <div className="description">{description}</div>}
              </div>
            </div>
          )
        })}

        {actions && (
          <div
            className="table__actions table__col"
            style={{ width: 40, textAlign: 'center' }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="cell">
              {actions.length && !confirm ? (
                <DropDown options={actions.map(wrapActions)}>
                  <MoreIcon />
                </DropDown>
              ) : (
                <div className="disabled">
                  <MoreIcon />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {open &&
      (expandable || confirm || edit) && (
        <RowExpanded confirm={confirm} edit={edit} open={open} config={config} item={item} />
      )}
    </div>
  )
}

export default observer(RowTr)
