import { useState } from 'react'

import cn from 'classnames'
import DownIcon from 'common/Icons/Down'
import DownloadFileIcon from 'common/Icons/DownloadFile'
import PdfIcon from 'common/Icons/Pdf'
import DocxIcon from 'common/Icons/Docx'
import AsyncButton from 'common/AsyncButton/AsyncButton'
import FileSaver from 'file-saver'
import _d from 'dictionary'
import DocxTx from '../MoreTabs/DocumentInfo/DocxTranslator'
import printApi from 'api/print'

const Download = ({ label, negotiation = {}, actions }) => {
  const [ showMore, setShowMore ] = useState()
  const { auditFile, doc, state } = negotiation

  const [ tpl ] = doc.tpls

  const downloadFile = async ({ state, complededAt, myParty, cpParty, _key }, { name, fileName, label }) => {
    const fileData = await printApi.download(_key, { fileName: name.split('/').pop() })

    fileName =
      state === 'completed'
        ? `${complededAt.split('T')[0]} ${label} - ${myParty.owner.displayName}${cpParty ? `  w  ${cpParty.owner.displayName}` : ''}.pdf`
        : fileName + '.pdf'

    FileSaver.saveAs(fileData, fileName)
  }

  const print = async ({ myPartyMeta, mainDoc, version, _key }, fileType = 'pdf', type) => {
    const fileName = mainDoc.label + '-' + (myPartyMeta.version || version) + '.' + fileType

    let fileData

    if (_key) {
      fileData = await printApi.print(_key, fileType, type)
    } else {
      fileData = await printApi.printDoc(mainDoc._key, fileType, type)
    }

    FileSaver.saveAs(fileData, fileName)
  }

  const printDocx = async ({ myPartyMeta, mainDoc }) => {
    const docTx = await DocxTx.toDocx()

    const fileName = mainDoc.label + '-' + myPartyMeta.version + '.docx'
    const fileContent = await printApi.getDocx(docTx)
    FileSaver.saveAs(fileContent, fileName)
  }

  return (
    <div className="toolbar__item">
      <button className={cn('toolbar__btn', { active: showMore })} onClick={() => setShowMore(true)}>
        <div className="flex">
          {_d(label || 'More')}
          <DownIcon />
          {/* <div className="btn__aside">
            <DownIcon />
          </div> */}
        </div>
      </button>
      {showMore && (
        <div className="toolbar__dd">
          <div className="toolbar__dd__bg" onClick={() => setShowMore(false)} />
          {actions ? (
            <ul>
              {actions.map((item, key) => (
                <li key={key}>
                  <button
                    className="toolbar__dd__btn"
                    disabled={item.disabled}
                    onClick={() => {
                      item.onClick()
                      setShowMore(false)
                    }}
                  >
                    {item.label} {item.icon}
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            <ul>
              {/* <li className="toolbar__dd__title">Download</li> */}
              <li>
                {tpl.file && tpl.file.fileName ? (
                  <AsyncButton
                    className="toolbar__dd__btn"
                    label={state !== 'completed' ? 'Execution version' : 'Signed version'}
                    onClick={() => downloadFile(negotiation, tpl.file)}
                    activeIcon={<DownloadFileIcon />}
                    icon={<PdfIcon />}
                  />
                ) : (
                  <AsyncButton
                    className="toolbar__dd__btn"
                    label="PDF"
                    onClick={() => print(negotiation, 'pdf')}
                    activeIcon={<DownloadFileIcon />}
                    icon={<PdfIcon />}
                  />
                )}
              </li>
              <li>
                {auditFile && (
                  <AsyncButton
                    className="toolbar__dd__btn"
                    label="Audit trail"
                    onClick={() => downloadFile(negotiation, auditFile)}
                    activeIcon={<DownloadFileIcon />}
                    icon={<PdfIcon />}
                  />
                )}
              </li>
              <li>
                <AsyncButton
                  className="toolbar__dd__btn"
                  label="Word version"
                  onClick={() => printDocx(negotiation)}
                  activeIcon={<DownloadFileIcon />}
                  icon={<DocxIcon />}
                />
              </li>
            </ul>
          )}
        </div>
      )}
    </div>
  )
}

export default Download
