import { decorate, observable } from 'mobx'

const api = {
  title: 'Yumi Docs',
  header: 'sticky',
  footer: true,
  currentPage: null,
  lang: localStorage.lang || 'en',
  isDev: process.env.REACT_APP_DEVELOPMENT === 'true',
  showEnvironmentLabel: !!global.APP_CONFIG.environmentLabel,
  environmentLabel: typeof global.APP_CONFIG.environmentLabel === 'string' && global.APP_CONFIG.environmentLabel,

  async loadFonts() {
    if ('fonts' in document) {
      await document.fonts.ready
      this._fontsLoaded = true
    } else {
      this._fontsLoaded = true
    }
  },

  get fontsLoaded() {
    return this._fontsLoaded
  },
}

decorate(api, {
  title: observable,
  lang: observable,
  header: observable,
  footer: observable,
  currentPage: observable.ref,
  modal: observable,
  _fontsLoaded: observable,
  debug: observable,
})

api.loadFonts()
export default api
