import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import PreLoad from 'common/PreLoad/PreLoad'
import { timeDistance } from 'common/helper/date'

import PlayIcon from 'common/Icons/Play3'
import CancelIcon from 'common/Icons/Cancel'
import ProgressLine from 'common/ProgressLine/Progress.line'
import Avatar from 'common/UserCard/Avatar'

import adminApi from 'api/admin'

import './History.scss'

class History extends React.Component {
  constructor(props) {
    super(props)
    this.preLoad = new PreLoad(() => this.getData(props))
  }

  getData = ({ _key }) => {
    return adminApi.getHistory(_key)
  }

  render() {
    const { _key } = this.props
    const { loading, result } = this.preLoad

    return (
      loading || (
        <div className="ani__parties">
          {result.map(({ party: { isTurn, displayName, stats, state, email, isOwner }, activities }, key) => (
            <div key={key} className="party">
              <h3 className={cn('party__header', { isTurn })}>
                {isTurn ? <PlayIcon /> : <CancelIcon />}
                <div>
                  {displayName} <div className="desc">{state}</div>
                </div>
                {stats && (
                  <ProgressLine
                    done={stats.matched}
                    total={stats.total}
                    completed={stats.completed}
                    className="dark small"
                  />
                )}
              </h3>
              <div>
                {email} {isOwner && <b>(Owner)</b>}
              </div>

              <ul>
                {activities.map(({ version, title, type, history, createdAt, createdBy }, j) => (
                  <li className="party__activity" key={j}>
                    <div className="flex">
                      <span className="version">{version || '0.0'}</span>
                      {title || '-- none --'}
                      <time>{timeDistance(createdAt)}</time>
                      <div className="party__activity__user">
                        <Avatar user={createdBy.user} />
                      </div>
                    </div>

                    {history && (
                      <div className={cn('party__history', history.party, 'col-' + key, { latest: history.latest })}>
                        <h4>{history._key}</h4>
                        {history.stats && (
                          <small>
                            {/* {history.stats.matched} / {history.stats.completed} / {history.stats.total} */}
                            {history.stats && (
                              <ProgressLine
                                done={history.stats.matched}
                                total={history.stats.total}
                                completed={history.stats.completed}
                                className="dark small"
                              />
                            )}
                          </small>
                        )}

                        <div className={cn('position', history.party)}>{history.party}</div>
                        {type === 'receivedFromCounterparty' && (
                          <button className="btn small" onClick={() => adminApi.revert(_key, { createdAt })}>
                            Revert
                          </button>
                        )}
                        <time>{history.updatedAt}</time>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      )
    )
  }
}

export default observer(History)
