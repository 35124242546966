const Icon = (props) => (
  <svg width="80px" height="80px" viewBox="0 0 512 512" fill="currentColor" {...props}>
    <g>
      <path d="M423.316 412.697H382.608V371.988C382.608 367.421 378.906 363.718 374.338 363.718C369.77 363.718 366.068 367.421 366.068 371.988V412.697H325.36C320.791 412.697 317.09 416.4 317.09 420.967C317.09 425.534 320.792 429.237 325.36 429.237H366.068V469.945C366.068 474.512 369.771 478.215 374.338 478.215C378.907 478.215 382.608 474.512 382.608 469.945V429.237H423.316C427.885 429.237 431.586 425.534 431.586 420.967C431.586 416.4 427.884 412.697 423.316 412.697Z" />
      <path d="M453.192 375.503C450.907 371.548 445.847 370.195 441.894 372.479C437.939 374.764 436.586 379.823 438.87 383.777C445.386 395.056 448.83 407.915 448.83 420.967C448.83 462.042 415.413 495.459 374.338 495.459C352.579 495.459 332.973 486.079 319.341 471.154C319.285 471.092 319.226 471.032 319.17 470.97C306.836 457.398 299.846 439.307 299.846 420.967C299.846 379.892 333.263 346.475 374.338 346.475C390.439 346.475 406.234 351.743 419.096 361.422C422.747 364.169 427.932 363.439 430.678 359.79C433.426 356.141 432.695 350.956 429.047 348.208C420.676 341.906 411.457 337.187 401.696 334.118V77.525C401.696 75.332 400.825 73.228 399.274 71.677L330.02 2.423C328.468 0.871 326.364 0 324.171 0H54.899C50.33 0 46.629 3.703 46.629 8.27V55.767C46.629 60.334 50.331 64.037 54.899 64.037C59.467 64.037 63.169 60.334 63.169 55.767V16.541H239.988V133.758C239.988 137.754 242.823 141.089 246.591 141.861C247.13 141.971 247.688 142.03 248.259 142.03H385.154C385.154 142.03 385.154 330.57 385.154 330.571C385.134 330.05 375.208 329.936 374.336 329.936C324.14 329.936 283.304 370.773 283.304 420.968C283.304 435.449 286.853 449.972 293.531 462.818C295.457 466.524 297.631 470.115 300.041 473.528H63.169V88.849C63.169 84.282 59.467 80.579 54.899 80.579C50.331 80.579 46.629 84.282 46.629 88.849V481.798C46.629 486.365 50.331 490.068 54.899 490.068H315.177C331.102 503.722 351.766 511.999 374.339 511.999C424.533 512 465.37 471.162 465.37 420.968C465.37 405.015 461.159 389.292 453.192 375.503ZM385.154 125.488H256.529V16.541H320.744L385.153 80.952V125.488H385.154Z" />
      <path d="M268.847 398.498C268.847 394.356 265.491 391 261.349 391H106.443C96.5149 391.344 96.5229 405.656 106.443 405.996H261.349C265.491 405.996 268.847 402.64 268.847 398.498Z" />
      <path d="M268.847 161.498C268.847 157.356 265.491 154 261.349 154H106.443C96.5149 154.344 96.5229 168.656 106.443 168.996H261.349C265.491 168.996 268.847 165.64 268.847 161.498Z" />
      <path d="M341.536 205H106.443C96.5149 205.344 96.5229 219.656 106.443 219.996H341.536C351.464 219.653 351.457 205.34 341.536 205Z" />
      <path d="M341.536 256H106.443C96.5149 256.344 96.5229 270.656 106.443 270.996H341.536C351.464 270.653 351.457 256.34 341.536 256Z" />
      <path d="M341.536 307H106.443C96.5149 307.344 96.5229 321.656 106.443 321.996H341.536C351.464 321.653 351.457 307.34 341.536 307Z" />
    </g>
  </svg>
)

export default Icon
