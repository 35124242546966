import { lazy } from 'react'

const EmailManager = lazy(() => import('./EmailManager'))
const EmailEdit = lazy(() => import('./Edit/Edit'))

const routes = {
  path: '/admin/email-manager',
  label: 'E-mail manager',
  roles: [ 'admin' ],
  showWhen: (user) => user.role.includes('admin'),
  Component: EmailManager,
  // Wrapper: ({ children }) => children,
  children: [
    {
      path: '/admin/email-manager/:id',
      Component: EmailEdit,
    },
  ],
}

export default routes
