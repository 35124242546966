import { lazy } from 'react'

const Editor = lazy(() => import('./Editor/Editor'))
const Editor2 = lazy(() => import('./Editor/Editor2'))
const Templates = lazy(() => import('./Templates/Templates'))
const Preview = lazy(() => import('./Preview/Preview'))
const Styles = lazy(() => import('./Styles/Styles'))

const routes = {
  path: '/editor/templates',
  label: 'Template Editor',
  roles: [ 'admin' ],
  Component: Templates,
  children: [
    {
      path: '/editor/document/:schemaId',
      label: 'Editor',
      Component: Editor,
    },
    {
      path: '/editor/published/:docId',
      label: 'Editor',
      Component: Editor2,
    },
    {
      path: '/editor/document/:schemaId/tpl/:id',
      label: 'Editor',
      Component: Editor,
    },
    {
      path: '/editor/templates/:id/preview',
      label: 'Preview',
      Component: Preview,
    },
    {
      path: '/editor/styles',
      label: 'Styles',
      Component: Styles,
    },
  ],
}

export default routes
