import React, { useState } from 'react'
import cn from 'classnames'

import UpIcon from 'common/Icons/Up'

import './Signature.style.scss'

const styles = [ 'Unicode', 'Dance', 'Lee', 'Arch', 'Graf', 'Ciao', 'Be' , 'Nothing']

const SignatureStyle = ({ displayName, value, onChange }) => {
  const [ open, setOpen ] = useState()

  return (
    <div className="signature-styles">
      <div className={cn('signature-styles__selected', value)} onClick={() => setOpen(!open)}>
        {displayName || 'Full Name'}

        <div className="signature-styles__icon">
          <UpIcon />
        </div>
      </div>
      <ul className={cn('signature-styles__list', { open })}>
        {styles.map((item, key) => (
          <li
            key={key}
            onClick={() => {
              setOpen(false)
              onChange(item)
            }}
            data-id={item}
            className={cn({ active: value === item })}
          >
            <div data-id={item} className={cn(item, { empty: !displayName })}>
              {displayName || 'Full Name'}
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SignatureStyle
