import React, { useState } from 'react'
import { observer } from 'mobx-react'

import _d from 'dictionary'

import ConfirmButton from 'common/ConfirmButton/ConfirmButton'
import SignIcon from 'common/Icons/Sign'
import SmartIdIcon from 'common/Icons/SmartId'

import SignatureStyles from '../../Signature.style'
import SmartIdRegister from './SmartId.register'

import SignPending from './Sign.pending'

import authApi from 'api/auth'
import negotiationsApi from 'api/negotiations'

import './SmartId.scss'

const SignatureActiveOnline = ({ meta, item, data = {} }) => {
  const { user } = authApi
  const { model } = meta
  const { positionLabel, behalfLabel, behalf } = item
  const { smartId } = user
  const { firstName, lastName } = smartId || {}

  const [ style, setStyle ] = useState('Unicode')
  const [ result, setResult ] = useState()

  const displayName = [ firstName, lastName ].join(' ')

  const behalfTitle = [ positionLabel, behalfLabel || behalf ].filter(Boolean).join(' as ')

  const negId = data._key || model.rootData._key

  const onSign = async () => {
    setResult({ started: true, state: 'started' })
    const res = await negotiationsApi.signWSmartId(negId, { style, displayName, signBehalf: behalf })

    setResult(res)
  }

  if (!smartId) return <SmartIdRegister />

  if (result) return <SignPending {...item} style={style} displayName={displayName} tryAgain={() => setResult(null)} />

  return (
    <article>
      <h4 onDoubleClick={() => console.log(result)}>{_d('Choose signature style')}</h4>
      <SignatureStyles displayName={displayName} value={style} onChange={(item) => setStyle(item)} />

      <div className="my-signature__actions">
        <div className="--label --smart-id">
          Sign with <SmartIdIcon /> {behalf ? _d(' on behalf ') + (behalfLabel || behalf) : ''}
        </div>

        <ConfirmButton
          label={_d('Sign')}
          confirm={_d(`Sign behalf|${behalfTitle}`)}
          icon={<SignIcon />}
          data-id="sign-for-party"
          disabled={!style || !displayName}
          onClick={onSign}
        />
      </div>
    </article>
  )
}

export default observer(SignatureActiveOnline)
