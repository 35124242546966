import axios from 'axios'
import FileSaver from 'file-saver'

const api = {
  //-----------------------------------------------------------------------
  //  Artifacts
  rawDocx: (data) => axios.post('/artifacts/rawDocx', data),
  patchDocx: (data) => axios.post('/artifacts/patchDocx', data),
  toHtml: (data) => axios.post('/artifacts/toHtml', data),
  getHtml: (data) => axios.post('/artifacts/getHtml', data),

  //-----------------------------------------------------------------------
  //  Get documents
  getDocuments: () => axios.get('/editor/documents'),
  addPermission: (id, data) => axios.post(`/editor/documents/${id}/allow`, data),

  updatePermission: (id, { role }) => axios.put(`/editor/documents/permission/${id}`, { role }),
  removePermission: (id, data) => axios.delete(`/editor/documents/permission/${id}`, data),

  deletePublished: (id) => axios.delete(`/editor/documents/${id}`),
  // updatePublished: (id, body) => axios.put(`/editor/published/${id}`, body),

  //-----------------------------------------------------------------------
  //  Schema
  getSchemas: () => axios.get('/schema'),
  getOneSchema: (id) => axios.get(`/schema/${id}`),
  createSchema: (data) => {
    if (typeof data.children === 'string') {
      try {
        data.children = JSON.parse(data.children)
      } catch (e) {
        data.children = []
      }
    }
    return axios.post(`/schema`, data)
  },
  updateSchema: (id, data) => axios.put(`/schema/${id}`, data),
  publish: (id, data = {}) => axios.post(`/schema/${id}/publish`, data),
  exportSchema: async (schema) => {
    const res = await axios.get(`/schema/${schema._key}/export`)
    const blob = new Blob([JSON.stringify(res, 2, 2)], { type: 'application/json;charset=utf-8' })
    FileSaver.saveAs(blob, schema.label + '-v' + schema.version + '.json')
  },
  importSchema: (data) => axios.post('/schema/import', data),
  deleteSchema: ({ _key }) => axios.delete(`/schema/${_key}`),

  updateSummary: (id, data) => axios.put(`/schema/${id}/summary`, data),

  //-----------------------------------------------------------------------
  //  Templates
  saveTemplate: (data) => axios.post('/tpl', data),
  updateTemplate: ({ _id }, data) => {
    const [table, id] = _id.split('/')

    if (table === 'Templates') {
      return axios.put(`/editor/published/tpl/${id}`, data)
    } else {
      return axios.put(`/tpl/${id}`, data)
    }
  },
  getTplHistory: ({ _key }) => axios.get(`/tpl/history/${_key}`),

  //-----------------------------------------------------------------------
  //  Published
  getPublished: (id) => axios.get(`/editor/published/${id}`),
  updatePublished: (id, data) => axios.put(`/editor/published/${id}`, data),
  updateMainDoc: (id, data) => axios.put(`/editor/main-doc/${id}`, data),
  //-----------------------------------------------------------------------
  //  Styles
  getStyles: () => axios.get('/editor/styles'),
  updateStyles: (id, data) => axios.put('/editor/styles/' + id, data),
  deleteStyles: (id) => axios.delete('/editor/styles/' + id),

  //-----------------------------------------------------------------------
  //  Print
  print: (data, type = 'pdf') => {
    return axios({
      method: 'POST',
      url: `/print/${type}`,
      data,
      responseType: 'blob',
    })
  },
}

export default api
