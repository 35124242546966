import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import cn from 'classnames'

import auth from 'api/auth'
import app from 'api/app'
import ShieldIcon from 'common/Icons/ShieldAccount'

import './Admin.scss'

class Admin extends React.Component {
  render() {
    const { user } = auth
    const { currentPage } = app

    const { children, tabs = [] } = this.props

    const list = tabs.filter((item) => (!item.showWhen || item.showWhen(user)) && item.label)

    return (
      <div className="admin">
        <aside className="admin__nav">
          <h1>
            <ShieldIcon /> Admin
          </h1>
          <ul>
            {list.map((item, key) => (
              <li
                key={key}
                className={cn('admin__nav__item', {
                  active: currentPage && (item === currentPage || item === currentPage.$parent),
                })}
              >
                <Link to={item.path}>{item.label}</Link>
              </li>
            ))}
          </ul>
          <div className="admin__nav__footer" />
        </aside>
        <div className="layout__content">{children}</div>
      </div>
    )
  }
}

export default observer(Admin)
