import React from 'react'

import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import SignIcon from 'common/Icons/Sign'

import negotiations from 'api/negotiations'

const config = {
  className: 'success',
  icon: <SignIcon />,
  header: 'Negotiation signature',
  description: [
    'Agree and start the execution process.',
    'After confirmation, the contract becomes read only for all parties and ready to be signed.',
  ],
  cp: {
    Content: () => {
      return <div />
    },
  },
  Action: ({ negotiation, model, settings }) => (
    <div className="party-line__center">
      <div className="party-line__btn --right">
        <button className="btn link" onClick={() => (settings.confirmPage = null)}>
          Close
        </button>
      </div>

      <div className="party-line__btn">
        <ConfirmButton
          confirm="I agree and sign"
          data-id="signAgreement"
          onClick={async () => {
            const { signature } = model

            delete signature.body
            delete signature.time
            delete signature.createdBy
            await negotiations.sign(negotiation._key, signature)
            settings.confirmPage = null
          }}
          icon={<SignIcon />}
        >
          Confirm
        </ConfirmButton>
      </div>
    </div>
  ),
}

export default config
