import React from 'react'

import DocumentIcon from 'common/Icons/DocumentIcon'

import './DocumentLogo.scss'

class DocumentLogo extends React.Component {
  render() {
    const { negotiation } = this.props
    const { mainDoc, myParty } = negotiation
    return (
      <div className="doc-logo">
        <div className="doc-logo__label">
          {mainDoc.label}
          <div className="desc">{mainDoc.category}</div>
          <div className="desc">Version: {myParty.version}</div>
        </div>
        <div>
          <DocumentIcon category={myParty.version} />
        </div>
      </div>
    )
  }
}

export default DocumentLogo
