import React, { useState } from 'react'
import cn from 'classnames'

import { timeDistance } from 'common/helper/date'
import Avatar from 'common/UserCard/Avatar'
import ProgressLine from 'common/ProgressLine/Progress.line'

import TimeIcon from 'common/Icons/Time'
import InIcon from 'common/Icons/In2'
import OutIcon from 'common/Icons/Out2'
import SaveIcon from 'common/Icons/Save'
import AddUserIcon from 'common/Icons/AddUser'
import DraftIcon from 'common/Icons/Draft'
import InvitationIcon from 'common/Icons/Invitations'
import DoneIcon from 'common/Icons/Tick'
import Done2Icon from 'common/Icons/Done2'
import SignIcon from 'common/Icons/Signature'
import StamperIcon from 'common/Icons/Stamper'
import UnlockIcon from 'common/Icons/Unlock'
import EyeIcon from 'common/Icons/Eye'
import SettingsIcon from 'common/Icons/Settings'

import ActivityExpanded from './Activities.expand'

import negotiationsApi from 'api/negotiations'

import './Activities.scss'

const icons = {
  sendToCounterparty: <OutIcon />,
  receivedFromCounterparty: <InIcon />,
  save: <SaveIcon />,
  addCp: <AddUserIcon />,
  updatedCp: <AddUserIcon />,
  draft: <DraftIcon />,
  inviting: <InvitationIcon />,
  accept: <DoneIcon />,
  executed: <SignIcon />,
  execution: <UnlockIcon />,
  completed: <StamperIcon />,
  agreed: <Done2Icon />,
  visited: <EyeIcon />,
  allowToSign: <SignIcon />,
  denyToSign: <SignIcon />,
  permissionsChanged: <SettingsIcon />,
  addAlias: <AddUserIcon />,
}

const showVersion = ({ type, version }) =>
  [ 'save', 'sendToCounterparty', 'receivedFromCounterparty' ].includes(type) ? version : null

const Activity = (props) => {
  const { item = {}, model } = props
  const { _key, type, position, comment, createdBy, createdAt, title, stats } = item
  const user = item.user || createdBy || { displayName: 'N/A' }
  const [ active, setActive ] = useState()
  return (
    <li
      className={cn(
        'activities__item',
        type,
        position,
        [ 'save', 'addCp', 'updatedCp', 'draft' ].includes(type) ? 'internal' : 'external',
        { comment, active }
      )}
    >
      <div
        className="activities__header"
        onClick={async () => {
          if (active) {
            return setActive(false)
          }
          const res = await negotiationsApi.getActivity(_key)
          setActive(res)
        }}
      >
        <div className="activities__user" version={showVersion(item)}>
          <Avatar user={user || createdBy} className="dark small" />
          <div>
            <div className="desc">{user.displayName}</div>
            <div>{title}</div>
          </div>
        </div>

        <div className="activities__icon">{icons[type]}</div>

        <div className="activities__title">
          <div className="desc">
            <TimeIcon /> {timeDistance(createdAt)} ago
          </div>
          {stats && (
            <div className="activities__progress">
              <ProgressLine done={stats.matched} total={stats.total} completed={stats.completed} className="in-table" />
            </div>
          )}
        </div>
      </div>
      {comment && <div className="activities__comment">{comment}</div>}
      {active && <ActivityExpanded {...active} model={model} />}
    </li>
  )
}

export default Activity
