import { formatDistance, format as fnsFormat } from 'date-fns'

export const timeDistance = (dateStr, ago) => {
  const date = new Date(dateStr)
  if (date.toString() !== 'Invalid Date') {
    return formatDistance(date, new Date()) + (ago ? ' ' + ago : '')
  }
}

export const timeDistanceBetween = (dateStr, dateStr2) => {
  const date = new Date(dateStr)
  if (date.toString() !== 'Invalid Date') {
    return formatDistance(date, new Date(dateStr2))
  }
}
export const format = (dateStr, dateFormat = 'do MMMM yyyy') => {
  const date = new Date(dateStr)
  if (date.toString() !== 'Invalid Date') {
    return fnsFormat(date, dateFormat)
  }
}

export const formatDate = format
export const formatTime = (dateStr, dateFormat = 'h:mm:ssaaaa') => format(dateStr, dateFormat)
export const formatDatetime = (dateStr) => formatDate(dateStr) && formatDate(dateStr) + ' @' + formatTime(dateStr)
