import React from 'react'

import _d from 'dictionary'
import Table from 'common/Table/Table'
import DateTime from 'common/DateTime/DateTime'

import Avatar from 'common/UserCard/Avatar'

import companyApi from 'api/company'

class ApiKeys extends React.Component {
  tableConfig = {
    header: _d('Api Keys'),
    getData: async () => {
      const { apiKeys } = await this.props.getData()
      return apiKeys
    },

    add: () => ({
      addButton: _d('New Api Key'),
      submitButton: _d('Create'),
      onSubmit: companyApi.createApiKey,
      inline: false,
      form: [
        {
          type: 'text',
          name: 'displayName',
          label: 'Name',
          // autoFocus: true,
          validate: {
            required: true,
          },
        },
        {
          type: 'checkbox',
          name: 'actions',
          label: 'Permissions',
          optional: true,
          options: [
            {
              label: _d('apiPermissions.listDocuments'),
              value: 'listDocuments',
            },
            {
              label: _d('apiPermissions.listNegotiations'),
              value: 'listNegotiations',
            },
            {
              label: _d('apiPermissions.viewNegotiation'),
              value: 'viewNegotiation',
            },
            {
              label: _d('apiPermissions.startNegotiation'),
              value: 'startNegotiation',
            },
            {
              label: _d('apiPermissions.editNegotiation'),
              value: 'editNegotiation',
            },
            {
              label: _d('apiPermissions.manageAlias'),
              value: 'manageAlias',
            },
            {
              label: _d('apiPermissions.addCounterparty'),
              value: 'addCounterparty',
            },
            {
              label: _d('apiPermissions.sendToCounterparty'),
              value: 'sendToCounterparty',
            },
            {
              label: _d('apiPermissions.getHistory'),
              value: 'getHistory',
            },
            {
              label: _d('apiPermissions.createAndExecuteViaAPIs'),
              value: 'createAndExecuteViaAPIs',
            },
          ],
        },
      ],
    }),

    actions: [
      {
        label: 'Edit',
        form: [
          {
            type: 'text',
            name: 'displayName',
            label: 'Name',
            validate: {
              required: true,
            },
          },
          {
            type: 'checkbox',
            name: 'actions',
            label: 'Permissions',
            // optional: true,
            options: [
              {
                label: _d('apiPermissions.listDocuments'),
                value: 'listDocuments',
              },
              {
                label: _d('apiPermissions.listNegotiations'),
                value: 'listNegotiations',
              },
              {
                label: _d('apiPermissions.viewNegotiation'),
                value: 'viewNegotiation',
              },
              {
                label: _d('apiPermissions.startNegotiation'),
                value: 'startNegotiation',
              },
              {
                label: _d('apiPermissions.editNegotiation'),
                value: 'editNegotiation',
              },
              {
                label: _d('apiPermissions.manageAlias'),
                value: 'manageAlias',
              },
              {
                label: _d('apiPermissions.addCounterparty'),
                value: 'addCounterparty',
              },
              {
                label: _d('apiPermissions.sendToCounterparty'),
                value: 'sendToCounterparty',
              },
              {
                label: _d('apiPermissions.getHistory'),
                value: 'getHistory',
              },
              {
                label: _d('apiPermissions.createAndExecuteViaAPIs'),
                value: 'createAndExecuteViaAPIs',
              },
            ],
          },
        ],
        onSubmit: ({ _key, displayName, actions }) => companyApi.updateApiKey(_key, { displayName, actions }),
      },
    ],
    columns: [
      {
        width: 50,
        content: (item) => <Avatar user={item} className="medium" />,
      },
      {
        header: 'User',
        content: ({ displayName, email, apiKey }) => (
          <div>
            <div>{displayName}</div>
            <div className="desc">
              {email}.{apiKey}
            </div>
          </div>
        ),
      },

      {
        header: _d('Permissions'),
        content: ({ actions }) =>
          actions &&
          actions.map((item) => (
            <div className="perm-view" key={item}>
              {_d('apiPermissions.' + item)}
            </div>
          )),
      },
      {
        header: _d('Added at'),
        align: 'center',
        width: 150,
        content: ({ createdAt }) => <DateTime date={createdAt} />,
      },
    ],
  }

  render() {
    return <Table config={this.tableConfig} />
  }
}

export default ApiKeys
