import { lazy } from 'react'

import app from 'api/app'
import DbRelations from './DbRelations/DbRelations'

const Development = lazy(() => import('./Development'))
const Users = lazy(() => import('./Users/Users'))
const StyleGuide = lazy(() => import('./StyleGuide/StyleGuide'))
const Icons = lazy(() => import('common/Icons'))
const Testing = lazy(() => import('./Testing/Testing'))

const routes = app.isDev && {
  path: '/dev',
  roles: ['user', 'admin'],
  Wrapper: Development,
  children: [
    {
      path: '/dev',
      label: 'Icons',
      Component: Icons,
    },
    {
      path: '/dev/style-guide',
      label: 'Style guide',
      Component: StyleGuide,
    },
    {
      path: '/dev/db-relations',
      label: 'DB',
      Component: DbRelations,
    },
    {
      path: '/dev/list',
      label: 'List',
      Component: Users,
    },
    {
      path: '/dev/testing',
      label: 'Testing',
      Component: Testing,
    },
  ],
}

export default routes
