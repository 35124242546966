const value = {
  name: 'value',

  render(el, model) {
    const obj = el && el.$obj

    const { value } = obj.attributes
    const whenValue = model.getValue(obj, 'value')

    obj.whenName = value
    obj.whenValue = whenValue
  },
}

export default value
