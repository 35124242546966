import React from 'react'
import { observer } from 'mobx-react'
import { decorate, observable } from 'mobx'
import { Link } from 'react-router-dom'

import app from 'api/app'

import { timeDistance } from 'common/helper/date'
import PreLoad from 'common/PreLoad/PreLoad'
import DropDown from 'common/DropDown/DropDown'

import BackIcon from 'common/Icons/ArrowLeft'

import ProseMirror from 'common/ProseMirror/ProseMirror'

import Aside from './Aside/Aside'
import AsideTabs from './Aside/Aside.tabs'
import JsonEditor from './JsonEditor/JsonEditor'

import documents from 'api/documents'

import draftJson from './draft.json'

import './Editor.scss'
import './IconFonts.scss'

class Edit extends React.Component {
  constructor(props) {
    super(props)
    app.header = 'relative'
    this.preLoad = new PreLoad(this.getData, '/documents')
    this.preLoad.thenFn = this.parseData
  }

  componentWillUnmount() {
    app.header = 'sticky'
  }

  getData = () => {
    const { id } = this.props.match.params

    return id ? documents.getOne(id) : this.getDraft()
  }

  getDraft = () => {
    const jsonStr = sessionStorage.getItem('documentJSON')

    try {
      const { label, category, lang, schema, pageStyle, version, _id, _key, tpls } = JSON.parse(jsonStr)

      const draft = JSON.parse(JSON.stringify(draftJson))

      const [tpl] = draft.tpls

      Object.assign(tpl, tpls[0])

      if (label) {
        const { mainDoc } = draft
        mainDoc.category = category
        mainDoc.lang = lang
        mainDoc.label = label
      }

      if (schema) {
        const { mainDoc } = draft

        mainDoc._id = _id
        mainDoc._key = _key

        draft.schema = schema
        draft.version = version
        tpl.pageStyle = pageStyle
      }

      return this.parseData({ ...draft })
    } catch (e) {
      console.error('Failed...', 'Wrong JSON format!', { jsonStr })
      sessionStorage.removeItem('documentJSON')
      app.modal = {
        header: 'Import failed !',
        text: 'JSON file was incorrect',
      }
    }
  }

  parseData = (doc) => {
    const { schema = {}, tpls, mainDoc } = doc

    this.data = doc
    this.mainDoc = mainDoc
    this.schema = schema

    this.model = new JsonEditor(schema)
    this.model.prose = this.prose

    this.tpl = { ...tpls[0] }
    this.tpl.pageStyle = this.tpl.pageStyle || { fontFamily: 'Arial', lineHeight: '1.2' }

    decorate(this.tpl, {
      pageStyle: observable,
    })

    return doc
  }

  getProse = (prose) => {
    this.prose = prose
    this.model.prose = prose
  }

  getPageStyle = () => {
    const { pageStyle } = this.tpl

    if (pageStyle) {
      const { fontFamily, lineHeight } = pageStyle
      return `.doc-style { 
        font-family: ${fontFamily}; 
        line-height: ${lineHeight}; 
      }`
    }
  }

  render() {
    const { loading, updating, settings, result } = this.preLoad
    const { tpl, mainDoc, data } = this

    return (
      loading || (
        <div>
          {updating}
          <div className="document-edit">
            <div className="document-edit__header__bg" />
            <div className="document-edit__header --a">
              <Link to="/documents">
                <BackIcon /> Back
              </Link>
            </div>

            <div className="document-edit__header --b">
              <h2>{mainDoc.label}</h2>
              <div>
                {tpl._key ? (
                  <div className="desc">ID: {tpl._key}</div>
                ) : (
                  <div className="document-edit__label --draft">Draft</div>
                )}
              </div>
            </div>

            <div className="document-edit__header --c">
              <AsideTabs {...this} settings={settings} />
            </div>

            <div className="document-edit__left">
              <div className="document-edit__left__content">
                <div className="document-edit__box">
                  <header>
                    <b>{data.version}</b>
                    <div>
                      <h1>{mainDoc.label}</h1>
                      <small>{mainDoc.category}</small>
                    </div>
                  </header>
                </div>

                {result.history && (
                  <div className="history">
                    <h2>History</h2>
                    <DropDown
                      value={data._key}
                      options={result.history.map((item) => ({
                        value: item._key,
                        label: (
                          <div>
                            <div className="history__version">
                              {item.version}{' '}
                              {/* {!!(item.totalNegotiations + item.totalPresets) && (
                              <i>
                                ({item.totalNegotiations}/{item.totalPresets})
                              </i>
                            )} */}
                            </div>
                            <time dateTime={item.createdAt}>{timeDistance(item.createdAt, 'ago')}</time>
                          </div>
                        ),
                      }))}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className="document-edit__content">
              {tpl.pageStyle && <style dangerouslySetInnerHTML={{ __html: this.getPageStyle() }} />}
              {tpl.style && <style dangerouslySetInnerHTML={{ __html: tpl.style.body }} />}

              <div className="doc-style">
                <ProseMirror value={tpl.body} model={this.model} getProse={this.getProse} isEditor />
              </div>
            </div>
            <Aside {...this} settings={settings} />
          </div>
        </div>
      )
    )
  }
}

export default observer(Edit)
