import { observer } from 'mobx-react'
import cn from 'classnames'

import TextIcon from 'common/Icons/TextIcon'
import TextAreaIcon from 'common/Icons/Text'
import NumbersIcon from 'common/Icons/Numbers'
import RadioIcon from 'common/Icons/RadioIcon'
import CheckboxIcon from 'common/Icons/CheckboxIcon'
import SelectIcon from 'common/Icons/DropDownIcon'
import CalendarIcon from 'common/Icons/Calendar'
import InfinityIcon from 'common/Icons/Infinity'

import CloseIcon from 'common/Icons/Close'

import AddIcon from 'common/Icons/Plus'

const update = (props, newAttrs = {}) => {
  const { editorView, settings } = props
  const { state } = editorView.props
  const { selection } = state
  const { node } = settings

  if (node.attrs) {
    const transaction = state.tr.setNodeMarkup(selection.from, undefined, { ...node.attrs, ...newAttrs })
    editorView.dispatch(transaction)
  }
}

const Condotion = (props) => {
  const { model, editorView, settings } = props
  const { children, items } = model
  const { node, isAtBottom, openTags } = settings
  const { attrs = {} } = node || {}
  const { drWhen, drValue } = attrs

  let selected
  let style

  if (drValue) {
    selected = items[drValue]
    if (selected) {
      style = `[data-name="${selected.fullName}"] .fel__content {background: var(--darker); color: white;}`
    }
  }

  return [
    selected && (
      <li
        className="inline-menu__item remove"
        key="if-remove"
        onClick={() => update(props, { drWhen: null, drValue: null })}
      >
        <CloseIcon />
      </li>
    ),
    <li key="if-tag" className={cn('inline-menu__item nested conditions', { active: settings.openTags, selected })}>
      <style dangerouslySetInnerHTML={{ __html: style }} />
      <div
        className="nested__label --if"
        onClick={(e) => {
          e.preventDefault()
          editorView.focus()
          settings.openTags = !openTags
          settings.openSettings = false
        }}
      >
        <b>IF: </b>
        {selected && selected.label}
      </div>
      <div className={cn('nested__dropdown', { isAtBottom })}>
        <div className={cn('nested__tabs', { active: openTags, selected })}>
          <div className="tab" onClick={() => (settings.openTags = !openTags)}>
            {drWhen || '... Select ...'}
          </div>
        </div>

        {openTags && (
          <div className="nested__content">
            <Nested {...props} selected={selected} children={children} />
          </div>
        )}
      </div>
    </li>,
  ]
}

const icons = {
  text: <TextIcon />,
  textarea: <TextAreaIcon />,
  numbers: <NumbersIcon />,
  editor: <TextAreaIcon />,
  radio: <RadioIcon />,
  checkbox: <CheckboxIcon />,
  select: <SelectIcon />,
  datePicker: <CalendarIcon />,
  array: <InfinityIcon />,
}

const Nested = (props) => {
  const { children, selected = {}, actions } = props

  return (
    <ul>
      {children
        .filter((item) => ['group', 'section', 'columns', 'radio', 'select', 'checkbox'].includes(item.type))
        .map((item, key) => {
          const { type, fullName, label, children, options, drValue } = item
          return (
            <li key={key} className={cn('--' + type, { active: fullName === selected.fullName })}>
              {label && (
                <div className="label">
                  {icons[type]} {label}
                </div>
              )}

              {options && (
                <ul className="nested__options">
                  {options.map((opt, i) => (
                    <li
                      className={cn({ selected: opt.value === drValue })}
                      key={i}
                      onClick={() => update(props, { drValue: fullName, drWhen: opt.value })}
                    >
                      {opt.label}
                    </li>
                  ))}
                </ul>
              )}

              {children && <Nested {...props} children={children} />}

              {['group', 'section'].includes(type) && (
                <div className="nested__add-new">
                  <button
                    className="btn add-new"
                    onClick={() => {
                      const el = item.append({ type: 'radio', label })
                      actions.replaceSelection(el)
                    }}
                  >
                    <AddIcon /> New
                  </button>
                </div>
              )}
            </li>
          )
        })}
    </ul>
  )
}

export default observer(Condotion)
