import React from 'react'
import { observer } from 'mobx-react'

import cn from 'classnames'

import './DropDown.scss'

class DropDown extends React.Component {
  state = {}

  onOpen = () => {
    const { disabled, onOpen = (f) => f, onClose = (f) => f } = this.props
    const { open } = this.state

    if (disabled) return

    open ? onClose() : onOpen()

    this.setState({ open: !open })
  }

  render() {
    const { open } = this.state

    const { label, icon, children, disabled, total, onClose } = this.props

    return (
      <div data-id={this.props['data-id']} className={cn('dd', { open })} onClick={this.onOpen}>
        <button className="toolbar__btn" disabled={disabled} total={total}>
          {icon} {label}
        </button>

        {open && (
          <div className="dd__box" onClick={onClose}>
            <div onClick={(e) => e.stopPropagation()}>{children}</div>
          </div>
        )}
      </div>
    )
  }
}

export default observer(DropDown)
