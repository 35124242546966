import React from 'react'
import ProseMirrosService from './ProseMirror.service'
import cn from 'classnames'

import './ProseMirror.content.scss'

class ProseEditor extends React.Component {
  state = { show: true }
  setRef = (ref) => {
    const { onChange, value, getProse = (f) => f, model, isEditor } = this.props

    if (ref && !this.prose) {
      Promise.resolve().then(() => {
        this.prose = new ProseMirrosService(ref, { value, model, isEditor, onChange })
        this.prose.page = this
        getProse(this.prose)
      })
    }
  }

  update = () => {
    this.prose = null
    this.setState({ show: false })
    Promise.resolve().then(() => this.setState({ show: true }))
  }

  render() {
    const { className } = this.props
    const { show } = this.state

    return show && <div className={cn('rte-editor', className)} ref={this.setRef} />
  }
}

export default ProseEditor
