import React from 'react'
import { observer } from 'mobx-react'

import AssignedUsers from '../AssignUsers/AssignedUsers'
import WaitingIcon from 'common/Icons/Signature'

import '../Signature.scss'

const SignatureNoForMe = ({ item, meta }) => {
  const { fieldName, searchStr } = item
  return (
    <div data-signature={searchStr} tabIndex="0" className="form-signature active">
      <div className="form-signature__header not-for-me">
        <div className="--header">
          <div className="--icon">
            <WaitingIcon />
          </div>
          <h3>{fieldName}</h3>
        </div>

        <AssignedUsers item={item} />
      </div>
    </div>
  )
}

export default observer(SignatureNoForMe)
