import React from 'react'
import { observer } from 'mobx-react'

import _d from 'dictionary'

import app from 'api/app'

import CloseIcon from 'common/Icons/Close'
import InfoIcon from 'common/Icons/Info'
import Loader from 'common/Icons/loadingBar4/LoadingBar'

import './Modal.scss'

class Modal extends React.Component {
  render() {
    const { modal } = app

    const { header, content, actions, text, icon, pre, list, loading, bgClick = true, close = true } = modal || {}
    return (
      !!modal && (
        <div className="modal" onClick={() => bgClick && (app.modal = null)}>
          {loading && (
            <div className="loading">
              <Loader />
            </div>
          )}

          {!loading && (
            <div className="modal__box" onClick={(e) => e.stopPropagation()}>
              <header>
                {icon || (
                  <div className="modal__info-icon">
                    <InfoIcon />
                  </div>
                )}
                <h1>{_d(header)}</h1>
                {close && (
                  <button className="btn-close" onClick={() => (app.modal = null)}>
                    <CloseIcon />
                  </button>
                )}
              </header>
              {content && <div className="modal__content">{content(this.props)}</div>}
              {text && (
                <div className="modal__content">{[].concat(text).map((text, key) => <p key={key}>{text}</p>)}</div>
              )}
              {list && (
                <div className="modal__list">
                  <ul>{[].concat(list).map((text, key) => <li key={key}>{text}</li>)}</ul>
                </div>
              )}

              {pre && (
                <div className="modal__pre">{[].concat(pre).map((text, key) => <pre key={key}>{text}</pre>)}</div>
              )}
              {actions && (
                <footer>
                  <button className="btn">ok</button>
                </footer>
              )}
            </div>
          )}
        </div>
      )
    )
  }
}

export default observer(Modal)
