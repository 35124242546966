import React from 'react'

const DownloadFile = (props) => (
  <svg width="24px" height="24px" xmlns="http://www.w3.org/2000/svg" viewBox="2 2 90 90" {...props}>
    <defs>
      <clipPath x="0" y="0" width="100" height="100" id="lds-download-cpid-25cab8652e14a">
        <circle cx="50" cy="50" r="28" />
      </clipPath>
    </defs>
    <circle cx="50" cy="50" r="37" fill="transparent" stroke="transparent" strokeWidth="6" />
    <g clipPath="url(#lds-download-cpid-25cab8652e14a)">
      <g>
        <g transform="scale(0.5)">
          <g transform="translate(-50,-50)">
            <path
              d="M71.989,44.694V8.711c0-0.419-0.34-0.759-0.759-0.759H28.769c-0.419,0-0.759,0.34-0.759,0.759v35.983H6.069 c-0.914,0-1.405,1.075-0.807,1.766l43.931,45.22c0.425,0.491,1.188,0.491,1.613,0l43.931-45.22c0.599-0.691,0.108-1.766-0.807-1.766 H71.989z"
              fill="currentColor"
            />
          </g>
        </g>
        <animateTransform
          attributeName="transform"
          type="translate"
          calcMode="linear"
          values="50 -20;50 120"
          keyTimes="0;1"
          dur="1s"
          begin="0s"
          repeatCount="indefinite"
        />
      </g>
    </g>
  </svg>
)

export default DownloadFile
