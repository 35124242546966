import React from 'react'
import { observer } from 'mobx-react'

import DropDown from 'common/DropDown/DropDown'
import DocumentView from './Dragon.document'
import AuditTrail from './AuditTrail'

import CloseIcon from 'common/Icons/Close'

import './Dragon.preview.scss'
import './Dragon.document.scss'
// import './DocStyle1.scss'
// import './PmStyle1.scss'

class DocPreview extends React.Component {
  state = { active: this.props.tpls[0] }

  constructor(props) {
    super(props)
    this.watchFocus()
  }

  watchFocus = () => {
    this.watch = document.addEventListener('focusin', this.onFocus)
  }

  onFocus = ({ target }) => {
    const group = target.closest('[data-group-id]')
    const input = target.closest('[data-name]')
    const signature = target.closest('[data-signature]')

    let style

    if (group) {
      style = `.document-preview [dr-amend="${group.dataset.groupId}"] { box-shadow: 0 0 5px var(--dark-orange); }`
    }

    if (signature) {
      const searchStr = signature.dataset.signature
      if (searchStr) {
        const [ , name ] = searchStr.split(':')
        const [ position, behalf ] = name.split('.')
        const behalfSelector = behalf ? `[dr-behalf="${behalf}"]` : ':not([dr-behalf])'
        const selector = [
          `.document-preview [dr-signature="${position}"]${behalfSelector}`,
          `.document-preview [dr-signature="_.${position}"]${behalfSelector}`,
        ]

        // const el = document.querySelector(selector)
        // el && el.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })

        style = `${selector} { box-shadow: 0 0 5px var(--dark-orange); position: relative; }`
      }
    }

    if (input) {
      style += `
      .document-preview [dr-text="${input.dataset.name}"] { box-shadow: 0 0 5px var(--dark-orange); }
      .document-preview [dr-value="${input.dataset.name}"] { box-shadow: 0 0 5px var(--dark-orange); }
    `
    }

    this.setState({ style })
  }

  componentWillUnmount = () => {
    document.removeEventListener('focusin', this.onFocus)
  }

  onChangeTab = (item) => {
    this.setState({ activeTab: item })
  }

  render() {
    const { active, style } = this.state

    const { tpls, activeTab, label, settings } = this.props

    return (
      <div className="document-container">
        <style dangerouslySetInnerHTML={{ __html: style }} />

        {tpls.length > 1 && (
          <div>
            <DropDown
              className="outline"
              value={active}
              options={tpls.map((item) => ({ label: item.label + ' ' + item.source.version, value: item }))}
              onChange={({ value }) => this.setState({ active: value })}
            />
          </div>
        )}

        {activeTab && activeTab.content ? (
          <div className="document-preview__tab">
            <header className="neg-aside__header">
              <h2>{activeTab.label}</h2>

              <button className="btn clear" onClick={() => (settings.activeTab = 'document')}>
                <CloseIcon />
              </button>
            </header>
            {activeTab.content()}
          </div>
        ) : (
          <div className="document-preview">
            {label && <h1>{label}</h1>}

            <DocumentView {...this.props} active={active} />

            <AuditTrail {...this.props} />
          </div>
        )}
      </div>
    )
  }
}

export default observer(DocPreview)
