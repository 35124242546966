import {
  wrapItem,
  blockTypeItem,
  Dropdown,
  // DropdownSubmenu,
  joinUpItem,
  liftItem,
  // selectParentNodeItem,
  undoItem,
  redoItem,
  icons,
  MenuItem,
} from 'prosemirror-menu'

import { toggleMark, setBlockType, createParagraphNear } from 'prosemirror-commands'
import { wrapInList } from 'prosemirror-schema-list'

// Helpers to create specific types of items

function canInsert(state, nodeType) {
  let $from = state.selection.$from
  for (let d = $from.depth; d >= 0; d--) {
    let index = $from.index(d)
    if ($from.node(d).canReplaceWith(index, index, nodeType)) return true
  }
  return false
}

// function insertImageItem(nodeType) {
//   return new MenuItem({
//     title: 'Insert image',
//     label: 'Image',
//     enable(state) {
//       return canInsert(state, nodeType)
//     },
//     run(state, _, view) {
//       let { from, to } = state.selection,
//         attrs = null
//       if (state.selection instanceof NodeSelection && state.selection.node.type == nodeType)
//         attrs = state.selection.node.attrs
//       openPrompt({
//         title: 'Insert image',
//         fields: {
//           src: new TextField({ label: 'Location', required: true, value: attrs && attrs.src }),
//           title: new TextField({ label: 'Title', value: attrs && attrs.title }),
//           alt: new TextField({
//             label: 'Description',
//             value: attrs ? attrs.alt : state.doc.textBetween(from, to, ' '),
//           }),
//         },
//         callback(attrs) {
//           view.dispatch(view.state.tr.replaceSelectionWith(nodeType.createAndFill(attrs)))
//           view.focus()
//         },
//       })
//     },
//   })
// }

function cmdItem(cmd, options) {
  let passedOptions = {
    label: options.title,
    run: cmd,
  }
  for (let prop in options) passedOptions[prop] = options[prop]
  if ((!options.enable || options.enable === true) && !options.select)
    passedOptions[options.enable ? 'enable' : 'select'] = (state) => cmd(state)

  return new MenuItem(passedOptions)
}

function markActive(state, type) {
  let { from, $from, to, empty } = state.selection
  if (empty) return type.isInSet(state.storedMarks || $from.marks())
  else return state.doc.rangeHasMark(from, to, type)
}

function markItem(markType, options) {
  let passedOptions = {
    active(state) {
      return markActive(state, markType)
    },
    enable: true,
  }
  for (let prop in options) passedOptions[prop] = options[prop]
  return cmdItem(toggleMark(markType), passedOptions)
}

// function linkItem(markType) {
//   return new MenuItem({
//     title: 'Add or remove link',
//     icon: icons.link,
//     active(state) {
//       return markActive(state, markType)
//     },
//     enable(state) {
//       return !state.selection.empty
//     },
//     run(state, dispatch, view) {
//       if (markActive(state, markType)) {
//         toggleMark(markType)(state, dispatch)
//         return true
//       }
//       openPrompt({
//         title: 'Create a link',
//         fields: {
//           href: new TextField({
//             label: 'Link target',
//             required: true,
//           }),
//           title: new TextField({ label: 'Title' }),
//         },
//         callback(attrs) {
//           toggleMark(markType, attrs)(view.state, view.dispatch)
//           view.focus()
//         },
//       })
//     },
//   })
// }

function wrapListItem(nodeType, options) {
  return cmdItem(wrapInList(nodeType, options.attrs), options)
}

// :: (Schema) → Object
// Given a schema, look for default mark and node types in it and
// return an object with relevant menu items relating to those marks:
//
// **`toggleStrong`**`: MenuItem`
//   : A menu item to toggle the [strong mark](#schema-basic.StrongMark).
//
// **`toggleEm`**`: MenuItem`
//   : A menu item to toggle the [emphasis mark](#schema-basic.EmMark).
//
// **`toggleCode`**`: MenuItem`
//   : A menu item to toggle the [code font mark](#schema-basic.CodeMark).
//
// **`toggleLink`**`: MenuItem`
//   : A menu item to toggle the [link mark](#schema-basic.LinkMark).
//
// **`insertImage`**`: MenuItem`
//   : A menu item to insert an [image](#schema-basic.Image).
//
// **`wrapBulletList`**`: MenuItem`
//   : A menu item to wrap the selection in a [bullet list](#schema-list.BulletList).
//
// **`wrapOrderedList`**`: MenuItem`
//   : A menu item to wrap the selection in an [ordered list](#schema-list.OrderedList).
//
// **`wrapBlockQuote`**`: MenuItem`
//   : A menu item to wrap the selection in a [block quote](#schema-basic.BlockQuote).
//
// **`makeParagraph`**`: MenuItem`
//   : A menu item to set the current textblock to be a normal
//     [paragraph](#schema-basic.Paragraph).
//
// **`makeCodeBlock`**`: MenuItem`
//   : A menu item to set the current textblock to be a
//     [code block](#schema-basic.CodeBlock).
//
// **`makeHead[N]`**`: MenuItem`
//   : Where _N_ is 1 to 6. Menu items to set the current textblock to
//     be a [heading](#schema-basic.Heading) of level _N_.
//
// **`insertHorizontalRule`**`: MenuItem`
//   : A menu item to insert a horizontal rule.
//
// The return value also contains some prefabricated menu elements and
// menus, that you can use instead of composing your own menu from
// scratch:
//
// **`insertMenu`**`: Dropdown`
//   : A dropdown containing the `insertImage` and
//     `insertHorizontalRule` items.
//
// **`typeMenu`**`: Dropdown`
//   : A dropdown containing the items for making the current
//     textblock a paragraph, code block, or heading.
//
// **`fullMenu`**`: [[MenuElement]]`
//   : An array of arrays of menu elements for use as the full menu
//     for, for example the [menu bar](https://github.com/prosemirror/prosemirror-menu#user-content-menubar).
export default function buildMenuItems(schema) {
  let r = {},
    type
  if ((type = schema.marks.strong))
    r.toggleStrong = markItem(type, { title: 'Toggle strong style', icon: icons.strong })
  if ((type = schema.marks.em)) r.toggleEm = markItem(type, { title: 'Toggle emphasis', icon: icons.em })
  if ((type = schema.marks.underline))
    r.toggleU = markItem(type, {
      title: 'Toggle underline',
      icon: {
        width: 24,
        height: 24,
        path: 'M5,21H19V19H5V21M12,17A6,6 0 0,0 18,11V3H15.5V11A3.5,3.5 0 0,1 12,14.5A3.5,3.5 0 0,1 8.5,11V3H6V11A6,6 0 0,0 12,17Z',
      },
    })

  if ((type = schema.marks.strike))
    r.toggleS = markItem(type, {
      title: 'Toggle strikethrough',
      icon: {
        width: 24,
        height: 24,
        path: 'M3,14H21V12H3M5,4V7H10V10H14V7H19V4M10,19H14V16H10V19Z',
      },
    })
  // if ((type = schema.marks.code)) r.toggleCode = markItem(type, { title: 'Toggle code font', icon: icons.code })
  // if ((type = schema.marks.link)) r.toggleLink = linkItem(type)

  //-----------------------------------------------------------------------------------------------
  const alignItem = ({ title, path, align }) =>
    new MenuItem({
      title,
      icon: {
        width: 24,
        height: 24,
        path,
      },
      active({ selection }) {
        const { attrs } = selection.node || selection.$head.parent || { attrs: {} }
        return attrs.align === align
      },
      run(state, dispatch) {
        const { selection } = state
        const { attrs, type } = selection.node || selection.$head.parent || { attrs: {} }
        return setBlockType(type, { ...attrs, align })(state, dispatch)
      },
    })
  //-----------------------------------------------------------------------------------------------

  r.left = alignItem({
    title: 'Align left',
    path: 'M3,3H21V5H3V3M3,7H15V9H3V7M3,11H21V13H3V11M3,15H15V17H3V15M3,19H21V21H3V19Z',
    align: null,
  })

  //-----------------------------------------------------------------------------------------------
  r.center = alignItem({
    title: 'Align center',
    path: 'M3,3H21V5H3V3M7,7H17V9H7V7M3,11H21V13H3V11M7,15H17V17H7V15M3,19H21V21H3V19Z',
    align: 'center',
  })

  //-----------------------------------------------------------------------------------------------
  r.right = alignItem({
    title: 'Align right',
    path: 'M3,3H21V5H3V3M9,7H21V9H9V7M3,11H21V13H3V11M9,15H21V17H9V15M3,19H21V21H3V19Z',
    align: 'right',
  })

  r.justify = alignItem({
    title: 'Justify',
    path: 'M3,3H21V5H3V3M3,7H21V9H3V7M3,11H21V13H3V11M3,15H21V17H3V15M3,19H21V21H3V19Z',
    align: 'justify',
  })

  //-----------------------------------------------------------------------------------------------
  // if ((type = schema.nodes.image)) r.insertImage = insertImageItem(type)
  if ((type = schema.nodes.bullet_list))
    r.wrapBulletList = wrapListItem(type, {
      title: 'Wrap in bullet list',
      icon: icons.bulletList,
    })
  if ((type = schema.nodes.ordered_list))
    r.wrapOrderedList = wrapListItem(type, {
      title: 'Wrap in ordered list',
      icon: icons.orderedList,
    })
  if ((type = schema.nodes.blockquote))
    r.wrapBlockQuote = wrapItem(type, {
      title: 'Wrap in block quote',
      icon: icons.blockquote,
    })

  if ((type = schema.nodes.div))
    r.wrapDiv = wrapItem(type, {
      title: 'Wrap in block',
      // icon: icons.blockquote,
      icon: {
        width: 24,
        height: 24,
        path: 'M18 4H6c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H6V6h12v12z',
      },
    })

  if ((type = schema.nodes.paragraph))
    r.makeParagraph = blockTypeItem(type, {
      title: 'Change to paragraph',
      label: 'Paragraph',
    })
  if ((type = schema.nodes.code_block))
    r.makeCodeBlock = blockTypeItem(type, {
      title: 'Change to code block',
      label: 'Code',
    })
  if ((type = schema.nodes.heading))
    for (let i = 1; i <= 10; i++)
      r['makeHead' + i] = blockTypeItem(type, {
        title: 'Change to heading ' + i,
        label: 'H ' + i,
        className: 'h-' + i,
        attrs: { level: i, align: null },
      })

  if ((type = schema.nodes.horizontal_rule)) {
    let hr = type
    r.insertHorizontalRule = new MenuItem({
      title: 'Insert horizontal rule',
      label: 'Horizontal rule',
      enable(state) {
        return canInsert(state, hr)
      },
      run(state, dispatch) {
        dispatch(state.tr.replaceSelectionWith(hr.create()))
      },
    })
  }

  if ((type = schema.nodes.page_break)) {
    const pageBreak = type
    r.insertPageBreak = new MenuItem({
      title: 'Insert page break',
      label: 'Page break',
      enable(state) {
        return canInsert(state, pageBreak)
      },
      run(state, dispatch) {
        dispatch(state.tr.replaceSelectionWith(pageBreak.create()))
      },
    })
  }

  if ((type = schema.nodes.paragraph)) {
    r.insertParagraph = new MenuItem({
      title: 'Insert new line',
      label: 'New line',
      // enable(state) {
      //   return canInsert(state, pageBreak)
      // },
      enable(state) {
        return createParagraphNear(state)
      },
      run(state, dispatch) {
        createParagraphNear(state, dispatch)
      },
    })
  }

  let cut = (arr) => arr.filter((x) => x)
  // r.insertMenu = new Dropdown(cut([r.insertImage, r.insertHorizontalRule]), {label: "Insert"})

  r.headingType =
    r.makeHead1 &&
    new Dropdown(cut([r.makeHead1, r.makeHead2, r.makeHead3, r.makeHead4, r.makeHead5, r.makeHead6, r.makeParagraph]))

  r.insert = new Dropdown(cut([r.insertPageBreak, r.insertHorizontalRule, r.insertParagraph]), {
    label: 'Insert...',
  })

  r.inlineMenu = cut([r.toggleStrong, r.toggleEm, r.toggleCode, r.toggleU, r.toggleS])
  r.blockMenu = cut([r.wrapBulletList, r.wrapOrderedList, joinUpItem, liftItem, r.wrapBlockQuote, r.wrapDiv])

  r.fullMenu = [
    [r.headingType],
    [...r.inlineMenu],
    [r.left, r.center, r.right, r.justify],
    [r.insert],
    [...r.blockMenu],
    [undoItem, redoItem],
  ]

  return r
}
