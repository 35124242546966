import { decorate, observable, toJS } from '../mobx'
import get from 'lodash.get'
import set from 'lodash.set'

import Info from './info'

import FormItemBasic from './form.item.basic'

class FormItem extends FormItemBasic {
  constructor(config, options) {
    super(config, options)

    return this.init()
  }

  settings = {}

  //---------------------------------------------------------------------------------------------------------
  init() {
    this._config = this.originalConfig
    let { config, model, originalConfig } = this
    this._id = null

    if (Array.isArray(config)) {
      return originalConfig.filter(Boolean).map((child) => new FormItem(child, this))
    }

    if (!model.isOwner && model.form) {
      if (this.isHidden || (!this.hasValue && !(this.children && this.children.length))) {
        return { show: false, hide: true }
      }
    }

    if (typeof config !== 'object' || (!config.type && !config.Component)) return

    this.setLastValue()
    this.setDefaultValue()

    if (!this.debug) {
      this.mapConditions()
    }

    this.getChildren(this.opt)

    this.checkOptions()

    this.checkAmend()

    this.watch()

    this.info = new Info(this)

    return this
  }

  //---------------------------------------------------------------------------------------------------------
  setDefaultValue() {
    this._value = toJS(get(this.model.answers, this.name))

    if (this._value === undefined && this.config.defaultValue !== undefined) {
      this.setValue(this.config.defaultValue)
      return this.config.defaultValue
    }
  }

  //---------------------------------------------------------------------------------------------------------
  setLastValue() {
    if (this.opt) {
      const { value, name, type } = this

      if (value === undefined && (name || type === 'array')) {
        this._value = this.model._lastValues[this.id]
        if (this._value) {
          set(this.model.answers, this.id, this._value)
        }
      }
    }
  }

  //---------------------------------------------------------------------------------------------------------
  watch() {
    if (!this.watching) {
      this.watching = true
      decorate(this, {
        _children: observable.ref,
        _open: observable,
        _config: observable,
        settings: observable,
        onBlur: observable,
        onFocus: observable,
        _hide: observable,
      })
    }
  }

  //---------------------------------------------------------------------------------------------------------
  mapConditions = () => {
    const { showIf, hideIf } = this.originalConfig
    if (showIf || hideIf) {
      this.model.conditions.push(this)
      this.checkConditions()
    }
  }

  checkConditions = () => {
    const { showIf, hideIf } = this.originalConfig
    let valid = true

    if (showIf) {
      valid = this.validCondition(showIf)
    }

    if (hideIf) {
      valid = !this.validCondition(hideIf)
    }

    const hide = !valid

    // skip first validation
    if (this.hide === undefined) {
      this._hide = hide
    }

    if (hide !== this.hide) {
      this.hide = hide
    }
    return valid
  }

  validCondition = (condition) => {
    const list = Object.entries(condition).map(([ name, search ]) => {
      const value = get(this.answers, name)

      const valueArr = [].concat(value)
      const valid = valueArr.find((val) => [].concat(search).includes(val)) !== undefined

      return { name, search, value, valid }
    })

    return !list.find(({ valid }) => !valid)
  }

  get hide() {
    return this._hide
  }

  set hide(hide) {
    const { _hide, value, children } = this

    if (!hide && _hide && this._lastValue !== undefined) {
      this.updateValue(this._lastValue)
    }

    if (hide && !_hide && value !== undefined) {
      this._lastValue = value
      this.updateValue()
    }

    if (children && hide !== _hide) {
      children.forEach((item) => (item.hide = hide))
    }

    this._hide = hide
  }

  //---------------------------------------------------------------------------------------------------------
  // GETTERS
  //---------------------------------------------------------------------------------------------------------

  // get config()
  // set config(value)
  // get isHidden()
  // get isVisible()
  // get isEditable()
  // get notEditable()
  // get viewOnly()
  // get myPosition()
  // get cpOnly()
  // get state()
  // get active()
  // get open()
  // get id()
  // get hasValue()
  // get isInput()
  // get name()
  // get label()
  // get description()
  // get type()
  // get options()
  // get answers()
  // get compare()
  // get onClick()
  // get data()
  // get cpData()
  // get value()
  // get viewValue()
  // get viewCpValue()
  // get myValue()
  // get cpValue()
  // get orgValue()
  // get position()

  //---------------------------------------------------------------------------------------------------------
  get configJS() {
    return toJS(this.config)
  }

  //---------------------------------------------------------------------------------------------------------
  get children() {
    return this._children || this.getChildren()
  }

  //---------------------------------------------------------------------------------------------------------
  get changed() {
    return get(this, 'stats.isChanged')
  }

  //---------------------------------------------------------------------------------------------------------
  getValues(res) {
    res = res || {}
    const { children, type, name, id } = this

    if (children && children.length && type !== 'array') {
      children.forEach((item) => item.getValues(res))
    }

    if (name || type === 'array') res[id] = this.value
    return res
  }

  //---------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------
  setOpen = (value) => {
    this._open = value
  }

  setRestriction(value) {
    const { form = {} } = this.model
    form[this.name] = value
    this.model.form = { ...form }
  }

  //---------------------------------------------------------------------------------------------------------
  getChildren(opt) {
    const { children, type } = this.originalConfig

    if (children) {
      if (type === 'array') {
        this._children = this.getArrayChildren()
      } else {
        this._children = new FormItem(children, { parent: this, model: this.model, opt })
      }
    }

    return this._children
  }

  getArrayChildren() {
    const { data = [], cpData, config } = this

    let extra = []
    if (cpData && cpData.length && cpData.length > data.length) {
      extra.length = cpData.length - data.length
      extra.fill({})
    }

    const items = [ ...[].concat(this.data).map(() => undefined), ...extra.map(() => ({ cpPosition: true })) ]

    return items.map((opt, index) => {
      const sections = {
        children: config.children,
        index,
        name: index + '',
        type: 'arrayItem',
      }

      return new FormItem(sections, { parent: this, model: this.model, opt })
    })
  }

  //---------------------------------------------------------------------------------------------------------

  setDisabled = (status) => {
    this.disabled = !!status
  }

  get validation() {
    return (this.info && this.info.self) || {}
  }

  get stats() {
    return (this.info && this.info.results) || {}
  }

  update = () => {
    const { name, model, children } = this
    if (name) {
      const value = get(model.answers, name)
      this.setValue(value)
    }

    if (children) {
      children.forEach((item) => item.update())
    }
  }

  setValue = (value) => {
    this.updateValue(value)

    this.model.checkConditions()

    this.checkOptions()

    if (this.info) {
      this.info.updateUp()
    }

    if (this.model.onChange) {
      this.model.onChange(this)
    }
  }

  //---------------------------------------------------------------------------------------------------------
  updateValue = (value) => {
    const { id, model } = this

    if (id) {
      set(model.answers, id, value)
    }
  }

  //---------------------------------------------------------------------------------------------------------
  remove() {
    const { parent, model, name, originalConfig } = this
    const index = parent.originalConfig.children.indexOf(originalConfig)

    parent.originalConfig.children.splice(index, 1)
    delete model.items[name]
    parent.init()
  }

  //---------------------------------------------------------------------------------------------------------
  setValues() {
    this._lastValue = this.value

    if (this.children) {
      this.children.forEach((item) => item.setValues())
    }

    this.updateValue()
  }

  //---------------------------------------------------------------------------------------------------------
  clearValues() {
    this.model._lastValues = { ...this.model._lastValues, ...this.getValues() }

    this.setValues()
    this.checkOptions()

    if (this.model.onChange) {
      this.model.onChange(this)
    }
  }

  //---------------------------------------------------------------------------------------------------------
  checkOptions() {
    const { options } = this.config

    const eq = (val, list) => [].concat(list).find((v) => v === val) !== undefined

    if (Array.isArray(options)) {
      const selected = options
        .filter((item) => item)
        .filter(({ value, children }) => children && (eq(value, this.value) || eq(value, this.cpValue)))

      const inactive = this.children && this.children.filter(({ opt = {} }) => !eq(opt.value, this.value))

      if (inactive) {
        inactive.forEach((item) => item.clearValues())
      }

      if (selected.length) {
        this._children = selected.map(
          ({ value, children, label }) =>
            new FormItem(
              { type: 'section', children },
              {
                parent: this,
                model: this.model,
                opt: {
                  value,
                  label,
                  cpPosition: eq(value, this.cpValue),
                  myPosition: eq(value, this.value),
                  metaValue: this.value,
                },
              }
            )
        )
      } else {
        delete this._children
      }
    }
  }

  //---------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------
  // Legacy AMEND stuff
  //---------------------------------------------------------------------------------------------------------
  amendForm = [
    {
      type: 'editor',
      label: 'Amend',
      name: '__amend',
    },
  ]

  checkAmend() {
    const { data, cpData, amendForm, model } = this

    const meHasAmend = !!(data && data.__amend)
    const cpHasAmend = !!(cpData && cpData.__amend)

    const meHasChildren = !meHasAmend
    const cpHasChildren = !!cpData && !cpData.__amend

    const childrenOpt = { myPosition: meHasChildren, cpPosition: cpHasChildren }
    const amendOpt = { myPosition: meHasAmend, cpPosition: cpHasAmend }

    if (meHasAmend || cpHasAmend) {
      this._children = []

      this.amend = new FormItem(amendForm, {
        parent: this,
        model,
        opt: amendOpt,
      })

      if (meHasChildren || cpHasChildren) {
        this._children = this.getChildren(childrenOpt)
      }
    } else {
      this.amend = false
    }
  }

  setAmend(__amend) {
    const data = { __amend }

    if (__amend) {
      this._lastValue = this.data && toJS(this.data)

      this.setValue(data)
      this.checkAmend()
    } else {
      if (this._lastValue) {
        delete this._lastValue.__amend
      }
      this.setValue(this._lastValue)
      this.getChildren()
      this.checkAmend()
    }
    this.model.getStats()
  }

  //---------------------------------------------------------------------------------------------------------
}

export default FormItem
