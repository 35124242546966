import React from 'react'

const Icon = (props) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
      <g transform="translate(3.500000, 3.000000)" fill="currentColor" fillRule="nonzero">
        <path d="M8.5,0 C3.81,0 0,3.82400735 0,8.53125 C0,13.2384926 3.81,17.0625 8.5,17.0625 L9,17.0625 L9,20.0735294 C13.86,17.7249265 17,13.0477941 17,8.53125 C17,3.82400735 13.19,0 8.5,0 Z M9.5,14.5533088 L7.5,14.5533088 L7.5,12.5459559 L9.5,12.5459559 L9.5,14.5533088 Z M9.9,9.75573529 C9.89,9.76577206 9.88,9.78584559 9.87,9.80591912 C9.82,9.88621324 9.77,9.96650735 9.73,10.0468015 C9.71,10.0769118 9.7,10.1170588 9.69,10.1572059 C9.66,10.2274632 9.63,10.2977206 9.61,10.3679779 C9.54,10.57875 9.51,10.7995588 9.51,11.0504779 L7.5,11.0504779 C7.5,10.5386029 7.58,10.1070221 7.7,9.74569853 C7.7,9.73566176 7.7,9.725625 7.71,9.71558824 C7.72,9.67544118 7.75,9.65536765 7.76,9.61522059 C7.82,9.45463235 7.89,9.31411765 7.98,9.17360294 C8.01,9.12341912 8.05,9.07323529 8.08,9.02305147 C8.11,8.98290441 8.13,8.93272059 8.16,8.90261029 L8.17,8.91264706 C9.01,7.80860294 10.38,7.46735294 10.49,6.22279412 C10.58,5.23919118 9.88,4.28569853 8.92,4.08496324 C7.88,3.86415441 6.94,4.47639706 6.62,5.36966912 C6.48,5.73099265 6.15,6.02205882 5.74,6.02205882 L5.54,6.02205882 C4.94,6.02205882 4.5,5.42988971 4.67,4.84775735 C5.22,3.02106618 7.04,1.74639706 9.1,2.0475 C10.79,2.29841912 12.14,3.69352941 12.43,5.38974265 C12.87,7.83871324 10.8,8.43088235 9.9,9.75573529 L9.9,9.75573529 Z" />
      </g>
    </g>
  </svg>
)

export default Icon
