import React from 'react'
import { observer } from 'mobx-react'

// import Load from 'common/Load/Load'
import Table from 'common/Table/Table'
import { timeDistance } from 'common/helper/date'

import InboxOne from './Inbox.one'

import messagesApi from 'api/messages'

import './Inbox.scss'

class Inbox extends React.Component {
  tableConfig = () => ({
    getData: messagesApi.getInbox,
    id: ({ message }) => message._key,
    rowClass: ({ meta }) => (meta.firstOpen ? 'read' : 'unread'),
    onClick: (item) => messagesApi.getMessage(item),
    columns: [
      {
        header: 'From',
        name: 'activity.createdBy.displayName',
      },
      {
        header: 'Subject',
        content: ({ message, meta }) => meta.subject || message.subject,
        desc: 'doc.label',
      },
      {
        header: 'Received',
        width: 170,
        content: ({ message }) => timeDistance(message.createdAt, 'ago'),
      },
      {
        header: 'E-mail',
        content: ({ meta }) => meta.status,
      },
    ],
    content: (item) => <InboxOne {...item} />,
  })

  render() {
    window.Inbox = messagesApi

    return (
      <div className="inbox">
        <div className="inbox__content">
          <div className="inbox__menu">
            <h1>Inbox</h1>
            <ul>
              <li>Inbox</li>
              <li>Sent</li>
              <li>Draft</li>
              <li>Archive</li>
            </ul>
          </div>
          <div className="inbox__mail">
            <Table config={this.tableConfig()} className="dark" />
          </div>
        </div>
      </div>
    )
  }
}

export default observer(Inbox)
