const Icon = (props) => (
  <svg width="80px" height="80px" viewBox="0 0 80 80" fill="none" {...props}>
    <path
      d="M17.5 78.75H5C2.9325 78.75 1.25 77.0675 1.25 75V62.5C1.25 60.4325 2.9325 58.75 5 58.75H17.5C19.5675 58.75 21.25 60.4325 21.25 62.5V75C21.25 77.0675 19.5675 78.75 17.5 78.75Z"
      fill="currentColor"
    />
    <path
      d="M75 50H62.5C60.4325 50 58.75 48.3175 58.75 46.25V33.75C58.75 31.6825 60.4325 30 62.5 30H75C77.0675 30 78.75 31.6825 78.75 33.75V46.25C78.75 48.3175 77.0675 50 75 50Z"
      fill="currentColor"
    />
    <path
      d="M25 21.25H12.5C10.4325 21.25 8.75 19.5675 8.75 17.5V5C8.75 2.9325 10.4325 1.25 12.5 1.25H25C27.0675 1.25 28.75 2.9325 28.75 5V17.5C28.75 19.5675 27.0675 21.25 25 21.25Z"
      fill="currentColor"
    />
    <path
      d="M39.8765 27.1601C37.3614 27.1601 34.9028 27.9059 32.8117 29.3032C30.7205 30.7005 29.0906 32.6865 28.1281 35.0101C27.1657 37.3337 26.9138 39.8905 27.4045 42.3572C27.8952 44.8239 29.1063 47.0898 30.8847 48.8682C32.6631 50.6466 34.9289 51.8577 37.3956 52.3483C39.8623 52.839 42.4192 52.5872 44.7428 51.6247C47.0664 50.6622 49.0524 49.0324 50.4497 46.9412C51.8469 44.85 52.5927 42.3914 52.5927 39.8764C52.5888 36.505 51.2477 33.2729 48.8638 30.889C46.4799 28.5051 43.2478 27.1641 39.8765 27.1601ZM44.1618 44.1618C43.9451 44.3785 43.6511 44.5002 43.3445 44.5002C43.038 44.5002 42.744 44.3785 42.5272 44.1618L39.0591 40.6937C38.8423 40.4769 38.7205 40.183 38.7204 39.8764V32.9402C38.7204 32.6336 38.8422 32.3396 39.059 32.1228C39.2758 31.906 39.5699 31.7842 39.8765 31.7842C40.183 31.7842 40.4771 31.906 40.6939 32.1228C40.9107 32.3396 41.0325 32.6336 41.0325 32.9402V39.3978L44.1618 42.5271C44.3786 42.7439 44.5003 43.0379 44.5003 43.3445C44.5003 43.651 44.3786 43.945 44.1618 44.1618Z"
      fill="currentColor"
    />
    <path
      d="M8.75039 70.7323L7.13414 69.1161L5.36664 70.8836L8.75039 74.2673L17.1341 65.8836L15.3666 64.1161L8.75039 70.7323Z"
      fill="white"
    />
    <path
      d="M16.2504 13.2323L14.6341 11.6161L12.8666 13.3836L16.2504 16.7673L24.6341 8.38359L22.8666 6.61609L16.2504 13.2323Z"
      fill="white"
    />
    <path
      d="M64.6341 40.3661L62.8666 42.1336L66.2504 45.5173L74.6341 37.1336L72.8666 35.3661L66.2504 41.9823L64.6341 40.3661Z"
      fill="white"
    />
    <path
      d="M58.4454 56.2873L59.0516 58.7123L66.1566 56.936C60.4179 65.826 50.6479 71.2498 39.9991 71.2498C35.4254 71.2498 31.0179 70.2848 26.9004 68.381L25.8516 70.6498C30.3004 72.7073 35.0604 73.7498 39.9991 73.7498C51.3229 73.7498 61.7229 68.0748 67.9579 58.7448L68.7591 65.1548L71.2391 64.8448L69.8141 53.4448L58.4454 56.2873Z"
      fill="#BDBDBD"
    />
    <path
      d="M14.9654 45.2897L12.5341 44.7085L11.0091 51.091C9.5141 47.391 8.7491 43.6722 8.7491 39.9997C8.7491 35.3872 9.73036 30.946 11.6654 26.7972L9.39911 25.7397C7.30911 30.2222 6.24911 35.0197 6.24911 39.9997C6.24911 43.9785 7.06786 47.9922 8.66786 51.9735L3.10286 48.9047L1.89536 51.0947L12.2179 56.7847L14.9654 45.2897Z"
      fill="#BDBDBD"
    />
    <path
      d="M41.9414 11.4598L37.8764 8.74984H39.9976C51.6914 8.74984 62.3176 15.1973 67.7276 25.5761L69.9439 24.4198C64.1026 13.2123 52.6276 6.24984 39.9976 6.24984H37.8764L41.9414 3.53984L40.5551 1.45984L31.4939 7.49984L40.5539 13.5398L41.9414 11.4598Z"
      fill="#BDBDBD"
    />
  </svg>
)

export default Icon
