import { lazy } from 'react'

import Admin from './Admin'

import TemplateEditorRoutes from '../TemplateEditor'
import EmailManagerRoutes from './EmailManager'
import Sse from './Sse/Sse'
import Negotiations from './Negotiations/Negotiations'
import Migrations from './Migrations/Migrations'
import UserDocuments from './UserDocuments/UserDocuments'
import Queue from './Queue/Queue'

const Users = lazy(() => import('./Users/Users'))
const FakeUsers = lazy(() => import('./FakeUsers/FakeUsers'))

const routes = {
  path: '/admin',
  roles: [ 'admin' ],
  Wrapper: Admin,
  children: [
    {
      path: '/admin/users',
      label: 'Users',
      Component: Users,
    },

    {
      path: '/admin/fake-users',
      label: 'Fake groups',
      Component: FakeUsers,
    },

    {
      path: '/admin/sse',
      label: 'SSE',
      Component: Sse,
    },
    {
      path: '/admin/queue',
      label: 'Queue',
      Component: Queue,
    },
    EmailManagerRoutes,
    TemplateEditorRoutes,
    {
      path: '/admin/negotiations',
      label: 'Negotiations',
      Component: Negotiations,
    },
    {
      path: '/admin/migrations',
      label: 'Migrations',
      Component: Migrations,
    },
    {
      path: '/admin/user-documents',
      label: 'User Documents',
      Component: UserDocuments,
    },
  ],
}

export default routes
