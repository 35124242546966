import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import DropDown from 'common/DropDown/DropDown'

import MoreIcon from 'common/Icons/More'
import DownIcon from 'common/Icons/Down'
import ProgressLine from 'common/ProgressLine'

import './Group.scss'

class GroupHeader extends React.Component {
  scroll = () => {
    const { meta: { id } } = this.props
    const el = document.querySelector(`.document-preview [dr-amend="${id}"]`)
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  count = 0

  render() {
    const { meta = {}, index, actions, open, onOpen } = this.props
    const { stats = {}, config = {}, active, label, description, model } = meta

    const { close = true, progress } = config

    const { noCompare, state } = model || {}

    const { total, totalCompleted, totalMatched, allCompleted, allMatched } = stats

    this.count++
    return (
      <div className={cn('form-group__label', { open })}>
        {close && (
          <div
            className={cn('form-group__icon', {
              allCompleted,
              allMatched: allMatched || (allCompleted && state === 'draft'),
              open,
            })}
            onClick={() => onOpen(!open)}
          >
            <DownIcon />
          </div>
        )}
        <div className={cn('form-group__title', { scroll: config.name })} onClick={this.scroll}>
          <h3>
            <div>{index + 1}.</div>
            <div>{label}</div>
            {config.state && <div className="state">{config.state}</div>}
          </h3>
          {description && <div className="desc">{description}</div>}
        </div>

        {active &&
        progress !== false &&
        !!total && (
          <div className="form-group__progress">
            <ProgressLine
              done={noCompare ? totalCompleted : totalMatched}
              total={total}
              completed={totalCompleted}
              className="small dark"
            />
          </div>
        )}
        {typeof actions === 'function' && (
          <div className="--more">
            <DropDown className="small" options={actions(meta, this.props)}>
              <MoreIcon />
            </DropDown>
          </div>
        )}
      </div>
    )
  }
}

export default observer(GroupHeader)
