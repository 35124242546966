import React from 'react'

const Icon = ({ version = '0.1', className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="32" height="42" viewBox="0 0 30.802 41.295">
    <g transform="translate(-39.041 -174.5)">
      <g transform="translate(39.541 175)">
        <path
          d="M32.865,16.628H23.241a.634.634,0,0,1-.639-.641h0a3.8,3.8,0,0,1,1.368-2.937l7.745-8.156a4.1,4.1,0,0,1,2.633-.949h0a.945.945,0,0,1,.946.949v9.3A2.432,2.432,0,0,1,32.865,16.628Z"
          transform="translate(-22.602 -3.935)"
          fill="currentColor"
          stroke="#fff"
          strokeWidth="1"
        />
        <path
          d="M19.126,11.394V3.938H33.977A3.072,3.072,0,0,1,37.114,6.92V41.25a3.072,3.072,0,0,1-3.137,2.982H10.45A3.072,3.072,0,0,1,7.313,41.25V15.588h7.4A4.313,4.313,0,0,0,19.126,11.394Z"
          transform="translate(-7.313 -3.938)"
          fill="currentColor"
          stroke="#fff"
          strokeWidth="1"
        />
      </g>
      <rect width="23" height="22" rx="2" transform="translate(43 190)" fill="#fff" />
      <text
        transform="translate(47 206)"
        fill="currentColor"
        fontSize="12"
        fontFamily="Arial-BoldMT, Arial"
        fontWeight="700"
        letterSpacing="-0.054em"
      >
        <tspan x="0" y="0">
          {version}
        </tspan>
      </text>
      <line
        x2="9.041"
        transform="translate(55.573 179.52)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2"
      />
      <line
        x2="9.041"
        transform="translate(55.573 183.539)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default Icon
