import React from 'react'

const Icon = () => (
  <svg focusable="false" height="24" width="24" viewBox="0 0 24 24" aria-hidden="true">
    <path
      fill="currentColor"
      d="M17.3 6.3a.9959.9959 0 00-1.41 0l-5.64 5.64 1.41 1.41L17.3 7.7c.38-.38.38-1.02 0-1.4zm4.24-.01l-9.88 9.88-3.48-3.47a.9959.9959 0 00-1.41 0c-.39.39-.39 1.02 0 1.41l4.18 4.18c.39.39 1.02.39 1.41 0L22.95 7.71c.39-.39.39-1.02 0-1.41h-.01c-.38-.4-1.01-.4-1.4-.01zM1.12 14.12L5.3 18.3c.39.39 1.02.39 1.41 0l.7-.7-4.88-4.9a.9959.9959 0 00-1.41 0c-.39.39-.39 1.03 0 1.42z"
    />
  </svg>
)

export default Icon
