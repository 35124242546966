import { useState } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Form from 'common/Form/Form'

import TextIcon from 'common/Icons/TextIcon'
import TextAreaIcon from 'common/Icons/Text'
import NumbersIcon from 'common/Icons/Numbers'
import RadioIcon from 'common/Icons/RadioIcon'
import CheckboxIcon from 'common/Icons/CheckboxIcon'
import SelectIcon from 'common/Icons/DropDownIcon'
import CalendarIcon from 'common/Icons/Calendar'
import InfinityIcon from 'common/Icons/Infinity'

import TagIcon from 'common/Icons/TagMultipleOutline'
import AddIcon from 'common/Icons/Plus'

import SignatureIcon from 'common/Icons/Signature'

const InlineTags = (props) => {
  const { label, model, editorView, settings } = props
  const { children, items, values } = model
  const { node, isAtBottom, openTags } = settings
  const { attrs = {} } = node || {}
  const { drText, drSignature, drBehalf } = attrs

  let selected
  let style

  if (drText) {
    selected = items[drText]
    if (selected) {
      style = `[data-name="${selected.fullName}"] .fel__content {background: var(--darker); color: white;}`
    }
  }

  if (drSignature) {
    const { positions = [] } = values
    const position = positions.find((v) => v.name === drSignature)

    if (position) {
      selected = {
        ...position,
        signatureType: drBehalf && 'behalf',
        drBehalf,
        signatureLabel: position.label + (drBehalf ? ' - ' + drBehalf : ''),
      }
    }
  }

  const activeTab = drSignature ? 'signature' : true

  return (
    <li className={cn('inline-menu__item nested', { active: settings.openTags, isSignature: drSignature })}>
      <style dangerouslySetInnerHTML={{ __html: style }} />
      <div
        className="nested__label"
        onClick={(e) => {
          e.preventDefault()
          editorView.focus()
          settings.openTags = openTags ? false : activeTab
          settings.openSettings = false
        }}
      >
        {drSignature ? <SignatureIcon /> : <TagIcon />}{' '}
        {(selected && (selected.signatureLabel || selected.label)) || label}
      </div>
      {openTags && (
        <div className={cn('nested__dropdown', { isAtBottom })}>
          <div className="nested__tabs">
            <div className={cn('tab', { active: openTags === true })} onClick={() => (settings.openTags = true)}>
              <TextIcon /> Input
            </div>
            <div
              className={cn('tab', 'signature', { active: openTags === 'signature' })}
              onClick={() => (settings.openTags = 'signature')}
            >
              <SignatureIcon /> Signature
            </div>
          </div>
          <div className="nested__content">
            {openTags === 'signature' ? (
              <Signature {...props} selected={selected} children={children} />
            ) : (
              <Nested {...props} selected={selected} children={children} />
            )}
          </div>
        </div>
      )}
    </li>
  )
}

const icons = {
  text: <TextIcon />,
  textarea: <TextAreaIcon />,
  numbers: <NumbersIcon />,
  editor: <TextAreaIcon />,
  radio: <RadioIcon />,
  checkbox: <CheckboxIcon />,
  select: <SelectIcon />,
  datePicker: <CalendarIcon />,
  array: <InfinityIcon />,
}

const Nested = (props) => {
  const { children, selected = {}, actions } = props

  return (
    <ul>
      {children.map((item, key) => (
        <li
          key={key}
          className={cn('--' + item.type, { isInput: item.isInput, active: item.fullName === selected.fullName })}
        >
          {item.label && (
            <div className="label" onClick={() => item.isInput && actions.replaceSelection(item)}>
              {icons[item.type]} {item.label}
            </div>
          )}
          {item.children && <Nested {...props} children={item.children} />}

          {[ 'group', 'section' ].includes(item.type) && (
            <div className="nested__add-new">
              <button
                className="btn add-new"
                onClick={() => {
                  const label = actions.textContent.replaceAll('[', '').replaceAll(']', '')
                  const el = item.append({ type: 'text', label })
                  actions.replaceSelection(el)
                }}
              >
                <AddIcon /> New
              </button>
            </div>
          )}
        </li>
      ))}
    </ul>
  )
}

const config = [
  {
    type: 'checkbox',
    name: 'signatureType',
    optional: true,
    options: [
      {
        label: 'Specify an additional signatory position for the party',
        value: 'behalf',
        children: [
          {
            type: 'text',
            name: 'drBehalf',
            autoFocus: true,
          },
        ],
      },
    ],
  },
  {
    type: 'actions',
    options: [
      {
        label: 'Save',
        type: 'submit',
        className: 'small',
      },
    ],
  },
]

const Signature = ({ model, actions, selected }) => {
  const { values } = model
  const { positions = [] } = values
  const [ partyA, partyB ] = positions
  const { name, drBehalf, signatureType } = selected || {}

  const active = positions.find((v) => name === v.name)
  const [ party, setParty ] = useState(active)

  const onSubmit = ({ values }) => {
    const data = {
      drSignature: party,
      drBehalf: values.drBehalf,
    }

    actions.replaceSelection(data)
  }

  return (
    <div className="nested__signature nested__form">
      {/* <div className="nested__signature__header">Signature</div> */}
      <div className="nested__signature__list">
        <div className={cn('nested__signature__item', { active: party === partyA })} onClick={() => setParty(partyA)}>
          {partyA.label || 'Party A'}
        </div>
        {partyB && (
          <div className={cn('nested__signature__item', { active: party === partyB })} onClick={() => setParty(partyB)}>
            {partyB.label || 'Party B'}
          </div>
        )}
      </div>
      {party && (
        <div className="nested__signature__properties">
          <Form config={config} values={{ drBehalf, signatureType }} onSubmit={onSubmit} />
        </div>
      )}
    </div>
  )
}

export default observer(InlineTags)
