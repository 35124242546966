import { AnalyticsBrowser } from '@segment/analytics-next'

class Segment {
  static analytics = null
  static segmentWriteKey = window.APP_CONFIG.segmentWriteKey || ''
  static isLocalhost = (window.APP_CONFIG.environment || 'localhost').includes('local')

  static async getAnalytics() {
    if (Segment.analytics == null) {
      return AnalyticsBrowser.load(
        { writeKey: Segment.segmentWriteKey, cdnURL: 'https://cdn.a.yumidocs.com' },
        {
          integrations: {
            'Segment.io': {
              apiHost: 'api.a.yumidocs.com/v1',
            },
          },
        }
      ).then((res) => {
        Segment.analytics = res[0]
        return Segment.analytics
      })
    } else return Promise.resolve(Segment.analytics)
  }

  static page(...args) {
    if (!Segment.isLocalhost) Segment.getAnalytics().then((ana) => ana.page(...args)).catch((e) => e)
  }
  static identify(...args) {
    if (!Segment.isLocalhost) Segment.getAnalytics().then((ana) => ana.identify(...args)).catch((e) => e)
  }

  static track(...args) {
    if (!Segment.isLocalhost) Segment.getAnalytics().then((ana) => ana.track(...args)).catch((e) => e)
  }
}

export default Segment
