import invitation from './invitation'
import execution from './execution'
import completed from './completed'
import rejected from './rejected'
import active from './active'
import sendToCounterparty from './sendToCounterparty'
import agree from './agree'
import sign from './sign'
import deleted from './deleted'

const config = {
  completed,
  // execution,
  execute: execution,
  inviting: invitation,
  rejected,
  active,
  agreed: active,
  sendToCounterparty,
  agree,
  sign,
  deleted,
}

export default config
