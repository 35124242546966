import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'

import negotiations from 'api/negotiations'
import app from 'api/app'

import SaveIcon from 'common/Icons/Save'
import SentIcon from 'common/Icons/Sent'

import WaitingIcon from 'common/Icons/Waiting'
import TickIcon from 'common/Icons/Tick'
import SettingsIcon from 'common/Icons/Settings'
import DocumentIcon from 'common/Icons/Document'
import LibraryIcon from 'common/Icons/Library'
import TimeIcon from 'common/Icons/Time'
import ExecuteIcon from 'common/Icons/Completed'
import SignIcon from 'common/Icons/Signature'

import _d from 'dictionary'

import DropDown from './DropDown/DropDown'

import ProgressBar from 'common/ProgressBar/ProgressBar'

import MoreTabs from './MoreTabs/MoreTabs'
import Download from './Download/Download'

import './Toolbar.scss'

class Toolbar extends React.Component {
  state = {}
  constructor(props) {
    super(props)
    app.header = 'relative'

    this.myPosition = props.negotiation.myParty.position
  }

  componentWillUnmount() {
    app.header = 'sticky'
  }

  onSave = async () => {
    const { negotiation, model, history } = this.props
    const { values, form, rootData: { myPartyMeta = {} } } = model

    const isDraft = !negotiation._key
    const body = { answers: values }
    if (form !== myPartyMeta.restrictions) {
      body.restrictions = form
    }

    if (negotiation.state === 'draft') {
      body.myPosition = negotiation.myParty.position
      this.myPosition = body.myPosition
    }

    model.copyOriginal()
    const res = await negotiations.saveAnswers(negotiation, body)
    if (isDraft) {
      history.replace(`/negotiations/${res._key}`)
    }
  }

  toggle = (tab) => {
    const { settings = {} } = this.props
    const { open } = settings

    settings.open = !open
    settings.tab = tab
  }

  open = (tab) => {
    const { settings = {} } = this.props
    settings.open = true
    settings.tab = tab
  }

  close = (tab) => {
    const { settings = {} } = this.props
    settings.open = false
    settings.tab = tab
  }

  onHighlight = (type) => {
    const { model } = this.props
    model.highlight = type
  }

  saveBtn = {
    state: {},
    label: _d('Save'),
    icon: <SaveIcon />,
    onOpen: () => this.onHighlight('changed-only'),
    onClose: () => this.onHighlight(),
    onCancel: () => {
      this.setState({ openSave: false })
      this.onHighlight()
    },
    onSave: async () => {
      await this.onSave()
      this.saveBtn.onCancel()
    },
  }

  nextStep = () => {
    const { negotiation, model, settings = {} } = this.props
    const { myParty, cpParty, state, doc } = negotiation

    const singleParty = doc.schema.positions.length === 1
    const { stats, form } = model

    const { allMatched, allCompleted, restricted = {} } = stats

    const canSend = model.isAllowed('canSend')
    const canAgree = model.isAllowed('canAgree')

    let result

    if ([ 'completed', 'rejected', 'execution' ].includes(state)) {
      return null
    }

    if (!cpParty) {
      if (singleParty) {
        return (
          <button
            className="btn round execute"
            onClick={() => {
              settings.confirmPage = 'execute'

              setTimeout(() => window.scrollTo(0, 0))
            }}
            data-id="toolbar:next"
            disabled={!allCompleted}
          >
            {_d('Execute')} <ExecuteIcon />
          </button>
        )
      }
      return null
    }

    if (cpParty.isTurn) {
      result = (
        <div className="waiting flex">
          {_d('Waiting for CP')} <WaitingIcon />
        </div>
      )
    }

    if (myParty.isTurn) {
      const { total, allCompleted } = restricted
      const disabled = form && total && !allCompleted

      result = (
        <button
          className="btn round white"
          onClick={() => {
            settings.confirmPage = 'sendToCounterparty'
            setTimeout(() => window.scrollTo(0, 0))
          }}
          data-id="toolbar:next"
          disabled={disabled || settings.confirmPage === 'sendToCounterparty' || !canSend}
        >
          {_d('Send')} <SentIcon />
        </button>
      )

      if (allMatched) {
        result = (
          <button
            className="btn round success"
            onClick={() => {
              settings.confirmPage = 'agree'

              setTimeout(() => window.scrollTo(0, 0))
            }}
            data-id="toolbar:next"
            disabled={settings.confirmPage === 'agree' || !canAgree}
          >
            {_d('Agree')} <TickIcon />
          </button>
        )
      }

      if (state === 'inviting') {
        result = null
      }

      // if (state === 'execution') {
      //   result = (
      //     <button
      //       className="btn round warning"
      //       onClick={() => {
      //         setTimeout(() => window.scrollTo(0, 0))
      //       }}
      //       data-id="toolbar:next"
      //     >
      //       {_d('Sign')} <SignIcon />
      //     </button>
      //   )
      // }
    }

    return result
  }

  render() {
    const { openSave } = this.state
    const { negotiation, model, settings = {} } = this.props
    const { open, tab, activeTab = 'document' } = settings
    const { mainDoc, myParty, myPartyMeta, state, _key } = negotiation

    const { totalChanged } = model.stats

    const { saveBtn } = this
    saveBtn.total = totalChanged
    saveBtn.disabled = !saveBtn.total

    return (
      <div className={cn('toolbar', { open })}>
        {open && <MoreTabs {...this.props} tab={tab} />}
        <div className="toolbar__main">
          <ul className="toolbar__line">
            <li className="toolbar__actions">
              {/* <div>
                <button className={cn('toolbar__btn', { open })} onClick={this.toggle} data-id="toolbar:more">
                  <Down2Icon /> {open ? _d('Close') : _d('More')}
                </button>
              </div> */}
              {myParty.isTurn &&
              [ 'draft', 'active' ].includes(state) && (
                <DropDown {...saveBtn} data-id="toolbar:save" key={!!saveBtn.total + openSave}>
                  <h3>
                    {_d('You have')} {saveBtn.total} {_d('changes')}
                  </h3>
                  <div className="dd__save">
                    <button className="btn cancel" onClick={saveBtn.onCancel} data-id="save-cancel">
                      {_d('Cancel')}
                    </button>
                    <button className="btn" onClick={saveBtn.onSave} data-id="save-answers">
                      {_d('Save')} <SaveIcon />
                    </button>
                  </div>
                </DropDown>
              )}
            </li>

            <li className="toolbar__item --border-left">
              <div className="toolbar__header">
                <div className="toolbar__title">
                  {mainDoc.label}
                  <span className="version">v{myPartyMeta.version || myParty.version}</span>
                  <span className={'label-' + state}>{_d('stateLabel.' + state)}</span>
                </div>
                <div data-id="toolbar:progress">
                  <ProgressBar model={model} negotiation={negotiation} />
                </div>
              </div>
            </li>
            <li>
              <div className="toolbar__actions  --border">
                {/* <div>
                  <button
                    className={cn('toolbar__btn', { active: activeTab === 'document' })}
                    onClick={() => (settings.activeTab = activeTab === 'document' ? null : 'document')}
                  >
                    <DocumentIcon /> {_d('Document')}
                  </button>
                </div>
                <div>
                  <button
                    className={cn('toolbar__btn', { active: activeTab === 'presets' })}
                    onClick={() => (settings.activeTab = activeTab === 'presets' ? 'document' : 'presets')}
                  >
                    <LibraryIcon /> {_d('Presets')}
                  </button>
                </div>
                {_key && (
                  <div>
                    <button
                      className={cn('toolbar__btn', { active: activeTab === 'timeline' })}
                      onClick={() => (settings.activeTab = activeTab === 'timeline' ? 'document' : 'timeline')}
                    >
                      <TimeIcon /> {_d('Timeline')}
                    </button>
                  </div>
                )}
                {_key && (
                  <div>
                    <button
                      className={cn('toolbar__btn', { active: activeTab === 'settings' })}
                      onClick={() => (settings.activeTab = activeTab === 'settings' ? 'document' : 'settings')}
                    >
                      <SettingsIcon /> {_d('Settings')}
                    </button>
                  </div>
                )} */}

                <Download label="Download" negotiation={negotiation} />
                <Download
                  label="More"
                  negotiation={negotiation}
                  actions={[
                    {
                      label: _d('Document'),
                      icon: <DocumentIcon />,
                      onClick: () => (settings.activeTab = activeTab === 'document' ? null : 'document'),
                    },
                    {
                      label: _d('Presets'),
                      icon: <LibraryIcon />,
                      onClick: () => (settings.activeTab = activeTab === 'presets' ? 'document' : 'presets'),
                    },
                    {
                      label: _d('Timeline'),
                      icon: <TimeIcon />,
                      onClick: () => (settings.activeTab = activeTab === 'timeline' ? 'document' : 'timeline'),
                      disabled: !_key,
                    },
                    {
                      label: _d('Settings'),
                      icon: <SettingsIcon />,
                      onClick: () => (settings.activeTab = activeTab === 'settings' ? 'document' : 'settings'),
                      disabled: !_key,
                    },
                  ]}
                />
              </div>
            </li>
            {/* <div className="flex">
              <Avatar user={myParty.owner} />
              <div>
                Me
                <div className="desc">{myParty.isOwner ? 'Sender' : 'Receiver'}</div>
              </div>
            </div> */}
            <li className="toolbar__cp">
              {/* <div className={cn('toolbar__cp__party', '--me', { active: tab === 'myParty' })}>
                <UserCard
                  user={myParty.owner}
                  className="blank hover"
                  email={myParty.email}
                  tagType="me"
                  data-id="toolbar:myParty"
                />
              </div>
              <div className="toolbar__cp__icon">
                <div className={cn('sender-icon', { sender: myParty.isOwner })}>
                  {myParty.isOwner ? <OutIcon /> : <InIcon />}
                </div>
              </div>
              <div className={cn('toolbar__cp__party', '--cp', { active: tab === 'counterparty' })}>
                {cpParty &&
                cpParty.owner && (
                  <UserCard
                    user={cpParty.owner}
                    className="blank hover"
                    email={cpParty.email}
                    data-id="toolbar:counterparty"
                  />
                )}
              </div> */}
              <div className="--add-cp">{this.nextStep(model.signature)}</div>
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default withRouter(observer(Toolbar))
