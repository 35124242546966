import React from 'react'

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M18.863 12.25l.004-.002-2.035-4.064a.955.955 0 0 0-.857-.532h-.386v-.378A.273.273 0 0 0 15.317 7H8.683a.273.273 0 0 0-.272.274v.378h-.386a.955.955 0 0 0-.857.532l-2.035 4.064.004.002a.962.962 0 0 0-.137.497v4.293c0 .53.426.96.95.96h12.1c.524 0 .95-.43.95-.96v-4.293a.962.962 0 0 0-.137-.497zm-3.274-3.99h.386c.136 0 .258.077.32.2l1.665 3.327h-1.132v-1.024a.273.273 0 0 0-.272-.274h-.335v-1.47a.273.273 0 0 0-.272-.275h-.36v-.483zm.696 2.777v.75h-1.432a.627.627 0 0 0-.57.372l-.391.876a.023.023 0 0 1-.021.014h-3.742a.023.023 0 0 1-.02-.014l-.392-.876a.627.627 0 0 0-.57-.372H7.715v-.75h8.57zM8.954 7.548h6.092v1.196H8.954V7.548zm6.724 1.745v1.196H8.322V9.293h7.356zm-7.972-.834a.356.356 0 0 1 .319-.198h.386v.483h-.36a.273.273 0 0 0-.272.275v1.47h-.335a.273.273 0 0 0-.272.274v1.024H6.04l1.666-3.328zm10.344 8.932H5.95a.35.35 0 0 1-.347-.351v-4.293a.35.35 0 0 1 .347-.35h3.197c.009 0 .017.004.02.013l.391.876a.627.627 0 0 0 .571.372h3.742c.246 0 .47-.146.57-.372l.391-.876a.023.023 0 0 1 .021-.014h3.197a.35.35 0 0 1 .347.35v4.294a.35.35 0 0 1-.347.35z"
      fill="currentColor"
      fillRule="nonzero"
      stroke="currentColor"
      strokeWidth=".5"
    />
  </svg>
)

export default Icon
