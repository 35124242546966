import React from 'react'

import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import TickIcon from 'common/Icons/Tick'

import negotiations from 'api/negotiations'

const config = {
  className: 'success',
  icon: <TickIcon />,
  header: 'Confirm and send to Counterparty',
  description: [
    'Agree and start the execution process.',
    'After confirmation, the contract becomes read only for all parties and ready to be signed.',
  ],
  cp: {
    Content: () => {
      return <div />
    },
  },
  Action: ({ negotiation, model, settings }) => (
    <div className="party-line__center">
      <div className="party-line__btn --right">
        <button className="btn link" onClick={() => (settings.confirmPage = null)}>
          Close
        </button>
      </div>

      <div className="party-line__btn">
        <ConfirmButton
          confirm="I agree with the current contract"
          data-id="agreeWithCP"
          onClick={async () => {
            await negotiations.agree(negotiation, { answers: model.answers })
            settings.confirmPage = null
          }}
          icon={<TickIcon />}
        >
          Confirm
        </ConfirmButton>
      </div>
    </div>
  ),
}

export default config
