import React, { useState } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import _d from 'dictionary'

import Avatar from 'common/UserCard/Avatar'

import SmartIdIcon from 'common/Icons/SmartId'
import SignatureIcon from 'common/Icons/Signature'

import AssignedUsers from '../AssignUsers/AssignedUsers'

import SignOnline from './Online/Online'
import SignOffline from './Signature.active.offline'
import SignWSmartID from './SmartId/SmartId'

import authApi from 'api/auth'
import profileApi from 'api/profile'

import Form from 'common/Form/Form'

import './Signature.active.scss'

const formConfig = [
  {
    type: 'text',
    name: 'firstName',
    label: _d('First name'),
    validate: {
      required: true,
    },
  },
  {
    type: 'text',
    name: 'lastName',
    label: _d('Last name'),
    validate: {
      required: true,
    },
  },
  {
    type: 'submit',
    label: _d('Next'),
    confirm: _d('Is your name correct?'),
    onSubmit: (values) => profileApi.update(values),
  },
]

const signTabs = [
  {
    label: _d('signatures.onlineWithYumiDocs'),
    desc: 'with YumiDocs',
    Component: SignOnline,
  },
  {
    label: _d('signatures.offlineQES'),
    desc: 'Offline',
    Component: SignOffline,
  },
  {
    Component: SignWSmartID,
    label: <SmartIdIcon />,
    className: '--smart-id',
  },
]

const SignatureActive = ({ meta, item, signatures, data }) => {
  const [activeTab, setActiveTab] = useState(signTabs[0])

  const { user } = authApi
  const { searchStr, fieldName } = item

  const userName = user.displayName && user.displayName.trim()

  const haveMain = !!signatures.find((v) => v.main)

  return (
    <div data-signature={searchStr} className={cn('form-signature', 'active')} tabIndex="0">
      <div className="form-signature__header">
        <div className="--header">
          <div className="--icon">
            <SignatureIcon />
          </div>
          <div>
            <h3>{fieldName}</h3>
          </div>
        </div>

        <AssignedUsers item={item} />
      </div>

      <div className="form-signature__content">
        {userName ? (
          <div className="my-signature">
            <div className="my-signature__user">
              <div>
                <Avatar user={user} />
              </div>
              <div className="my-signature__user__name">
                <div>
                  <b>{user.displayName}</b>
                </div>
                <div className="email">{user.email}</div>
              </div>
            </div>

            <section>
              {haveMain && (
                <nav>
                  {signTabs.map((item, key) => (
                    <button
                      key={key}
                      className={cn(item.className, { active: item === activeTab, activeTab })}
                      onClick={() => setActiveTab(item === activeTab ? null : item)}
                    >
                      {item.label}
                      {item.desc && <div className="desc">{item.desc}</div>}
                    </button>
                  ))}
                </nav>
              )}
              {activeTab && <activeTab.Component meta={meta} item={item} data={data} />}

              {activeTab && (
                <div className="my-signature__disclaimer">
                  <div>
                    <SignatureIcon />
                  </div>
                  <p>{_d('Signature disclaimer')}</p>
                </div>
              )}
            </section>
          </div>
        ) : (
          <div>
            <div className="my-signature">
              <div className="my-signature__user">
                <div>
                  <Avatar user={user} />
                </div>
                <div className="my-signature__user__name">
                  <div>
                    <b>{user.displayName}</b>
                  </div>
                  <div className="desc">{user.email}</div>
                </div>
              </div>
            </div>
            <Form config={formConfig} />
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(SignatureActive)
