import React from 'react'
import { observer } from 'mobx-react'

import Publish from '../Publish/Publish'
import Positions from '../Positions/Positions'
import Settings from '../Settings/Settings'
import PageSettings from '../PageSettings/PageSettings'

import './Aside.scss'

class Aside extends React.Component {
  tabs = {
    form: (props) => {
      const { model } = props
      return (
        <div className="aside">
          <Positions {...props} />
          {model.render()}
        </div>
      )
    },
    settings: () => <Settings {...this.props} />,
    pageSettings: () => <PageSettings {...this.props} />,
    publish: ({ settings }) => <Publish {...this.props} onClose={() => (settings.active = 'form')} />,
  }

  render() {
    const { settings } = this.props

    const tab = this.tabs[settings.active] || this.tabs['form']
    return (
      <div>
        <div className="document-edit__aside">{tab(this.props)}</div>
      </div>
    )
  }
}

export default observer(Aside)
