import { observer } from 'mobx-react'

import DragIcon from 'common/Icons/Drag2'
import EditIcon from 'common/Icons/Edit2'
import RemoveIcon from 'common/Icons/Delete'
import PlusIcon from 'common/Icons/PlusCircleOutline'
// import SettingsIcon from 'common/Icons/Settings'
import CheckboxIcon from 'common/Icons/Checkbox'
import CheckboxIcon2 from 'common/Icons/CheckboxIcon'

import DropDown from 'common/DropDown/DropDown'
import Edit from '../Edit'

const Checkbox = ({ model }) => {
  const { label, type, edit, tags = [], options } = model
  const count = tags.length

  return (
    <div className="fel --checkbox">
      <div className="fel__content">
        <div className="fel__header">
          <div className="drag-handle">
            <DragIcon />
          </div>
          <div count={count} title={`Total ${count} tags!`} />
          <h4 onDoubleClick={() => console.log(model)}>{label}</h4>

          <DropDown
            className="fel__settings"
            options={[
              { label: 'Edit', onClick: () => (model.edit = !edit), icon: <EditIcon /> },
              { label: 'New below', onClick: () => model.after(), icon: <PlusIcon /> },
              {
                label: 'Remove',
                onClick: () => model.remove(),
                className: 'error2',
                icon: <RemoveIcon />,
              },
            ]}
          >
            <span>{type}</span>
            <CheckboxIcon2 />
          </DropDown>
        </div>
        <ul className="fel__options">
          {options &&
            options.map((item, key) => (
              <li key={key}>
                <CheckboxIcon /> {item.label}
              </li>
            ))}
        </ul>
      </div>

      {edit && (
        <div className="fel__edit">
          <Edit model={model} />
        </div>
      )}
    </div>
  )
}

export default observer(Checkbox)
