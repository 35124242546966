import React from 'react'
import { decorate, observable } from 'mobx'
import { Redirect } from 'react-router-dom'

import Loader from 'common/Icons/loadingBar4/LoadingBar'

import './PreLoad.scss'

export class PreLoad {
  constructor(fn, redirectTo) {
    this.fn = fn
    this.redirectTo = redirectTo
    this.init(fn)
  }

  ready = false
  loading = 'Loading...'
  updating = null
  result = {}
  state = {}
  settings = {}

  loader = (
    <div className="pre-load">
      <div>
        <Loader />
      </div>
    </div>
  )

  redirect = () => {
    return <Redirect to={this.redirectTo} />
  }

  setResult = (result) => {
    if (!result && this.redirectTo) {
      this.loading = this.redirect()
      return
    }

    if (this.thenFn) this.thenFn(result)
    this.result = result
    this.ready = true
    this.loading = false
    this.updating = null
  }

  init = (fn) => {
    this.promise = fn()
    this.isAsync = this.promise === Promise.resolve(this.promise)
    if (this.isAsync) {
      this.loadResult(this.promise)
    } else {
      this.setResult(this.promise)
    }
  }

  loadResult = async (promise) => {
    this.ready = false
    this.loading = this.loader
    const result = await promise.catch((e) => console.log(9, 'PreLoad Error', e))

    this.setResult(result)

    return result
  }

  load = async (promise) => {
    this.updating = (
      <div className="pre-update">
        <div className={this.fixed && 'pre-update__fixed'}>
          <Loader className="dark" />
        </div>
      </div>
    )

    const result = await promise.catch((e) => console.log(9, 'PreLoad Error', e))

    this.setResult(result)

    return result
  }

  update = async (fn = this.fn) => {
    this.ready = false
    this.updating = (
      <div className="pre-update">
        <div className={this.fixed && 'pre-update__fixed'}>
          <Loader className="dark" />
        </div>
      </div>
    )

    this.result = await fn(true)

    if (this.thenFn) {
      this.result = this.thenFn(this.result)
    }

    this.ready = true
    this.updating = null

    return this.result
  }

  then = (fn) => {
    this.thenFn = fn
    return this
  }
}

decorate(PreLoad, {
  ready: observable,
  loading: observable.ref,
  state: observable,
  settings: observable,
  updating: observable.ref,
})

export default PreLoad
