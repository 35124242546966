import { useState } from 'react'
import cn from 'classnames'

import HelpIcon from 'common/Icons/Help'

import './Toolbars.scss'

const info = {
  noAction: 'This section becomes active during the Execution stage. Nothing to do here for now.',
  canSign: [
    'All signature blocks below need to be signed.',
    'The date of signature is captured automatically when signing.',
    'You can assign other people to sign on behalf of your party.',
    `Depending on how your company's account is set up, you may not be able to sign this document because you don't have signature rights. You will see a read-only Signature section here.`,
  ],
}

const Ul = ({ list }) => (
  <ul className="help-box__list">
    {list.map((text, key) => (
      <li key={key}>{text}</li>
    ))}
  </ul>
)

const SignatureToolbar = ({ nr, canSign, className }) => {
  const [showHelp, setShowHelp] = useState()

  const help = canSign ? info.canSign : info.noAction
  const text = Array.isArray(help) ? <Ul list={help} /> : help

  return [
    <div className={cn('toolbars', '--nr-' + nr, className)} key="toolbar">
      {/* {nr && <div className="toolbars__nr">{nr}</div>} */}

      <div className="toolbars__content">
        <div className="flex">
          <h3>Digital signature</h3>
        </div>
      </div>

      <button className="btn help-btn" onClick={() => setShowHelp(!showHelp)}>
        <HelpIcon />
      </button>
    </div>,
    showHelp && (
      <div className={cn('help-box')} key="help-box">
        <div>
          {text}
          <div className="help-box__actions">
            <button className="btn link" onClick={() => setShowHelp(false)}>
              close
            </button>
          </div>
        </div>
      </div>
    ),
  ]
}

export default SignatureToolbar
