const Russian = Object.freeze({
  dict: {
    units: [ 'ноль', 'один', 'два', 'три', 'четыре', 'пять', 'шесть', 'семь', 'восемь', 'девять' ],
    tens: [
      null,
      'десять',
      'двадцать',
      'тридцать',
      'сорок',
      'пятьдесят',
      'шестьдесят',
      'семьдесят',
      'восемьдесят',
      'девяносто',
    ],
    hundreds: [ 'сто' ],
    specialGroups: {
      11: 'одиннадцать',
      12: 'двенадцать',
      13: 'тринадцать',
      14: 'четырнадцать',
      15: 'пятнадцать',
      16: 'шестнадцать',
      17: 'семнадцать',
      18: 'восемнадцать',
      19: 'девятнадцать',
      200: 'двести',
      300: 'триста',
      400: 'четыреста',
      500: 'пятьсот',
      600: 'шестьсот',
      700: 'семьсот',
      800: 'восемьсот',
      900: 'девятьсот',
    },

    scales: [
      null,
      'тысяча',
      'миллион',
      'миллиард',
      'trillion',
      'quadrillion',
      'quintillion',
      'sextillion',
      'septillion',
      'octillion',
      'nonillion',
      'decillion',
      'undecillion',
      'duodecillion',
      'tredecillion',
      'quatttuor-decillion',
      'quindecillion',
      'sexdecillion',
      'septen-decillion',
      'octodecillion',
      'novemdecillion',
      'vigintillion',
      'centillion',
    ],
  },

  options: {
    useOne: false,
  },
})

export default Russian
