import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import diff from 'node-htmldiff'

import MoreIcon from 'common/Icons/More'
import ToggleButton from 'common/ToggleButton/ToggleButton'
import DropDown from 'common/DropDown/DropDown'

class Compare extends React.Component {
  state = { showBlackline: false }
  render() {
    const { showBlackline } = this.state
    const { meta = {}, readOnly, onChange } = this.props
    const { viewCpValue, viewValue, value, cpValue, setValue, compare = {}, cpOnly, options, stats = {} } = meta

    const onUpdate = onChange || setValue
    if (stats.isMatch) return null

    if (value === cpValue || !cpValue || typeof cpValue === 'object') return null

    let blackline = viewCpValue

    if (!options && showBlackline && !cpOnly) {
      if (compare.label) {
        blackline = diff(viewValue || '', viewCpValue || '', 'blackline')
      } else {
        blackline = diff(viewCpValue || '', viewValue || '', 'blackline')
      }
    }

    return (
      <div className={cn('form-compare', compare.theme)}>
        {!options &&
        !cpOnly && (
          <DropDown
            options={[
              {
                label: (
                  <small className="flex">
                    Blackline <ToggleButton className="small" value={showBlackline} />
                  </small>
                ),
                onClick: () => this.setState({ showBlackline: !showBlackline }),
              },
              !readOnly && { label: <small>Accept {compare.label}</small>, onClick: () => onUpdate(cpValue) },
            ]}
          >
            <MoreIcon />
          </DropDown>
        )}

        {compare.label && (
          <div className="form-compare__header">
            <label>{compare.label}</label>
          </div>
        )}
        <div className="form-compare__content" dangerouslySetInnerHTML={{ __html: blackline }} />
        {/* {!readOnly && (
          <button data-id={`apply:${meta.config.name}`} onClick={() => onUpdate(cpValue)}>
            {compare.label ? 'Apply' : 'Reset'}
          </button>
        )} */}
      </div>
    )
  }
}
export default observer(Compare)
