import React, { useState } from 'react'
import { observer } from 'mobx-react'
import FileSaver from 'file-saver'
import cn from 'classnames'

import _d from 'dictionary'

import ConfirmButton from 'common/ConfirmButton/ConfirmButton'
import UploadButton from 'common/UploadButton/UploadButton'
import AsyncButton from 'common/AsyncButton/AsyncButton'
import OfflineSignature from 'common/OfflineSignature/OfflineSignature'

import SignIcon from 'common/Icons/Signature'
import SaveOutline from 'common/Icons/SaveOutline'
import DownloadIcon from 'common/Icons/Download'
import DownloadFileIcon from 'common/Icons/DownloadFile'
import TickIcon from 'common/Icons/Tick'
import CloseIcon from 'common/Icons/Close'
import WarningIcon from 'common/Icons/Warning'
import NotEqualIcon from 'common/Icons/NotEqual'

import negotiationsApi from 'api/negotiations'
import printApi from 'api/print'

import './Signature.active.scss'

const SignatureActiveOffline = ({ meta, data }) => {
  const [ file, setFile ] = useState()
  const [ response, setResponse ] = useState()

  const onUpload = async (file) => {
    setFile(file)
    const { _key } = negotiationsApi.current

    const response = await negotiationsApi.uploadSigned(_key, file.form)
    setResponse(response)
  }

  const onConfirm = async () => {
    const { _key } = negotiationsApi.current
    const res = await negotiationsApi.uploadSigned(_key, file.form, true)
    setResponse(res._key ? null : res)
  }

  const downloadFile = async () => {
    const { doc, state } = negotiationsApi.current
    const { tpls } = doc
    const { name, fileName, label } = tpls[0].file

    const fileData = await printApi.download(data._key, { fileName: name.split('/').pop() })

    const saveName = state === 'completed' ? label + '.signed.pdf' : fileName + '.pdf'

    FileSaver.saveAs(fileData, saveName)
  }

  const { valid, newSignatures, message, missingSignatures, negotiationId } = response || {}
  const isSame = data._key === negotiationId
  return (
    <article>
      <h4>{_d('signatures.downloadSignUpload')}</h4>

      <ul className="offline-steps">
        <li>
          <i className="--step">1</i>
          <AsyncButton
            label={_d('Download the execution version')}
            className="btn cancel"
            activeIcon={<DownloadFileIcon />}
            icon={<DownloadIcon />}
            onClick={() => downloadFile()}
          />
        </li>
        <li>
          <i className="--step">2</i>
          <SignIcon /> {_d('signatures.signOffline')}
        </li>
        <li>
          <i className="--step">3</i>
          <SaveOutline /> {_d('signatures.saveToDevice')}
        </li>
        <li>
          <i className="--step">4</i>
          <UploadButton label={_d('signatures.uploadPdf')} onSubmit={onUpload} />
        </li>
        {isSame &&
        newSignatures && (
          <li>
            <div className="offline-steps__info">
              <h4>{_d(`signatures.signaturesFound|${newSignatures.length}`)}</h4>
              <div>
                <b>{_d('File')}:</b> {file && file.file.name}
              </div>
              <ListSignatures list={newSignatures} />
              {!!missingSignatures.length && (
                <div>
                  <h4>{_d(`signatures.numberOfMissingSignatures|${missingSignatures.length}`)}</h4>
                  <ListSignatures list={missingSignatures} />
                </div>
              )}
            </div>
          </li>
        )}
        {isSame &&
        valid && (
          <li>
            <i className="--step --success">
              <TickIcon />
            </i>
            <div>
              <ConfirmButton
                label={_d('signatures.importSignatures')}
                confirm={_d('signatures.importSignatureConfirmation')}
                onClick={() => onConfirm()}
              />
            </div>
          </li>
        )}

        {response &&
        !isSame && (
          <li>
            <div>
              <div>
                <b>{_d('File')}:</b> {file && file.file.name}
              </div>
              <div className="flex">
                {message || _d('signatures.differentNegotiation')} <WarningIcon />
              </div>
            </div>
          </li>
        )}
        {valid === false && (
          <li>
            <i className="--step --error">
              <CloseIcon />
            </i>
            <h4>{_d('signatures.tryAnotherDocument')}</h4>
          </li>
        )}
      </ul>
    </article>
  )
}

const ListSignatures = ({ list }) => {
  const getMessage = ({ allowSign, conflict, missing, position }) => {
    let message
    if (!allowSign) message = _d('signatures.notAllowedToSign')
    if (conflict) message = _d('signatures.doNotMatch')
    if (missing) message = _d('signatures.signatureMissing')
    if (myParty.position !== position) message = _d('signatures.signedForCounterparty')
    return message
  }

  const getIcon = ({ allowSign, conflict, missing }) => {
    let icon = <TickIcon />
    if (!allowSign || missing) icon = <WarningIcon />
    if (conflict) icon = <CloseIcon />
    return icon
  }

  const { myParty = {} } = negotiationsApi.current

  return (
    <ul className="offline-steps__list">
      {list.map((item, key) => {
        const { position, positionLabel, behalfLabel, behalf, signed, allowSign, conflict, missing, valid } = item

        return (
          <li key={key} className={cn('offline-steps__item', { allowSign, conflict, missing })}>
            <h4>
              <div className="--title">
                {positionLabel}
                {behalf && ' - ' + (behalfLabel || behalf)}

                {myParty.position !== position && <div className="--counterparty">(Counterparty)</div>}
              </div>
            </h4>

            <div className="offline-steps__item__signature">
              <div className="--signature">
                <OfflineSignature {...item} />
              </div>

              {conflict && (
                <div className="--icon">
                  <NotEqualIcon />
                </div>
              )}
              {signed && (
                <div>
                  {signed.body ? (
                    <div dangerouslySetInnerHTML={{ __html: signed.body }} />
                  ) : (
                    <div className="offline-signature">
                      <h5>{_d('signatures.signOffline')}</h5>
                      <div>{signed.displayName}</div>
                    </div>
                  )}
                </div>
              )}
              {valid && (
                <div className="--icon">
                  <TickIcon />
                </div>
              )}
            </div>
            {!valid && (
              <div className="offline-steps__item__disclaimer">
                <div>{getMessage(item)}</div>
                <div className="--icon">{getIcon(item)}</div>
              </div>
            )}
          </li>
        )
      })}
    </ul>
  )
}

export default observer(SignatureActiveOffline)
