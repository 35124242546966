import React from 'react'
import { format } from 'date-fns'
import cn from 'classnames'

import './Days.scss'

const compare = (d1, y2, m2, d2) => {
  if (d1) {
    const year = d1.getFullYear()
    const month = d1.getMonth()
    const day = d1.getDate()
    return year === y2 && month === m2 && day === d2
  }
}

const getDays = ({ active = new Date(), selected }) => {
  // const { active, selected } = this.state
  const year = active.getFullYear()
  const month = active.getMonth()

  const startDate = new Date(year, month, 1)
  const endDate = new Date(year, month - 1, 0)
  const monthName = format(startDate, 'MMMM')
  const yearName = startDate.getFullYear()
  const m = startDate.getMonth()

  const shift = (startDate.getDay() + 6) % 7
  const end = endDate.getDate()
  const total = end + shift > 35 ? 42 : 35

  const days = []
  for (let d = 1; d <= total; d++) {
    const day = d - shift
    const currentMonth = day > 0 && day <= end
    const isToday = compare(new Date(), yearName, m, day)
    const isSelected = compare(selected, yearName, m, day)
    const weekDay = d % 7
    const weekend = weekDay === 6 || weekDay === 0

    days.push({ year, month, day: currentMonth ? day : '', currentMonth, isToday, isSelected, weekDay, weekend })
  }
  return { days, shift, end, total, monthName, yearName }
}

const Days = (props) => {
  const { onChange } = props
  const { days } = getDays(props)
  return (
    <article>
      <div className="date-picker__weekdays">
        <div className="date-picker__w-cell">Mon</div>
        <div className="date-picker__w-cell">Tue</div>
        <div className="date-picker__w-cell">Wed</div>
        <div className="date-picker__w-cell">Thu</div>
        <div className="date-picker__w-cell">Fri</div>
        <div className="date-picker__w-cell">Sat</div>
        <div className="date-picker__w-cell">Sun</div>
      </div>
      <div className="date-picker__days">
        {days.map((item, key) => (
          <div
            key={key}
            onClick={() => onChange(item)}
            className={cn('date-picker__cell', {
              'current-month': item.currentMonth,
              today: item.isToday,
              selected: item.isSelected,
              weekend: item.weekend,
            })}
          >
            {item.day}
          </div>
        ))}
      </div>
    </article>
  )
}

export default Days
