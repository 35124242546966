const drNames = {
  drText: 'dr-text',
  drFormat: 'dr-format',
  drValue: 'dr-value',
  drWhen: 'dr-when',
  drWhenNot: 'dr-when-not',
  drDefault: 'dr-default',
  // drEach: 'dr-each',
  // drSignature: 'dr-signature',
  // drBehalf: 'dr-behalf',
  // drFieldName: 'dr-field-name',
}

const drValues = Object.values(drNames)
const mapNames = (fn) => Object.fromEntries(Object.entries(drNames).map(fn))

const attrs = mapNames(([name]) => [name, { default: null }])

const getAttrs = (dom) => mapNames(([name, attr]) => [name, dom.getAttribute(attr)])

const setAttrs = (node) => mapNames(([name, attr]) => [attr, node.attrs[name]])

const nodes = {
  code: {
    inline: true,
    group: 'inline',
    content: 'inline*',
    parseDOM: [{ tag: 'code' }],
    toDOM: () => ['code', 0],
  },

  //----------------------------------------------------------------------------------------------------
  dr_signature: {
    label: 'Signature',
    inline: true,
    group: 'inline',
    content: 'text*',
    atom: true,
    isolating: true,
    defining: true,
    marks: '_',
    attrs: {
      drSignature: { default: null },
      drBehalf: { default: null },
    },
    parseDOM: [
      {
        tag: '[dr-signature]',
        priority: 65,
        getAttrs: (dom) => ({
          drSignature: dom.getAttribute('dr-signature'),
          drBehalf: dom.getAttribute('dr-behalf'),
        }),
      },
    ],
    toDOM(node) {
      const { drSignature, drBehalf } = node.attrs
      return ['dr-signature', { 'dr-signature': drSignature, 'dr-behalf': drBehalf }, ['span', 0]]
    },
  },

  //----------------------------------------------------------------------------------------------------
  dr_inline: {
    label: 'Text',
    inline: true,
    group: 'inline',
    content: 'text*',
    atom: true,
    isolating: true,
    marks: '_',
    attrs: {
      ...attrs,
      tagName: { default: null },
    },
    parseDOM: [
      {
        tag: drValues.map((attr) => `span[${attr}], strong[${attr}], em[${attr}]`).join(','),
        priority: 65,
        getAttrs(dom) {
          return {
            ...getAttrs(dom),
            tagName: dom.tagName.toLowerCase(),
          }
        },
      },
    ],
    toDOM(node) {
      const { tagName } = node.attrs
      return [tagName, { ...setAttrs(node) }, 0]
    },
  },

  //----------------------------------------------------------------------------------------------------
  // dr_div: {
  //   attrs: attrs,
  //   content: 'block*',
  //   group: 'block',
  //   atom: true,
  //   selectable: true,
  //   parseDOM: [
  //     {
  //       tag: drValues.map((attr) => `div[${attr}]`).join(','),
  //       priority: 60,
  //       getAttrs,
  //     },
  //   ],
  //   toDOM: (node) => [ 'div', { ...setAttrs(node) }, 0 ],
  // },

  // dr_block: {
  //   label: 'Block element',
  //   content: '(paragraph | heading) block*',
  //   defining: true,
  //   attrs: {
  //     className: { default: null },
  //     drWhen: { default: null },
  //     drValue: { default: null },
  //   },
  //   parseDOM: [
  //     {
  //       tag: 'div',
  //       getAttrs: (dom) => ({
  //         className: dom.getAttribute('class'),
  //         drWhen: dom.getAttribute('dr-when'),
  //         drValue: dom.getAttribute('dr-value'),
  //       }),
  //     },
  //   ],
  //   toDOM(node) {
  //     const { className, drWhen, drValue } = node.attrs
  //     return ['div', { class: className, 'dr-when': drWhen, 'dr-value': drValue }, 0]
  //   },
  // },

  //----------------------------------------------------------------------------------------------------
  // dr_block: {
  //   attrs: {
  //     ...attrs,
  //     tagName: { default: 'h1' },
  //   },
  //   content: 'inline*',
  //   group: 'block',
  //   atom: true,
  //   selectable: true,
  //   parseDOM: [
  //     {
  //       tag: drValues.map((attr) => `[${attr}]`).join(','),
  //       priority: 60,
  //       getAttrs: (dom) => ({
  //         ...getAttrs(dom),
  //         tagName: dom.tagName.toLowerCase(),
  //       }),
  //     },
  //   ],
  //   toDOM(node) {
  //     const { tagName } = node.attrs
  //     return [ tagName, { ...setAttrs(node) }, 0 ]
  //   },
  // },

  //----------------------------------------------------------------------------------------------------
  // dr_list_item: {
  //   group: 'block',
  //   content: 'block*',
  //   selectable: true,
  //   defining: true,
  //   attrs: {
  //     ...attrs,
  //     className: { default: null },
  //     marker: { default: null },
  //   },
  //   parseDOM: [
  //     {
  //       tag: drValues.map((attr) => `li[${attr}]`).join(','),
  //       priority: 65,
  //       getAttrs: (dom) => ({
  //         ...getAttrs(dom),
  //         className: dom.getAttribute('class'),
  //         marker: dom.getAttribute('marker'),
  //       }),
  //     },
  //   ],
  //   toDOM(node) {
  //     const { className, marker } = node.attrs
  //     return [ 'li', { class: className, marker, ...setAttrs(node) }, 0 ]
  //   },
  // },
  //----------------------------------------------------------------------------------------------------

  // dr_table_row: {
  //   content: '(table_cell | table_header)*',
  //   tableRole: 'row',
  //   attrs,
  //   parseDOM: [
  //     {
  //       tag: drValues.map((attr) => `tr[${attr}]`).join(','),
  //       priority: 65,
  //       getAttrs,
  //     },
  //   ],
  //   toDOM(node) {
  //     return [ 'tr', { ...setAttrs(node) }, 0 ]
  //   },
  // },
}

//------------------------------------------------------------------------------------------------------

export { nodes as default, nodes }
