import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Form from 'common/Form/Form'
import ConfirmButton from 'common/ConfirmButton/ConfirmButton'
import TickIcon from 'common/Icons/Tick'
import CloseIcon from 'common/Icons/Close'
import LogoEditor from 'common/LogoEditor/LogoEditor'

import SmartId from 'common/SamrtId/SmartId'

import _d from 'dictionary'
import Auth from 'api/auth'
import profileApi from 'api/profile'
import app from 'api/app'

import './Profile.scss'

class Profile extends React.Component {
  state = {}

  settings = [
    {
      type: 'group',
      label: _d('Profile info'),
      children: [
        {
          content: () => {
            const { user } = Auth
            const l = user.displayName.split(' ').map((v) => v[0]).join(' ')

            return (
              <div className="profile__info">
                <div
                  className="profile__avatar"
                  onClick={() => {
                    app.modal = {
                      header: _d('Change your logo...'),
                      bgClick: false,
                      content: () => (
                        <div>
                          <LogoEditor
                            useBlob={true}
                            onSave={async ({ formData }) => {
                              Auth.user = await profileApi.saveImageFile(formData)
                              app.modal = null
                            }}
                          />
                        </div>
                      ),
                    }
                  }}
                >
                  {user.photoURL ? <img src={user.photoURL} alt={user.displayName} /> : l}
                  <button className="profile__edit-avatar">Edit</button>
                </div>
                <div className="profile__info__content">
                  <div>
                    <div className="profile__name">{user.displayName}</div>

                    <ul>
                      <li>
                        <label>Email:</label> <b>{user.email}</b>
                      </li>
                      <li>
                        <label>{_d('Stats')}:</label> <b>{user.status}</b>
                      </li>
                      {user.company && (
                        <li>
                          <label>{_d('Member of')}:</label> <b>{user.company.displayName}</b>
                        </li>
                      )}
                      <li className={cn({ accepted: user.termsAccepted })}>
                        {_d('Terms and conditions')}:
                        {user.termsAccepted ? (
                          [ <TickIcon key="icon" />, <span key="text">{_d('Accepted')}</span> ]
                        ) : (
                          <CloseIcon />
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )
          },
        },
        {
          type: 'dropDown',
          name: 'lang',
          label: _d('Language'),
          options: [
            { label: 'English', value: 'en' },
            { label: 'Romanian', value: 'ro' },
            { label: 'Lithuanian', value: 'lt' },
          ],
          validate: {
            required: true,
          },
        },
        {
          type: 'text',
          name: 'firstName',
          label: _d('First name'),
          validate: {
            required: true,
            minLength: 2,
          },
        },
        {
          type: 'text',
          name: 'lastName',
          label: _d('Last name'),
          validate: {
            required: true,
            minLength: 2,
          },
        },
        {
          content: ({ parent }, model) => {
            const { values } = model

            const { allValid, allMatched } = parent.stats
            const { firstName, lastName, lang } = values

            if (allMatched) return null

            return (
              <div className="profile__actions">
                <ConfirmButton
                  confirm={_d('Are you sure?')}
                  data-id={_d('Save')}
                  onClick={() => this.onSave({ firstName, lastName, lang }, model)}
                  disabled={!allValid}
                  label={_d('Save')}
                />
              </div>
            )
          },
        },
      ],
    },
    {
      type: 'group',
      label: 'Smart-ID',
      children: [
        {
          type: 'radio',
          name: 'smartIdDetails.identityType',
          label: 'Identity type',
          options: [
            {
              label: '(PAS) Passport number',
              value: 'PAS',
              children: [
                {
                  type: 'columns',
                  children: [
                    {
                      type: 'select',
                      label: 'Country',
                      name: 'smartIdDetails.countryCode',
                      description: 'Select the country that issued your ID',
                      options: [
                        {
                          label: 'Estonia',
                          value: 'EE',
                        },
                        {
                          label: 'Latvia',
                          value: 'LV',
                        },
                        {
                          label: 'Lithuania',
                          value: 'LT',
                        },
                      ],
                    },
                    {
                      type: 'numbers',
                      label: 'Passport number',
                      name: 'smartIdDetails.identifier',
                      description: '9-14 digits',
                      autoFocus: true,
                      format: false,
                      validate: {
                        required: true,
                        minLength: 9,
                        maxLength: 14,
                      },
                    },
                  ],
                },
              ],
            },
            {
              label: '(IDC) National identity card number',
              value: 'IDC',
              children: [
                {
                  type: 'columns',
                  children: [
                    {
                      type: 'select',
                      label: 'Country',
                      name: 'smartIdDetails.countryCode',
                      description: 'Select the country that issued your ID',
                      options: [
                        {
                          label: 'Estonia',
                          value: 'EE',
                        },
                        {
                          label: 'Latvia',
                          value: 'LV',
                        },
                        {
                          label: 'Lithuania',
                          value: 'LT',
                        },
                      ],
                    },
                    {
                      type: 'numbers',
                      label: 'National identity card number',
                      name: 'smartIdDetails.identifier',
                      description: '9-14 digits',
                      autoFocus: true,
                      format: false,
                      validate: {
                        required: true,
                        minLength: 9,
                        maxLength: 14,
                      },
                    },
                  ],
                },
              ],
            },
            {
              label: '(PNO) National personal number (national civic registration number)',
              value: 'PNO',
              children: [
                {
                  type: 'columns',
                  children: [
                    {
                      type: 'select',
                      label: 'Country',
                      name: 'smartIdDetails.countryCode',
                      description: 'Select the country that issued your ID',
                      options: [
                        {
                          label: 'Estonia',
                          value: 'EE',
                        },
                        {
                          label: 'Latvia',
                          value: 'LV',
                        },
                        {
                          label: 'Lithuania',
                          value: 'LT',
                        },
                      ],
                    },
                    {
                      type: 'numbers',
                      label: 'National personal number',
                      description: '9-14 digits',
                      name: 'smartIdDetails.identifier',
                      autoFocus: true,
                      format: false,
                      validate: {
                        required: true,
                        minLength: 9,
                        maxLength: 14,
                      },
                    },
                  ],
                },
              ],
            },
          ],
          showIf: { registerSmartId: 'active' },
          validate: {
            required: true,
          },
        },

        {
          content: SmartId,
          name: 'registerSmartId',
        },
      ],
    },
    {
      type: 'group',
      label: _d('notifications.groupLabel'),
      children: [
        {
          type: 'checkbox',
          label: _d('Receive E-mail when'),
          name: 'skipEmail',
          invertValues: true,
          optional: true,
          validate: {
            required: true,
          },
          options: [
            {
              label: _d('notifications.inviting'),
              value: 'inviting',
            },
            {
              label: _d('notifications.accepted'),
              value: 'accept',
            },
            {
              label: _d('notifications.rejected'),
              value: 'reject',
            },
            {
              label: _d('notifications.addAlias'),
              value: 'addAlias',
            },
            {
              label: _d('notifications.receivedFromCounterparty'),
              value: 'receivedFromCounterparty',
            },
            {
              label: _d('notifications.execution'),
              value: 'execution',
            },
            {
              label: _d('notifications.signed'),
              value: 'signed',
            },
            {
              label: _d('notifications.signedAndCompleted'),
              value: 'signedAndCompleted',
            },
          ],
        },
        {
          content: ({ parent }, model) => {
            const { values } = model

            const { allMatched } = parent.stats

            if (allMatched) return null

            const { skipEmail } = values

            return (
              <div className="profile__actions" key="action">
                <ConfirmButton
                  confirm={_d('Are you sure?')}
                  data-id="save"
                  label={_d('Save')}
                  onClick={() => this.onSave({ skipEmail }, model)}
                />
              </div>
            )
          },
        },
      ],
    },
    {
      type: 'group',
      label: _d('Password'),
      closed: true,
      progress: false,
      children: [
        {
          type: 'checkbox',
          label: _d('Reset password'),
          name: 'resetPassword',
          optional: true,
          options: [
            {
              label: 'Send reset URL to my E-mail',
              value: 'reset',
            },
          ],
        },
        {
          content: ({ parent }) => {
            const { allCompleted } = parent.stats
            if (!allCompleted) return null

            return (
              <div className="profile__actions" key="action">
                <ConfirmButton
                  confirm="Are you sure?"
                  data-id="save"
                  label="Send"
                  onClick={async () => {
                    await profileApi.resetPassword()
                    parent.clearValues()
                  }}
                />
              </div>
            )
          },
        },
      ],
    },
  ]

  onSave = async (values, model) => {
    const answers = await profileApi.update(values)
    model.setCompare({ answers })
    model.setAnswers(answers)
    model.getStats()
  }

  render() {
    const { user } = Auth

    return (
      <div className="elections profile">
        <Form config={this.settings} values={user} compare={{ ...user }} />
      </div>
    )
  }
}

export default observer(Profile)
