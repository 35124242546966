import { useRef, useState } from 'react'
import cn from 'classnames'

import Loader from 'common/Icons/loadingBar4/LoadingBar'

import SvgLine from 'screens/TemplateEditor/Templates/SvgLine/SvgLine'
import authApi from 'api/auth'
import './Story.scss'

const Loading = () => (
  <div className="pre-load">
    <div>
      <Loader />
    </div>
  </div>
)

const Story = ({ index, header, image, imagePos, tips = [] }) => {
  const imageRef = useRef(null)
  const [ pos, setPos ] = useState()
  const [ isReady, setReady ] = useState()

  return (
    <li className={cn('story', imagePos)}>
      <h2 className="story__header">{header}</h2>

      <article className="story__article">
        <div className="story__content">
          <div
            className="story__image"
            ref={imageRef}
            onClick={(evt) => {
              const imagePos = imageRef.current.getBoundingClientRect()
              const { top, left } = imagePos

              var x = evt.pageX - left
              var y = evt.pageY - top - document.documentElement.scrollTop

              authApi.isAdmin && setPos([ x, y ])
            }}
          >
            {!isReady && <Loading />}
            {/* <div className="story__bg-image" style={{ backgroundImage: `url(/images/screenshots/${image})` }} /> */}
            <img
              className="story__bg-image"
              src={'/images/screenshots/' + image}
              alt={header}
              onLoad={() => setReady(true)}
            />

            {isReady &&
              tips.map((item, key) => (
                <div key={key} className="story__pos" style={{ top: item.pos[1], left: item.pos[0] }}>
                  <SvgLine to={index + '|' + key} />
                </div>
              ))}
          </div>
        </div>
        <aside>
          <ul className="story__tips">
            {tips.map((item, key) => (
              <li key={key} className="story__tips__item">
                <h3>
                  <b className="story__tips__nr" id={index + '|' + key}>
                    {key + 1}
                  </b>

                  <div>{item.label}</div>
                </h3>
                <p>{item.text}</p>
              </li>
            ))}
          </ul>
          {pos && <pre>{JSON.stringify(pos)}</pre>}
        </aside>
      </article>
    </li>
  )
}

export default Story
