const Icon = (props) => (
  <svg width="80px" height="80px" viewBox="0 0 496 496" fill="currentColor" {...props}>
    <g>
      <path d="M168,296v-24h16v-16h-16v-16h16v-16h-16v-16c22.056,0,40-17.944,40-40c0-17.864-11.784-33.04-27.976-38.16L192,105.888
				v-59.36c9.288-3.312,16-12.112,16-22.528c0-13.232-10.768-24-24-24s-24,10.768-24,24c0,6.216,2.432,11.832,6.32,16.096
				l-4.104,6.152C158.152,52.352,151.336,56,144,56s-14.152-3.648-18.216-9.752l-4.104-6.152C125.568,35.832,128,30.216,128,24
				c0-13.232-10.768-24-24-24S80,10.768,80,24c0,6.216,2.432,11.832,6.32,16.096l-4.104,6.152C78.152,52.352,71.336,56,64,56
				s-14.152-3.648-18.216-9.752l-4.104-6.152C45.568,35.832,48,30.216,48,24C48,10.768,37.232,0,24,0S0,10.768,0,24
				c0,10.416,6.712,19.216,16,22.528v59.368l11.976,23.952C11.784,134.96,0,150.136,0,168c0,22.056,17.944,40,40,40v16H24v16h16v16
				H24v16h16v24c0,69.272-38.384,131.128-38.776,131.736L0,429.696V496h208v-66.32l-1.24-1.96C206.368,427.104,168,365.696,168,296z
				 M184,16c4.416,0,8,3.584,8,8s-3.584,8-8,8s-8-3.584-8-8S179.584,16,184,16z M104,16c4.416,0,8,3.584,8,8s-3.584,8-8,8
				s-8-3.584-8-8S99.584,16,104,16z M24,32c-4.416,0-8-3.584-8-8s3.584-8,8-8s8,3.584,8,8S28.416,32,24,32z M16,168
				c0-13.232,10.768-24,24-24h12.944H128v-16H44.944L32,102.112V54.424l0.472,0.704C39.512,65.696,51.296,72,64,72
				s24.488-6.304,31.528-16.872l4.984-7.48C101.664,47.816,102.808,48,104,48s2.336-0.184,3.48-0.352l4.984,7.48
				C119.512,65.696,131.296,72,144,72s24.488-6.304,31.528-16.872L176,54.424v47.688L163.056,128H144v16h11.056H168
				c13.232,0,24,10.768,24,24s-10.768,24-24,24H40C26.768,192,16,181.232,16,168z M56,224v-16h96v16H56z M152,240v16H56v-16H152z
				 M192,480H16v-40h144v-16H21.712C33.48,401.744,56,351.88,56,296v-24h96v24c0,56.152,22.464,105.8,34.248,128H176v16h16V480z" />
      <path d="M456,288c22.056,0,40-17.944,40-40c0-22.056-17.944-40-40-40c-19.312,0-35.472,13.768-39.192,32h-65.616
				C348,224.352,335.648,212,320,208.808V88c0-22.056,17.944-40,40-40h56.808c3.72,18.232,19.872,32,39.192,32
				c22.056,0,40-17.944,40-40c0-22.056-17.944-40-40-40c-19.312,0-35.472,13.768-39.192,32H360c-30.872,0-56,25.128-56,56v120.808
				C288.352,212,276,224.352,272.808,240H200v16h72.808C276,271.648,288.352,284,304,287.192V408c0,30.872,25.128,56,56,56h56.808
				c3.72,18.232,19.872,32,39.192,32c22.056,0,40-17.944,40-40c0-22.056-17.944-40-40-40c-19.312,0-35.472,13.768-39.192,32H360
				c-22.056,0-40-17.944-40-40V287.192C335.648,284,348,271.648,351.192,256h65.616C420.528,274.232,436.688,288,456,288z M456,224
				c13.232,0,24,10.768,24,24s-10.768,24-24,24s-24-10.768-24-24S442.768,224,456,224z M456,16c13.232,0,24,10.768,24,24
				s-10.768,24-24,24s-24-10.768-24-24S442.768,16,456,16z M456,432c13.232,0,24,10.768,24,24s-10.768,24-24,24s-24-10.768-24-24
				S442.768,432,456,432z M312,272c-13.232,0-24-10.768-24-24s10.768-24,24-24s24,10.768,24,24S325.232,272,312,272z" />
      <rect
        x="267.265"
        y="151.309"
        transform="matrix(0.3824 0.924 -0.924 0.3824 317.2047 -155.9549)"
        width="16"
        height="16"
      />
      <rect
        x="236.118"
        y="172.106"
        transform="matrix(0.7071 0.7071 -0.7071 0.7071 198.8543 -119.8656)"
        width="16"
        height="16"
      />
      <rect
        x="215.307"
        y="203.266"
        transform="matrix(0.924 0.3824 -0.3824 0.924 97.7598 -69.3355)"
        width="16"
        height="16"
      />
      <rect
        x="215.307"
        y="276.733"
        transform="matrix(0.924 -0.3824 0.3824 0.924 -91.9099 107.0331)"
        width="16"
        height="16"
      />
      <rect
        x="236.114"
        y="307.862"
        transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 193.3801 711.8237)"
        width="16"
        height="16"
      />
      <rect
        x="267.27"
        y="328.68"
        transform="matrix(-0.9238 -0.3828 0.3828 -0.9238 400.686 753.0895)"
        width="16"
        height="16"
      />
      <rect
        x="340.712"
        y="328.71"
        transform="matrix(-0.9238 0.3828 -0.3828 -0.9238 799.7571 514.2798)"
        width="16"
        height="16"
      />
      <rect
        x="371.849"
        y="307.894"
        transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 425.0724 807.8585)"
        width="16"
        height="16"
      />
      <rect
        x="392.703"
        y="276.717"
        transform="matrix(0.924 0.3824 -0.3824 0.924 139.3301 -131.5891)"
        width="16"
        height="16"
      />
      <rect
        x="392.702"
        y="203.25"
        transform="matrix(0.3828 0.9238 -0.9238 0.3828 442.467 -239.7978)"
        width="16"
        height="16"
      />
      <rect
        x="371.853"
        y="172.138"
        transform="matrix(-0.7071 -0.7071 0.7071 -0.7071 521.0733 576.1116)"
        width="16"
        height="16"
      />
      <rect
        x="340.741"
        y="151.313"
        transform="matrix(-0.3824 0.924 -0.924 -0.3824 629.3037 -102.0013)"
        width="16"
        height="16"
      />
    </g>
  </svg>
)

export default Icon
