import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Form from '../../Form'
import Label from '../Label'
import Compare from '../Compare'
import ErrorMessage from '../ErrorMessage'

import './Select.scss'

class Select extends React.Component {
  onChange = (e) => {
    const { meta } = this.props
    const { value } = e.target
    meta.setValue(JSON.parse(value).value)
  }

  render() {
    let { meta = {}, readOnly } = this.props
    const {
      name,
      value,
      disabled,
      children,
      config = {},
      cpValue,
      cpOnly,
      validation = {},
      isRestricted,
      onBlur,
      isHidden,
      options = [],
    } = meta
    const { flex } = config
    const { isInvalid, isMatched, isCompleted, isChanged } = validation

    return (
      <div
        style={{ flex }}
        input-name={name}
        className={cn({
          isHidden,
          isRestricted,
          isMatched,
          isUnmatched: !isMatched,
          isIncompleted: !isCompleted,
          isCompleted,
          isInvalid,
          isChanged,
        })}
      >
        <div className={cn('form-item form-select', { readOnly, cpOnly })}>
          <div className="form-item__content">
            <Label {...this.props} />
            {!cpOnly &&
              (readOnly ? (
                <div className="form-input">
                  <div className={cn('form-input__value', { empty: !value, matched: isMatched, cpValue, value })}>
                    {value}
                  </div>
                </div>
              ) : (
                <div className="form-input">
                  <select
                    data-id={'select:' + name}
                    value={JSON.stringify({ value })}
                    onChange={this.onChange}
                    disabled={disabled || readOnly}
                    onBlur={() => {
                      meta.onBlur = isInvalid
                      meta.onFocus = false
                    }}
                    onFocus={() => (meta.onFocus = true)}
                    className={cn({
                      empty: value === undefined,
                      matched: isMatched,
                      cpValue,
                      value,
                      error: isInvalid && onBlur,
                    })}
                  >
                    {value === undefined && <option className="read-only">-- select --</option>}
                    {options.filter((item) => item).map((item, key) => (
                      <option key={key} value={JSON.stringify({ value: item.value })}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage validation={validation} meta={meta} />
                </div>
              ))}
          </div>

          <Compare meta={meta} readOnly={readOnly || cpOnly} />
        </div>
        {children && (
          <ul>
            <li>
              <Form items={children} readOnly={readOnly} />
            </li>
          </ul>
        )}
      </div>
    )
  }
}

export default observer(Select)
