import React from 'react'

const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
      <g transform="translate(3.000000, 5.000000)" fill="currentColor" fillRule="nonzero">
        <path d="M16.2,6.70574707e-14 L1.8,6.70574707e-14 C0.81,6.70574707e-14 0.009,0.792391304 0.009,1.76086957 L0,12.326087 C0,13.2945652 0.81,14.0869565 1.8,14.0869565 L16.2,14.0869565 C17.19,14.0869565 18,13.2945652 18,12.326087 L18,1.76086957 C18,0.792391304 17.19,6.70574707e-14 16.2,6.70574707e-14 Z M15.84,3.74184783 L9.477,7.63336957 C9.189,7.80945652 8.811,7.80945652 8.523,7.63336957 L2.16,3.74184783 C1.935,3.60097826 1.8,3.36326087 1.8,3.10793478 C1.8,2.51804348 2.457,2.16586957 2.97,2.47402174 L9,6.16304348 L15.03,2.47402174 C15.543,2.16586957 16.2,2.51804348 16.2,3.10793478 C16.2,3.36326087 16.065,3.60097826 15.84,3.74184783 Z" />
      </g>
    </g>
  </svg>
)

export default Icon
