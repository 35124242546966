import React from 'react'
import { observer } from 'mobx-react'
// import cn from 'classnames'

// import MinusIcon from 'common/Icons/Minus'
// import PlusIcon from 'common/Icons/Add'

import Form from '../../Form'

import './States.scss'

class StateGroup extends React.Component {
  toggle = (open) => {
    const { items } = this.props
    items.forEach((item) => item.setOpen(open))
  }

  render() {
    const { items, readOnly, wrapper, tpls } = this.props
    const props = { items, readOnly, wrapper, tpls }

    // const openAll = !items.find((item) => !item.open)

    return (
      <div className="form-state-group">
        {/* <header className={cn({ active })}>
          <div>
            {items.length > 1 && (
              <div>
                {openAll ? (
                  <button className="btn clear small" onClick={() => this.toggle(false)}>
                    <MinusIcon /> Collapse all
                  </button>
                ) : (
                  <button className="btn clear small" onClick={() => this.toggle(true)}>
                    <PlusIcon /> Expand all
                  </button>
                )}
              </div>
            )}
          </div>
        </header> */}

        <ul>
          <li>
            <Form {...props} />
          </li>
        </ul>
      </div>
    )
  }
}

export default observer(StateGroup)
