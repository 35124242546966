import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import _d from 'dictionary'

import Avatar from 'common/UserCard/Avatar'
import OfflineSignature from 'common/OfflineSignature/OfflineSignature'
import SignatureIcon from 'common/Icons/Signature'

import '../Signature.scss'

const SignatureCompleted = ({ item }) => {
  const { signed, fieldName } = item
  const { body, createdBy } = signed

  return (
    <div data-signature={item.searchStr} tabIndex="0" className={cn('form-signature')}>
      <div className="form-signature__completed">
        <div>
          <div className="--header">
            <div className="--icon">
              <SignatureIcon />
            </div>
            <div>
              <h3>{fieldName}</h3>
            </div>
          </div>
          <label>&nbsp;</label>
          <div className="flex">
            <div>
              <Avatar user={createdBy} />
            </div>
            <div>
              <div>{createdBy.displayName}</div>
              <div className="desc">{createdBy.email}</div>
            </div>
          </div>
        </div>

        {body ? <div dangerouslySetInnerHTML={{ __html: body }} /> : <OfflineSignature {...signed} />}
      </div>
    </div>
  )
}

export default observer(SignatureCompleted)
