import { toJS } from '../mobx'
import get from 'lodash.get'

import _d from './dictionary'

class FormItemBasic {
  constructor(config = {}, { model, parent, opt }) {
    this._label = config.label
    this._config = null
    this.model = model
    this.parent = parent
    this.opt = opt
    this.disabled = config.disabled
    this.unique = Math.random().toString().split('.')[1]

    this.debug = model.options && model.options.debug

    this.originalConfig = toJS(config)

    if (config.closed) {
      this._open = false
    }
  }

  //---------------------------------------------------------------------------------------------------------
  get config() {
    return this._config || {}
  }

  set config(value) {
    for (let i in this._config) {
      delete this._config[i]
    }
    Object.assign(this._config, value)
  }

  //---------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------
  //  Hidden elections
  get isHidden() {
    const { form } = this.model
    return form && form[this.name]
  }

  get isVisible() {
    return !this.isHidden
  }

  get isEditable() {
    const { form } = this.model
    return !form || typeof form[this.name] !== 'boolean'
  }

  get notEditable() {
    return !this.isEditable
  }

  get isRestricted() {
    const { form } = this.model
    return form && typeof form[this.name] === 'boolean'
  }

  get viewOnly() {
    return this.notEditable && this.model.state !== 'draft'
  }

  //---------------------------------------------------------------------------------------------------------
  get myPosition() {
    return !this.opt || this.opt.myPosition || this.config.name === '__amend'
  }

  get cpOnly() {
    if (this.opt) {
      const { myPosition, cpPosition } = this.opt
      return cpPosition && !myPosition
    }
    return false
  }

  get state() {
    return this.config.state || (this.parent && this.parent.state) || 'active'
  }

  get active() {
    const { state } = this.model

    let currentState = 'active'
    if ([ 'execution', 'completed' ].includes(state)) {
      currentState = 'execution'
    }

    return !state || !this.parent || currentState === this.state
  }

  get isInput() {
    return this.hasValue
  }

  get open() {
    return this._open === undefined
      ? (this.active && this.children && !!this.children.length) ||
        (this.data && this.data.__amend) ||
        (this.cpData && this.cpData.__amend)
      : this._open
  }

  get id() {
    return this._id || this.getId()
  }

  get hasValue() {
    const { name, type } = this.config
    return (
      (name && ![ 'group', 'columns', 'section', 'array', 'arrayItem', 'toggle' ].includes(type)) ||
      type === 'signature'
    )
  }

  get name() {
    return this.hasValue && this.id
  }

  get label() {
    return this.useTranslation(this.config.label)
  }

  get format() {
    return this.config.format
  }

  get description() {
    return this._description || this.useTranslation(this.config.description)
  }

  get type() {
    return this.config.type
  }

  get options() {
    const { options } = this.config

    if (Array.isArray(options)) {
      return options.filter(Boolean).map((item) => {
        let newItem = { ...item }

        if (typeof item === 'string') {
          newItem = {
            label: item,
            value: item,
          }
        }

        if (newItem.value === undefined) {
          newItem.value = newItem.label
        }

        if (newItem.label) {
          newItem.label = this.useTranslation(newItem.label)
        }

        if (newItem.description) {
          newItem.description = this.useTranslation(newItem.description)
        }

        return newItem
      })
    }
    return options
  }

  get answers() {
    return this.model.answers
  }

  get values() {
    return this.model.values
  }

  get compare() {
    return this.model.compare
  }

  get onClick() {
    return this.model.options && this.model.options.onClick
  }

  get data() {
    return get(this.model.answers, this.id)
  }

  get cpData() {
    return get(this.model.compare.answers, this.id)
  }

  //---------------------------------------------------------------------------------------------------------

  get value() {
    this._value = toJS(get(this.model.answers, this.id))

    return this._value
  }

  get viewValue() {
    const { value, options } = this
    if (Array.isArray(options)) {
      const viewValue = options.find((v) => value === v.value) || {}
      return viewValue.label
    }
    return value
  }

  get viewCpValue() {
    const { cpValue, options } = this
    if (Array.isArray(options)) {
      const viewValue = options.find((v) => cpValue === v.value) || {}
      return viewValue.label
    }
    return cpValue
  }

  get myValue() {
    return toJS(get(this.model.answers, [ this.name, this.position ].join('.')))
  }

  get cpValue() {
    this._cpValue = get(this.model.compare.answers, this.name)
    return this._cpValue
  }

  get orgValue() {
    return get(this.model.original, this.name)
  }

  get position() {
    const { primary } = this.model
    return primary.isOwner ? 'sender' : 'receiver'
  }

  //---------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------
  useTranslation(value) {
    return value && value[0] === '=' ? _d(value) : value
  }

  //---------------------------------------------------------------------------------------------------------
  getId() {
    const { originalConfig, parent } = this

    const parentName = this.getParentName(parent)

    this._id = [ ...parentName, originalConfig.name ].join('.')
    return this._id
  }

  //---------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------
  getParentName({ config, parent, hasValue } = {}, nameArr = []) {
    if (config && config.name && !hasValue) nameArr.unshift(config.name)

    if (parent) this.getParentName(parent, nameArr)
    return nameArr
  }

  //---------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------
}

export default FormItemBasic
