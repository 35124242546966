import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Form from '../../Form'
import Label from '../Label'

import CheckboxIcon from 'common/Icons/Checkbox'

import './Checkbox.scss'

class Checkbox extends React.Component {
  select = (val) => {
    const { meta } = this.props
    const { value, config } = meta

    if (val === null) {
      return meta.setValue(val)
    }

    let values = [].concat(value).filter((item) => ![ null, undefined ].includes(item))

    if (values.includes(val)) {
      values = values.filter((item) => item !== val)
    } else {
      values.push(val)
    }

    if (!values.length) {
      values = null
    }

    if (!values && config.optional && !config.invertValues) {
      values = undefined
    }

    meta.setValue(values)
  }

  invertValues = (arr, options) => {
    return options.filter(({ value }) => !(arr || []).includes(value)).map(({ value }) => value)
  }

  render() {
    const { meta = {}, readOnly, wrapper } = this.props
    const {
      name,
      disabled,
      children,
      config = {},
      primaryShort,
      compare = {},
      options = [],
      validation = {},
      isRestricted,
      isHidden,
      viewOnly,
      notEditable,
    } = meta

    const { flex = 1, optional, invertValues } = config
    const { isMatched, isCompleted, isChanged, isInvalid } = validation

    const value = invertValues ? this.invertValues(meta.value, options) : meta.value
    const cpValue = invertValues ? this.invertValues(meta.cpValue, options) : meta.cpValue

    return (
      <div
        style={{ flex }}
        input-name={name}
        className={cn({
          isHidden,
          isRestricted,
          isMatched,
          isUnmatched: !isMatched,
          isIncompleted: !isCompleted,
          isCompleted,
          isChanged,
          isInvalid,
          viewOnly,
          notEditable,
        })}
      >
        <div className={cn('form-item', { readOnly, isChanged })}>
          <div className="form-item__content">
            <Label {...this.props} />
            {viewOnly ? (
              <div className="form-input">
                <div className="form-input__view-only">
                  <ul>
                    {value === null ? (
                      'None'
                    ) : (
                      value &&
                      options
                        .filter((item) => value.includes(item.value))
                        .map((item) => <li key={item.value}>{item.label}</li>)
                    )}
                  </ul>
                </div>
              </div>
            ) : (
              <div className="form-input">
                <ul className="checkbox" data-id={'checkbox:' + name}>
                  {options.map((item, key) => {
                    const selected = value && [].concat(value).includes(item.value)
                    const cpSelected = cpValue && cpValue.includes(item.value)
                    return (
                      <li
                        key={key}
                        tabIndex="0"
                        onClick={() => this.select(item.value)}
                        className={cn('checkbox__item', compare.theme, {
                          disabled: disabled || item.disabled,
                          readOnly,
                          selected,
                          cpPosition: cpSelected,
                        })}
                      >
                        {(selected || cpSelected) && (
                          <div className="tag">
                            {selected && <div className="tag__primary">{primaryShort}</div>}
                            {cpSelected &&
                            compare.shortLabel && <div className="tag__compare">{compare.shortLabel}</div>}
                          </div>
                        )}
                        <CheckboxIcon value={selected} /> {item.label}
                      </li>
                    )
                  })}

                  {!optional && <hr />}
                  {!optional && (
                    <li
                      onClick={() => this.select(null)}
                      className={cn('checkbox__item', 'checkbox__none', compare.theme, {
                        disabled,
                        readOnly,
                        selected: value === null,
                        cpPosition: cpValue === null,
                      })}
                    >
                      {(value === null || cpValue === null) && (
                        <div className="tag">
                          {value === null && <div className="tag__primary">{primaryShort}</div>}
                          {cpValue === null && <div className="tag__compare">{compare.shortLabel}</div>}
                        </div>
                      )}
                      <CheckboxIcon value={value === null} /> {config.noneLabel || 'None'}
                    </li>
                  )}
                </ul>
              </div>
            )}
          </div>
        </div>
        {children &&
        !!children.length && (
          <ul className="checkbox__children">
            <li>
              <Form items={children} readOnly={readOnly} wrapper={wrapper} />
            </li>
          </ul>
        )}
      </div>
    )
  }
}

export default observer(Checkbox)
