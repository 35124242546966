import React from 'react'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import DocumentIcon from 'common/Icons/Document'
import SettingsIcon from 'common/Icons/Settings'
import NegotiationsIcon from 'common/Icons/Negotiations'
import ApiIcon from 'common/Icons/Api'

import HelpIcon from 'common/Icons/Help'
import _d from 'dictionary'
import Auth from 'api/auth'

import './Tiles.scss'

class Tiles extends React.Component {
  tiles = [
    {
      icon: <DocumentIcon />,
      title: _d('Templates'),
      desc: _d('homeScreen.templatesDescription'),
      url: '/documents',
    },
    {
      icon: <NegotiationsIcon />,
      title: _d('Documents'),
      desc: _d('homeScreen.documentsDescription'),
      url: '/negotiations',
      get total() {
        const { negCount: { total, isNew } = {} } = Auth.user
        return total - isNew
      },
      get isNew() {
        const { negCount = {} } = Auth.user
        return negCount.isNew
      },
    },

    {
      icon: <SettingsIcon />,
      title: _d('Settings'),
      desc: _d('homeScreen.settingsDescription'),
      url: '/settings',
    },
    {
      icon: <HelpIcon />,
      title: _d('How to use'),
      desc: _d('homeScreen.howToUseDescription'),
      url: '/how-to-use',
      // disabled: true,
    },
    Auth.user.role.includes('api') && {
      icon: <ApiIcon />,
      title: _d('API Documentation'),
      desc: _d('homeScreen.docsDescription'),
      url: '/docs',
    },
  ]

  render() {
    return (
      <ul className="tiles">
        {this.tiles.filter(Boolean).map(({ disabled, title, desc, url, total, isNew, icon }, key) => (
          <li key={key} className="tile" disabled={disabled}>
            <div className="tile__icon">{icon}</div>
            <div className="tile__content">
              <div className="tile__title">
                <Link to={url}>{title}</Link>
              </div>
              <div className="tile__desc">{desc}</div>
            </div>

            {!!total && (
              <div className="tile__count">
                <div className="--total">{total}</div>
                {!!isNew && <div className="--is-new">{isNew}</div>}
              </div>
            )}
          </li>
        ))}
      </ul>
    )
  }
}

export default observer(Tiles)
