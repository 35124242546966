import React from 'react'

const Icon = (props) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
      <path
        d="M18.7293873,13.065 C18.766054,12.7716667 18.793554,12.4783333 18.793554,12.1666667 C18.793554,11.855 18.766054,11.5616667 18.7293873,11.2683333 L20.663554,9.75583333 C20.8377206,9.61833333 20.883554,9.37083333 20.773554,9.16916667 L18.9402206,5.9975 C18.8302206,5.79583333 18.5827206,5.7225 18.381054,5.79583333 L16.098554,6.7125 C15.6218873,6.34583333 15.108554,6.04333333 14.5493873,5.81416667 L14.201054,3.385 C14.173554,3.165 13.981054,3 13.7518873,3 L10.0852206,3 C9.85605397,3 9.66355397,3.165 9.63605397,3.385 L9.28772064,5.81416667 C8.72855397,6.04333333 8.21522064,6.355 7.73855397,6.7125 L5.45605397,5.79583333 C5.24522064,5.71333333 5.0068873,5.79583333 4.8968873,5.9975 L3.06355397,9.16916667 C2.9443873,9.37083333 2.9993873,9.61833333 3.17355397,9.75583333 L5.10772064,11.2683333 C5.07105397,11.5616667 5.04355397,11.8641667 5.04355397,12.1666667 C5.04355397,12.4691667 5.07105397,12.7716667 5.10772064,13.065 L3.17355397,14.5775 C2.9993873,14.715 2.95355397,14.9625 3.06355397,15.1641667 L4.8968873,18.3358333 C5.0068873,18.5375 5.2543873,18.6108333 5.45605397,18.5375 L7.73855397,17.6208333 C8.21522064,17.9875 8.72855397,18.29 9.28772064,18.5191667 L9.63605397,20.9483333 C9.66355397,21.1683333 9.85605397,21.3333333 10.0852206,21.3333333 L13.7518873,21.3333333 C13.981054,21.3333333 14.173554,21.1683333 14.201054,20.9483333 L14.5493873,18.5191667 C15.108554,18.29 15.6218873,17.9783333 16.098554,17.6208333 L18.381054,18.5375 C18.5918873,18.62 18.8302206,18.5375 18.9402206,18.3358333 L20.773554,15.1641667 C20.883554,14.9625 20.8377206,14.715 20.663554,14.5775 L18.7293873,13.065 Z M11.918554,15.375 C10.1493873,15.375 8.71022064,13.9358333 8.71022064,12.1666667 C8.71022064,10.3975 10.1493873,8.95833333 11.918554,8.95833333 C13.6877206,8.95833333 15.1268873,10.3975 15.1268873,12.1666667 C15.1268873,13.9358333 13.6877206,15.375 11.918554,15.375 Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default Icon
