import React from 'react'
import { observer } from 'mobx-react'
import _d from 'dictionary'

import Avatar from 'common/UserCard/Avatar'
import ApiKeys from './ApiKeys/ApiKeys'
import Users from './Users/Users'
import SignaturePref from './SignaturePref/SignaturePref'

import companyApi from 'api/company'
import auth from 'api/auth'

import './Company.scss'

class Company extends React.Component {
  state = {}

  getData = async () => {
    const result = await companyApi.getCompany()
    const { users, me } = result
    this.setState({ result })

    const userList = users.sort((item) => {
      if (item._key === me._key) {
        item.isMe = true
        return -1
      }
      return 0
    })

    this.promise = null
    return { ...result, userList }
  }

  getPromise = () => {
    if (this.promise) {
      return this.promise
    }

    this.promise = this.getData()
    return this.promise
  }

  render() {
    const { result } = this.state
    const { displayName, users } = result || {}
    const { role } = auth.user

    return (
      <div className="company">
        {result && (
          <div className="company__logo">
            <div>
              <Avatar user={result} />
            </div>
            <div>
              <h2>{displayName}</h2>
              <div>
                {_d('Total users')}: {users && users.length}
              </div>
            </div>
          </div>
        )}

        <article className="elections company__users">
          <Users getData={this.getPromise} />
        </article>

        {role.includes('api') && (
          <article className="elections company__users">
            <ApiKeys getData={this.getPromise} />
          </article>
        )}

        {/* <article>
          <SignaturePref getData={this.getPromise} />
        </article> */}
      </div>
    )
  }
}

export default observer(Company)
