const German = Object.freeze({
  dict: {
    units: [ 'null', 'ein', 'zwei', 'drei', 'vier', 'fünf', 'sechs', 'sieben', 'acht', 'neun' ],
    tens: [ null, 'zehn', 'zwanzig', 'dreißig', 'vierzig', 'fünfzig', 'sechzig', 'siebzig', 'achtzig', 'neunzig' ],
    hundreds: [ 'hundert' ],
    specialGroups: {
      1: [ 'eins', 'ein', 'eine' ],
      11: 'elf',
      12: 'zwölf',
      13: 'dreizehn',
      14: 'vierzehn',
      15: 'fünfzehn',
      16: 'sechzehn',
      17: 'siebzehn',
      18: 'achtzehn',
      19: 'neunzehn',
    },
    scales: [
      null,
      'tausend',
      [ 'Million', 'Millionen' ],
      [ 'Milliarde', 'Milliarden' ],
      [ 'Billion', 'Billionen' ],
      [ 'Billiarde', 'Billiarden' ],
      [ 'Trillion', 'Trillionen' ],
      [ 'Trilliarde', 'Trilliarden' ],
      [ 'Quadrillion', 'Quadrillionen' ],
    ],
  },
  options: {
    useOne: true,
    swapSingleWithTens: true,
    bindingWord: 'und',
    separator: ' ',

    and: (twoDigits, one, ten, hundred, value) => {
      if (value % 100 > 20 && value % 10 !== 0) {
        return German.options.bindingWord
      }
    },
  },
})

export default German
