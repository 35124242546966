import React from 'react'

const Icon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" transform="rotate(90)">
    <path
      fill="currentColor"
      d="M17.45,17.55L12,23L6.55,17.55L7.96,16.14L11,19.17V4.83L7.96,7.86L6.55,6.45L12,1L17.45,6.45L16.04,7.86L13,4.83V19.17L16.04,16.14L17.45,17.55Z"
    />
  </svg>
)

export default Icon
