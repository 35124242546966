import React from 'react'
import cn from 'classnames'

import './LoadingBar.scss'

const Icon = ({ className }) => (
  <div className={cn('cssload-loader', className)}>
    <div className="cssload-inner cssload-one" />
    <div className="cssload-inner cssload-two" />
    <div className="cssload-inner cssload-three" />
  </div>
)

export default Icon
