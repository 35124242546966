import React from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react'

class Affix extends React.Component {
  render() {
    const { matched, selected, cpSelected, values, onChange, type } = this.props

    const isStatic = Array.isArray(values) && values.length === 1

    if (isStatic) {
      return <div className="prefix single">{values[0]}</div>
    }

    return (
      <select
        className={cn(type, { matched, cpValue: cpSelected, value: selected })}
        value={selected}
        onChange={({ target }) => {
          onChange(target.value)
        }}
      >
        <option value="">-- Select --</option>
        {values.map((item, key) => (
          <option key={key} value={item}>
            {item}
          </option>
        ))}
      </select>
    )
  }
}

export default observer(Affix)
