import axios from 'axios'

import helper from 'common/helper'

const api = {
  //-------------------------------------------------------------------------------------------
  baseURL: (window.APP_CONFIG && window.APP_CONFIG.endpoint) || 'http://localhost:3000/api',
  init() {
    this.close()

    const sse = new EventSource(api.baseURL + `/test/sse?stream`)

    sse.addEventListener('register', ({ data }) => {
      return axios.post(`/test/sse`, { id: data })
    })

    sse.addEventListener('negotiation', async ({ lastEventId, data }) => {
      if (this.negotiation && this.negotiation.result && this.negotiation.result._key === lastEventId) {
        await helper.delay(3000)

        try {
          data = JSON.parse(data)
          if (data.deleted) {
            return this.negotiation.setResult(null)
          }
          this.negotiation.update()
        } catch (e) {}
      }
    })

    this.eventSource = sse
    return sse
  },

  //-------------------------------------------------------------------------------------------
  close() {
    if (this.eventSource) {
      this.eventSource.close()
    }
  },

  //-------------------------------------------------------------------------------------------
  on(name, fn) {
    const handler = (event) => {
      try {
        const { lastEventId, type, data } = event
        fn({ data: JSON.parse(data), id: lastEventId, type })
      } catch (error) {
        console.log('ERROR IN UPDATE SSE: ', { event, error })
      }
    }

    this.eventSource.addEventListener(name, handler)

    return () => {
      this.eventSource && this.eventSource.removeEventListener(name, handler, false)
    }
  },

  //-------------------------------------------------------------------------------------------
  getInfo() {
    return axios.get(`/test/sse/info`)
  },

  //-------------------------------------------------------------------------------------------
  disconnect(id) {
    return axios.delete(`/test/sse/` + id)
  },

  //-------------------------------------------------------------------------------------------
}

window.sse = api
export default api
