import React from 'react'
import { observer } from 'mobx-react'

import _d from 'dictionary'

import Avatar from 'common/UserCard/Avatar'
import Table from 'common/Table/Table'

import negotiationsApi from 'api/negotiations'

import MyUser from './Settings.me'
import CpUser from './Settings.cp'
import DeleteNegotiation from './Settings.delete'

import './Settings.scss'

class Settings extends React.Component {
  isAllowed(name, perm = this.me) {
    const { actions, permissions } = perm
    const isAdmin = permissions === 'admin'

    return (actions && actions.includes(name)) || isAdmin
  }

  usersTable = {
    // header: 'Other users',

    actions: ({ perm, type, company }) => {
      const isAdmin = perm.permissions === 'admin'
      const isCompany = type === 'company'
      const isDefault = perm._to.split('/')[0] === 'Companies'

      const { owner } = this.props.negotiation.myParty
      const isSameCompany = owner._id === (company && company._id)

      const { signatories } = this

      const meAllowed = this.isAllowed('canAssignUsers')
      const meAdmin = this.me.permissions === 'admin'

      const isEditable = !(isAdmin && !signatories)
      const canDowngrade = (meAdmin || !isAdmin) && isEditable
      const canEdit = meAllowed && !isCompany && isEditable

      return (
        meAllowed && [
          canEdit && {
            label: 'Edit',
            onSubmit: (values) => {
              const { negotiation } = this.props
              const { actions, allowSignBehalf, permissions } = values.perm
              negotiationsApi.updateAlias(negotiation, { actions, allowSignBehalf, permissions, email: values.email })
            },

            form: [
              !isAdmin && {
                type: 'checkbox',
                name: 'perm.actions',
                // defaultValue: [ 'canEdit', 'canSend', 'canAgree' ],
                optional: true,
                options: [
                  {
                    label: '=negotiationPermissions.canAssignUsers',
                    value: 'canAssignUsers',
                  },
                  this.isOwner && {
                    label: '=negotiationPermissions.canHide',
                    value: 'canHide',
                  },
                  {
                    label: '=negotiationPermissions.canEdit',
                    value: 'canEdit',
                  },
                  {
                    label: '=negotiationPermissions.canSend',
                    value: 'canSend',
                  },
                  {
                    label: '=negotiationPermissions.canAgree',
                    value: 'canAgree',
                  },
                  {
                    label: '=negotiationPermissions.canSign',
                    value: 'canSign',
                  },
                ],
              },
              signatories && {
                type: 'checkbox',
                name: 'perm.allowSignBehalf',
                optional: true,
                options: [
                  ...[]
                    .concat(signatories)
                    .map((item) => ({ ...item, value: item.name, label: 'Sign behalf - ' + item.label })),
                ],
              },
            ],
          },
          canDowngrade &&
            !isSameCompany &&
            !isDefault && {
              label: _d('Remove'),
              confirm: _d('negotiationPermissions.confirmRemove'),
              className: 'error',
              onClick: this.onRemove,
            },
          canDowngrade &&
            isSameCompany &&
            !isDefault && {
              label: _d('Reset'),
              confirm: _d('negotiationPermissions.resetPermissionConfirmation'),
              onClick: this.onRemove,
            },
        ]
      )
    },
    add: () =>
      this.isAllowed('canAssignUsers') && {
        addButton: 'Invite user',
        submitButton: 'Invite user',
        onSubmit: (values) => {
          const { negotiation } = this.props
          negotiationsApi.share(negotiation, values)
        },
        inline: false,
        form: [
          {
            type: 'text',
            name: 'email',
            label: 'Email',
            autoFocus: true,
            validate: {
              required: true,
              type: 'email',
            },
          },
          {
            type: 'checkbox',
            name: 'actions',
            label: '=Permissions',
            optional: true,
            defaultValue: ['canEdit', 'canSend', 'canAgree'],
            options: [
              {
                label: '=negotiationPermissions.canAssignUsers',
                value: 'canAssignUsers',
              },
              this.isOwner && {
                label: '=negotiationPermissions.canHide',
                value: 'canHide',
              },
              {
                label: '=negotiationPermissions.canEdit',
                // description: _d('negotiationPermissions.canEditDescription'),
                value: 'canEdit',
              },
              {
                label: '=negotiationPermissions.canSend',
                value: 'canSend',
              },
              {
                label: '=negotiationPermissions.canAgree',
                value: 'canAgree',
              },
              {
                label: '=negotiationPermissions.canSign',
                value: 'canSign',
              },
            ],
          },
          this.signatories && {
            type: 'checkbox',
            name: 'allowSignBehalf',
            optional: true,
            options: [
              ...[]
                .concat(this.signatories)
                .map((item) => ({ ...item, value: item.name, label: 'Sign behalf - ' + item.label })),
            ],
          },
        ],
      },
    columns: [
      {
        width: 50,
        content: (item) => <Avatar user={item} className="medium" />,
      },
      {
        header: _d('User'),
        content: ({ displayName, email }) => (
          <div>
            <div>{displayName}</div>
            <div className="desc">{email}</div>
          </div>
        ),
      },

      this.signatories && {
        header: _d('Sign on behalf'),
        content: ({ perm }) => <div className="desc">{this.getSignatoryLabel(perm.allowSignBehalf).join(', ')}</div>,
      },
      this.isAllowed('canAssignUsers') && {
        header: _d('Permissions'),
        content: ({ perm: { actions, permissions, allowSignBehalf } }) => (
          <div>
            {permissions === 'admin' ? (
              <h4>Admin</h4>
            ) : (
              actions && (
                <ul className="perm-list">
                  {actions.map((item) => (
                    <li key={item}>{_d('negotiationPermissions.' + item)}</li>
                  ))}
                </ul>
              )
            )}
            {allowSignBehalf && (
              <ul className="perm-list">
                {allowSignBehalf.map((item) => (
                  <li key={item}>Sign behalf of {item}</li>
                ))}
              </ul>
            )}
          </div>
        ),
      },
    ],
  }

  get position() {
    const { doc = {}, myParty } = this.props.negotiation
    return doc.schema.positions.find((item) => item.name === myParty.position)
  }

  get signatories() {
    const { signatories } = this.position
    return signatories && !!signatories.length && signatories
  }

  getSignatoryLabel(list) {
    const { signatories } = this
    if (signatories && list) {
      const signatory = signatories.filter((item) => list.includes(item.name)).map((item) => item.label)
      return signatory || list
    }
    return []
  }

  get negotiation() {
    return negotiationsApi.current
  }

  get isAdmin() {
    return this.me.permissions === 'admin'
  }

  get me() {
    const { me = {}, user } = this.negotiation
    return { ...me, id: user._key }
  }

  get isOwner() {
    const { myParty } = this.negotiation
    return myParty && myParty.isOwner
  }

  onRemove = (user) => {
    const { negotiation } = this.props
    return negotiationsApi.removeAlias(negotiation, user.perm)
  }

  render() {
    const { myParty } = this.negotiation

    myParty.users.forEach((item) => {
      if (item.perm.permissions === 'write') {
        item.perm.permissions = null
        item.perm.actions = ['canEdit', 'canSend', 'canAgree']
      }
    })
    const alias = myParty.users

    const { id } = this.me

    const isMe = ({ _key }) => _key === id

    const myUser = alias.find(isMe)
    const otherAlias = alias.filter((u) => !isMe(u))

    return (
      <div className="neg-settings">
        <CpUser {...this.negotiation} />
        <h3>{_d('My party')}</h3>
        <div>
          <MyUser user={myUser} signatories={this.signatories} negotiation={this.negotiation} />
          <Table config={this.usersTable} list={otherAlias} className="small dark elections" />

          <DeleteNegotiation negotiation={this.negotiation} user={myUser} />
        </div>
      </div>
    )
  }
}

export default observer(Settings)
