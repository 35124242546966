import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import DocumentInfo from './DocumentInfo/DocumentInfo'
import Counterparty from './Counterparty/Counterparty'
import MyParty from './MyParty/MyParty'
// import Progress from './Progress/Progress'
import Settings from './Settings/Settings'
import _d from 'dictionary'
import InfoIcon from 'common/Icons/Info'
import CounterpartyIcon from 'common/Icons/Counterparty'
import PartyIcon from 'common/Icons/Party'
// import ProgressIcon from 'common/Icons/Progress'
import SettingsIcon from 'common/Icons/Settings'

import './MoreTabs.scss'

class Toolbar extends React.Component {
  tabs = [
    {
      icon: <InfoIcon />,
      label: _d('Document summary'),
      name: 'documentInfo',
      content: DocumentInfo,
    },
    {
      icon: <CounterpartyIcon />,
      label: _d('Counterparty'),
      name: 'counterparty',
      content: Counterparty,
    },
    {
      icon: <PartyIcon />,
      label: _d('My party'),
      name: 'myParty',
      content: MyParty,
    },
    // {
    //   icon: <ProgressIcon />,
    //   label: 'Progress',
    //   name: 'progress',
    //   content: Progress,
    // },
    {
      icon: <SettingsIcon />,
      label: _d('Settings'),
      name: 'settings',
      content: Settings,
    },
  ]

  render() {
    const { settings = {} } = this.props
    const { tab } = settings
    const active = this.tabs.find((item) => item.name === tab) || this.tabs[0]

    return (
      <div className="sub-menu">
        <div className="sub-menu__tabs">
          <ul>
            {this.tabs.map((item, key) => (
              <li
                key={key}
                onClick={() => (settings.tab = item.name)}
                className={cn({ active: active === item })}
                data-id={'tab:' + item.name}
              >
                {item.icon && <div className="sub-menu__tabs__icon">{item.icon}</div>} {item.label}
              </li>
            ))}
          </ul>
        </div>
        <div className="sub-menu__content">{<active.content {...this.props} />}</div>
      </div>
    )
  }
}

export default observer(Toolbar)
