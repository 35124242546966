import React, { useState } from 'react'

import _d from 'dictionary'

import { timeDistance } from 'common/helper/date'
import Avatar from 'common/UserCard/Avatar'
import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import SentIcon from 'common/Icons/Sent'
import CloseIcon from 'common/Icons/Close'

import messagesApi from 'api/messages'
import auth from 'api/auth'

import './Settings.me.scss'

const Cp = ({ cpParty, _key, lastExternalActivity, me }) => {
  const [reminder, setReminder] = useState(null)

  const { title, comment, createdAt } = lastExternalActivity || {}
  const timeDiff = createdAt && new Date().getTime() - new Date(createdAt).getTime()

  if (!cpParty) return null

  const wait8h = 1000 * 60 * 60 * 8
  const wait1min = 1000 * 60
  const waitTime = auth.isAdmin ? wait1min : wait8h

  const isAdmin = me.permissions === 'admin'
  const allow = isAdmin && cpParty.isTurn && timeDiff && timeDiff > waitTime

  return (
    <div>
      <h3>{_d('Counterparty')}</h3>
      <div className="cp">
        <div className="cp__main">
          <div className="cp__view">
            <div className="cp__avatar">
              <Avatar user={cpParty.owner} className="big" />
            </div>
            <div className="cp__content">
              <div className="cp__name">
                {cpParty.owner.displayName}
                <div className="cp__email">{cpParty.email}</div>
              </div>

              <div className="cp__last-updated">
                <div>
                  <b>{_d('Last updated')}</b>
                  <time>{timeDistance(cpParty.lastUpdated, _d('ago'))}</time>
                </div>

                <button
                  className="btn"
                  onClick={() => setReminder('')}
                  disabled={!isAdmin || !cpParty.isTurn || reminder !== null}
                >
                  <SentIcon /> {_d('Send reminder')}
                </button>
              </div>
            </div>
          </div>
        </div>

        {reminder !== null && (
          <div className="cp__reminder">
            <h4>
              {_d('Send reminder to all Counterparty users')}
              <button className="btn remove" onClick={() => setReminder(null)}>
                <CloseIcon />
              </button>
            </h4>

            <div className="cp__last-activity">
              <div>
                <label>{_d('Last counterparty action')}:</label>
                <div>{title}</div>
                <time>{timeDistance(createdAt, _d('ago'))}</time>
              </div>

              {comment && (
                <div>
                  <label>{_d('Comment')}:</label>
                  <div className="--comment">{comment}</div>
                </div>
              )}
            </div>

            {allow ? (
              <div>
                <div className="cp__comment">
                  <label>{_d('Write comment')}</label>
                  <textarea value={reminder} onChange={({ target }) => setReminder(target.value)} autoFocus />
                </div>
                <div className="cp__actions">
                  <button
                    className="btn cancel"
                    onClick={() => {
                      setReminder(null)
                    }}
                  >
                    {_d('Cancel')}
                  </button>

                  <ConfirmButton
                    label={_d('Send reminder')}
                    confirm={_d('Are you sure?')}
                    onClick={() => messagesApi.sendReminder(_key, { comment: reminder })}
                  />
                </div>
              </div>
            ) : (
              <div className="cp__disclaimer">{_d('notifications.sendReminderNote')}</div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default Cp
