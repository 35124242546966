import { observer } from 'mobx-react'

import './FloatingMenu.scss'

import Inline from './Inline/Inline'
import OrderedList from './OrderedList/OrderedList'
import UnorderedList from './UnorderedList/UnorderedList'
import Table from './Table/Table'

const FloatingMenu = (props) => {
  const { settings } = props
  const { node, open } = settings
  const name = node && node.type.name

  let MenuComponent = Inline
  if (name === 'ordered_list') {
    MenuComponent = OrderedList
  }

  if (name === 'bullet_list') {
    MenuComponent = UnorderedList
  }

  if (name === 'table') {
    MenuComponent = Table
  }

  return open ? <MenuComponent {...props} /> : null
}

export default observer(FloatingMenu)
