import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Form from 'common/Form/Form'
import FormModel from 'common/Form/model'
import Segment from '../../common/SegmentIO/SegmentIO'
import PreLoad from 'common/PreLoad/PreLoad'

import Dragon from 'common/Dragon'
import DragonPreview from 'common/DocumentView/Dragon.preview'

import Toolbar from './Toolbar/Toolbar'
import ConfirmBox from './ConfirmBox/ConfirmBox'
import Positions from './Positions/Positions'
import Signature from './Signature/Signature'

import Activities from './Activities/Activities'
import Presets from './Presets/Presets'
import Settings from './Settings/Settings'

import _d from 'dictionary'
import negotiationsApi from 'api/negotiations'

import auth from 'api/auth'
import sse from 'api/sse'
import app from 'api/app'

import FormToolbar from './Toolbars/FormToolbar'
// import AsideToolbar from './Toolbars/AsideToolbar'
import PositionsToolbar from './Toolbars/PositionsToolbar'

import './Negotiation.scss'

class Negotiation extends React.Component {
  constructor(props) {
    super(props)
    this.preLoad = new PreLoad(this.getData, '/negotiations')
    this.preLoad.fixed = true

    sse.negotiation = this.preLoad
    app.header = 'relative'
  }

  componentWillUnmount() {
    sse.negotiation = null
    app.header = 'sticky'
    return true
  }

  getData = async () => {
    const { location, history, match } = this.props
    const { id, d } = match.params
    const query = new URLSearchParams(location.search)
    const hash = query.get('hash')

    let negotiation
    if (d === 'draft') {
      negotiation = await negotiationsApi.getOneDraft(id)
    } else {
      negotiation = await negotiationsApi.getOne(id, hash)
    }

    if (!negotiation || !negotiation.myParty) {
      return history.replace('/negotiations')
    }
    const { doc = {}, myParty = {}, myPartyMeta = {}, cpParty, _id, state, version, mainDoc } = negotiation

    Segment.page('App', 'Negotiation', {
      negotiationId: _id,
      version: version,
      state: state,
      docName: mainDoc.label,
    })

    const answersSet = {
      primary: {
        label: _d('My position'),
        shortLabel: _d('me'),
        ...myPartyMeta,
      },
      cpPosition: cpParty && {
        name: 'cpPosition',
        label: _d('Counterparty position'),
        shortLabel: 'CP',
        ...cpParty,
      },
    }

    this.negotiation = negotiation
    this.schema = doc.schema

    const schema = { ...doc.schema, children: [ { type: 'states', label: 'Schema', children: doc.schema.children } ] }

    this.model = new FormModel(schema, answersSet, negotiation)

    const { tpls } = doc

    this.templates = tpls.map((item) => new Dragon(item, myParty.answers, { negotiation }))
    this.template = this.templates[0]

    return negotiation
  }

  tabs = {
    document: {
      label: _d('Document'),
      className: 'doc',
    },
    presets: {
      label: _d('Presets'),
      content: () => <Presets model={this.model} negotiation={negotiationsApi.current} />,
    },
    timeline: {
      label: _d('Timeline'),
      content: () => <Activities model={this.model} negotiation={negotiationsApi.current} />,
    },
    settings: {
      label: _d('Settings'),
      content: () => <Settings model={this.model} negotiation={negotiationsApi.current} />,
    },
  }

  componentDidUpdate() {
    const { model } = this
    const neg = negotiationsApi.current || {}
    if (model && neg.state !== model.state) {
      model.setState(neg.state)
    }
  }

  check = () => {
    const { location, history } = this.props
    const { user } = auth
    const query = new URLSearchParams(location.search)
    const hash = query.get('hash')

    if (!user && !hash) {
      history.push('/login')
    }
  }

  render() {
    this.check()

    const { model = {} } = this

    const { loading, updating, state } = this.preLoad
    const { activeTab = 'document' } = state

    const neg = negotiationsApi.current || {}
    const { me = {}, myParty = {} } = neg

    const confirmBox = neg.state === 'completed'
    return (
      loading || (
        <div className="negotiation">
          <Toolbar negotiation={neg} model={model} settings={state} tpl={this.template} />

          <ConfirmBox negotiation={neg} model={model} settings={state}>
            <div className={cn('split-page', { expand__content: confirmBox })}>
              <div className={cn('split-page__main', model.highlight)}>
                <div className={cn('elections', { isOwner: model.isOwner })}>
                  <PositionsToolbar nr="1" negotiation={neg} />
                  {updating}
                  <Positions negotiation={neg} settings={state} />
                  <FormToolbar negotiation={neg} model={model} settings={state} nr="2" />
                  <div className="elections__form">
                    <Form
                      model={model}
                      tpls={this.templates}
                      readOnly={
                        !myParty.isTurn ||
                        ![ 'draft', 'active', 'agreed', 'execution' ].includes(neg.state) ||
                        me.permissions === 'read' ||
                        state.confirmPage ||
                        (model.settings && model.settings.readOnly)
                      }
                    />
                  </div>

                  <Signature neg={neg} templates={this.templates} />
                </div>
              </div>

              {activeTab && (
                <div className="split-page__aside">
                  <DragonPreview
                    result={model.values}
                    model={model}
                    tpls={this.templates}
                    activeTab={this.tabs[activeTab]}
                    settings={state}
                    negotiation={negotiationsApi.current}
                  />
                </div>
              )}
            </div>
          </ConfirmBox>
        </div>
      )
    )
  }
}

export default observer(Negotiation)
