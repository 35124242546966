import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Form from '../../Form'
import Label from '../Label'

import RadioIcon from 'common/Icons/Radio'

import './Radio.scss'

class Text extends React.Component {
  render() {
    let { meta = {}, readOnly, wrapper } = this.props
    const {
      name,
      value,
      disabled,
      children,
      config = {},
      cpValue,
      primaryShort,
      compare = {},
      cpOnly,
      options = [],
      validation = {},
      isHidden,
      isRestricted,
      viewOnly,
      viewValue,
    } = meta
    const { className } = config

    const { isMatched, isCompleted, isChanged, isInvalid } = validation

    readOnly = readOnly || cpOnly
    return (
      <div
        input-name={name}
        className={cn(className, {
          isHidden,
          isRestricted,
          isMatched,
          isUnmatched: !isMatched,
          isIncompleted: !isCompleted,
          isCompleted,
          isChanged,
          isInvalid,
          viewOnly,
        })}
      >
        <div className={cn('form-item', { cpOnly, empty: !value, readOnly })}>
          <div className="form-item__content">
            <Label {...this.props} />
            {viewOnly ? (
              <div className="form-input">
                <div className="form-input__view-only">{viewValue}</div>
              </div>
            ) : (
              <div className="form-input">
                <ul className={cn('radio', { disabled })} data-id={'radio:' + name}>
                  {options.filter((item) => item).map((item, key) => {
                    const selected = item.value === value
                    const cpPosition = item.value === cpValue
                    return (
                      <li
                        key={key}
                        tabIndex="0"
                        onClick={() => !readOnly && meta.setValue(item.value)}
                        className={cn('radio__item', compare.theme, { selected, cpPosition })}
                      >
                        {(selected || cpPosition) && (
                          <div className="tag">
                            {selected && <div className="tag__primary">{primaryShort}</div>}
                            {cpPosition &&
                            compare.shortLabel && <div className="tag__compare">{compare.shortLabel}</div>}
                          </div>
                        )}
                        <RadioIcon value={selected} />
                        <div>
                          {item.label}
                          {item.description && <div className="desc">{item.description}</div>}
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
        {children &&
        !!children.length && (
          <ul className="radio__children">
            <li>
              <Form items={children} readOnly={readOnly} wrapper={wrapper} />
            </li>
          </ul>
        )}
      </div>
    )
  }
}

export default observer(Text)
