import { useState } from 'react'

import Form from 'common/Form/Form'
import EditIcon from 'common/Icons/Edit2'

import './Positions.scss'

const Positions = ({ model }) => {
  const [ edit, setEdit ] = useState()
  const { positions } = model.values
  const [ partyA, partyB ] = positions || []
  const singleParty = positions.length === 1 ? true : null

  const positionsForm = [
    {
      type: 'radio',
      label: 'Type',
      name: 'singleParty',
      options: [
        {
          label: 'Single party',
          value: true,
        },
        {
          label: 'Multiple parties',
          value: null,
        },
      ],
    },
    {
      type: 'columns',

      children: [
        { type: 'text', label: 'Party A', name: 'partyA.label', validate: { required: true } },
        {
          type: 'text',
          label: 'Party B',
          name: 'partyB.label',
          validate: { required: true },
          hideIf: { singleParty: true },
        },
      ],
    },
    {
      type: 'actions',
      options: [
        {
          label: 'Cancel',
          className: 'cancel',
          onClick: () => setEdit(false),
        },
        {
          label: 'Save',
          type: 'submit',
          validate: true,
        },
      ],
    },
  ]

  const onSubmit = ({ values }) => {
    const { partyA, partyB, singleParty } = values

    const pos = [ partyA, partyB ]
      .map((item = {}) => {
        const { name } = item
        item.name = model.getAutoName(item.label)

        model.pm.replaceSignatures(name, item)

        return { name: item.name, label: item.label }
      })
      .filter((item) => item && item.label)

    model._config.positions = pos
    model._config.singleParty = singleParty

    setEdit(false)
  }

  return (
    <div className="positions elections">
      <header>
        <h2>Positions</h2>
        <button className="positions__edit-btn" onClick={() => setEdit(!edit)} disabled={edit}>
          <EditIcon /> Edit
        </button>
      </header>
      {edit ? (
        <Form config={positionsForm} values={{ partyA, partyB, singleParty }} onSubmit={onSubmit} />
      ) : (
        <div className="positions__view">
          <div className="positions__item">
            <div className="desc">Party A</div>
            <div>{partyA.label}</div>
          </div>
          {partyB && (
            <div className="positions__item">
              <div className="desc">Party B</div>
              <div>{partyB.label}</div>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default Positions
