import React from 'react'

const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
      <g transform="translate(3.000000, 5.000000)" fill="currentColor">
        <path
          d="M7.79889829,12.7379306 C7.99054033,12.9546158 11.0051218,11.4851188 11.0681218,11.4165474 L19.1130038,3.75927781 C20.2710883,2.52132817 20.5416769,1.59538769 19.9247696,0.981456368 C19.3078623,0.367525048 18.4215329,0.436322671 17.2657816,1.18784924 L8.85100383,9.43089686 C8.78100383,9.49946829 7.60725624,12.5212453 7.79889829,12.7379306 Z M11.3543231,4.42156256 L15.7540987,0.111578369 C15.9068777,-0.0380827649 16.1512915,-0.0380827649 16.3040706,0.111578369 L16.3040706,0.111578369 C16.4528418,0.257313382 16.455303,0.496057805 16.309568,0.644828964 C16.3077545,0.646680283 16.3059219,0.648512856 16.3040706,0.650326393 L11.9042951,4.96031058 C11.751516,5.10997172 11.5071022,5.10997172 11.3543231,4.96031058 L11.3543231,4.96031058 C11.205552,4.81457557 11.2030907,4.57583115 11.3488257,4.42705999 C11.3506392,4.42520867 11.3524718,4.4233761 11.3543231,4.42156256 Z"
          fillRule="nonzero"
        />
        <path d="M10.1111111,6.02608744 L7.9005848,8.15063251 C7.78657605,8.25343644 5.55895699,13.5487459 6.22222222,14.0647935 C6.67893032,14.4201312 8.76096559,13.88042 10.0469651,13.4762355 C9.80316549,14.4871015 8.8928403,15.2380952 7.80711111,15.2380952 L2.304,15.2380952 C1.03153594,15.2380952 4.25863035e-14,14.2065593 4.13002965e-14,12.9340952 L4.17443857e-14,3.82780952 C4.15885538e-14,2.55534546 1.03153594,1.52380952 2.304,1.52380952 L7.80711111,1.52380952 C9.07957517,1.52380952 10.1111111,2.55534546 10.1111111,3.82780952 L10.1111111,6.02608744 Z" />
      </g>
    </g>
  </svg>
)

export default Icon
