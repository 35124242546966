import React, { useState } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Story from './Story/Story'

import StartNegotiation from 'data/tutorial/startNegotiation.json'
import AcceptInvitation from 'data/tutorial/acceptInvitation.json'
import Negotiate from 'data/tutorial/negotiate.json'
import Execute from 'data/tutorial/execution.json'
import _d from 'dictionary'
import './Home.scss'

const tabs = [ StartNegotiation, AcceptInvitation, Execute, Negotiate ]

const Home = () => {
  const [ active, setActive ] = useState(tabs[0])
  window.Home = this

  return (
    <div className="home">
      <div>
        <div>
          <h1>{_d('How to use')}?</h1>
        </div>

        <div>
          <ul className="flex">
            {tabs.map((item, key) => (
              <li key={key}>
                <button onClick={() => setActive(item)} className={cn('btn tab', { active: active === item })}>
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <ul>
            {active.children.map((item, key) => <Story key={key + '|' + tabs.indexOf(active)} index={key} {...item} />)}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default observer(Home)
