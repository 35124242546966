import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Form from '../../Form'

import GroupHeader from './Group.header'
import GroupAmend from './Group.amend'

import './Group.scss'

class Group extends React.Component {
  render() {
    const { meta = {}, readOnly, wrapper, data } = this.props
    const { children, config = {}, amend, id, active, open, state, setOpen } = meta

    const hasChildren = children && !!children.length
    const hasContent = amend || hasChildren
    const showExpand = hasContent || config.amend
    const inactiveMessage = !active && config.inactive

    const props = {
      className: cn('form-group', state, { dark: !active, open, 'no-children': !hasChildren }),
      tabIndex: 0,
    }

    if (id) {
      props['data-group-id'] = id
    }

    return (
      <div {...props}>
        <GroupHeader {...this.props} open={open} onOpen={(open) => setOpen(open)} />

        {open &&
        showExpand && (
          <ul className="form-group__children">
            {inactiveMessage ? (
              <li className="form-group__message">
                <h3>{inactiveMessage}</h3>
              </li>
            ) : (
              children && (
                <li>
                  <Form items={children} readOnly={readOnly || !active} wrapper={wrapper} data={data} />
                </li>
              )
            )}

            <GroupAmend {...this.props} />
          </ul>
        )}
      </div>
    )
  }
}

export default observer(Group)
