import React from 'react'

const Icon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M12 20C16.4 20 20 16.4 20 12S16.4 4 12 4 4 7.6 4 12 7.6 20 12 20M12 2C17.5 2 22 6.5 22 12S17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2M17 13.9L16.3 15.2L11 12.3V7H12.5V11.4L17 13.9Z"
    />
  </svg>
)

export default Icon
