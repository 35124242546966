const dic = {
  Lithuanian: 'Lietuviu',
  Home: 'Pradzia',
  Documents: 'Dokumentai',
  Document: 'Documentas',
  Templates: 'Šablonai',
  Negotiations: 'Sutartys',
  CompanyInfo: 'Kompanijos info',
  Profile: 'Mano profilis',
  Help: 'Pagalba',
  Login: 'Prisijungti',
  Logout: 'Atsijungti',
  Users: 'Vartotojai',
  Version: 'Versija',
  DocumentName: 'Dokumento pavadinimas',
  Category: 'Kategorija',
  Language: 'Kalba',
  Presets: 'Išsaugoti',
  Counterparty: 'Sandorio šalis',
  AddCounterparty: 'pridėti sandorio šalį',
  MyParty: 'My Party',
  'Start new Negotiation': 'Pradeti nauja sutarti',
  Elections: 'Elections',
  Preview: 'Preview',
  Updated: 'Pakeista',
  Visited: 'Ziureta',
  Created: 'Sukurta',
  CreatedBy: 'Sukurta',
  State: 'Statusas',
  More: 'Daugiau',
  Save: 'Issaugot',
  Highlight: 'Pazymeti',
  Timeline: 'Istorija',
  Settings: 'Nustatymai',
  //-------------------------------------------------------
  // Create account
  'Choose account type': 'Pasirinkit paskyros tipa',
  'Profile info': 'Vartotojo informacija',
  Password: 'Slaptazodis',
  'Confirm password': 'Pakartoti slaptazodi',
  'Password have to match': 'Slaptazodziai turi sutapti',
  'First name': 'Vardas',
  'Last name': 'Pavarde',
  'Your email': 'Jusu E-pastas',
  Individual: 'Asmeninis',
  Company: 'Kompanija',
  'Minimum characters': (nr) => `Maziausiai ${nr} simboliai!`,
  'Read our': 'Susipazinkite su',
  'terms and conditions': 'naudojimo taisykles',
  'Accept terms and conditions': 'Susipazinau su naudojimo taisyklemis',
  'Create account': 'Sukurti paskyra',
  Skip: 'Praleisti',
  'Fields to complete': 'Neuzpildyti laukai',
  ca: {
    Description: null,
    'Individual desc': null,
  },
  //-------------------------------------------------------
  // Signature
  'Other assigned signatories': 'Kiti vartotojai galintys patvirtinti E-parasu',
  'Sign as': 'Pasirasyti kaip',
  'Choose signature style': 'Pasirinkit paraso stiliu',
  Assign: 'Priskirti',
  Cancel: 'Uzdaryti',
  Email: 'E-pastas',
  'Choose user': 'Pasirinkti vartotoja',
  'New user': 'Naujas vartotojas',
  Sign: 'Pasirasyti',
  Permissions: 'Privilegijos',
  'Sign behalf': (position) => `Pasirasyti kaip ${position}?`,
  'Signature disclaimer': 'Your signature will be applied on the document and will be visible to all parities!',
}

export default dic
