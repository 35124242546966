import { observer } from 'mobx-react'

import DragIcon from 'common/Icons/Drag2'
import EditIcon from 'common/Icons/Edit2'
import RemoveIcon from 'common/Icons/Delete'
import PlusIcon from 'common/Icons/PlusCircleOutline'
// import SettingsIcon from 'common/Icons/Settings'
import RadioIcon from 'common/Icons/Radio'
import RadioIcon2 from 'common/Icons/RadioIcon'

import DropDown from 'common/DropDown/DropDown'
import Edit from '../Edit'

const Radio = ({ model }) => {
  const { label, type, edit, showRemove, options, tags = [], isFocused, fullName, attributes } = model
  const count = tags.length

  return (
    <div className="fel --radio">
      {isFocused && (
        <style>
          .document-edit__content [dr-text="{fullName}"] {`{ background: var(--dark); }`}
        </style>
      )}
      <div className="fel__content" {...attributes}>
        <div className="fel__header">
          <div className="drag-handle">
            <DragIcon />
          </div>
          <div count={count} title={`Total ${count} tags!`} />

          <h4 onDoubleClick={() => console.log(model)}>{label}</h4>

          <DropDown
            className="fel__settings"
            options={[
              { label: 'Edit', onClick: () => (model.edit = !edit), icon: <EditIcon /> },
              { label: 'New below', onClick: () => model.after(), icon: <PlusIcon /> },
              {
                label: 'Remove',
                onClick: () => (model.showRemove = !showRemove),
                className: 'error2',
                icon: <RemoveIcon />,
              },
            ]}
          >
            <span>{type}</span>
            <RadioIcon2 />
          </DropDown>
        </div>
        <ul className="fel__options">
          {options &&
            options.map((item, key) => (
              <li key={key}>
                <RadioIcon /> {item.label}
              </li>
            ))}
        </ul>
      </div>

      {edit && (
        <div className="fel__edit">
          <Edit model={model} />
        </div>
      )}

      {showRemove && (
        <div className="fel__remove">
          <h4>Sure?</h4>
          <div className="fel__remove__actions">
            <button className="btn cancel small" onClick={() => (model.showRemove = false)}>
              Cancel
            </button>
            <button className="btn small error" onClick={() => model.remove()}>
              Remove
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(Radio)
