import { useState } from 'react'
import { useHistory } from 'react-router-dom'

import AsyncButton from 'common/AsyncButton/AsyncButton'
import negotiationsApi from 'api/negotiations'

const DeleteNegotiation = ({ user, negotiation }) => {
  const history = useHistory()
  const [confirm, setConfirm] = useState()

  const { perm = {} } = user
  const isNegotiationAdmin = perm.permissions === 'admin'

  return (
    <div className="neg-settings__actions">
      {confirm && (
        <div className="delete-overlay">
          <h1>Are you sure?</h1>
          <div className="delete-overlay__actions">
            <button className="btn cancel" onClick={() => setConfirm(false)}>
              Cancel
            </button>
            <AsyncButton
              className="btn error"
              onClick={async () => {
                await negotiationsApi.delete(negotiation)
                history.replace('/negotiations')
              }}
            >
              Delete
            </AsyncButton>
          </div>
        </div>
      )}

      <button className="btn error" onClick={() => setConfirm(true)} disabled={!isNegotiationAdmin}>
        Delete negotiation
      </button>
    </div>
  )
}

export default DeleteNegotiation
