import React from 'react'
import { observer } from 'mobx-react'

import Form from '../../Form'

import ToggleButton from 'common/ToggleButton/ToggleButton'

import './Group.scss'

class GroupAmend extends React.Component {
  amend = (on) => {
    const { meta = {}, tpls } = this.props
    const { id } = meta
    let { data } = meta

    let body

    if (on) {
      this.data = data

      const tpl = tpls.find(({ amend }) => amend[id])
      body = tpl.amend[id].getResult()

      data = { __amend: body }

      meta.body = body
    } else {
      data = this.data
    }

    meta.setAmend(body)
  }

  render() {
    const { meta = {}, readOnly } = this.props
    const { config = {}, data = {}, amend, updated } = meta
    this.updated = updated

    return [
      config.amend && (
        <li key="btn" className="form-group__amend">
          {amend ? (
            <ToggleButton
              disabled={readOnly}
              onChange={(state) => this.amend(state)}
              label="Amend"
              value={!!data.__amend}
            />
          ) : (
            <button className="btn link mini" disabled={readOnly} onClick={() => this.amend(true)}>
              Amend
            </button>
          )}
        </li>
      ),
      amend && (
        <li key="content" className="form-group__amend__content">
          <Form items={amend} readOnly={readOnly} />
        </li>
      ),
    ]
  }
}

export default observer(GroupAmend)
