const docx = {
  defaultCounterName: 'DEFAULT_COUNTER',
  mainConfig: {
    highlightFields: false,
    lang: 'en-GB',
    fontFamily: 'Arial',
    bold: 0,
    italic: 0,
    underlined: 0,
    highlighted: 0,
    insideContainer: 0,
    parentStyle: null,
    refStyle: [],
    tablesProps: [],
    images: [],
    counters: {},
    bulletListDepth: -1,
    numberedListStyleIDList: [],
    numberedListStyleIDInDOCX: 2,
    bulletListStyleIDInDOCX: 2,
    lastWrapperListType: [],
    nextPListItemType: null,
    documentWidthPixels: 664,

    paperSize: {
      A4: {
        dimensions: { MM: { portraitWidth: 210, portraitHeight: 297 } },
        margins: { MM: { top: 20, right: 15, left: 21, bottom: 15 } },
      },
      Letter: {
        dimensions: { MM: { portraitWidth: 215.9, portraitHeight: 279.4 } },
        margins: { MM: { top: 20, right: 15, left: 21, bottom: 15 } },
      },
    },
  },

  mmToTwips(millis) {
    return Math.round(millis * 56.69291338582677)
  },
  twipsToMM(twips) {
    return Math.round(twips * 0.017638888888888888)
  },

  async toDocx(config = JSON.parse(JSON.stringify(this.mainConfig))) {
    let container = window.document.querySelector('div.document-preview > div')

    const marginTwips = {
      left: this.mmToTwips(config.paperSize.A4.margins.MM.left),
      right: this.mmToTwips(config.paperSize.A4.margins.MM.right),
      top: this.mmToTwips(config.paperSize.A4.margins.MM.top),
      bottom: this.mmToTwips(config.paperSize.A4.margins.MM.bottom),
    }

    config.paperSize.A4.margins.Twips = marginTwips
    config.paperSize.A4.maxUsableWidthMM =
      config.paperSize.A4.dimensions.MM.portraitWidth - config.paperSize.A4.margins.MM.left - config.paperSize.A4.margins.MM.right
    config.paperSize.A4.maxUsableWidthTwips = this.mmToTwips(config.paperSize.A4.maxUsableWidthMM)

    const buffer = []
    this.dfsOnHTMLNodesPreOrder(container, buffer, config)

    const sectionProps = `<w:sectPr>
    <w:pgSz w:w="${this.mmToTwips(config.paperSize.A4.dimensions.MM.portraitWidth)}" w:h="${this.mmToTwips(
      config.paperSize.A4.dimensions.MM.portraitHeight
    )}"/>
    <w:pgMar w:top="${marginTwips.top}" w:right="${marginTwips.right}" w:bottom="${marginTwips.bottom}" w:left="${marginTwips.left}" w:header="709" w:footer="709" w:gutter="0"/>
    <w:cols w:space="708"/>
    <w:docGrid w:linePitch="360"/>
    </w:sectPr>`.replaceAll(/\n\s+/g, '')
    return { document: `<w:body>${buffer.join('')}${sectionProps}</w:body>`, config }
  },

  dfsOnHTMLNodesPreOrder(currentNode, buffer, config) {
    let counterNameList = []
    let localBuff = buffer
    let prevStyle = config.parentStyle

    if (![ '#text', '#comment', 'STYLE' ].includes(currentNode.nodeName)) {
      config.parentStyle = window.getComputedStyle(currentNode)
      counterNameList = this.counterIncrementLevel(currentNode, config)
      if (counterNameList.length > 0) {
        config.lastWrapperListType.push({ type: 'numbered', counters: counterNameList })
        // console.log('COUNTER: ', counterNameList, 'at', currentNode.nodeName, JSON.stringify(config.counters))
      }
    }
    switch (currentNode.nodeName) {
      case '#comment':
      case 'STYLE':
        return

      case '#text':
        this.mapToTextRun(currentNode, localBuff, config)
        return

      case 'IMG':
        this.mapImage(currentNode, localBuff, config)
        return

      case 'BR':
        this.mapBR(currentNode, localBuff, config)
        this.counterDecLevelIfAny(counterNameList, config)
        return

      case 'H1':
      case 'H2':
      case 'H3':
      case 'H4':
      case 'H5':
      case 'H6':
      case 'H7':
      case 'H8':
      case 'H9':
        config.insideContainer = config.insideContainer + 1
        config.lastTextRun = null
        this.mapHeading(currentNode, localBuff, config)
        config.lastTextRun = null
        config.insideContainer = config.insideContainer - 1
        config.parentStyle = prevStyle
        return

      case 'DIV':
        this.setListItemType(currentNode, config)
        break

      case 'U':
        config.underlined = config.underlined + 1
        break

      case 'EM':
      case 'I':
        config.italic = config.italic + 1
        break

      case 'STRONG':
      case 'B':
        config.bold = config.bold + 1
        break

      case 'DEL':
        config.deleted = config.deleted + 1
        break
      case 'INS':
        config.inserted = config.inserted + 1
        break

      case 'TABLE':
        config.tablesProps.push({ columnsPerRow: [] })
        localBuff = []
        config.lastTextRun = null
        break

      case 'UL':
        config.lastWrapperListType.push({ type: 'bullet' })
        config.bulletListDepth = config.bulletListDepth + 1
        config.lastTextRun = null
        break

      case 'LI':
        this.setListItemType(currentNode, config)
        config.lastTextRun = null
        break

      case 'TR':
        config.tablesProps[config.tablesProps.length - 1].columnsPerRow.push(0)
        localBuff = []
        config.lastTextRun = null
        break

      case 'TH':
      case 'TD':
        let counter = config.tablesProps[config.tablesProps.length - 1].columnsPerRow.pop()
        config.tablesProps[config.tablesProps.length - 1].columnsPerRow.push(counter + 1)
        localBuff = []
        config.lastTextRun = null
        break

      //Wrapper tags. Collect children separately
      case 'P':
        config.insideContainer = config.insideContainer + 1
        localBuff = []
        config.lastTextRun = null
        break
    }

    if (currentNode && currentNode.childNodes && currentNode.childNodes.length > 0) {
      for (let i = 0; i < currentNode.childNodes.length; i++) {
        this.dfsOnHTMLNodesPreOrder(currentNode.childNodes[i], localBuff, config)
      }
    }

    switch (currentNode.nodeName) {
      case 'P':
        this.mapP(currentNode, localBuff, buffer, config)
        config.insideContainer = config.insideContainer - 1
        config.nextPListItemType = null
        config.lastTextRun = null
        break

      case 'TABLE':
        this.mapTable(currentNode, localBuff, buffer, config)
        config.tablesProps.pop()
        break

      case 'TR':
        this.mapTableRow(currentNode, localBuff, buffer, config)
        config.lastTextRun = null
        break

      case 'TH':
      case 'TD':
        this.mapTableColumn(currentNode, localBuff, buffer, config)
        config.lastTextRun = null
        break

      case 'UL':
        config.lastWrapperListType.pop()
        config.bulletListDepth = config.bulletListDepth - 1
        config.lastTextRun = null
        break

      case 'DEL':
        config.deleted = config.deleted - 1
        break

      case 'INS':
        config.inserted = config.inserted - 1
        break

      case 'U':
        config.underlined = config.underlined - 1
        break

      case 'EM':
      case 'I':
        config.italic = config.italic - 1
        break

      case 'STRONG':
      case 'B':
        config.bold = config.bold - 1
        break

      case 'DIV':
      //nop
      default:
        break
      // console.log('NO MATCH: ', currentNode.nodeName)
    }
    config.parentStyle = prevStyle
    if (counterNameList.length > 0) {
      // console.log('DECREMENT: ', counterNameList, currentNode.nodeName, JSON.stringify(config.counters))
      this.counterDecLevelIfAny(counterNameList, config)
      config.lastWrapperListType.pop()
    }
  },

  inlineChildren(localBuffer, buffer) {
    if (localBuffer.length <= 0) return
    buffer.push(localBuffer.join(''))
  },

  mapTable(node, localBuffer, buffer, config) {
    if (!localBuffer || localBuffer.length <= 0) return

    buffer.push('<w:tbl>')
    this.createStyleForTable(buffer, config)
    this.inlineChildren(localBuffer, buffer, config)
    buffer.push('</w:tbl>')
    buffer.push('<w:p/>')
  },

  mapTableRow(node, localBuffer, buffer, config) {
    if (!localBuffer || localBuffer.length <= 0) return

    buffer.push('<w:tr>')
    this.inlineChildren(localBuffer, buffer, config)
    buffer.push('</w:tr>')
  },

  mapTableColumn(node, localBuffer, buffer, config) {
    buffer.push('<w:tc>')
    this.createStyleForColumn(node, buffer, config)
    //when empty cell, create empty P
    if (localBuffer <= 0) this.mapP(node, localBuffer, buffer, config)
    else this.inlineChildren(localBuffer, buffer, config)
    buffer.push('</w:tc>')
  },

  mapP(node, localBuffer, buffer, config) {
    this.setListItemType(node, config)
    buffer.push(`<w:p>`)
    this.createStyleForParagraph(node, buffer, config)
    this.inlineChildren(localBuffer, buffer)
    buffer.push(`</w:p>`)
    config.nextPListItemType = null
  },

  escapedText(text) {
    // return new Option(text.replaceAll(/[ \t\n\r]+/gm, ' ')).innerHTML
    return text.replaceAll(/[ \t\n\r]+/gm, ' ').replaceAll('&', '&amp;').replaceAll('<', '&lt;').replaceAll('>', '&gt;')
  },

  fixSpacing(text, config) {
    if (config.lastTextRun && config.lastTextRun.endsWith(' ') && text.startsWith(' ')) {
      text = text.trimLeft()
    } 

    if (config.lastTextRun === null) {
      text = text.trimLeft()
    }

    if (text)config.lastTextRun = text
    return text
  },

  mapToTextRun(nodeSpan, buffer, config) {
    if (!nodeSpan.textContent) return
    let hasSqBrackets = null

    if (config.highlightFields) {
      hasSqBrackets = nodeSpan.textContent.match(/\[[^\]\[]+\]/)
      if (hasSqBrackets && nodeSpan.childNodes.length <= 0) {
        config.highlighted = config.highlighted + 1
      }
    }

    let content = this.escapedText(nodeSpan.textContent)

    //Non-empty nodes only get inserted inside Ps
    if (config.insideContainer > 0) {
      // w:r needs to be inside a container like P or TD, otherwise it breaks the document

      content = this.fixSpacing(content, config)

      if (content ) {
        buffer.push(`<w:r>`)
        this.createStylePropsForRun(buffer, config)  
        buffer.push(`<w:t xml:space="preserve">${content}</w:t></w:r>`)
      }      
    } else if (nodeSpan.textContent.trim()) {
      // if not, wrap in P
      buffer.push('<w:p>')
      this.createStyleForParagraph(nodeSpan, buffer, config)
      buffer.push('<w:r>')
      this.createStylePropsForRun(buffer, config)
      buffer.push(`<w:t xml:space="preserve">${content}</w:t></w:r></w:p>`)
    }

    if (config.highlightFields && hasSqBrackets) {
      config.highlighted = config.highlighted - 1
    }
  },

  saveImgToFile(imgSrc, config) {
    if (!imgSrc.trim()) return

    // if (imgSrc.startsWith('https')) {
    // } else
    if (imgSrc.startsWith('data:')) {
      const indexContentSep = imgSrc.indexOf(',')
      let format = imgSrc.slice(5, indexContentSep).toLowerCase().split(';')

      if (format[1].toLowerCase() === 'base64') {
        let content = imgSrc.slice(imgSrc.indexOf(',') + 1)
        let imgIndex = config.images.push({ content, format: format[0].split('/')[1], formatContentType: format[0] })
        return imgIndex
      }
    }
    return null
  },

  mapImage(node, buffer, config) {
    const EMU = 360000
    const relsOffset = 6
    let imgSrc = node.src
    let imgIndex = this.saveImgToFile(imgSrc, config)
    if (!imgIndex) return

    imgIndex = relsOffset + imgIndex

    let imgWidth = Math.round(
      config.paperSize.A4.maxUsableWidthMM / 10 * parseFloat(config.parentStyle.width) / config.documentWidthPixels * EMU
    )
    let imgHeight = Math.ceil(parseFloat(config.parentStyle.height) / parseFloat(config.parentStyle.width) * imgWidth)

    let imgObject = `
    <w:drawing>
      <wp:inline distT="0" distB="0" distL="0" distR="0" wp14:anchorId="654A42AB" wp14:editId="6CEC81F8">
      <wp:extent cx="${imgWidth}" cy="${imgHeight}"/>
      <wp:effectExtent l="0" t="0" r="0" b="1905"/>
      <wp:docPr id="1" name="Picture 1"/>
      <wp:cNvGraphicFramePr>
        <a:graphicFrameLocks xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" noChangeAspect="1"/>
      </wp:cNvGraphicFramePr>
      <a:graphic xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
        <a:graphicData uri="http://schemas.openxmlformats.org/drawingml/2006/picture">
          <pic:pic xmlns:pic="http://schemas.openxmlformats.org/drawingml/2006/picture">
            <pic:nvPicPr>
              <pic:cNvPr id="1" name="Picture 1" descr="A picture containing linedrawing&#xA;&#xA;Description automatically generated"/>
              <pic:cNvPicPr/>
            </pic:nvPicPr>
            <pic:blipFill>
              <a:blip r:embed="rId${imgIndex}" cstate="print">
                <a:extLst>
                  <a:ext uri="{28A0092B-C50C-407E-A947-70E740481C1C}">
                    <a14:useLocalDpi xmlns:a14="http://schemas.microsoft.com/office/drawing/2010/main" val="0"/>
                  </a:ext>
                </a:extLst>
              </a:blip>
              <a:stretch>
                <a:fillRect/>
              </a:stretch>
            </pic:blipFill>
            <pic:spPr>
              <a:xfrm>
                <a:off x="0" y="0"/>
                <a:ext cx="${imgWidth}" cy="${imgHeight}"/>
              </a:xfrm>
              <a:prstGeom prst="rect">
                <a:avLst/>
              </a:prstGeom>
            </pic:spPr>
          </pic:pic>
        </a:graphicData>
      </a:graphic>
    </wp:inline>
  </w:drawing>
  `

    if (config.insideContainer <= 0) {
      let localBuffer = []
      localBuffer.push('<w:r>')
      this.createStylePropsForRun(localBuffer, config)
      // localBuffer.push('<w:r><w:br/></w:r>')

      localBuffer.push(imgObject)
      localBuffer.push('</w:r>')

      this.mapP(node, localBuffer, buffer, config)
    } else buffer.push(imgObject)
  },

  mapHeading(node, buffer, config) {
    config.refStyle.push('Heading' + node.nodeName[node.nodeName.length - 1])

    buffer.push('<w:p>')
    this.createStyleForParagraph(node, buffer, config)
    this.mapToTextRun(node, buffer, config)
    buffer.push(`</w:p>`)

    config.refStyle.pop()
    config.nextPListItemType = null
  },

  mapBR(node, buffer, config) {
    if (config.insideContainer > 0) {
      buffer.push(`<w:r><w:br/></w:r>`)
    } else buffer.push(`<w:p></w:p>`)
  },

  //   <w:tblW w:w="${config.paperSize.A4.maxUsableWidthTwips}" w:type="dxa"/>
  //   <w:tblBorders>
  //     <w:top w:val="single" w:sz="4" w:space="0" w:color="auto"/>
  //     <w:left w:val="single" w:sz="4" w:space="0" w:color="auto"/>
  //     <w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto"/>
  //     <w:right w:val="single" w:sz="4" w:space="0" w:color="auto"/>
  //     <w:insideH w:val="single" w:sz="4" w:space="0" w:color="auto"/>
  //     <w:insideV w:val="single" w:sz="4" w:space="0" w:color="auto"/>
  //   </w:tblBorders>

  createStyleForTable(buffer, config) {
    let widthProportional = Math.ceil(
      parseFloat(config.parentStyle.width) * config.paperSize.A4.maxUsableWidthTwips / config.documentWidthPixels
    )

    buffer.push(`<w:tblPr>
      <w:tblStyle w:val="TableGrid"/>
      <w:tblW w:w="${widthProportional}" w:type="dxa"/>
      <w:tblBorders>
        <w:top w:val="none" w:sz="0" w:space="0" w:color="auto"/>
        <w:left w:val="none" w:sz="0" w:space="0" w:color="auto"/>
        <w:bottom w:val="none" w:sz="0" w:space="0" w:color="auto"/>
        <w:right w:val="none" w:sz="0" w:space="0" w:color="auto"/>
        <w:insideH w:val="none" w:sz="0" w:space="0" w:color="auto"/>
        <w:insideV w:val="none" w:sz="0" w:space="0" w:color="auto"/>
      </w:tblBorders>
      <w:tblLook w:val="04A0" w:firstRow="1" w:lastRow="0" w:firstColumn="1" w:lastColumn="0" w:noHBand="0" w:noVBand="1"/>
    </w:tblPr>`)

    const maxWidth = config.paperSize.A4.maxUsableWidthTwips

    let props = config.tablesProps[config.tablesProps.length - 1]
    let maxNumCols = Math.max(...props.columnsPerRow)
    let colWidth = Math.ceil(maxWidth / maxNumCols)

    buffer.push(`<w:tblGrid>`)
    for (let i = 1; i < maxNumCols; i++) {
      buffer.push(`<w:gridCol w:w="${colWidth}"/>`)
    }
    buffer.push(`<w:gridCol w:w="` + (maxWidth - colWidth * (maxNumCols - 1)) + `"/>`)
    buffer.push(`</w:tblGrid>`)
  },

  createStyleForColumn(node, buffer, config) {
    let localPropsBuffer = []
    // scale the px dimensions to word page dimensions
    //9016 - max width in dxa, 660px in our viewport
    let widthProportional = Math.ceil(
      parseFloat(config.parentStyle.width) * config.paperSize.A4.maxUsableWidthTwips / config.documentWidthPixels
    )
    localPropsBuffer.push(`<w:tcW w:w="${widthProportional}" w:type="dxa"/>`)
    // localPropsBuffer.push(`<w:tcW w:w="9016" w:type="auto"/>`)

    if (node.colSpan && node.colSpan > 1) localPropsBuffer.push(`<w:gridSpan w:val="${node.colSpan}"/>`)

    let localBorderBuffer = []
    if (config.parentStyle.borderLeftStyle !== 'none' && parseFloat(config.parentStyle.borderLeftWidth) !== 0)
      localBorderBuffer.push('<w:left w:val="single" w:sz="4" w:space="0" w:color="auto" />')
    if (config.parentStyle.borderRightStyle !== 'none' && parseFloat(config.parentStyle.borderRightWidth) !== 0)
      localBorderBuffer.push('<w:right w:val="single" w:sz="4" w:space="0" w:color="auto" />')
    if (config.parentStyle.borderTopStyle !== 'none' && parseFloat(config.parentStyle.borderTopWidth) !== 0)
      localBorderBuffer.push('<w:top w:val="single" w:sz="4" w:space="0" w:color="auto" />')
    if (config.parentStyle.borderBottomStyle !== 'none' && parseFloat(config.parentStyle.borderBottomWidth) !== 0)
      localBorderBuffer.push('<w:bottom w:val="single" w:sz="4" w:space="0" w:color="auto" />')

    if (localBorderBuffer.length > 0) localPropsBuffer.push(`<w:tcBorders>${localBorderBuffer.join('')}</w:tcBorders>`)
    if (localPropsBuffer.length > 0) buffer.push(`<w:tcPr>${localPropsBuffer.join('')}</w:tcPr>`)
  },

  createStyleForParagraph(node, buffer, config) {
    buffer.push(`<w:pPr><w:spacing w:before="120" w:after="120"/>`)

    if (config.nextPListItemType) {
      if (config.nextPListItemType.type === 'numbered') {
        let counter = config.counters[config.nextPListItemType.counterName]
        buffer.push(
          `<w:pStyle w:val="ListParagraph"/><w:numPr>
              <w:ilvl w:val="${counter.level}"/>
              <w:numId w:val="${counter.styleId}"/>
           </w:numPr>`
        )
      } else {
        buffer.push(
          `<w:pStyle w:val="ListParagraph"/><w:numPr>
            <w:ilvl w:val="${config.bulletListDepth}"/>
            <w:numId w:val="${config.bulletListStyleIDInDOCX}"/>
          </w:numPr>`
        )
      }
    } else if (config.refStyle.length > 0) buffer.push(`<w:pStyle w:val="` + config.refStyle[config.refStyle.length - 1] + `"/>`)

    if (config.lastWrapperListType.length > 0 && !config.nextPListItemType) {
      // console.log('LastWrapperListType: ', JSON.stringify(config.lastWrapperListType), node.nodeName)//node.innerText.slice(0,25)
      buffer.push('<w:ind w:left="709" />')
    }

    if (config.parentStyle && config.parentStyle.textAlign && config.parentStyle.textAlign !== 'left') {
      buffer.push(`<w:jc w:val="${this.textAlignToDocx(config.parentStyle.textAlign)}"/>`)
    }

    buffer.push(
      `<w:rPr><w:rFonts w:ascii="${config.fontFamily}" w:eastAsia="${config.fontFamily}" w:hAnsi="${config.fontFamily}" w:cs="${config.fontFamily}"/><w:lang w:val="${config.lang}"/></w:rPr></w:pPr>`
    )
  },

  createStylePropsForRun(buffer, config) {
    const localBuffer = []

    if (config.bold > 0) localBuffer.push(`<w:b/><w:bCs/>`)
    if (config.italic > 0) localBuffer.push(`<w:i/><w:iCs/>`)
    if (config.underlined > 0) localBuffer.push(`<w:u w:val="single" />`)
    if (config.highlighted > 0) localBuffer.push(`<w:highlight w:val="yellow"/>`)

    if (localBuffer.length > 0) buffer.push(`<w:rPr>${localBuffer.join('')}</w:rPr>`)
  },

  //contentBefore: "(" counter(level1, lower-roman) ") "
  //contentBefore: counters(levelEn, ".") ". "
  setListItemType(node, config) {
    if (![ '#text', '#comment', 'STYLE' ].includes(node.nodeName)) {
      let contentBefore = window.getComputedStyle(node, ':before').content
      if (contentBefore !== 'none' && contentBefore.includes('counter')) {
        let res = new RegExp(/counters?\(([^,)]+)/, 'i').exec(contentBefore)
        config.nextPListItemType = {
          type: 'numbered',
          counterName: res[1],
        }
      } else {
        if (node.nodeName === 'LI') {
          if (config.lastWrapperListType && config.lastWrapperListType.type === 'numbered') {
            config.nextPListItemType = {
              type: 'numbered',
              counterName: this.defaultCounterName,
            }
          } else {
            config.nextPListItemType = {
              type: 'bullet',
            }
          }
        }
      }
    }
  },

  // window.getComputedStyle(node,':before').content
  counterIncrementLevel(node, config) {
    let counterNameList = config.parentStyle.counterReset.split(/[ ,]/).filter((e) => e && e !== 'none').filter((e) => isNaN(parseInt(e)))
    if (counterNameList.length === 0 && node.nodeName === 'OL') counterNameList.push(this.defaultCounterName)
    if (counterNameList.length === 0) return []

    // console.log('Counters now: ', JSON.stringify(config.counters))
    //in MSWord level counting starts at 0
    counterNameList.forEach((counterName) => {
      if (config.counters[counterName]) {
        if (config.counters[counterName].level === -1) {
          // we need to create a new numbered list so that numbering restarts.
          config.counters[counterName].styleId = this.createNewNumberedStyle(config)
        }
        config.counters[counterName].level = config.counters[counterName].level + 1
      } else {
        config.counters[counterName] = {
          level: 0,
          name: counterName,
          styleId: this.createNewNumberedStyle(config),
        }
      }
      // console.log(`Increment counter ${node.nodeName}. After increment`, config.counters[counterName])
    })

    return counterNameList
  },

  counterDecLevelIfAny(counterNameList, config) {
    counterNameList.forEach((counterName) => {
      config.counters[counterName].level = config.counters[counterName].level - 1
    })
  },

  createNewNumberedStyle(config) {
    config.numberedListStyleIDInDOCX = config.numberedListStyleIDInDOCX + 1
    config.numberedListStyleIDList.push(config.numberedListStyleIDInDOCX)
    return config.numberedListStyleIDInDOCX
  },

  textAlignToDocx(textAlign) {
    return textAlign
      .replace('justify', 'both')
      .replace('justify-all', 'both')
      .replace('-webkit-right', 'right')
      .replace('-webkit-left', 'left')
      .replace('-webkit-center', 'center')
      .replace('-moz-right', 'right')
      .replace('-moz-left', 'left')
      .replace('-moz-center', 'center')
      .replace('start', 'left')
      .replace('end', 'right')
      .trim()
  },
}

export default docx
