import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'
import { format } from 'common/helper/date'

import DatePicker from 'common/DatePicker/DatePicker'

import Label from '../Label'
import Compare from '../Compare'

import './DatePicker.scss'

class DateInput extends React.Component {
  viewFormat = 'do MMMM yyyy'
  onChange = (date) => {
    const { meta } = this.props
    meta.setValue(date)
  }

  render() {
    const { meta = {}, readOnly } = this.props
    const {
      name,
      value,
      cpValue,
      disabled,
      opt = {},
      cpOnly,
      validation = {},
      isHidden,
      isRestricted,
      viewOnly,
      notEditable,
    } = meta

    const { isMatched, isCompleted, isChanged, isInvalid } = validation

    const viewValue = format(new Date(value), this.viewFormat)

    return (
      <div
        className={cn({
          isHidden,
          isRestricted,
          isMatched,
          isUnmatched: !isMatched,
          isIncompleted: !isCompleted,
          isCompleted,
          isChanged,
          isInvalid,
          viewOnly,
          notEditable,
        })}
        input-name={name}
      >
        <div
          className={cn('form-item', {
            readOnly,

            nested: opt.label,
            cpOnly,
          })}
        >
          <div className="form-item__content">
            <Label {...this.props} />
            {viewOnly ? (
              <div className="form-input">
                <div className="form-input__view-only">{viewValue}</div>
              </div>
            ) : (
              !cpOnly && (
                <div className="form-input" data-id={'datePicker:' + name}>
                  <DatePicker
                    className={cn({ matched: isMatched, cpValue, empty: !value, value })}
                    disabled={disabled || readOnly}
                    value={value}
                    onChange={this.onChange}
                    key={value}
                  />
                </div>
              )
            )}
          </div>
          <Compare meta={meta} readOnly={readOnly || cpOnly} />
        </div>
      </div>
    )
  }
}

export default observer(DateInput)
