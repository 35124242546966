import { useState } from 'react'
import cn from 'classnames'

import FilterMenuIcon from 'common/Icons/FilterMenu'
import CloseIcon from 'common/Icons/Close'

import FilterOne from './Filter.one'

const Filter = ({ group, onChange, selected }) => {
  const [ show, setShow ] = useState()

  if (!group) {
    return null
  }

  const haveFilters = !!Object.values(selected).find((list) => Object.values(list).find(Boolean))
  const legend = group.find((item) => item.legend)

  const onSelect = (label, value) => {
    if (label) {
      selected[label] = selected[label] || {}
      selected[label][value] = !selected[label][value]
    }

    onChange(selected)
  }

  return (
    <aside className="filter">
      <div className="filter__header">
        <button className="button" onClick={() => setShow(!show)}>
          <FilterMenuIcon /> Filter
        </button>
        {haveFilters && (
          <div className="filter__reset">
            <button className="btn small error" onClick={() => onChange({})}>
              <CloseIcon /> Reset
            </button>
          </div>
        )}
      </div>
      {show && (
        <div className="filter__content">
          {group.map((item, key) => <FilterOne key={key} {...item} selected={selected} onSelect={onSelect} />)}
        </div>
      )}

      {legend && (
        <div className="legend">
          {legend.list.map((item, i) => {
            const { length } = item.filtered

            return (
              <li className={cn('legend__item', { empty: !length })} key={i}>
                <div className="legend__view">{item.viewValue}</div>
                <div className="filter__item__count">
                  {length !== item.list.length && <div className={cn('--available', { empty: !length })}>{length}</div>}
                  <div className="--total">{item.list.length}</div>
                </div>
              </li>
            )
          })}
        </div>
      )}
    </aside>
  )
}

export default Filter
