import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import cn from 'classnames'
import get from 'lodash.get'

const RowLink = ({ item, config }) => {
  //-----------------------------------------------------------------------------------------
  const { columns, link = () => null } = config

  return (
    <Link
      to={link(item)}
      className={cn('table__row', config.rowClass && config.rowClass(item), {
        active: config.active && config.active(item),
      })}
    >
      {columns.map(({ content, name, width, align, desc, cellClass }, colKey) => {
        const body = name ? get(item, name) : content ? content(item) : ''
        const description = desc && get(item, desc)
        cellClass = typeof cellClass === 'function' ? cellClass(item) : cellClass

        return (
          <div key={colKey} className="table__col" style={{ width, textAlign: align }}>
            <div className={cn('cell', cellClass)}>
              {body} {description && <div className="description">{description}</div>}
            </div>
          </div>
        )
      })}
    </Link>
  )
}

export default observer(RowLink)
