import React from 'react'
import { observer } from 'mobx-react'

import Table from 'common/Table/Table'
import Avatar from 'common/UserCard/Avatar'

import sseApi from 'api/sse'

import './Sse.scss'

class Sse extends React.Component {
  tableConfig = {
    getData: sseApi.getInfo,
    actions: [
      {
        label: 'Delete',
        confirm: 'Are you sure?',
        onClick: ({ _id }) => sseApi.disconnect(_id),
      },
    ],
    columns: [
      {
        header: 'Email',
        content: ({ email, user }) => (
          <div className="flex">
            {email && <Avatar user={user} />}
            <div>
              <h4>{user.displayName}</h4>
              <div className="desc">{email}</div>
            </div>
          </div>
        ),
      },
      {
        header: 'ID',
        content: ({ _id }) => _id,
      },
      {
        header: 'Browser',
        content: ({ useragent = {} }) => (
          <div>
            <div>{useragent.browser}</div>
            <div className="desc">{useragent.version}</div>
          </div>
        ),
      },
      {
        header: 'OS',
        content: ({ useragent = {} }) => (
          <div>
            <div>{useragent.os}</div>
            <div className="desc">{useragent.platform}</div>
          </div>
        ),
      },
      {
        header: 'Active for',
        content: ({ live }) => live,
      },
      {
        header: 'Status',
        content: ({ status }) => status,
      },
    ],
  }

  render() {
    return (
      <div className="page-inner sse">
        <h1>Server side events</h1>
        <Table config={this.tableConfig} className="dark" />
      </div>
    )
  }
}

export default observer(Sse)
