import DropDown from 'common/DropDown/DropDown'

import SmartIdIcon from 'common/Icons/SmartId'
import MoreIcon from 'common/Icons/More'

const SmartIdPreview = ({ firstName, lastName, country, serialNumber, onRemove }) => {
  return (
    <div className="smart-id">
      <div>
        <h4 className="--label --smart-id">
          Your <SmartIdIcon /> is resgistered
        </h4>

        <ul className="smart-id__success">
          <div className="smart-id__more-actions">
            <DropDown options={[ { label: 'Remove', onClick: onRemove } ]}>
              <MoreIcon />
            </DropDown>
          </div>
          <li className="flex">
            First name: <b>{firstName}</b>
          </li>
          <li className="flex">
            Last name: <b>{lastName}</b>
          </li>
          <li className="flex">
            Country: <b>{country}</b>
          </li>
          <li className="flex">
            Serial Number: <b>{serialNumber}</b>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default SmartIdPreview
