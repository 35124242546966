const nodes = (editor) => ({
  //----------------------------------------------------------------------------------------------------
  ordered_list: {
    group: 'block',
    content: 'list_item+',
    attrs: {
      start: { default: 1 },
      type: { default: null },
      className: { default: null },
      dataStyle: { default: null },
      dataMarker: { default: null },
      dataIndent: { default: null },
    },
    parseDOM: [
      {
        tag: 'ol',
        getAttrs: (dom) => ({
          start: dom.hasAttribute('start') ? +dom.getAttribute('start') : 1,
          type: dom.getAttribute('type'),
          className: dom.getAttribute('class'),
          dataMarker: dom.getAttribute('data-marker'),
          dataIndent: dom.getAttribute('data-indent'),
          dataStyle: dom.getAttribute('data-style'),
        }),
      },
    ],
    toDOM(node) {
      const { start, type, dataStyle, dataMarker, dataIndent } = node.attrs
      const view = [
        'ol',
        {
          start: start > 1 ? start : null,
          type,
          'data-style': dataStyle,
          'data-marker': dataMarker,
          'data-indent': dataIndent,
        },
        0,
      ]

      return editor ? [ 'pm-block', [ 'pm-btn', { onclick: 'pmContextMenu(this)' } ], view ] : view
    },
  },

  //----------------------------------------------------------------------------------------------------
  bullet_list: {
    group: 'block',
    content: 'list_item+',
    attrs: { type: { default: null } },
    parseDOM: [
      {
        tag: 'ul',
        getAttrs: (dom) => ({ type: dom.getAttribute('type') }),
      },
    ],
    toDOM(node) {
      const { type } = node.attrs
      const view = [ 'ul', { type }, 0 ]
      return editor ? [ 'pm-block', [ 'pm-btn', { onclick: 'pmContextMenu(this)' } ], view ] : view
    },
  },

  //----------------------------------------------------------------------------------------------------
  list_item: {
    label: 'List item',
    content: '(paragraph | heading) block*',
    defining: true,
    attrs: {
      className: { default: null },
      drWhen: { default: null },
      drValue: { default: null },
    },
    parseDOM: [
      {
        tag: 'li',
        getAttrs: (dom) => ({
          className: dom.getAttribute('class'),
          drWhen: dom.getAttribute('dr-when'),
          drValue: dom.getAttribute('dr-value'),
        }),
      },
      {
        tag: '[data-number]',
        priority: 60,
      },
    ],
    toDOM(node) {
      const { className, drWhen, drValue } = node.attrs
      return [ 'li', { class: className, 'dr-when': drWhen, 'dr-value': drValue }, 0 ]
    },
  },

  //----------------------------------------------------------------------------------------------------
  clear: {
    content: '',
    parseDOM: [
      {
        tag: '[class="header-section-number"]',
        priority: 60,
      },
    ],
    toDOM: () => [],
  },

  //----------------------------------------------------------------------------------------------------
})

export { nodes as default, nodes }
