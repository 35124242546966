import React from 'react'
import { observer } from 'mobx-react'
import { decorate, observable } from 'mobx'
import cn from 'classnames'

import _d from 'dictionary'

import PreLoad from 'common/PreLoad/PreLoad'
import PlusIcon from 'common/Icons/PlusCircle'

import FilterModel from './Filter.model'

import TableAdd from './Table.add'

import RowTr from './Rows/Row.tr'

import Filter from './Filter/Filter'

import Grid from './Grid/Grid'
import Categories from './Categories/Categories'
import TableLink from './Table.link'

import './Table.scss'

class Table extends React.Component {
  state = { selected: {} }

  constructor(props) {
    super(props)
    const { config = {} } = props

    decorate(config, {
      selected: observable.ref,
      data: observable.ref,
      open: observable.ref,
    })

    this.preLoad = new PreLoad(this.getData).then(this.init)
  }

  init = (list) => {
    const { config } = this.props
    if (config.transformData) {
      list = config.transformData(list)
    }

    this.model = new FilterModel(list, config)
  }

  getData = async (update) => {
    const { list, config } = this.props

    if (update) {
      if (config.getData) return config.getData(update)
    }

    if (list) return list
    if (config.data) return config.data
    if (config.list) return config.list
    if (config.getData) return config.getData(update)
    return []
  }

  onChange = (selected) => {
    this.setState({ selected })
    this.model.filter(selected)
  }

  onSort = (item) => {
    const { sort = {} } = this.state
    const { header } = item

    if (header) {
      const sortItem = sort[header] ? (sort[header] === 'DESC' ? null : 'DESC') : 'ASC'

      this.model.sort(item, sortItem)
      this.setState({ sort: { [header]: sortItem } })
    }
  }

  //-----------------------------------------------------------------------------------------
  get filtered() {
    return this.model && this.model.filtered
  }

  get group() {
    return this.model && this.model.group
  }

  get list() {
    return this.model && this.model.list
  }

  id = ({ _key }, key) => {
    return _key || key
  }

  render() {
    window.Table = this
    let { selected, showAdd, sort } = this.state
    const { config = {}, className, list } = this.props
    const { data, add, id = this.id, open, categories } = config
    const columns = [].concat(config.columns).filter((v) => v)

    const { loading, updating } = this.preLoad

    if (this.data !== data || (list && this.list !== list)) {
      showAdd = false
      this.init(data || list)
    }
    this.data = data

    const addConfig = typeof add === 'function' ? add(this.props) : add

    const visibleList = this.filtered || []

    const showHeader = !!visibleList.length && (!config.showHeader || config.showHeader(visibleList))

    let tableBody

    if (categories) {
      const byType = { grid: Grid }
      const Component = byType[categories.type] || Categories
      tableBody = (
        <Component onSort={this.onSort} sort={sort} config={config} list={visibleList} preLoad={this.preLoad} />
      )
    } else if (config.link) {
      tableBody = (
        <TableLink onSort={this.onSort} sort={sort} config={config} list={visibleList} preLoad={this.preLoad} />
      )
    }

    return (
      loading || (
        <div className={cn('search-table', className)}>
          <Filter group={this.group} selected={selected} onChange={this.onChange} />

          <article>
            {updating}
            {(config.header || !!config.content || addConfig) && (
              <div className="table__head">
                <div>
                  <label>{_d(config.header)}</label>
                  {!!config.content &&
                  config.expandAll !== false && (
                    <div>
                      <button className="table__btn" onClick={() => (config.open = !config.open)}>
                        {config.open ? 'Collapse all' : 'Expand all'}
                      </button>
                    </div>
                  )}
                </div>

                {addConfig && (
                  <div>
                    <button
                      className="btn round table__add__btn"
                      onClick={() => this.setState({ showAdd: true })}
                      disabled={showAdd}
                    >
                      <div>
                        <PlusIcon />
                      </div>
                      {_d(addConfig.addButton || 'Add')}
                    </button>
                  </div>
                )}
              </div>
            )}

            {tableBody || (
              <div className={cn('table', className)}>
                <TableAdd
                  {...this.props}
                  data={addConfig}
                  open={showAdd}
                  onClose={() => this.setState({ showAdd: false })}
                  preLoad={this.preLoad}
                />

                {showHeader && (
                  <div className="table__tbody">
                    <div className="table__row">
                      {config.content && <div className="table__col" />}
                      {columns.map((item, key) => (
                        <div
                          className="table__col table__header"
                          key={key}
                          style={{ width: item.width, textAlign: item.align }}
                        >
                          {item.header}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {!!visibleList.length &&
                  visibleList.map((item, key) => (
                    <RowTr
                      key={id(item, key) + '|' + !!open}
                      id={id(item, key)}
                      config={config}
                      preLoad={this.preLoad}
                      item={item}
                    />
                  ))}
              </div>
            )}
          </article>
        </div>
      )
    )
  }
}

export default observer(Table)
