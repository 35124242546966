import React, { useState } from 'react'
import { format } from 'date-fns'
import cn from 'classnames'

import UpIcon from 'common/Icons/Up'
import DownIcon from 'common/Icons/Down'

import './Months.scss'

const getMonths = ({ active, selected }) => {
  const year = active.getFullYear()
  const month = active.getMonth()

  const months = []

  for (let i = 0; i < 12; i++) {
    const date = new Date(year, i, 1)

    const title = format(date, 'MMMM')

    months.push({
      key: i,
      month: i,
      title,
      selected: i === month,
    })
  }

  return months
}

const getYears = ({ active }, offset) => {
  const before = 3
  const after = 3
  const year = active.getFullYear()

  const years = []
  for (let i = year - before; i <= year + after; i++) {
    const shift = year - i
    const newYear = i + offset
    years.push({
      year: newYear,
      title: newYear,
      selected: year === newYear,
      shift,
    })
  }

  return years.reverse()
}

const Months = (props) => {
  const { active, onChange, onClose } = props

  const [ offset, setOffset ] = useState(0)

  const year = active.getFullYear()
  const month = active.getMonth()

  const months = getMonths(props)
  const years = getYears(props, offset)

  const selectYear = ({ year, shift = 0 }) => {
    onChange({ year, month })
    setOffset(shift)
  }

  const selectMonth = ({ month }) => {
    onChange({ year, month })
    onClose()
  }

  return (
    <article className="pick">
      <div className="pick__year">
        <ul>
          <div className="up" onClick={() => setOffset(offset + 3)}>
            <UpIcon />
          </div>
          {years.map((item, key) => (
            <li key={key} className={cn({ selected: item.selected })} onClick={() => selectYear(item)}>
              {item.title}
            </li>
          ))}
          <div className="down" onClick={() => setOffset(offset - 3)}>
            <DownIcon />
          </div>
        </ul>
      </div>

      <div className="pick__month">
        <ul>
          {months.map((item, key) => (
            <li key={key} className={cn({ selected: item.selected })} onClick={() => selectMonth(item)}>
              {item.title}
            </li>
          ))}
        </ul>

        <div className="pick__actions">
          <button className="btn link" onClick={onClose}>
            Done
          </button>
        </div>
      </div>
    </article>
  )
}

export default Months
