import React, { useState } from 'react'
import cn from 'classnames'

import DownloadFileIcon from 'common/Icons/DownloadFile'

const DownloadButton = ({ label, icon, onClick, className }) => {
  const [ loading, setLoading ] = useState(false)

  return (
    <div
      className="download__icon"
      onClick={async () => {
        setLoading(true)
        await onClick()
        setLoading(false)
      }}
    >
      {loading ? <DownloadFileIcon /> : icon}
      <div className={cn('download__icon__label', className)}>{label}</div>
    </div>
  )
}

export default DownloadButton
