import React from 'react'

import EmailOpenIcon from 'common/Icons/EmailOpen'

const config = {
  className: 'error',
  icon: <EmailOpenIcon />,
  header: 'Rejected',
  description: [ 'The invitation to sign the contract was rejected. The counterparty was notified.' ],
  cp: {
    label: 'Rejected',
    date: ({ negotiation: { cpParty } }) => cpParty.lastUpdated,
  },
}

export default config
