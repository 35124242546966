import React from 'react'

const InboxOne = ({ doc, activity, myParty, cpParty }) => {
  return (
    <div>
      <div>Version {activity.version}</div>
      <h2>{doc.label}</h2>
      <div className="inbox-one__parties">
        <div>{myParty.displayName}</div>
        <div>{cpParty && cpParty.displayName}</div>
      </div>
    </div>
  )
}

export default InboxOne
