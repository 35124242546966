import React from 'react'
import LoadingIcon from 'common/Icons/spinner/Spinner'

class AsyncButton extends React.Component {
  state = {}

  onClick = async (e) => {
    e.stopPropagation()
    this.setState({ loading: true })
    const { onClick = (f) => f } = this.props
    await onClick(e)
    this.setState({ loading: false })
  }

  render() {
    const { loading } = this.state
    const { label, children, icon, activeIcon, className, disabled, useSpinner = true } = this.props

    const loadingIcon = useSpinner ? <LoadingIcon /> : activeIcon || icon

    return (
      <button className={className} disabled={disabled || loading} onClick={this.onClick}>
        {children || label} {loading ? loadingIcon : icon}
      </button>
    )
  }
}

export default AsyncButton
