import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import { timeDistance } from 'common/helper/date'
import Avatar from 'common/UserCard/Avatar'

import SignIcon from 'common/Icons/Signature'
import SwitchIcon from 'common/Icons/Switch2'

import PartyAdd from './Party.add'
import PartySignature from './Party.signature'

import './Party.scss'

const Party = (props) => {
  const { position, negotiation, index, onSwitch } = props

  const { label, title, description, type, isMe, lastActivity, party, name } = position

  const { owner = {}, isTurn, email } = party || {}
  const { state, signatures, singleParty } = negotiation

  const isDraft = state === 'draft'

  const availableSignatures = signatures && signatures.filter((item) => item.position === name)
  const right = !!index

  return (
    <div>
      {!!index &&
      isDraft && (
        <button className="positions__switch" title="Switch positions" onClick={onSwitch}>
          <i>
            <SwitchIcon />
          </i>
          <span>Switch</span>
        </button>
      )}

      {isDraft && !isMe ? (
        <PartyAdd {...props} />
      ) : (
        <div className={cn('positions__party', type, { isTurn, right, isDraft })}>
          <div className="positions__header">
            <div className="positions__label">
              <h3>{label}</h3>
              <div className="desc">{description}</div>
            </div>

            <div className="positions__pos">
              {title && <label className={type}>{title}</label>}
              {lastActivity && (
                <time dateTime={lastActivity.createdAt}>{timeDistance(lastActivity.createdAt, 'ago')}</time>
              )}
            </div>
          </div>

          {!party && singleParty ? (
            <div className="positions__single">Single party</div>
          ) : (
            <article className="positions__content">
              <div className="positions__user">
                <div className="positions__user__card">
                  <Avatar user={owner} />
                  <div className="--title">
                    <div>{owner.displayName}</div>
                    <div className="desc">{owner.email || email}</div>
                  </div>
                </div>
              </div>
            </article>
          )}
        </div>
      )}

      {/* {availableSignatures &&
      !!availableSignatures.length && (
        <div className={cn('position__signatures', { right })}>
          <h4 className="signature__title">
            <SignIcon /> Signatures
          </h4>
          {availableSignatures.map((item, key) => <PartySignature key={key} {...item} />)}
        </div>
      )} */}
    </div>
  )
}

export default observer(Party)
