import lt from './i18n/lt.js'
import en from './i18n/en.js'
import de from './i18n/de.js'
import ro from './i18n/ro.js'
import ru from './i18n/ru.js'

const langs = {
  en,
  de,
  lt,
  ro,
  ru,
}

class NumberToWords {
  constructor({ lang = 'en', useOne } = {}) {
    this.lang = lang
    this.dict = langs[lang].dict || langs.en.dict

    const opts = langs[lang].options || {}
    this.swapFirstTwoDigits = opts.swapSingleWithTens || false
    this.bindingWord = opts.bindingWord || null
    this.segmentSeparator = opts.separator || ' '
    this.usePluralForm = opts.usePluralForm || false
    this.useOne = useOne === undefined ? opts.useOne : ![ '0', 'false', 'none', 'null' ].includes(useOne)

    this.bespoke = opts.languageSpecificRules || (() => {})
    this.and = opts.and || (() => {})
  }

  w1(nr) {
    if (nr) {
      const units = this.dict.units[nr]
      return Array.isArray(units) ? units[0] : units
    }
  }

  w10(nr) {
    return this.dict.tens[nr]
  }

  w100(nr) {
    if (nr) {
      const units = this.dict.units[nr]
      const unit = Array.isArray(units) ? units[2] : units

      const [ one, many ] = this.dict.hundreds
      const word = nr !== 1 && many ? many : one

      if (this.dict.specialGroups[nr * 100]) {
        return this.dict.specialGroups[nr * 100]
      }

      if (!this.useOne && nr === 1) {
        return word
      }

      return unit + this.segmentSeparator + word
    }
  }

  w1000(scaleIdx, { value }) {
    if (scaleIdx) {
      const scale = this.dict.scales[scaleIdx]
      if (typeof scale === 'string') {
        return scale
      }

      if (value === 1) return scale[Math.min(0, scale.length - 1)]
      if (value > 1 && value < 10) return scale[Math.min(1, scale.length - 1)]
      return scale[Math.min(2, scale.length - 1)]
    }
  }

  wGroups(group, value, scaleIdx = 0) {
    let groupScales = this.dict.specialGroups[group]
    if (!groupScales) return null

    if (typeof groupScales === 'string') return groupScales

    if (Array.isArray(groupScales)) {
      let groupAsWords = groupScales[scaleIdx] || groupScales[groupScales.length - 1]
      if (typeof groupAsWords === 'string') return groupAsWords

      if (this.usePluralForm) {
        const [ singular, plural ] = groupAsWords
        return value > 1 ? plural : singular
      }
    }

    return null
  }

  toWords(scaleIdx, { twoDigits, one, ten, hundred, value }) {
    let digitsToWords = [ this.w100(hundred) ]

    const d2 = this.wGroups(twoDigits, value, scaleIdx)
    if (d2) digitsToWords.push(d2)
    else {
      if (this.swapFirstTwoDigits)
        digitsToWords.push(this.w1(one), this.and(twoDigits, one, ten, hundred, value), this.w10(ten))
      else digitsToWords.push(this.w10(ten), this.and(twoDigits, one, ten, hundred, value), this.w1(one))
    }

    digitsToWords = digitsToWords.filter((v) => v).join(this.segmentSeparator)

    if (scaleIdx) {
      const scale = this.w1000(scaleIdx, { value })
      return value === 1 && !this.useOne ? scale : digitsToWords + this.segmentSeparator + scale
    }
    return digitsToWords
  }

  getChunks(string) {
    /* Split user argument into 3 digit chunks from right to left */
    let start = string.length
    let end
    const chunks = []
    while (start > 0) {
      end = start
      start = Math.max(0, end - 3)
      chunks.push(string.slice(start, end))
    }
    return chunks
  }

  convert(nr) {
    let string = `${nr}`.toString()

    /* Remove spaces and commas */
    string = string.replace(/[, ]/g, '')

    const number = parseInt(string)

    if (isNaN(number) || number.toString().length !== string.length) return `[Invalid number: ${nr}]`

    /* Is number zero? */
    if (parseInt(string) === 0) return this.dict.units[0]

    const chunks = this.getChunks(string)

    if (chunks) {
      return chunks.map(this.parseChunk).reverse().filter((v) => v).join(this.segmentSeparator)
    }

    return `[Invalid number: ${nr}]`
  }

  parseChunk = (chunk, scale) => {
    const value = parseInt(chunk)

    if (value) {
      /* Split chunk into array of individual integers */
      const [ one, ten, hundred ] = chunk.split('').reverse().map(parseFloat)
      const twoDigits = ten * 10 + one || one
      return this.toWords(scale, { twoDigits, one, ten, hundred, value })
    }
  }
}

export default NumberToWords
