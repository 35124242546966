import React from 'react'

import Table from 'common/Table/Table'
import { timeDistance } from 'common/helper/date'

import adminApi from 'api/admin'

import './Queue.scss'

class Queue extends React.Component {
  tableConfig = {
    getData: async () => {
      const data = await adminApi.getQueue()
      return Object.values(data)
    },

    columns: [
      {
        header: 'Id',
        name: 'id',
      },
      {
        header: 'Created',
        content: ({ createdAt }) => <time>{timeDistance(createdAt, 'ago')}</time>,
      },
      {
        header: 'Jobs',
        content: ({ jobs, active }) => (
          <ul className="queue__jobs">
            {active && (
              <li className="queue__active">
                {active.name} <time>{timeDistance(active.info.createdAt, 'ago')}</time>
              </li>
            )}
            {jobs.map(({ name, info }, key) => (
              <li key={key}>
                {name} <time>{timeDistance(info.createdAt, 'ago')}</time>
              </li>
            ))}
          </ul>
        ),
      },
      {
        header: 'Total',
        name: 'jobs.length',
      },
    ],
  }

  render() {
    return (
      <div className="page-inner">
        <h1>Queues on server</h1>
        <Table config={this.tableConfig} className="dark" />
      </div>
    )
  }
}

export default Queue
