import { useState } from 'react'
import cn from 'classnames'

import Emails from './Emails/Emails'
import History from './History/History'

import './Negotiations.content.scss'

const tabs = [
  {
    label: 'Emails',
    Tab: Emails,
  },
  {
    label: 'History',
    Tab: History,
  },
]

const NegotiationContent = (props) => {
  const [ active, setActive ] = useState(tabs[0])

  return (
    <div className="ani">
      <nav className="ani__nav">
        <ul>
          {tabs.map((item, key) => (
            <li key={key}>
              <button className={cn('btn', { cancel: item !== active })} onClick={() => setActive(item)}>
                {item.label}
              </button>
            </li>
          ))}
        </ul>
      </nav>
      {active && <active.Tab {...props} />}
    </div>
  )
}

export default NegotiationContent
