import React from 'react'

import _d from 'dictionary'
import Table from 'common/Table/Table'
import DateTime from 'common/DateTime/DateTime'

import Avatar from 'common/UserCard/Avatar'

import companyApi from 'api/company'
import auth from 'api/auth'

class CompanyUsers extends React.Component {
  isAllowed(name) {
    const { perm: { companyActions, companyPermissions, permissions } = {} } = auth.user.company

    const isAdmin = companyPermissions === 'admin' || (companyPermissions === undefined && permissions === 'admin')
    return (companyActions && companyActions.includes(name)) || isAdmin
  }

  tableConfig = {
    header: _d('Users'),
    getData: async () => {
      const { userList } = await this.props.getData()
      return userList
    },
    actions: (item) =>
      item.isMe
        ? []
        : [
            this.isAllowed('canUpdateUsers') && {
              label: _d('Edit'),
              onSubmit: ({ _key, perm }) => companyApi.updateUser(_key, perm),
              form: [
                {
                  type: 'columns',
                  children: [
                    {
                      type: 'radio',
                      label: _d('Permission') + ' ' + _d('for') + ' ' + _d('Negotiation'),
                      name: 'perm.permissions',
                      options: [
                        {
                          label: 'Admin',
                          value: 'admin',
                        },
                        {
                          label: _d('Custom'),
                          value: null,
                          children: [
                            {
                              type: 'checkbox',
                              name: 'perm.actions',
                              optional: true,
                              options: [
                                {
                                  label: _d('negotiationPermissions.canAssignUsers'),
                                  value: 'canAssignUsers',
                                },
                                {
                                  label: _d('negotiationPermissions.canHide'),
                                  value: 'canHide',
                                  hide: true,
                                },
                                {
                                  label: _d('negotiationPermissions.canEdit'),
                                  description: 'Edit negotiation',
                                  value: 'canEdit',
                                },
                                {
                                  label: _d('negotiationPermissions.canSend'),
                                  value: 'canSend',
                                },
                                {
                                  label: _d('negotiationPermissions.canAgree'),
                                  value: 'canAgree',
                                },
                                {
                                  label: _d('negotiationPermissions.canSign'),
                                  value: 'canSign',
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },

                    {
                      type: 'radio',
                      label: _d('Permission') + ' ' + _d('form') + ' ' + _d('Company'),
                      name: 'perm.companyPermissions',
                      options: [
                        {
                          label: 'Admin',
                          value: 'admin',
                        },
                        {
                          label: _d('Custom'),
                          value: null,
                          children: [
                            {
                              type: 'checkbox',
                              name: 'perm.companyActions',
                              optional: true,
                              options: [
                                {
                                  label: _d('companyPermissions.canAddUsers'),
                                  value: 'canAddUsers',
                                },
                                {
                                  label: _d('companyPermissions.canUpdateUsers'),
                                  value: 'canUpdateUsers',
                                },
                                {
                                  label: _d('companyPermissions.canRemoveUsers'),
                                  value: 'canRemoveUsers',
                                },
                                {
                                  label: _d('companyPermissions.canEdit'),
                                  value: 'canEdit',
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            this.isAllowed('canRemoveUsers') && {
              label: _d('Remove'),
              confirm: _d('Are you sure to remove?'),
              className: 'error',
              onClick: (item) => {
                return companyApi.removeUser(item._key)
              },
            },
          ],
    add: () =>
      this.isAllowed('canAddUsers') && {
        addButton: _d('Invite user'),
        submitButton: _d('Invite user'),
        onSubmit: companyApi.addUser,
        inline: false,
        form: [
          {
            type: 'text',
            name: 'email',
            label: 'Email',
            // autoFocus: true,
            validate: {
              required: true,
              type: 'email',
            },
          },
          {
            type: 'columns',
            children: [
              {
                type: 'radio',
                label: _d('Negotiation permissions'),
                name: 'permissions',
                defaultValue: 'admin',
                options: [
                  {
                    label: 'Admin',
                    value: 'admin',
                  },
                  {
                    label: 'Custom',
                    value: null,
                    children: [
                      {
                        type: 'checkbox',
                        name: 'actions',
                        optional: true,
                        defaultValue: [ 'canHide', 'canEdit', 'canSend', 'canAgree' ],
                        options: [
                          {
                            label: _d('negotiationPermissions.canAssignUsers'),
                            value: 'canAssignUsers',
                          },
                          {
                            label: _d('negotiationPermissions.canHide'),
                            value: 'canHide',
                            hide: true,
                          },
                          {
                            label: _d('negotiationPermissions.canEdit'),
                            description: 'Edit negotiation',
                            value: 'canEdit',
                          },
                          {
                            label: _d('negotiationPermissions.canSend'),
                            value: 'canSend',
                          },
                          {
                            label: _d('negotiationPermissions.canAgree'),
                            value: 'canAgree',
                          },
                          {
                            label: _d('negotiationPermissions.canSign'),
                            value: 'canSign',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },

              {
                type: 'radio',
                label: _d('Company permissions'),
                name: 'companyPermissions',
                defaultValue: 'admin',
                options: [
                  {
                    label: 'Admin',
                    value: 'admin',
                  },
                  {
                    label: 'Custom',
                    value: null,
                    children: [
                      {
                        type: 'checkbox',
                        name: 'companyActions',
                        optional: true,
                        options: [
                          {
                            label: 'Add new users',
                            value: 'canAddUsers',
                          },
                          {
                            label: 'Update users',
                            value: 'canUpdateUsers',
                          },
                          {
                            label: 'Remove users',
                            value: 'canRemoveUsers',
                          },
                          {
                            label: 'Edit company profile',
                            value: 'canEdit',
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    columns: [
      {
        width: 50,
        content: (item) => <Avatar user={item} className="medium" />,
      },
      {
        header: 'User',
        content: ({ displayName, email }) => (
          <div>
            <div>{displayName}</div>
            <div className="desc">{email}</div>
          </div>
        ),
      },
      {
        content: ({ dummy }) => dummy && 'Fake user',
      },
      {
        header: _d('Negotiation permissions'),
        content: ({ perm: { actions, permissions } }) =>
          permissions === 'admin' ? (
            <h4>Admin</h4>
          ) : (
            actions &&
            actions.map((item) => (
              <div className="perm-view" key={item}>
                {_d('negotiationPermissions.' + item)}
              </div>
            ))
          ),
      },
      {
        header: _d('Company permissions'),
        content: ({ perm: { companyActions, companyPermissions } }) =>
          companyPermissions === 'admin' ? (
            <h4>Admin</h4>
          ) : (
            companyActions &&
            companyActions.map((item) => (
              <div className="perm-view" key={item}>
                {_d('companyPermissions.' + item)}
              </div>
            ))
          ),
      },
      {
        header: _d('Added at'),
        align: 'center',
        width: 150,
        content: ({ perm }) => <DateTime date={perm.createdAt} />,
      },
    ],
  }

  render() {
    return <Table config={this.tableConfig} />
  }
}

export default CompanyUsers
