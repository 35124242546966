import React from 'react'

import DocumentVersionIcon from 'common/Icons/DocumentVersion'
import Table from 'common/Table/Table'
import documentsApi from 'api/documents'
import _d from 'dictionary'

import './DocumentsList.scss'

class DocumentsList extends React.Component {
  config = {
    async getData() {
      const publishedTemplates = await documentsApi.getList(true)
      return Array.from(publishedTemplates).flatMap((tpl) => {
        return (tpl.mainDoc.group || '')
          .split(/[,;]/)
          .map((e) => e.trim())
          .map((e) => (e ? `${e[0].toUpperCase()}${e.slice(1)}` : ''))
          .map((gLabel) => {
            return {
              ...tpl,
              mainDoc: {
                ...tpl.mainDoc,
                group: gLabel,
              },
            }
          })
      })
    },
    onClick: ({ mainDoc }) => {
      const { history } = this.props
      history.push(`/negotiations/${mainDoc._key}/draft`)
    },

    categories: {
      value: 'mainDoc.group',
      sortOrder: [
        'Account Private',
        'Standard agreements',
        'NDA',
        'Sales',
        'Employment',
        'USA',
        'UK',
        'Germany',
        'Romania',
        'Lithuania',
        'General',
      ],
      otherLabel: 'General',
      content: ({ label }) => <div>{label}</div>,
    },
    groupBy: [
      {
        label: _d('Language'),
        value: 'language.label',
      },
      {
        label: _d('Category'),
        value: 'mainDoc.category',
      },
      // {
      //   label: _d('Privacy'),
      //   value: 'isRestricted',
      //   view: ({ isRestricted }) => (isRestricted ? 'Private' : 'Public'),
      // },
    ],
    columns: [
      {
        width: 60,
        content: (item) => (
          <div>
            <DocumentVersionIcon className="doc-version" version={item.language.code.toUpperCase()} />
          </div>
        ),
      },
      {
        name: 'version',
      },
      {
        header: _d('TemplateName'),
        sortBy: 'mainDoc.label',
        content: (item) => (
          <div>
            <h4>{item.mainDoc.label}</h4>
            <div className="desc">{item.mainDoc.description}</div>
          </div>
        ),
      },

      {
        header: _d('Category'),
        name: 'mainDoc.category',
      },
      {
        header: _d('Language'),
        name: 'language.label',
      },
      {
        header: _d('Presets'),
        align: 'center',
        // width: 100,
        sortBy: 'countPresets',
        content: (item) => <div number={item.countPresets} />,
      },
      {
        header: _d('Negotiations'),
        align: 'center',
        // width: 100,
        sortBy: 'countNegotiations',
        content: (item) => <div number={item.countNegotiations} />,
      },
    ],
  }

  render() {
    return (
      <div className="p1__content">
        <Table config={this.config} className="dark2" />
      </div>
    )
  }
}

export default DocumentsList
