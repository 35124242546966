import { format } from 'date-fns'
import { ro, lt, ru, de } from 'date-fns/locale'
import UAParser from 'ua-parser-js'

import NumberToWords from './toWords'

const langs = { ro, lt, ru, de }

const dateHelper = {
  date(value, params = 'MMMM d, yyyy', { lang }) {
    const date = new Date(value)

    if (value && date.toString() !== 'Invalid Date') {
      if (value.length < 11) {
        const offset = date.getTimezoneOffset()
        date.setMinutes(offset)
      }

      return format(date, params, { locale: langs[lang] })
    }
  },
  ordinal(i) {
    const isNumber = [ 'number', 'string' ].includes(typeof i) && parseInt(i).toString() === i.toString()

    if (!isNumber) {
      return i
    }

    var j = i % 10,
      k = i % 100
    if (j === 1 && k !== 11) {
      return i + 'st'
    }
    if (j === 2 && k !== 12) {
      return i + 'nd'
    }
    if (j === 3 && k !== 13) {
      return i + 'rd'
    }
    return i + 'th'
  },

  number(val, [ thausand, comma ] = [ ',', '.' ]) {
    const nr = parseFloat(val)
    if (!isNaN(nr)) {
      const arr = nr.toString().split('.')

      const big = arr[0]
      arr[0] = arr[0].split('').map((nr, i) => ((big.length - i) % 3 === 1 ? nr + thausand : nr)).join('').slice(0, -1)

      return arr.join(comma)
    }

    return '[Invalid Number]'
  },

  numberDE(val) {
    return this.number(val, [ '.', ',' ])
  },

  A(val) {
    const upperLatin = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
    return upperLatin[val]
  },

  toWords(value, _, options) {
    const num2word = new NumberToWords(options)
    return num2word.convert(value)
  },

  userAgent(value, param) {
    const agent = new UAParser(value)

    const { os, browser } = agent.getResult()

    const result = {
      os: os.name + ' ' + os.version,
      browser: browser.name + ' ' + browser.version,
    }

    return result[param] || `OS: ${result.os}, Browser: ${result.browser}`
  },
}

export default dateHelper
