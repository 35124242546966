import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import ProseEditor from 'common/ProseMirror/ProseMirror'

import Label from '../Label'
import Compare from '../Compare'

class Editor extends React.Component {
  onChange = (value) => {
    const { meta } = this.props
    meta.setValue(value)
  }

  onUpdate = (value) => {
    this.prose.setValue(value)
    this.onChange(value)
  }

  getProse = (prose) => {
    this.prose = prose
  }

  render() {
    window.Editor = this
    const { meta = {}, readOnly } = this.props
    const {
      name,
      config,
      value,
      cpValue,
      disabled,
      unmatched,
      opt = { myPosition: true },
      update,
      isHidden,
      isRestricted,
      validation = {},
      viewOnly,
      notEditable,
    } = meta

    const matched = value && value === cpValue
    const { isMatched, isCompleted, isChanged, isInvalid } = validation

    return (
      <div
        input-name={name + ''}
        className={cn({
          isHidden,
          isRestricted,
          isMatched,
          isUnmatched: !isMatched,
          isIncompleted: !isCompleted,
          isCompleted,
          isChanged,
          isInvalid,
          viewOnly,
          notEditable,
        })}
        input-type={config.type}
      >
        <div className={cn('form-item', { readOnly, unmatched, cpOnly: !opt.myPosition })}>
          <div className="form-item__content">
            <Label {...this.props} />
            {opt.myPosition && (
              <div className="form-input" data-id={'editor:' + name}>
                {readOnly ? (
                  <div
                    className={cn('form-input__value textarea', { empty: !value, matched, cpValue, value })}
                    dangerouslySetInnerHTML={{ __html: value }}
                  />
                ) : (
                  <ProseEditor
                    className={cn({ matched, cpValue, value })}
                    onChange={this.onChange}
                    value={value}
                    key={update}
                    disabled={disabled}
                    getProse={this.getProse}
                  />
                )}
              </div>
            )}
          </div>
          <Compare meta={meta} readOnly={readOnly || !opt.myPosition} onChange={this.onUpdate} />
        </div>
      </div>
    )
  }
}

export default observer(Editor)
