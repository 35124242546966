import React from 'react'

import EmailRemovedIcon from 'common/Icons/EmailRemoved'

const config = {
  className: 'error',
  icon: <EmailRemovedIcon />,
  header: 'Negotiation Deleted!',
  description: [ 'The negotiation was deleted.' ],
  me: {
    label: 'Deleted at',
    date: ({ negotiation: { myPartyMeta } }) => myPartyMeta.deletedAt,
  },
}

export default config
