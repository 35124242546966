import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

import AsyncButton from 'common/AsyncButton/AsyncButton'
import LoadingIcon from 'common/Icons/spinner/Spinner'

import app from 'api/app'
import auth from 'api/auth'

import './AccessViaEmail.scss'

class AccessViaEmail extends React.Component {
  onSkip = async () => {
    const { hash, location, history } = this.props
    if (hash) {
      await auth.autoLogin({ hash })
      history.replace(location.pathname)
    }
    app.modal = null
  }

  render() {
    const { user } = auth

    return (
      <div className="access-link">
        <p className="access-link__desc">
          You are now accessing the negotiation via a private link sent to <b>{user.displayName}</b>
          <br />Keep it safe
        </p>
        <p>
          E-mail: <b>{user.email}</b>
        </p>
        <div className="access-link__actions">
          <div />
          <AsyncButton className="btn light" onClick={this.onSkip} activeIcon={<LoadingIcon />}>
            Acces via OneLink
          </AsyncButton>
        </div>
      </div>
    )
  }
}

export default observer(withRouter(AccessViaEmail))
