import React from 'react'
import { observer } from 'mobx-react'

import Form from '../../Form'
import PlusIcon from 'common/Icons/Plus'

import './Array.scss'

class ArrayForm extends React.Component {
  onAdd = () => {
    const { meta } = this.props
    const { data } = meta

    const value = {}

    meta.setValue([ ...data, value ])
    meta.getChildren()
  }

  render() {
    const { meta = {}, readOnly, wrapper } = this.props
    const { label, children } = meta

    return (
      <div className="form-array">
        <div className="form-label">
          <h4>{label}</h4>
        </div>
        {children && (
          <ul>
            <Form items={children} wrapper={wrapper} readOnly={readOnly} />
          </ul>
        )}
        {!readOnly && (
          <button type="button" className="array__btn --add" onClick={this.onAdd}>
            <PlusIcon /> Add
          </button>
        )}
      </div>
    )
  }
}

export default observer(ArrayForm)
