import React from 'react'

const Icon = () => (
  <svg width="24px" height="24px" viewBox="-5 -5 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-808 -170.04)">
      <g transform="translate(808 183.55)">
        <g transform="translate(0)">
          <path
            d="M33.3,176.309a5.259,5.259,0,0,1-3.187,1.052,5.361,5.361,0,0,1-3.2-1.052L9.824,163.55v21.879a3.21,3.21,0,0,0,3.187,3.23H47.188a3.21,3.21,0,0,0,3.187-3.23V163.55Z"
            transform="translate(-9.824 -163.55)"
            fill="currentColor"
          />
        </g>
      </g>
      <g transform="translate(814.009 170.04)">
        <g transform="translate(0)">
          <path
            d="M65.825,37.65V53.016L78.165,62.2a3.3,3.3,0,0,0,3.874,0l12.222-9.11V37.65Zm7.619,8.584h5.794a1.073,1.073,0,0,1,0,2.146H73.444a1.073,1.073,0,1,1,0-2.146Zm13.306,7.511H73.444a1.073,1.073,0,1,1,0-2.146H86.749a1.073,1.073,0,1,1,0,2.146Z"
            transform="translate(-65.825 -37.65)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default Icon
