const helper = {
  randomInt: (min, max) => {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min)) + min
  },
  delay(ms, to) {
    if (to) ms = this.randomInt(ms, to)
    return new Promise((resolve) => setTimeout(() => resolve(ms), ms))
  },
  compareArrays(arr1, arr2) {
    if (!arr1 || !arr2) {
      return arr1 === arr2
    }

    const hasInvalid = arr1
      .map((v1) => {
        const index = arr2.indexOf(v1)
        if (index !== -1) {
          arr2 = arr2.filter((v2, key) => key !== index)
          return false
        }

        return true
      })
      .find((invalid) => invalid)

    return !hasInvalid && !arr2.length
  },

  isEqual(a1, a2) {
    if (typeof a1 !== typeof a2) {
      return false
    }

    if (Array.isArray(a1)) {
      return this.compareArrays(a1, a2)
    }

    return a1 === a2
  },
  toWords() {},
}

export default helper
