import React from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'

import _d from 'dictionary'

import Form from 'common/Form/Form'
import AsyncButton from 'common/AsyncButton/AsyncButton'
import LoadingIcon from 'common/Icons/spinner/Spinner'
import DropDown from 'common/DropDown/DropDown'

import app from 'api/app'
import auth from 'api/auth'
import profileApi from 'api/profile'
import negotiationsApi from 'api/negotiations'

import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import Countries from './Countries.json'

import './CreateAccount.scss'

class CreateAccount extends React.Component {
  constructor(props) {
    super(props)
    const { negotiation: { language } = {} } = props

    if (language && language.code) {
      app.lang = language.code
    }
  }

  settings = [
    {
      type: 'group',
      label: '=Profile info',
      close: false,
      children: [
        {
          type: 'columns',
          children: [
            {
              type: 'section',
              children: [
                {
                  label: '=Your email',
                  name: 'email',
                  content: ({ value }) => <div className="create-account__email">{value}</div>,
                },

                {
                  type: 'radio',
                  label: '=Choose account type',
                  name: 'type',
                  validate: {
                    required: true,
                  },
                  options: [
                    {
                      label: '=Individual',
                      value: 'individual',
                      description: '=ca.Individual desc',
                    },
                    {
                      label: '=Company',
                      value: 'company',
                      description: '=ca.Company desc',
                      children: [
                        {
                          type: 'section',
                          // label: 'Company info',
                          children: [
                            {
                              type: 'text',
                              label: 'Company name',
                              name: 'company.displayName',
                              validate: {
                                required: true,
                              },
                            },
                            {
                              type: 'select',
                              label: 'Country of incorporation',
                              name: 'company.country',
                              options: Countries,
                              validate: {
                                required: true,
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              type: 'section',
              children: [
                {
                  type: 'text',
                  name: 'firstName',
                  label: '=First name',
                  // autoFocus: true,
                  validate: {
                    required: true,
                    minLength: 2,
                  },
                },
                {
                  type: 'text',
                  name: 'lastName',
                  label: '=Last name',
                  validate: {
                    required: true,
                    minLength: 2,
                  },
                },
                {
                  type: 'text',
                  name: 'password',
                  label: '=Password',
                  description: '=Minimum characters|6',
                  input: {
                    type: 'password',
                    autoComplete: 'on',
                  },
                  validate: {
                    required: true,
                    minLength: 6,
                    sync: 'confirmPassword',
                  },
                },
                {
                  type: 'text',
                  name: 'confirmPassword',
                  label: '=Confirm password',
                  description: '=Password have to match',
                  input: {
                    type: 'password',
                    autoComplete: 'on',
                  },
                  validate: {
                    required: true,
                    // minLength: 6,
                    match: 'password',
                  },
                },
                {
                  content: () => (
                    <div className="info-line">
                      {_d('Read our')} <a href="https://yumidocs.com/terms-of-service">{_d('terms and conditions')}</a>.
                      <span className="required"> *</span>
                    </div>
                  ),
                },
                {
                  type: 'checkbox',
                  name: 'termsAccepted',
                  optional: true,
                  options: [
                    {
                      label: '=Accept terms and conditions',
                      value: true,
                    },
                  ],
                  validate: {
                    required: true,
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      content: (meta, { values }) => {
        const { stats = {} } = meta.model
        const { confirmPassword, ...rest } = values
        const { allValid, totalInvalid } = stats

        const disabled = !allValid

        return (
          <div className="create-account__actions" key="action">
            <div className="create-account__main-btn">
              <ConfirmButton
                disabled={disabled}
                confirm="Are you sure?"
                data-id="save"
                onClick={() => this.onSave({ ...rest, lang: app.lang })}
                label={_d('Create account')}
              />
              <AsyncButton
                className="btn link skip"
                onClick={this.onSkip}
                activeIcon={<LoadingIcon />}
                label={_d('Continue without account')}
              />
            </div>
            {!!totalInvalid && (
              <div className="warning-msg">
                <b>{totalInvalid}</b> {_d('Fields to complete')}
              </div>
            )}
          </div>
        )
      },
    },
  ]

  onSave = async (values) => {
    const { history, location, hash } = this.props
    const response = await profileApi.createAccount({ ...values, hash }).catch(() => {
      app.modal = { header: 'Incorrect access code! Try to use correct link' }
      auth.user = null
      history.replace('/login')
      return Promise.reject()
    })

    if (response && response.jwt) {
      history.replace(location.pathname)
      await auth.setToken(response.jwt)
      app.modal = null
    }
  }

  onSkip = async () => {
    const { hash, location, history } = this.props

    if (hash) {
      await auth.autoLogin({ hash, lang: app.lang })
      history.replace(location.pathname)
    }
    app.modal = null
  }

  render() {
    const { email, firstName, lastName } = auth.user || {}
    const { language } = negotiationsApi.current || {}

    return (
      <div className="create-account elections">
        <div className="create-account__desc">
          <div>{_d('ca.Description')}</div>
          {language &&
          language.code !== 'en' && (
            <div>
              <DropDown
                options={[ { ...language, value: language.code }, { label: 'English', value: 'en' } ]}
                value={app.lang}
                onChange={({ value }) => (app.lang = value)}
              />
            </div>
          )}
        </div>
        <Form config={this.settings} values={{ email, firstName, lastName }} noCompare />
      </div>
    )
  }
}

export default withRouter(observer(CreateAccount))
