const whenNot = {
  name: 'whenNot',

  render(el, model) {
    const obj = el && el.$obj

    const { whenNot } = obj.attributes
    const whenValue = obj.whenName ? obj.whenValue : obj.parent.whenValue

    const showWhenNot = [].concat(whenValue).find((v) => v + '' === whenNot + '') === undefined

    if (obj.showWhenNot !== showWhenNot) {
      obj.showWhenNot = showWhenNot

      obj.el && obj.el.remove()
      obj.children = []

      if (showWhenNot) {
        obj.el = obj.copy.cloneNode(true)
        obj.el.$obj = obj

        model.parse(obj.el)
        obj.comment.after(obj.el)
      }
    }

    return showWhenNot
  },
}

export default whenNot
