import React from 'react'
import { withRouter } from 'react-router-dom'

import _d from 'dictionary'

import negotiationsApi from 'api/negotiations'
import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import './Settings.scss'

class Settings extends React.Component {
  onDelete = async () => {
    const { negotiation, history } = this.props
    await negotiationsApi.delete(negotiation)
    history.replace('/negotiations')
  }

  render() {
    return (
      <div className="settings-tab">
        <div className="settings-tab__delete">
          <div className="sub-menu__section">
            <ConfirmButton
              confirm={_d('Delete this negotiation?')}
              label={_d('Delete negotiation')}
              className="error"
              data-id="deleteNegotiation"
              reverse
              onClick={this.onDelete}
            />
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Settings)
