import React from 'react'

const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
      <path
        d="M17.6,5.4 L16.9,5.4 L16.9,4.7 C16.9,4.315 16.585,4 16.2,4 C15.815,4 15.5,4.315 15.5,4.7 L15.5,5.4 L8.5,5.4 L8.5,4.7 C8.5,4.315 8.185,4 7.8,4 C7.415,4 7.1,4.315 7.1,4.7 L7.1,5.4 L6.4,5.4 C5.63,5.4 5,6.03 5,6.8 L5,18 C5,18.77 5.63,19.4 6.4,19.4 L17.6,19.4 C18.37,19.4 19,18.77 19,18 L19,6.8 C19,6.03 18.37,5.4 17.6,5.4 Z M16.9,18 L7.1,18 C6.715,18 6.4,17.685 6.4,17.3 L6.4,8.9 L17.6,8.9 L17.6,17.3 C17.6,17.685 17.285,18 16.9,18 Z"
        fill="currentColor"
        fillRule="nonzero"
      />
      <rect fill="currentColor" x="12" y="13.1" width="4.2" height="3.5" rx="1" />
    </g>
  </svg>
)

export default Icon
