import React from 'react'

import Table from 'common/Table/Table'
import DateTime from 'common/DateTime/DateTime'
import ProgressLine from 'common/ProgressLine/Progress.line'
import TickIcon from 'common/Icons/Done'
import WarningIcon from 'common/Icons/Warning'

import presetsApi from 'api/presets'
import negotiationsApi from 'api/negotiations'

class Presets extends React.Component {
  getPresets = async () => {
    const { mainDoc } = negotiationsApi.current
    const res = await presetsApi.getMainList(mainDoc._key)
    return res.sort((a, b) => (a.displayName || '').localeCompare(b.displayName))
  }

  onSavePreset = ({ displayName, description }) => {
    const { model, negotiation } = this.props
    const { doc, _key } = negotiation
    const { answers, form } = model

    return presetsApi.create(doc._key, {
      displayName,
      description,
      answers,
      restrictions: form,
      fromNegotiation: _key,
    })
  }

  onUpdate = ({ displayName, description, _key }) => {
    const { answers, form } = this.props.model

    return presetsApi.update(_key, {
      displayName,
      description,
      answers,
      restrictions: form,
    })
  }

  onDelete = ({ _key }) => {
    return presetsApi.remove(_key)
  }

  config = {
    getData: this.getPresets,
    id: ({ _key }) => _key,
    add: {
      addButton: 'Create preset',
      submitButton: 'Create preset',
      header: 'Create new preset',
      onSubmit: this.onSavePreset,
      form: [
        {
          type: 'text',
          name: 'displayName',
          label: 'Preset name',
          validate: {
            required: true,
          },
        },
        {
          type: 'textarea',
          name: 'description',
          label: 'Description',
        },
      ],
    },
    actions: ({ createdByMe }) =>
      createdByMe && [
        {
          label: 'Edit',
          onSubmit: this.onUpdate,
          form: [
            {
              type: 'text',
              name: 'displayName',
              label: 'Preset name',
              validate: {
                required: true,
              },
            },
            {
              type: 'textarea',
              name: 'description',
            },
          ],
        },
        {
          label: 'Update answers',
          confirmLabel: 'Update answers',
          confirm: 'Are you sure to UPDATE preset answers?',
          onClick: ({ _key }) => this.onUpdate({ _key }),
        },
        {
          label: 'Delete',
          className: 'error',
          confirm: 'Are you sure to DELETE preset?',
          confirmLabel: 'Delete',
          onClick: this.onDelete,
        },
      ],
    onClick: async (item, config) => {
      const { model } = this.props
      config.selected = item
      model.form = item.restrictions
      model.setAnswers(item.answers)
    },
    showHeader: (list = []) => list.length > 1,
    columns: [
      {
        content: (_, { selected }) => <div className="table__icon">{selected && <TickIcon />}</div>,
      },

      {
        width: 5,
        content: ({ isLatest, doc = {} }) =>
          !isLatest &&
          doc.version && (
            <span className="outdated dark">
              <WarningIcon /> {doc.version}
            </span>
          ),
      },

      {
        header: 'Title',
        width: '50%',
        content: ({ displayName, description }) => (
          <div>
            <h4>{displayName}</h4>
            <div className="desc">{description}</div>
          </div>
        ),
      },
      {
        header: 'Created by',
        width: '50%',
        content: ({ createdBy = {} }) => (
          <div className="title">
            {createdBy.displayName}
            <div className="desc">{createdBy.email}</div>
          </div>
        ),
      },
      {
        header: 'Created',
        width: 120,
        align: 'center',
        content: ({ createdAt }) => <DateTime date={createdAt} />,
      },
      {
        header: 'Completed',
        width: 80,
        content: ({ stats = {} }) => (
          <div>{!!stats.total && <ProgressLine done={stats.completed} total={stats.total} className="in-table" />}</div>
        ),
      },
    ],
  }

  render() {
    return (
      <div>
        <Table config={this.config} className="dark" />
      </div>
    )
  }
}

export default Presets
