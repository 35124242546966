import ProgressLine from 'common/ProgressLine/Progress.line'

import TickIcon from 'common/Icons/TickCircle'

const Stats = ({ model, label }) => {
  const {
    state,
    stats: { allCompleted, allMatched, totalChanged, totalCompleted, total, totalMatched, totalInvalid } = {},
  } = model

  const isDraft = state === 'draft'

  return (
    <div>
      <div className="party-line">
        {label && <div className="party-line__label">{label}</div>}
        <div className="party-line__stats">
          {!allCompleted ? (
            <ProgressLine done={totalCompleted} total={total} label="Completed" className="--completed" />
          ) : (
            <div className="--all-done">
              <TickIcon /> All completed
            </div>
          )}
          {!isDraft &&
            (!allMatched ? (
              <ProgressLine done={totalMatched} total={total} label="Matched" className="--matched" />
            ) : (
              <div className="--all-done">
                <TickIcon /> All matched
              </div>
            ))}
          {!isDraft &&
          !!totalChanged && <ProgressLine done={totalChanged} total={total} label="Changed" className="--changed" />}
          {!!totalInvalid && <ProgressLine done={totalInvalid} total={total} label="Error" className="--error" />}
        </div>
      </div>
    </div>
  )
}

export default Stats
