import React from 'react'
import { observer } from 'mobx-react'

import ToggleButton from 'common/ToggleButton/ToggleButton'

import StateGroup from './States.group'
import negotiationsApi from 'api/negotiations'

import './States.scss'

class StatesCompleted extends React.Component {
  state = { open: false }
  render() {
    const { state, myParty = {} } = negotiationsApi.current

    const { label } = this.props
    const { open } = this.state

    const isTurn = myParty.isTurn

    const showGroupped = !isTurn || [ 'execution', 'completed' ].includes(state)

    return showGroupped ? (
      <div className="state-completed">
        <div className="state-completed__header">
          <div className="state-completed__line">
            <h4 className="state-completed__title">
              {label}
              {/* {isActive && !isCompleted && 'Completed'} */}
            </h4>
            {/* <p>Total elections: {items.length}</p> */}
          </div>
          <div className="state-completed__line">
            <ToggleButton label={open ? 'Hide' : 'Show'} value={open} onChange={() => this.setState({ open: !open })} />
          </div>
        </div>
        {open && <StateGroup {...this.props} />}
      </div>
    ) : (
      <StateGroup {...this.props} />
    )
  }
}
export default observer(StatesCompleted)
