import React from 'react'

import negotiations from 'api/negotiations'
import _d from 'dictionary'
import UserCard from 'common/UserCard/UserCard'
import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import '../Parties.scss'

class Counterparty extends React.Component {
  render() {
    const { negotiation } = this.props
    const { cpParty } = negotiation

    const { owner } = cpParty || {}

    let email
    return (
      <div className="parties">
        <div className="parties__list">
          <h3>Counterparty</h3>

          <div className="parties__content">{owner && <UserCard user={owner} className="dark" email={cpParty.email} />}</div>
        </div>

        <div className="parties__add">
          {!owner && (
            <div>
              <div className="form-item">
                <div className="form-item__content">
                  <label className="form-label">Email: </label>
                  <div className="form-input">
                    <input placeholder="user@email.com" ref={(ref) => (email = ref)} autoFocus />
                  </div>
                </div>
              </div>
              <div className="form-item">
                <ConfirmButton
                  confirm={_d("Send invitation to CP?")}
                  onClick={() => negotiations.addCP(negotiation, { email: email.value })}
                  data-id="addCounterparty"
                >
                  {_d('Add counterparty')}
                </ConfirmButton>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default Counterparty
