import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import SortAscIcon from 'common/Icons/SortAsc'
import SortDescIcon from 'common/Icons/SortDesc'

import RowLink from './Rows/Row.link'

const TableLink = (props) => {
  const { list, className, sort, config, onSort } = props
  //-----------------------------------------------------------------------------------------
  const { columns } = config

  console.log({ config, list })

  return !!list.length ? (
    <ul className={cn('table', className)}>
      <li className="table__row table__header">
        {columns.map((item, key) => (
          <div key={key} className="table__col" style={{ width: item.width, textAlign: item.align }}>
            <div onClick={() => onSort(item)} className={cn('--sort-header', item.align)}>
              {item.header}{' '}
              {sort && sort[item.header] && (sort[item.header] === 'ASC' ? <SortAscIcon /> : <SortDescIcon />)}
            </div>
          </div>
        ))}
      </li>

      {list.map((item, key) => <RowLink key={key} item={item} config={config} />)}
    </ul>
  ) : null
}

export default observer(TableLink)
