const href = {
  name: 'href',

  render(el, model) {
    const obj = el && el.$obj

    const value = model.getValue(obj, 'href')

    if (obj.value !== value) {
      obj.value = value
      obj.el.setAttribute('href', value)
    }
  },
}

export default href
