import { useState, useEffect } from 'react'
import cn from 'classnames'

import sse from 'api/sse'
import auth from 'api/auth'

import SmartIdIcon from 'common/Icons/SmartId'
import SpinnerIcon from 'common/Icons/spinner/Spinner'

import _d from 'dictionary'

const AuthPending = ({ tryAgain }) => {
  const [ notification, setNotification ] = useState({})

  useEffect(
    () =>
      sse.on('smartId.register', ({ data }) => {
        setNotification(data)

        if (data && data.result.endResult === 'OK') {
          auth.getMe()
        }
      }),
    []
  )

  const { status, result, verificationCode, message } = notification
  const state = (result && result.endResult) || status

  return (
    <article className="smart-id__register sign__pending">
      <h4 className="--label --smart-id">
        Register <SmartIdIcon />
      </h4>
      <div className={cn('sign__content', state)}>
        {message && <div className="--message">{message}</div>}
        <h4>
          {state ? _d('smartId.' + state) : 'Connecting...'}
          <button className="btn" onClick={tryAgain}>
            Try again
          </button>
        </h4>
        {verificationCode && (
          <div className="flex">
            <div className="--code">{verificationCode}</div>
            <SpinnerIcon />
          </div>
        )}
      </div>
    </article>
  )
}

export default AuthPending
