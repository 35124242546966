import { observer } from 'mobx-react'
import cn from 'classnames'

import DragIcon from 'common/Icons/Drag2'
import EditIcon from 'common/Icons/Edit2'
import RemoveIcon from 'common/Icons/Delete'
import PlusIcon from 'common/Icons/PlusCircleOutline'
import SettingsIcon from 'common/Icons/Settings'

import DropDown from 'common/DropDown/DropDown'
import Edit from '../Edit'

import './DefaultEl.scss'

const Group = ({ model }) => {
  const { label, renderChildren, edit, showRemove, children } = model

  const haveChildren = children && !!children.length

  return (
    <div className={cn('fel --group', { empty: !haveChildren })}>
      <div className="fel__title">
        <div className="drag-handle">
          <DragIcon />
        </div>
        <h2 onDoubleClick={() => console.log(model)}>{label}</h2>

        <DropDown
          className="fel__settings"
          options={[
            { label: 'Edit', onClick: () => (model.edit = !edit), icon: <EditIcon /> },
            {
              label: 'New child',
              onClick: () => model.append({ type: 'text' }),
              icon: <PlusIcon />,
            },
            {
              label: 'Remove',
              onClick: () => (model.showRemove = !showRemove),
              className: 'error2',
              icon: <RemoveIcon />,
            },
            {
              label: 'New Group',
              onClick: () => model.after({ children: [] }),
              icon: <PlusIcon />,
            },
          ]}
        >
          <SettingsIcon /> Group
        </DropDown>
      </div>

      {edit && (
        <div className="fel__edit">
          <Edit model={model} />
        </div>
      )}

      {renderChildren()}

      {showRemove && (
        <div className="fel__remove">
          <h4>Sure?</h4>
          <div className="fel__remove__actions">
            <button className="btn cancel small" onClick={() => (model.showRemove = false)}>
              Cancel
            </button>
            <button className="btn small error" onClick={() => model.remove()}>
              Remove
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(Group)
