import React from 'react'

const Icon = (props) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
      <g transform="translate(6.000000, 5.000000)" fill="currentColor" fillRule="nonzero">
        <path d="M10.7621429,5.42911765 L9.28571429,5.42911765 L9.28571429,9.54676471 C9.28571429,9.99970588 8.86785714,10.3702941 8.35714286,10.3702941 L4.64285714,10.3702941 C4.13214286,10.3702941 3.71428571,9.99970588 3.71428571,9.54676471 L3.71428571,5.42911765 L2.23785714,5.42911765 C1.41142857,5.42911765 0.993571429,4.53970588 1.57857143,4.02088235 L5.84071429,0.240882353 C6.20285714,-0.0802941176 6.78785714,-0.0802941176 7.15,0.240882353 L11.4121429,4.02088235 C11.9971429,4.53970588 11.5885714,5.42911765 10.7621429,5.42911765 Z M0,13.1764706 C0,13.6294118 0.417857143,14 0.928571429,14 L12.0714286,14 C12.5821429,14 13,13.6294118 13,13.1764706 C13,12.7235294 12.5821429,12.3529412 12.0714286,12.3529412 L0.928571429,12.3529412 C0.417857143,12.3529412 0,12.7235294 0,13.1764706 Z" />
      </g>
    </g>
  </svg>
)

export default Icon
