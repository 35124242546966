import { render } from 'react-dom'
import { decorate, observable } from 'mobx'

import { Plugin, NodeSelection } from 'prosemirror-state'

import FloatingMenu from './FloatingMenu.component'
import Actions from './FloatingMenu.actions'

//--------------------------------------------------------------------------------------------
class TagPlugin {
  constructor(items, editorView, options) {
    this.items = items.map((item) => new MenuItem(item, editorView))
    this.editorView = editorView
    this.options = options
    this.model = options && options.model
    this.service = options.service

    decorate(this.settings, {
      open: observable,
      openTags: observable,
      openSettings: observable,
      node: observable.ref,
    })

    this.actions = new Actions(this)

    this.create()
    this.initMenuBtn()
  }

  settings = { openTags: null, openSettings: null, open: null, node: null }

  initMenuBtn = () => {
    global.pmContextMenu = (el) => {
      this.select(el)
    }
  }

  select = (el) => {
    const { pmViewDesc } = (el && el.parentElement) || {}
    const { posBefore } = pmViewDesc || {}

    const { state } = this.editorView.props

    const nodeSelection = NodeSelection.create(state.doc, posBefore)
    this.editorView.dispatch(state.tr.setSelection(nodeSelection))
  }

  update(view, lastState) {
    const { state } = view
    if (lastState && lastState.doc.eq(state.doc) && lastState.selection.eq(state.selection)) return

    if (state.selection.empty) {
      if (this.settings.open !== false) {
        this.service.getTagged()
      }

      this.settings.openTags = false
      this.settings.openSettings = false
      this.settings.open = false
      this.settings.node = null

      return
    }

    this.items.forEach((item) => item.update())

    const { node } = state.selection
    this.settings.node = node
    this.settings.open = true

    const { from, to } = state.selection
    const start = view.coordsAtPos(from)
    const end = view.coordsAtPos(to)
    const box = this.dom.offsetParent.getBoundingClientRect()
    const left = Math.max((start.left + end.left) / 2, start.left + 3)

    const bottom = box.bottom - start.top
    this.settings.isAtBottom = bottom < 200
    this.settings.isTgged = node && !![ 'drText', 'drWhen', 'drValue' ].find((name) => node.attrs[name])

    this.dom.style.left = left - box.left + 'px'
    this.dom.style.bottom = bottom + 'px'
  }

  destroy() {
    this.dom.remove()
  }

  create = () => {
    this.dom = document.createElement('div')
    this.dom.className = 'floating-menu'
    this.editorView.dom.parentNode.insertBefore(this.dom, this.editorView.dom)

    render(<FloatingMenu {...this} />, this.dom)
  }
}

//--------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------
class MenuItem {
  constructor(item, editorView) {
    this.editorView = editorView
    this.spec = item.spec

    this.update()

    decorate(this, {
      isDisabled: observable,
      isActive: observable,
    })
  }

  get icon() {
    return this.spec.icon
  }

  get title() {
    return this.spec.title
  }

  run = (e) => {
    const { editorView } = this
    e.preventDefault()
    editorView.focus()

    return this.spec.run(editorView.state, editorView.dispatch, editorView)
  }

  update = () => {
    const { editorView } = this

    this.available = this.spec.run(editorView.state, null, editorView)
    this.active = this.spec.active && this.spec.active(editorView.state)
    this.isDisabled = !this.available
    this.isActive = !!this.active
  }
}

//--------------------------------------------------------------------------------------------
const menuPlugin = (items, options) => new Plugin({ view: (editorView) => new TagPlugin(items, editorView, options) })

export default menuPlugin
