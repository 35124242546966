import cn from 'classnames'

import { timeDistance, format } from 'common/helper/date'
import InIcon from 'common/Icons/In'
import OutIcon from 'common/Icons/Out'

import SignIcon from 'common/Icons/Signature'

import ProgressLine from 'common/ProgressLine/Progress.line'
import Avatar from 'common/UserCard/Avatar'

import _d from 'dictionary'

import './ListItem.scss'

const NegListItem = ({
  // doc,
  mainDoc = {},
  state,
  cpParty,
  myParty,
  myPartyMeta,
  visited,
  createdAt,
  updatedAt,
  signatures,
  signed,
}) => {
  const { stats } = myPartyMeta

  const lastOpen = visited && new Date(visited.createdAt)
  const updated = new Date(updatedAt || myPartyMeta.updatedAt || createdAt)

  let flag

  if (lastOpen && updated > lastOpen) {
    flag = 'updated'
  }

  if (!lastOpen) {
    flag = 'new'
  }

  const sig = signatures && signatures.filter((item) => !item.main)

  return (
    <div className={cn('neg-item', state, flag && '--' + flag, { isTurn: myParty.isTurn })}>
      <div className="neg-item__title">
        <h4>
          {mainDoc.label}
          <div className="neg-item__sub-title">
            <div className="desc">{myParty.displayName}</div>
          </div>
        </h4>
        <div className="version">v {myParty.version}</div>
      </div>

      <div>
        {cpParty &&
        cpParty.owner && (
          <dl>
            <dd>
              <div className="user --cp">
                <div className="user__content">
                  {cpParty.owner.displayName}
                  <div className="desc">{cpParty.email}</div>
                </div>
                <div className="user__photo">
                  <Avatar user={cpParty.owner} className="small" />
                </div>
              </div>
            </dd>
          </dl>
        )}
      </div>

      <ul className="neg-item__info">
        <li className={cn('owner', { sender: myParty.isOwner })}>
          {myParty.isOwner ? <InIcon /> : <OutIcon />} {format(createdAt, 'MMM d')}
        </li>

        {sig &&
        !!sig.length && (
          <li>
            <SignIcon />
            <div>
              {signed.length} <span>/</span> {sig.length}
            </div>
          </li>
        )}
      </ul>

      <div className="neg-item__stats">
        <div>
          <div className={'neg-item__label label-' + state}>{_d('stateLabel.' + state)}</div>
          {[ 'active', 'draft', 'inviting' ].includes(state) &&
          stats && (
            <div className="neg-item__propgress">
              <ProgressLine done={stats.matched} total={stats.total} completed={stats.completed} className="in-table" />
            </div>
          )}
        </div>
        <div className="neg-item__updated">
          <div>Updated {timeDistance(updatedAt || myPartyMeta.updatedAt || createdAt, 'ago')}</div>
          {visited && <div className="desc">Visited {timeDistance(visited.createdAt, 'ago')}</div>}
        </div>
      </div>
    </div>
  )
}

export default NegListItem
