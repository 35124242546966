const whenDefault = {
  name: 'default',

  render(el, model) {
    const obj = el && el.$obj

    const whenValue = obj.whenName ? obj.whenValue : obj.parent.whenValue

    const showDefault = whenValue === undefined

    if (obj.showDefault !== showDefault) {
      obj.showDefault = showDefault

      obj.el && obj.el.remove()
      obj.children = []

      if (showDefault) {
        obj.el = obj.copy.cloneNode(true)
        obj.el.$obj = obj

        model.parse(obj.el)
        obj.comment.after(obj.el)
      }
    }

    return showDefault
  },
}

export default whenDefault
