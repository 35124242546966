import { decorate, observable } from 'mobx'
import axios from 'axios'

import UserIcon from 'common/Icons/User'
import Segment from '../common/SegmentIO/SegmentIO'
import http from './http'
import sse from './sse'
import app from './app'

import CreateAccount from 'screens/Settings/CreateAccount/CreateAccount'

const api = {
  init() {
    if (http.validToken) {
      this.me()
    } else {
      this.user = null
    }
  },

  //-------------------------------------------------------------------------------------------
  async logout() {
    await axios.get('/logout')
    http.logout()
    sse.close()

    this.user = null
  },

  //-------------------------------------------------------------------------------------------
  getUsers() {
    return axios.get('/users')
  },

  //-------------------------------------------------------------------------------------------
  getCompany() {
    return axios.get('/company')
  },

  //-------------------------------------------------------------------------------------------
  getUserByCode(code) {
    return axios.get('/register/' + code)
  },

  //-------------------------------------------------------------------------------------------
  getByResetCode(code) {
    return axios.get('/reset-password/' + code)
  },

  //-------------------------------------------------------------------------------------------
  async signupWithCredentials(data) {
    const response = await axios.post('/signupWithCredentials', data)
    http.setJwt(response.jwt)

    this.user = response.user
    return response
  },

  //-------------------------------------------------------------------------------------------
  async resetPassword(code, body) {
    const response = await axios.put('/reset-password/' + code, body)

    return this.setToken(response.jwt)
  },

  //-------------------------------------------------------------------------------------------
  async registerWithCode(code, body) {
    const response = await axios.put('/register/' + code, body)
    http.setJwt(response.jwt)

    this.user = response.user
    return response
  },

  //-------------------------------------------------------------------------------------------
  async login(user) {
    if (user) {
      const response = await axios.post('/login', user)

      if (response && response.jwt) {
        return this.setToken(response.jwt)
      }
    }
  },

  setToken(jwt) {
    http.setJwt(jwt)

    return api.me()
  },

  //-------------------------------------------------------------------------------------------
  async autoLogin(body) {
    const response = await axios.post('/auto/login', body)

    if (response && response.jwt) {
      return await api.setToken(response.jwt)
      // window.location.reload()
    }
  },

  //-------------------------------------------------------------------------------------------
  async getMe() {
    const response = await axios.get('/me').catch((e) => {
      http.logout()
      sse.close()
    })

    this.user = response || null

    if (this.user) {
      Segment.identify(this.user._id)
    }
    return this.user
  },

  async me() {
    const response = await this.getMe()

    if (response) {
      const isLoginUrl = window.location.pathname.includes('login')
      app.lang = response.lang
      if (response.status === 'inviting' && !isLoginUrl) {
        this.createAccount()
      }

      sse.init()
      sse.on('updateMe', ({ data }) => {
        this.user = data
        app.lang = data.lang
      })
    }
    return response
  },

  createAccount() {
    app.modal = { header: 'Create account', bgClick: false, content: () => <CreateAccount />, icon: <UserIcon /> }
  },

  get isAdmin() {
    const role = this.user && this.user.role
    return role && role.includes('admin')
  },

  get isApiUser() {
    const role = this.user && this.user.role
    return role && role.includes('api')
  },

  get isLoggedIn() {
    return this.user && this.user.email
  },
  //-------------------------------------------------------------------------------------------
}

decorate(api, {
  activeUser: observable,
  user: observable,
})

api.init()

window.auth = api
export default api
