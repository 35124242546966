import React from 'react'
import { withRouter } from 'react-router-dom'

import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import CompletedIcon from 'common/Icons/Completed'
import TickIcon from 'common/Icons/Tick'

import negotiations from 'api/negotiations'

const config = {
  icon: <CompletedIcon />,
  header: 'Execution',
  description: [
    'The document is in execution stage. Review your contract details below and execute.',
    'Executed documents cannot be changed. They are always available for download and review.',
  ],

  me: {
    Content: () => <div />,
  },
  Action: withRouter(({ negotiation, model, settings, history }) => (
    <div className="party-line__center">
      <div className="party-line__btn --right">
        <button className="btn link" onClick={() => (settings.confirmPage = null)}>
          Close
        </button>
      </div>

      <div className="party-line__btn">
        <ConfirmButton
          confirm="I agree with the current contract"
          data-id="agreeWithCP"
          onClick={async () => {
            const neg = await negotiations.executeSingle(negotiation, { answers: model.answers })
            settings.confirmPage = null
            if (!negotiation._key) {
              history.replace(`/negotiations/${neg._key}`)
            }
          }}
          icon={<TickIcon />}
        >
          Confirm
        </ConfirmButton>
      </div>
    </div>
  )),
}

export default config
