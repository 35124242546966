import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import _d from 'dictionary'

import SentIcon from 'common/Icons/Sent'
import WaitingIcon from 'common/Icons/Waiting'
import TickIcon from 'common/Icons/Tick'
import HelpIcon from 'common/Icons/Help'

import Stats from './Stats'

import './Toolbars.scss'

const info = {
  draft: [
    'Please fill in the form below with your preferred position. The document on the right updates in real-time.',
    "Click on the field's title to scroll the document on the right to its location in the document.",
    "You can send partially filled in forms to your counterparty (CP) so that they fill in the rest (eg. their company's details).",
    'The starting position for the CP will be the answers you set in the form below.',
    `If you don't want to negotiate an election, you can hide it or mark it as read-only. Hover the component and use the three dots that appear.`,
    'Next time you need to fill in the same document, you can reuse the same answers using the Preset functionality. Create a new preset by using the toolbar above the document on the right. Presets are great to apply the same business and legal terms to similar CPs (SMEs, employee types, business situations).',
    'Once created, presets are visible to the entire team.',
    `If the CP didn't accept your invitation yet, send them a quick reminder. Go to Settings, click the three dots next to your CP name and select reminder.`,
  ],
  active: [
    'Please fill in the form below with your preferred position. The document on the right updates in real-time.',
    "Click on the field's title to scroll the document on the right to its location in the document.",
    "As a receiving party, your starting document matches the sending party's position. Change the document according to your business interest and send it back to the counterparty.",
    'We will automatically indicate where parties have diverging answers. If that field is text, we can show you the redlining of the two positions. When your answers differ, you can still send the document back to the counterparty. It indicates you are rejecting their proposal.',
    `When you reject your counterparty's proposal, explain your reasons in the comment box that appears. You can provide further comments before you send the document back to the CP.`,
    `The negotiation stage goes on until 100% of the form is filled in and both parties' answers are identical.`,
  ],
  execution: [
    `You are in execution mode. In execution mode no more changes are accepted. It's time for the parties to sign the document.`,
    'Your answers match your counterparty answers. You can review them below by expanding the section below.',
  ],
  completed: 'Negotiation completed. All elections matched',
  waiting: [
    'Your answers have been sent to counterparty. You can review them below by expanding the section below. Wait for the CP to get back to you.',
    'If your CP takes too long, you can nudge them with a reminder. Go to Settings, click the three dots next to your CP name and select reminder.',
  ],
}

const Ul = ({ list }) => (
  <ul className="help-box__list">
    {list.map((text, key) => (
      <li key={key}>{text}</li>
    ))}
  </ul>
)

class FormToolbar extends React.Component {
  state = {}

  onHighlight = (type) => {
    const { model } = this.props
    model.highlight = type
  }

  nextStep = () => {
    const { negotiation, model, settings = {} } = this.props
    const { myParty, cpParty, state } = negotiation
    const { stats, form } = model

    const { allMatched, restricted = {} } = stats

    const canSend = model.isAllowed('canSend')
    const canAgree = model.isAllowed('canAgree')

    let result
    if (!cpParty) {
      return null
    }

    if (cpParty.isTurn) {
      result = (
        <div className="waiting flex">
          {_d('Waiting for CP')} <WaitingIcon />
        </div>
      )
    }

    if (myParty.isTurn) {
      const { total, allCompleted } = restricted
      const disabled = form && total && !allCompleted

      result = (
        <button
          className="btn round white"
          onClick={() => {
            settings.confirmPage = 'sendToCounterparty'
            setTimeout(() => window.scrollTo(0, 0))
          }}
          data-id="toolbar:next"
          disabled={disabled || settings.confirmPage === 'sendToCounterparty' || !canSend}
        >
          {_d('Send')} <SentIcon />
        </button>
      )

      if (allMatched) {
        result = (
          <button
            className="btn round success"
            onClick={() => {
              settings.confirmPage = 'agree'

              setTimeout(() => window.scrollTo(0, 0))
            }}
            data-id="toolbar:next"
            disabled={settings.confirmPage === 'agree' || !canAgree}
          >
            {_d('Agree')} <TickIcon />
          </button>
        )
      }

      if (state === 'inviting') {
        result = null
      }
    }

    if (['completed', 'rejected', 'executed', 'execution'].includes(state)) {
      result = null
    }

    return result
  }

  render() {
    const { nr, model, negotiation = {} } = this.props

    const { myParty: { isTurn } = {}, myPartyMeta: { stats } = {}, state, singleParty } = negotiation

    let { total, totalIncompleted, totalMatched, totalInvalid, allMatched, totalChanged } = model.stats

    const completed = ['execution', 'completed'].includes(state)
    const showGuide = ['draft'].includes(state) || (state === 'active' && !isTurn)

    const { showHelp = showGuide } = this.state

    // map to stats from db
    if (!total && stats) {
      total = stats.total
      totalIncompleted = total - stats.completed
      totalMatched = stats.matched
    }

    // const totalUnmatched = total - totalMatched

    let help = info[state] || info.active

    if (['inviting', 'active'].includes(state) && !isTurn) {
      help = info.waiting
    }

    const text = Array.isArray(help) ? <Ul list={help} /> : help

    return [
      <div className={cn('toolbars', '--nr-' + nr, { completed })} key="toolbar">
        {/* <div className="toolbars__nr">{nr}</div> */}
        {/* {completed ? (
          <div className="toolbars__nr">{nr}</div>
        ) : (
          <div className={cn('percent', { success: allMatched })} style={{ '--percents': totalMatched / total * 100 }}>
            <svg viewBox="0 0 150 150" width="150" height="150">
              <circle cx="75" cy="75" r="65" />
            </svg>
            <div className="number">
              <h2>2</h2>
            </div>
          </div>
        )} */}

        {/* <button className="btn none">
          <DownIcon />
        </button> */}

        <div className="toolbars__content">
          <div>
            <h3>Form</h3>
            <div className="desc">{totalIncompleted ? 'Fill all fields' : 'Completed'}</div>
          </div>
          {/* <div className="toolbars__info">
            <div className="info-list">
              <span>{total}</span> Total
            </div>

            {!singleParty &&
            !!totalUnmatched &&
            !model.noCompare && (
              <div className="info-list unmatched">
                <span>{totalUnmatched}</span> Not matched
              </div>
            )}

            {!!totalIncompleted && (
              <div className="info-list incompleted">
                <span>{totalIncompleted}</span> Incompleted
              </div>
            )}

            {!!totalInvalid && (
              <div className="info-list error">
                <span>{totalInvalid}</span> Error
              </div>
            )}

            {!!totalChanged && (
              <div className="info-list changed">
                <span>{totalChanged}</span> Changed
              </div>
            )}
          </div> */}

          <Stats model={model} onChange={this.onHighlight} />
          <button className="btn help-btn" onClick={() => this.setState({ showHelp: !showHelp })}>
            <HelpIcon />
          </button>
          {/* {isTurn && [ 'draft', 'active' ].includes(state) && <SaveBtn {...this.props} />} */}
        </div>

        {/* <div className="toolbars__actions">{this.nextStep()}</div> */}
      </div>,

      showHelp && (
        <div className="help-box" key="help-box">
          <div>
            {text}
            <div className="help-box__actions">
              <button className="btn link" onClick={() => this.setState({ showHelp: false })}>
                close
              </button>
            </div>
          </div>
          {/* <HelpIcon /> */}
        </div>
      ),
    ]
  }
}

export default observer(FormToolbar)
