import React from 'react'

const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
      <g transform="translate(3.000000, 5.000000)" fill="currentColor">
        <path d="M2.2,-9.05941988e-14 L2.91538462,-9.01501096e-14 C4.13041107,-9.03733063e-14 5.11538462,0.98497355 5.11538462,2.2 L5.11538462,11.8 C5.11538462,13.0150264 4.13041107,14 2.91538462,14 L2.2,14 C0.98497355,14 -7.42050821e-14,13.0150264 -7.32747196e-14,11.8 L-8.2600593e-14,2.2 C-8.27493909e-14,0.98497355 0.98497355,-9.03710021e-14 2.2,-9.05941988e-14 Z M1.26923077,11.8461538 C0.971846673,11.8461538 0.730769231,12.0872313 0.730769231,12.3846154 C0.730769231,12.6819995 0.971846673,12.9230769 1.26923077,12.9230769 L3.84615385,12.9230769 C4.14353794,12.9230769 4.38461538,12.6819995 4.38461538,12.3846154 C4.38461538,12.0872313 4.14353794,11.8461538 3.84615385,11.8461538 L1.26923077,11.8461538 Z" />
        <path d="M8.77692308,-9.05941988e-14 L9.49230769,-9.01501096e-14 C10.7073341,-9.03733063e-14 11.6923077,0.98497355 11.6923077,2.2 L11.6923077,11.8 C11.6923077,13.0150264 10.7073341,14 9.49230769,14 L8.77692308,14 C7.56189663,14 6.57692308,13.0150264 6.57692308,11.8 L6.57692308,2.2 C6.57692308,0.98497355 7.56189663,-9.03710021e-14 8.77692308,-9.05941988e-14 Z M7.84615385,11.8461538 C7.54876975,11.8461538 7.30769231,12.0872313 7.30769231,12.3846154 C7.30769231,12.6819995 7.54876975,12.9230769 7.84615385,12.9230769 L10.4230769,12.9230769 C10.720461,12.9230769 10.9615385,12.6819995 10.9615385,12.3846154 C10.9615385,12.0872313 10.720461,11.8461538 10.4230769,11.8461538 L7.84615385,11.8461538 Z" />
        <path d="M13.3942789,0.352111073 C14.4549124,0.0144545344 15.5884505,0.600543171 15.926107,1.66117668 C15.9307182,1.67566102 15.9351653,1.69019707 15.9394476,1.70478203 L18.6816572,11.0444711 C19.0045114,12.1440801 18.3900519,13.3002394 17.2980288,13.6478889 C16.2373953,13.9855455 15.1038572,13.3994568 14.7662007,12.3388233 C14.7615895,12.324339 14.7571424,12.3098029 14.7528601,12.295218 L12.0106505,2.95552887 C11.6877963,1.8559199 12.3022558,0.699760614 13.3942789,0.352111073 Z M15.5340821,11.4674625 C15.2517656,11.557339 15.0929118,11.8562363 15.1763781,12.1405139 C15.1774851,12.1442845 15.1786348,12.1480425 15.1798269,12.1517871 C15.26712,12.4259885 15.5601691,12.5775077 15.8343705,12.4902147 L17.7652718,11.8755052 C18.0475883,11.7856288 18.2064421,11.4867314 18.1229758,11.2024538 C18.1218688,11.1986832 18.1207191,11.1949253 18.119527,11.1911807 C18.0322339,10.9169793 17.7391848,10.7654601 17.4649833,10.8527531 L15.5340821,11.4674625 Z" />
      </g>
    </g>
  </svg>
)

export default Icon
