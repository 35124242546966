import React from 'react'

import { timeDistance } from 'common/helper/date'
import ProgressLine from 'common/ProgressLine/Progress.line'

import adminApi from 'api/admin'

import Table from 'common/Table/Table'

import PartyUsers from './Negotiations.users'
import NegotiationContent from './Negotiations.content'

import './Negotiations.scss'

class AdminNegotiations extends React.Component {
  timeBetweenReminders = 1000 * 60 * 15

  tableConfig = {
    getData: adminApi.getAllNegotiations,
    expandAll: false,
    actions: ({ state, auditFile, parties, _key }) => [
      state === 'completed' &&
      !auditFile && {
        label: 'Audit trail',
        onClick: (item) => {
          const user = parties[0].users[0]
          return adminApi.createAuditTrail(_key, user.email)
        },
        confirm: 'Are you sure?',
      },
    ],
    rowClass: ({ state, signatures }) => [ 'execution', 'completed' ].includes(state) && !signatures && 'error',
    columns: [
      {
        width: 80,
        align: 'center',
        header: 'Title',
        content: ({ label, version, state, lastActivity }) => {
          const { stats } = lastActivity || {}
          return (
            <div>
              <h4>{label}</h4>
              <div className="desc">{version}</div>
              <div>
                <div className={'label-' + state}>{state}</div>
                {state === 'active' &&
                stats && (
                  <ProgressLine
                    done={stats.matched}
                    total={stats.total}
                    completed={stats.completed}
                    className="in-table"
                  />
                )}
              </div>
            </div>
          )
        },
      },
      {
        header: 'Updated',
        width: 100,
        content: ({ createdAt }) => (
          <div className="table__date">
            <div>
              {timeDistance(createdAt)}
              {createdAt && <div className="desc">ago</div>}
            </div>
          </div>
        ),
      },
      {
        header: 'Document',
        content: ({ doc = {}, _key, lastActivity }) => {
          return (
            <div>
              <h4>{doc.label}</h4>
              {lastActivity && <small>{lastActivity.title}</small>}
              <div className="desc">{_key}</div>
            </div>
          )
        },
      },

      {
        header: 'Sender',
        cellClass: ({ parties = [] }) => parties[0] && parties[0].isTurn && 'isTurn',
        content: (item) => <PartyUsers {...item} nr={0} />,
      },
      {
        header: 'Receiver',
        cellClass: ({ parties = [] }) => parties[1] && parties[1].isTurn && 'isTurn',
        content: (item) => <PartyUsers {...item} nr={1} timeBetweenReminders={this.timeBetweenReminders} />,
      },
    ],
    content: (item) => <NegotiationContent {...item} timeBetweenReminders={this.timeBetweenReminders} />,
  }

  render() {
    return (
      <div className="page-inner admin-negotiations">
        <h2>All Negotiations</h2>
        <Table config={this.tableConfig} className="dark" />
      </div>
    )
  }
}

export default AdminNegotiations
