import React from 'react'
import cn from 'classnames'

import Form from '../../Form'

import './Section.scss'

class Section extends React.Component {
  render() {
    const { meta = {}, readOnly, wrapper } = this.props
    const { config = {}, children, opt = {} } = meta
    const { label, flex } = config

    const props = { items: children, readOnly, wrapper }

    return (
      <div
        style={{ flex }}
        className={cn('form-section', {
          matched: opt.myPosition && opt.cpPosition,
          cpPosition: opt.cpPosition,
          myPosition: opt.myPosition,
        })}
      >
        {label && <h4>{label}</h4>}
        {children && (
          <ul>
            <li>
              <Form {...props} />
            </li>
          </ul>
        )}
      </div>
    )
  }
}

export default Section
