import React, { Suspense, lazy } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import PreLoad from 'common/PreLoad/PreLoad'
import ArrowIcon from 'common/Icons/SmallRight'

import devApi from 'api/dev'

import './DbRelations.scss'

const DbRelationsChart = lazy(() => import('./DbRelations.chart'))

const Collection = (props) => {
  const { name, total, properties, relation } = props
  return (
    <div className="db__collection">
      <header>
        {name} <b>{total}</b>
      </header>
      <ul>
        <h4>Properties</h4>
        {Object.entries(properties).map(([ name, total ]) => (
          <li key={name}>
            {name} -&nbsp; <b>{total}</b>
          </li>
        ))}
      </ul>

      {!!relation.length && (
        <ul>
          <h4>EDGE relation</h4>
          {relation.map(({ from, to }, key) => (
            <li key={key}>
              <div className={cn('--from', { '--me': from === name })}>{from}</div>
              <ArrowIcon />
              <div className={cn('--to', { '--me': to === name })}>{to}</div>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

class DbRelations extends React.Component {
  constructor(props) {
    super(props)
    this.preLoad = new PreLoad(devApi.getCollections)
  }

  parse = ({ info }) => {
    const collectionsMap = {}

    const collections = info.filter((item) => {
      if (item.type === 'collection') {
        collectionsMap[item.name] = item
        item.relation = []
        return true
      }
      return false
    })

    this.edge = info.filter(({ edge }) => {
      if (edge) {
        Object.keys(edge).forEach((from) => {
          Object.keys(edge[from]).forEach((to) => {
            collectionsMap[from].relation.push({ from, to })
            if (from !== to) {
              collectionsMap[to].relation.push({ from, to })
            }
          })
        })
      }
      return edge
    })
    this.collections = collections

    return collections
  }

  render() {
    window.DbRelations = this
    const { loading, result, loader } = this.preLoad

    const collections = result.info && this.parse(result)

    return (
      loading || (
        <div>
          <div className="page-inner">
            <div className="section">
              <header className="section__header">Collections</header>

              <div className="section__content">
                <div className="db">{collections.map((item, key) => <Collection key={key} {...item} />)}</div>
              </div>
            </div>
          </div>

          <div className="page-inner">
            <div className="section --dark">
              <header className="section__header">DB Relations</header>

              <div className="section__content">
                <Suspense fallback={loader}>
                  <DbRelationsChart {...result} />
                </Suspense>
              </div>
            </div>
          </div>
        </div>
      )
    )
  }
}

export default observer(DbRelations)
