import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import ProgresLine from 'common/ProgressLine/Progress.line'
import _d from 'dictionary'
import './ProgressBar.scss'

class ProgressBar extends React.Component {
  get sender() {
    const { model, negotiation } = this.props
    const { stats } = model
    const { state, cpParty, signatures = [], doc } = negotiation
    const singleParty = doc.schema.positions.length === 1

    let draft = { done: true }
    let inviting = { done: true }
    let active = { done: true }
    let execution = {}
    let completed = {}

    const { totalCompleted, total, totalMatched } = stats

    if (state === 'draft') {
      draft = {
        done: totalCompleted,
        total,
        active: true,
      }
      inviting = {}
      active = {}
    }

    if (state === 'inviting') {
      inviting = { done: false, active: true }
      active = {}
    }

    if (state === 'active' || (state === 'agreed' && !stats.isMatched)) {
      active = {
        done: totalMatched,
        completed: totalCompleted,
        total,
        active: true,
      }
    }

    if (state === 'execution') {
      const all = signatures.filter((item) => !item.main)
      const signed = all.filter((item) => item.signed)

      execution = {
        done: signed.length,
        total: all.length,
        active: true,
      }
    }

    if (state === 'completed') {
      execution = { done: true }
      completed = { done: true, active: true }
    }

    if (cpParty && cpParty.isOwner) {
      if (state === 'rejected') {
        return [ { label: _d('Received'), done: true }, { label: _d('Rejected'), error: true, active: true } ]
      }

      return [
        { label: _d('Received'), ...inviting },
        { label: _d('In progress'), ...active },
        { label: _d('Execution'), ...execution },
        { label: _d('Completed'), ...completed },
      ]
    }

    if (state === 'rejected') {
      return [
        { label: _d('Draft'), done: true },
        { label: _d('Inviting'), done: true },
        { label: _d('Rejected'), error: true, active: true },
      ]
    }

    if (singleParty) {
      return [
        { label: _d('Draft'), ...draft },
        { label: _d('Execution'), ...execution },
        { label: _d('Completed'), ...completed },
      ]
    }

    return [
      { label: _d('Draft'), ...draft },
      { label: _d('Inviting'), ...inviting },
      { label: _d('In progress'), ...active },
      { label: _d('Execution'), ...execution },
      { label: _d('Completed'), ...completed },
    ]
  }
  render() {
    return (
      <div className="progress-bar">
        <ul className="progress-bar__list">
          {this.sender.map((item, key) => (
            <li key={key} className={cn('progress-bar__item', { active: item.active })}>
              <ProgresLine {...item} className="small" />
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default observer(ProgressBar)
