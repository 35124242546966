import sortBy from 'lodash.sortby'
import get from 'lodash.get'

class FilterModel {
  constructor(list, config) {
    this.list = list
    this.config = config
    this.parse()
    this.sortGroup()
    this.filter()
  }

  //-----------------------------------------------------------------------------------------
  parse = () => {
    const { list, config } = this

    if (config.groupBy && list && list[0]) {
      const group = {}
      const defaultFilter = config.groupBy.find((item) => item.default) || {
        label: '__All_records__',
        value: () => true,
        hidden: true,
      }

      const groupBy = [ defaultFilter, ...config.groupBy.filter((item) => !item.default) ]

      list.forEach((item) => {
        // console.group(item._key)
        groupBy.forEach((gr) => {
          let value
          let sortValue

          if (typeof gr.value === 'string') {
            value = get(item, gr.value) + ''
          }

          if (typeof gr.value === 'function') {
            value = gr.value(item) + ''
          }

          if (typeof gr.sortBy === 'function') {
            sortValue = gr.sortBy(item, gr)
          }

          // console.log(gr.label, gr)
          group[gr.label] = group[gr.label] || {
            ...gr,
            items: {},
          }
          group[gr.label].items[value] = group[gr.label].items[value] || {
            list: [],
            filtered: [],
            value,
            viewValue: gr.view ? gr.view(item) : value,
            sortValue,
          }
          group[gr.label].items[value].list.push(item)
        })
        // console.groupEnd(item._key)
      })

      this.groupsByLabel = group
      this.group = Object.values(group)
      this.group.forEach((item) => (item.list = Object.values(item.items)))
    }
  }

  //-----------------------------------------------------------------------------------------
  filter = async (selected = {}) => {
    this.selected = selected

    if (this.group) {
      console.time('Filter')

      this.group.forEach(async (gr) => await this.filterSingle(gr, selected))

      const firstGroup = this.group[0]
      let active = selected[firstGroup.label] || {}

      active = Object.keys(active).filter((name) => active[name])

      const list = active.length ? firstGroup.list.filter((item) => active.includes(item.value)) : firstGroup.list

      this.filtered = list.map((item) => item.filtered).flat()
      console.timeEnd('Filter')

      if (this.sortBy && this.sortBy.direction) {
        this.sort(this.sortBy.column, this.sortBy.direction)
      }

      return this.filtered
    }

    this.filtered = this.list
    return this.list
  }

  //-----------------------------------------------------------------------------------------
  filterSingle = async (gr, selected = {}) => {
    const sel = { ...selected }
    delete sel[gr.label]
    gr.sortGroup = async (l) => await this.sortSingle(gr, l)

    gr.list.forEach((section) => {
      section.filtered = section.list

      for (let i in sel) {
        const searchIn = this.groupsByLabel[i]

        section.filtered = this.compareSameGroup(section.filtered, searchIn, sel[i])
      }
    })
  }

  //-----------------------------------------------------------------------------------------
  compareSameGroup = (list, searchIn, active) => {
    let available = searchIn.list.filter((item) => active[item.value])
    if (!available.length) {
      return list
    }
    available = available.map((item) => item.list).flat()

    return list.filter((item) => available.includes(item))
  }

  //-----------------------------------------------------------------------------------------
  sortSingle = async (gr, limit) => {
    if (gr.sortBy) {
      gr.list = sortBy(gr.list, ({ sortValue }) => sortValue)
      if (gr.sortDirection === 'DESC') {
        gr.list.reverse()
      }
    } else {
      gr.list = sortBy(gr.list, ({ list }) => list.length).reverse()
    }

    const arr = gr.originalList || gr.list

    limit = limit || gr.limit

    if (limit) {
      if (arr.length < limit) {
        gr.list = arr
        return arr
      }
      gr.activeLimit = limit

      if (!gr.originalList) {
        gr.originalList = [ ...gr.list ]
      }

      const visible = arr.slice(0, limit)
      const rest = arr.slice(limit)

      const restNr = rest.length
      const other = {
        value: `[Other]`,
        restNr,
        viewValue: `Other [${restNr}]`,
        list: [],
      }
      rest.forEach(({ list }) => {
        other.list = [ ...other.list, ...list ]
      })
      gr.list = [ ...visible, other ]
    }
  }

  //-----------------------------------------------------------------------------------------
  sortGroup = () => {
    return this.group && this.group.forEach((gr) => this.sortSingle(gr))
  }

  sortAsc = (column) => {
    return sortBy(this.filtered, (item) => {
      if (typeof column.sortBy === 'string') {
        return get(item, column.sortBy)
      }

      if (typeof column.sortBy === 'function') {
        return column.sortBy(item)
      }

      if (column.name) {
        return get(item, column.name)
      }

      if (typeof column.content === 'function') {
        return column.content(item)
      }

      return this.filtered
    })
  }

  //-----------------------------------------------------------------------------------------
  sort(column, direction) {
    this.sortBy = {
      column,
      direction,
    }

    if (direction) {
      console.time(direction)
      const sortedAsc = this.sortAsc(column)

      this.filtered = direction === 'DESC' ? sortedAsc.reverse() : sortedAsc
      console.timeEnd(direction)
    } else {
      this.filter(this.selected)
    }
  }
}

export default FilterModel
