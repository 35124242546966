const when = {
  name: 'when',

  render(el, model) {
    const obj = el && el.$obj

    const { when } = obj.attributes
    const whenValue = obj.whenName ? obj.whenValue : obj.parent.whenValue

    const show = [].concat(whenValue).find((v) => v + '' === when + '') !== undefined

    if (obj.show !== show) {
      obj.show = show

      obj.el && obj.el.remove()
      obj.children = []

      if (show) {
        obj.el = obj.copy.cloneNode(true)
        obj.el.$obj = obj

        model.parse(obj.el)
        obj.comment.after(obj.el)
      }
    }

    return !show
  },
}

export default when
