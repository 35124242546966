const helper = {
  compareArrays(arr1, arr2) {
    if (!arr1 || !arr2) {
      return arr1 === arr2
    }

    const hasInvalid = arr1
      .map((v1) => {
        const index = arr2.indexOf(v1)
        if (index !== -1) {
          arr2 = arr2.filter((_, key) => key !== index)
          return false
        }

        return true
      })
      .find((invalid) => invalid)

    return !hasInvalid && !arr2.length
  },

  isEqual(a1, a2) {
    if (typeof a1 !== typeof a2) {
      return false
    }

    if (Array.isArray(a1)) {
      return this.compareArrays(a1, a2)
    }

    if (a1 && typeof a1 === 'object') {
      return !Object.entries(a1).find(([ name, val ]) => !this.isEqual(val, a2 && a2[name]))
    }

    return a1 === a2
  },
}

export default helper
