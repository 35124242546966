import React, { useState } from 'react'
import { observer } from 'mobx-react'

import _d from 'dictionary'

import ConfirmButton from 'common/ConfirmButton/ConfirmButton'
import SignIcon from 'common/Icons/Sign'
import YumiDocsIcon from 'common/Icons/Logo'

import SignatureStyles from '../../Signature.style'
import SignPending from './Sign.pending'

import authApi from 'api/auth'
import negotiationsApi from 'api/negotiations'

const SignOnline = ({ item, data = {} }) => {
  const { user } = authApi
  const { positionLabel, behalfLabel, behalf, fieldName } = item

  const [pending, setPending] = useState()

  const [style, setStyle] = useState('Unicode')

  const displayName = user.displayName && user.displayName.trim()

  const behalfTitle = [positionLabel, behalfLabel || behalf].filter(Boolean).join(' as ')

  if (pending)
    return <SignPending {...item} style={style} displayName={displayName} tryAgain={() => setPending(null)} />

  return (
    <article>
      <h4>{_d('Choose signature style')}</h4>
      <SignatureStyles displayName={displayName} value={style} onChange={(item) => setStyle(item)} />

      <div className="my-signature__actions">
        <div className="--label">
          Sign with <YumiDocsIcon /> E-signature {behalf ? _d(' on behalf ') + (behalfLabel || behalf) : ''}
        </div>

        <ConfirmButton
          label={_d('Sign')}
          confirm={_d(`Sign behalf|${behalfTitle}`)}
          icon={<SignIcon />}
          data-id="sign-for-party"
          disabled={!style || !displayName}
          onClick={() => {
            setPending(true)
            negotiationsApi.sign(data._key, { style, displayName, signBehalf: behalf, fieldName })
          }}
        />
      </div>
    </article>
  )
}

export default observer(SignOnline)
