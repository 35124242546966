export default class AffixModel {
  constructor(meta) {
    const { value, cpValue, config } = meta
    const { prefix, suffix, spacer } = config

    this.originalValue = value
    this._value = value || ''
    this._cpValue = cpValue || ''
    this.meta = meta

    this.prefix = this.normalize('prefix', prefix, spacer)
    this.parse('prefix')
    this.suffix = this.normalize('suffix', suffix, spacer)
    this.parse('suffix')
  }

  normalize = (type, list, spacer = ' ') => {
    if (Array.isArray(list) || typeof list === 'string') {
      list = {
        values: [].concat(list),
        spacer,
        start: '',
        end: '',
      }
    }

    if (typeof list === 'object') {
      let { start = '', end = '', cut = 'pop' } = list

      if (type === 'prefix') {
        end += spacer
        cut = 'shift'
      } else {
        start = spacer + start
      }

      list = { ...list, cut, start, end }
      return list
    }
  }

  parse = (type = 'prefix') => {
    const affix = this[type]
    if (affix) {
      const { values, cut, start, end } = affix

      const fullText = (text) => start + text + end
      const isStatic = values.length === 1
      const selected = isStatic
        ? values[0]
        : values.find((text) => this._value && this._value.split(fullText(text))[cut]() === '')
      const cpSelected = values.find((text) => this._cpValue && this._cpValue.split(fullText(text))[cut]() === '')
      const matched = selected && selected === cpSelected

      let value
      let cpValue

      if (selected) {
        value = fullText(selected)
        const valArr = this._value.split(value)
        valArr[cut]()
        this._value = valArr.join(value)
      }

      if (cpSelected) {
        cpValue = fullText(cpSelected)
        const valArr = this._cpValue.split(cpValue)
        valArr[cut]()
        this._cpValue = valArr.join(cpValue)
      }

      this[type] = { ...affix, cut, fullText, isStatic, selected, cpSelected, matched, value, cpValue }
    }
  }

  getAffixValue = (affix) => {
    if (affix) {
      const { isStatic, value } = affix
      if (isStatic) {
        return this._value ? value : undefined
      }
      return value
    }
  }

  get value() {
    return [ this.getAffixValue(this.prefix), this._value, this.getAffixValue(this.suffix) ].filter((v) => v).join('')
  }

  get viewValue() {
    return this._value
  }

  get cpViewValue() {
    return this._cpValue
  }

  get prefixValue() {
    return this.prefix && this.prefix.selected
  }

  get suffixValue() {
    return this.suffix && this.suffix.selected
  }

  get viewMatched() {
    return this.viewValue && this.viewValue === this.cpViewValue
  }

  setValue = (value) => {
    this._value = value
    return this.value
  }

  setPrefix = (value) => {
    const { values, fullText } = this.prefix
    const selected = values.find((v) => v === value)
    this.prefix.selected = selected
    this.prefix.value = fullText(selected)
    return this.value
  }

  setSuffix = (value) => {
    const { values, fullText } = this.suffix
    const selected = values.find((v) => v === value)
    this.suffix.selected = selected
    this.suffix.value = fullText(selected)
    return this.value
  }
}
