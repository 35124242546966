import cn from 'classnames'

import './ProgressCircle.scss'

const ProgressCircle = ({ total, done, label, className }) => {
  const r = 60
  const percents = Math.round(done / total * 100)

  return (
    <div className={cn('progress-circle', className)} style={{ '--percents': percents, '--r': r }}>
      <div className="progress-circle__chart">
        <svg viewBox="0 0 150 150" width="150" height="150">
          <circle className="bg" cx="75" cy="75" r={r} />
          <circle cx="75" cy="75" r={r} />
        </svg>
        <div className="progress-circle__chart__center">
          <h2>{percents}%</h2>
        </div>
      </div>
      <div className="progress-circle__label">
        {label}
        <div className="desc">
          {done}/{total}
        </div>
      </div>
    </div>
  )
}

export default ProgressCircle
