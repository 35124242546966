import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'

import Form from 'common/Form/Form'
import Avatar from 'common/UserCard/Avatar'
import AddUserIcon from 'common/Icons/AddUser'
import DownIcon from 'common/Icons/Down'

import DropDown from 'common/DropDown/DropDown'

import negotiationsApi from 'api/negotiations'

const PartyAdd = (props) => {
  const [ add, setAdd ] = useState()

  const { position, negotiation, index } = props
  const { label, description, party = {} } = position
  const { owner } = party
  const { singleParty } = negotiation

  const addForm = [
    {
      label: 'Counterparty E-mail',
      name: 'email',
      type: 'text',
      autoFocus: true,
      validate: {
        required: true,
        type: 'email',
      },
    },
    {
      type: 'submit',
      onClose: () => setAdd(false),
      onSubmit: async ({ email }) => {
        const isDraft = !negotiation._key
        const res = await negotiationsApi.addCP(negotiation, { email })
        if (isDraft) {
          props.history.replace(`/negotiations/${res._key}`)
        }
      },
    },
  ]
  return (
    <div className={cn('party-add', { right: index })}>
      <header className='positions__header'>
        <div className='positions__label'>
          <div className='party-add__counterparty'>
            {owner ? (
              <div>
                <DropDown
                  options={[
                    !singleParty && owner && { label: 'Edit', onClick: () => setAdd(true) },
                    !singleParty &&
                    owner && {
                      label: 'Remove',
                      className: 'error',
                      onClick: () => negotiationsApi.removeCP(negotiation),
                    },
                  ]}
                >
                  <h3>{label}</h3>
                  <DownIcon />
                </DropDown>
              </div>
            ) : (
              <h3>{label}</h3>
            )}
          </div>
          <div className='desc'>{description}</div>
        </div>
        {!singleParty && owner && (
          <div className='positions__pos'>
            <label className='cp'>Counterparty</label>
          </div>
        )}
      </header>
      <article>
        {singleParty ? (
          <div className='positions__single'>Single party</div>
        ) : owner && !add ? (
          <div className='positions__user'>
            <div className='positions__user__card' onClick={() => setAdd(true)}>
              <Avatar className='dark' user={owner} />
              <div className='--title'>
                <div>{owner.displayName}</div>
                <div className='cp-email'>{party.email}</div>
              </div>
            </div>
          </div>
        ) : (
          <div className='party-add__content'>
            {add ? (
              <div className='party-add__form'>
                <Form config={addForm} values={party} compare={party} className='dark' />
              </div>
            ) : (
              <div className='party-add__empty'>
                <button
                  className='btn success'
                  onClick={() => setAdd(true)}
                  data-id='infoBox:addCounterparty'
                  title='Add counterparty'
                >
                  Add counterparty <AddUserIcon />
                </button>
              </div>
            )}
          </div>
        )}
      </article>
    </div>
  )
}

export default observer(withRouter(PartyAdd))
