import Settings from './Settings'
import Company from './Company/Company'
import Profile from './Profile/Profile'

import UserIcon from 'common/Icons/User2'
import CompanyIcon from 'common/Icons/Company'

const routes = {
  path: '/settings',
  roles: [ 'user', 'admin' ],
  Wrapper: Settings,
  children: [
    {
      path: '/settings/company',
      label: 'Company Info',
      showWhen: (user) => user.company,
      Component: Company,
      Icon: CompanyIcon,
    },
    {
      path: '/settings',
      label: 'Profile',
      title: 'User Profile',
      Component: Profile,
      Icon: UserIcon,
    },
  ],
}

export default routes
