import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Label from '../Label'
import Compare from '../Compare'
import ErrorMessage from '../ErrorMessage'

class TextArea extends React.Component {
  onChange = (e) => {
    const { meta } = this.props
    meta.setValue(e.target.value || undefined)
  }

  render() {
    const { meta = {}, readOnly } = this.props
    const {
      name,
      value,
      cpValue,
      disabled,
      opt = { myPosition: true },
      isHidden,
      isRestricted,
      viewOnly,
      notEditable,
      onBlur,
      validation = {},
    } = meta

    const { isInvalid, isDirty, isMatched, isCompleted, isChanged } = validation

    return (
      <div
        input-name={name}
        className={cn({
          isHidden,
          isRestricted,
          isMatched,
          isUnmatched: !isMatched,
          isIncompleted: !isCompleted,
          isCompleted,
          isChanged,
          isInvalid,
          viewOnly,
          notEditable,
        })}
      >
        <div className={cn('form-item', { readOnly, isChanged, cpOnly: !opt.myPosition })}>
          <div className="form-item__content">
            <Label {...this.props} />
            {opt.myPosition && (
              <div className="form-input">
                {viewOnly ? (
                  <pre className={cn('form-input__view-only')}>{value}</pre>
                ) : (
                  <textarea
                    data-id={'textarea:' + name}
                    className={cn({
                      matched: isMatched,
                      cpValue,
                      value,
                      error: isInvalid && isDirty,
                      dirty: isDirty,
                      invalid: isInvalid,
                      blur: onBlur,
                    })}
                    onBlur={() => {
                      meta.onBlur = isInvalid
                      meta.onFocus = false
                    }}
                    rows="5"
                    value={value || ''}
                    onChange={this.onChange}
                    disabled={disabled}
                  />
                )}
                <ErrorMessage meta={meta} />
              </div>
            )}
          </div>
          <Compare meta={meta} readOnly={readOnly || !opt.myPosition} />
        </div>
      </div>
    )
  }
}

export default observer(TextArea)
