import React from 'react'
import { observer } from 'mobx-react'

import Avatar from 'common/UserCard/Avatar'
import Table from 'common/Table/Table'

import TickIcon from 'common/Icons/Tick'

import negotiationsApi from 'api/negotiations'

import '../Parties.scss'

class MyParty extends React.Component {
  tableConfig = {
    columns: [
      {
        width: 50,
        content: (item) => <Avatar user={item} />,
      },
      {
        header: 'Name',
        content: ({ displayName, email }) => (
          <div>
            <div>{displayName}</div>
            <div className="desc">{email}</div>
          </div>
        ),
      },
      {
        header: 'Sign',
        content: ({ perm }) => <div className="desc">{perm.allowSignMain && <TickIcon />}</div>,
      },
      {
        header: 'Perm',
        width: 50,
        content: ({ perm }) => <div className="desc">{perm.permissions}</div>,
      },
    ],
  }

  get negotiation() {
    return negotiationsApi.current
  }

  render() {
    window.MyParty = this
    const { myParty } = this.negotiation

    return (
      <div className="parties">
        <div className="parties__list">
          <h3>My Party</h3>
          <div className="parties__content">
            <Table config={this.tableConfig} list={myParty.alias} className="small darker" />
          </div>
        </div>
      </div>
    )
  }
}

export default observer(MyParty)
