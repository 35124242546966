import { observer } from 'mobx-react'
import cn from 'classnames'

import Form from 'common/Form/Form'

import SettingsIcon from 'common/Icons/Settings'

const TagSettings = ({ model, editorView, settings }) => {
  const { node, isAtBottom } = settings
  const { attrs = {} } = node || {}
  const item = model.items[attrs.drText]

  const { selection, schema, tr } = editorView.props.state
  const { content = [] } = selection.content().content
  const { textContent } = content[0] || {}

  const data = {
    textContent,
    drFormat: attrs.drFormat,
  }

  let formatter = [
    { value: 'ordinal', label: 'Ordinal numbers' },
    { value: 'number', label: 'Number 1,000.25' },
    { value: 'numberDE', label: 'Number 1.000,25' },
    { value: 'toWords', label: 'Numbers to words' },
    { value: 'multiline', label: 'Multiline text' },
  ]

  if (item && item.type === 'datePicker') {
    formatter = [
      { value: 'date', label: 'Date' },
      { value: 'date|MMMM dd, yyyy', label: 'Date: Month dd, yyyy' },
      { value: 'date|dd MMMM yyyy', label: 'Date: dd Month yyyy' },
      { value: 'date|dd.MM.yyyy', label: 'Date: dd.MM.yyyy' },
      { value: 'date|dd-MM-yyyy', label: 'Date: dd-MM-yyyy' },
      { value: 'date|MM/dd/yyyy', label: 'US Date: MM/dd/yyyy' },
    ]
  }

  const config = [
    {
      type: 'text',
      name: 'textContent',
      label: 'Default text',
      validate: {
        required: true,
      },
    },
    {
      type: 'select',
      name: 'drFormat',
      label: 'Formatter',
      options: [ { label: '-- None --' }, ...formatter ],
    },

    {
      type: 'actions',
      options: [
        {
          label: 'Save',
          type: 'submit',
          className: 'small',
        },
      ],
    },
  ]

  const onSubmit = ({ values }) => {
    const { textContent, drFormat } = values
    const json = {
      type: 'dr_inline',
      attrs: { ...attrs, drFormat },
      content: [
        {
          type: 'text',
          text: textContent,
        },
      ],
    }

    editorView.focus()

    const node = schema.nodeFromJSON(json)
    const transaction = tr.replaceSelectionWith(node)

    editorView.dispatch(transaction)
  }

  return (
    <li className={cn('inline-menu__item nested', { active: settings.openSettings })}>
      <div
        className="nested__label"
        onClick={(e) => {
          e.preventDefault()
          editorView.focus()
          settings.openSettings = !settings.openSettings
          settings.openTags = false
        }}
      >
        <SettingsIcon /> {attrs.drFormat}
      </div>
      {settings.openSettings && (
        <div className={cn('nested__dropdown', 'nested__form', 'nested__content', { isAtBottom })}>
          <h4>{item && item.label}</h4>
          <Form config={config} values={data} onSubmit={onSubmit} />
        </div>
      )}
    </li>
  )
}

export default observer(TagSettings)
