import React from 'react'
import { observer } from 'mobx-react'

import UserIcon from 'common/Icons/User2'
import HelpIcon from 'common/Icons/Help'
import EditSingleIcon from 'common/Icons/EditSingle'
import DevIcon from 'common/Icons/Dev'
import LogoutIcon from 'common/Icons/Logout'
import AccountSwitchIcon from 'common/Icons/AccountSwitchOutline'
import InfoIcon from 'common/Icons/Info'
// import EmailIcon from 'common/Icons/Email'
import WaitingIcon from 'common/Icons/Waiting'
import ShieldIcon from 'common/Icons/ShieldAccount'
import CompanyIcon from 'common/Icons/Company'

import DropDown from 'common/DropDown/DropDown'
import Avatar from 'common/UserCard/Avatar'

import SwitchUser from 'screens/Settings/SwitchFakeUsers/SwitchFakeUsers'

import auth from 'api/auth'
import app from 'api/app'

import betaInfo from 'data/betaInfo.json'

import './UserProfile.scss'

class UserProfile extends React.Component {
  getOptions = () => {
    const { isAdmin, isApiUser } = auth
    const { company, group } = auth.user
    return [
      group && [
        {
          label: 'Switch account',
          icon: <AccountSwitchIcon />,
          onClick: () => {
            app.modal = {
              header: 'Switch account',
              icon: <AccountSwitchIcon />,
              content: () => <SwitchUser />,
            }
          },
        },
      ],
      company && [
        {
          link: '/settings/company',
          Content: () => {
            const { user } = auth
            const { company } = user
            const nameLetters = company.displayName.split(' ').map((item) => item[0])

            return (
              <div className="user-profile__info">
                <div>
                  {company.logoURL ? (
                    <img className="company-logo" alt="User" src={company.logoURL} />
                  ) : (
                    <div className="letters">{nameLetters}</div>
                  )}
                </div>
                <ul>
                  <li>{company.displayName}</li>
                </ul>
              </div>
            )
          },
        },
      ],
      [
        {
          link: '/settings',
          Content: () => {
            const { user } = auth

            if (!user || !user.email) {
              return null
            }

            let { photoURL, displayName, email, role, status } = user
            displayName = displayName && displayName.trim()
            const nameLetters = displayName ? displayName.split(' ').map((item) => item[0] + '.') : <WaitingIcon />

            return (
              <div className="user-profile__info">
                <div className="user-profile__photo">
                  {photoURL ? (
                    <img className="medium" alt="User" src={photoURL} />
                  ) : (
                    <div className="letters">{nameLetters}</div>
                  )}
                </div>
                <ul>
                  <li>{displayName}</li>
                  <li className="user-profile__email">{email}</li>
                  <li className="user-profile__desc">
                    <label>Status:</label> {status}
                  </li>
                  <li className="user-profile__desc">
                    <label>Role:</label> {[].concat(role).join(', ')}
                  </li>
                </ul>
              </div>
            )
          },
        },
      ],
      [
        company && {
          label: 'Company',
          link: '/settings/company',
          icon: <CompanyIcon />,
        },
        {
          label: 'Profile',
          link: '/settings',
          icon: <UserIcon />,
        },
        isAdmin && {
          label: 'Template Editor',
          link: '/editor/templates',
          icon: <EditSingleIcon />,
        },
        isAdmin && {
          link: '/admin/users',
          icon: <ShieldIcon />,
          label: <div className="beta-testing">Admin</div>,
        },
        isApiUser && {
          link: '/sign-pdf',
          icon: <EditSingleIcon />,
          label: <div className="beta-testing">Sign PDF</div>,
        },
        // {
        //   label: 'Inbox',
        //   link: '/inbox',
        //   icon: <EmailIcon />,
        // },
        {
          label: 'Contact Support',
          link: 'mailto:support@yumidocs.com',
          target: '_blank',
          icon: <HelpIcon />,
        },
        app.isDev && {
          label: 'Development',
          link: '/dev',
          icon: <DevIcon />,
        },
        app.showEnvironmentLabel && {
          onClick: () => (app.modal = betaInfo),
          icon: <InfoIcon />,
          label: <div className="beta-testing">{app.environmentLabel || 'PREVIEW'} VERSION</div>,
        },
      ],
      [
        {
          label: 'Logout',
          onClick: () => {
            auth.logout()
          },
          icon: <LogoutIcon />,
        },
      ],
    ]
  }

  render() {
    const { user } = auth

    if (!user || !user.email) {
      return null
    }

    const { displayName, email } = user

    const options = this.getOptions()
    return (
      <div className="user-profile">
        <DropDown options={options} className="multi">
          <div className="user-profile__preview">
            <div className="user-profile__photo">
              <Avatar user={user} className="dark" />
            </div>
            <ul>
              <li>{displayName}</li>
              <li className="user-profile__email">{email}</li>
            </ul>
          </div>
        </DropDown>
      </div>
    )
  }
}

export default observer(UserProfile)
