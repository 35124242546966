import React from 'react'
import cn from 'classnames'

import './Progress.line.scss'

class ProgressLine extends React.Component {
  render() {
    const { done, completed, label, total, className, error } = this.props

    let percents = done === true ? 100 : done
    let completedPercents = completed || 0

    const doneLabel = done === undefined ? completed : done

    if (total) {
      percents = Math.round(done / total * 100)
      completedPercents = Math.round((completed || 0) / total * 100) || 0
    }

    return (
      <div className={cn('progress-line', className, { error, active: done === false })}>
        <div className={cn('progress-line__step', { 'multi-level': completed !== undefined || done === undefined })}>
          {!!completed && <div className="--completed" style={{ width: completedPercents + '%' }} />}
          <div className="--progress" style={{ width: percents + '%' }} />
        </div>
        <div className="progress-line__caption">
          {typeof done === 'number' && <div className="--percents">{(percents || completedPercents) + '%'}</div>}
          {!!total && (
            <div className="--label">
              ( {doneLabel || '0'} / {total} )
            </div>
          )}
          {label && <div className="--label">{label}</div>}
        </div>
      </div>
    )
  }
}

export default ProgressLine
