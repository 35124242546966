import Dragon from '../index'

const amend = {
  name: 'amend',

  onInit(el, model) {
    const obj = el && el.$obj

    obj.id = obj.attributes.amend

    obj.getResult = (answers) => {
      const tpl = obj.copy.innerHTML
      const template = new Dragon(tpl, answers, { once: true })

      return template.html.innerHTML
    }

    model.amend[obj.id] = obj
  },

  render(el, model) {
    const obj = el && el.$obj

    const value = model.getValue(obj, 'amend', '__amend')

    if (obj.value !== value) {
      obj.value = value

      if (value) {
        obj.el.innerHTML = value
        return true
      } else {
        obj.el.remove()
        obj.el = obj.copy.cloneNode(true)
        obj.el.$obj = obj
        obj.children = []

        model.parse(obj.el)
        obj.comment.after(obj.el)
      }
    }
    return false
  },
}

export default amend
