import get from 'lodash.get'
import { timeDistance, formatDate } from './date'

const parse = (conf, answers) => {
  // const conf = {
  //   label: 'Expiration date',
  //   name: 'expirationDate',
  //   values: [
  //     {
  //       name: 'dates.effectiveDate',
  //       type: 'date',
  //     },
  //     {
  //       name: 'termination.duration',
  //       type: 'duration',
  //       format: 'value unit',
  //     },
  //   ],
  //   action: 'addDate',
  // }

  const { values, value, action, resultFormat } = conf
  let result

  if (values) {
    const list = getValues(conf, answers).map((item) => item.result)
    result = actions[action](list)
  } else {
    result = get(answers, value)
  }

  conf.result = resultFormat === 'date' && result ? new Date(result) : getViewValue(result, conf)

  conf.viewValue = getViewValue(result, conf)
  return conf.viewValue
}

const getViewValue = (result, conf) => {
  const { view } = conf
  return views[view] ? views[view](result, conf) : result && result + ''
}

const views = {
  timeDistance: (result) => timeDistance(result),
  label: (result, { options }) => ((options && options.find((item) => item.value === result)) || {}).label,
  date: (result) => formatDate(result),
}

const getValues = ({ values }, answers) => {
  values.forEach((item) => {
    item.value = get(answers, item.name)
    item.result = convert[item.type](item.value, item)
  })

  return values
}

const actions = {
  addDate: (values) => {
    const [ start, duration ] = values
    return (
      start &&
      duration &&
      new Date(
        start.year + duration.year,
        start.month + duration.month,
        start.day + duration.day,
        start.hour + duration.hour,
        start.minutes + duration.minutes,
        start.seconds + duration.seconds
      )
    )
  },
}

const convert = {
  date: (value) => {
    const date = value && new Date(value)
    if (date && date.toString() !== 'Invalid Date') {
      const [ month, day, year ] = [ date.getMonth(), date.getDate(), date.getFullYear() ]
      const [ hour, minutes, seconds ] = [ date.getHours(), date.getMinutes(), date.getSeconds() ]

      return { month, day, year, hour, minutes, seconds, date }
    }
  },

  duration: (str, item) => {
    const { format } = item
    const units = format.split(' ')

    const res =
      (str &&
        str.split(' ').map((item, key) => ({ value: item, unit: units[key] })).reduce((acc, item) => {
          acc[item.unit] = item.value
          return acc
        }, {})) ||
      {}

    const { value, unit } = res
    res.nr = parseInt(value)

    const diff = { year: 0, month: 0, day: 0, hour: 0, minutes: 0, seconds: 0 }

    const getYears = (v) => {
      diff.year += v
    }
    const getMonths = (v) => {
      diff.month += v
    }
    const getWeeks = (v) => {
      diff.day += v * 7
    }
    const getDays = (v) => {
      diff.day += v
    }

    const mapUnits = {
      years: getYears,
      months: getMonths,
      weeks: getWeeks,
      days: getDays,

      metu: getYears,
      menesiu: getMonths,
      savaiciu: getWeeks,
      dienu: getDays,

      ani: getYears,
      luni : getMonths,
      saptamani: getWeeks,
      săptămâni: getWeeks,
      zile: getDays,

      jahre: getYears,
      Jahre: getYears,
      monate: getMonths,
      Monate: getMonths,
      wohen: getWeeks,
      Wohen: getWeeks,
      tage: getDays,
      Tage: getDays
    }

    item.res = res

    if (!isNaN(res.nr) && res.nr.toString() === value && unit) {
      mapUnits[unit](res.nr)
      return diff
    }
  },
}

export default parse
