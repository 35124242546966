import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import MoreIcon from 'common/Icons/More'
import DropDown from 'common/DropDown/DropDown'
import LockIcon from 'common/Icons/Lock'
import ViewIcon from 'common/Icons/Preview2'
import EditIcon from 'common/Icons/Edit2'

import DoneIcon from 'common/Icons/Tick'
import Done2Icon from 'common/Icons/Done2'
import RequiredIcon from 'common/Icons/Required'
import NotEqualIcon from 'common/Icons/NotEqual'

import './Label.scss'

class Label extends React.Component {
  count = 0

  scroll = () => {
    const { meta: { id } } = this.props
    const el = document.querySelectorAll(`.document-preview [dr-value="${id}"], .document-preview [dr-text="${id}"]`)

    const current = el[this.count % el.length]

    if (current) {
      const { top } = current.getBoundingClientRect()
      const preview = document.querySelector('.document-preview')

      const fromTop = preview.scrollTop + top - 200
      preview.scrollTop = fromTop
      this.count++
    }
  }

  onSetRestriction = async (value) => {
    const { meta } = this.props
    const { model = {}, name } = meta || {}

    let { form } = model
    form = form || {}
    form[name] = value

    model.setForm(form)
    if (meta.info) {
      meta.info.updateUp(true)
    }
  }

  render() {
    const { meta = {}, readOnly } = this.props
    const {
      config = {},
      value,
      cpValue,
      orgValue,
      label,
      description,
      validation = {},
      isHidden,
      notEditable,
      viewOnly,
      model,
      cpOnly,
    } = meta
    const { validate = {} } = config
    const { required } = validate
    const { isValid, isChanged, isCompleted, isMatched } = validation

    const { isOwner, options: { simple } = {}, state } = model
    const isDraft = state === 'draft'

    const isViewOnly = notEditable && !isHidden
    const isUpgradable = isDraft || isMatched

    const canHide = isOwner && model.isAllowed('canHide')

    if (!label) {
      return null
    }

    let Icon = RequiredIcon
    let title = 'Required'

    if (isCompleted) {
      Icon = cpValue ? NotEqualIcon : DoneIcon
      title = cpValue ? 'Do not match with Counterparty!' : 'Completed'
    }

    if (isMatched) {
      Icon = Done2Icon
      title = 'Matched'
    }

    return [
      <div
        className="form-label"
        onDoubleClick={() => console.log(label, meta, { values: model.values })}
        onClick={this.scroll}
        tabIndex="-1"
        key="label"
      >
        {state &&
        !cpOnly && (
          <div title={title} className={cn('form-label__state', { isCompleted, isMatched })}>
            <Icon />
          </div>
        )}
        <label>
          {label} {/* <small>
            {total} - {totalCompleted}
          </small> */}
          {!state && required && <span className={cn('required', { isValid })}> *</span>}
          {/* <small className={isVisible ? 'required' : 'desc'}>{isVisible + '!'}</small> */}
        </label>
        {!simple && (
          <div className="form-actions">
            {!readOnly &&
            !cpOnly &&
            (!viewOnly || canHide) && (
              <div className="--more">
                <DropDown
                  className="small"
                  onChange={({ value }) => this.onSetRestriction(value)}
                  value={viewOnly}
                  options={[
                    !viewOnly && {
                      label: 'Clear values',
                      disabled: value === undefined,
                      onClick: () => meta.setValue(),
                    },
                    !viewOnly && {
                      label: 'Restore last changes',
                      disabled: !isChanged,
                      onClick: () => meta.setValue(orgValue),
                    },
                    canHide && [
                      {
                        label: 'Editable',
                        icon: <EditIcon />,
                        value: null,
                        selected: !notEditable,
                      },
                      {
                        label: 'View only',
                        icon: <ViewIcon />,
                        value: false,
                        selected: isViewOnly,
                        disabled: !isUpgradable,
                      },
                      {
                        label: 'Hidden',
                        icon: <LockIcon />,
                        value: true,
                        selected: isHidden,
                        disabled: !isUpgradable,
                      },
                    ],
                  ]}
                >
                  <MoreIcon />
                </DropDown>
              </div>
            )}

            {notEditable && (
              <div className="notEditable__icon">
                {isHidden ? (
                  <i title="Hidden">
                    <LockIcon />
                  </i>
                ) : (
                  <i title="View only">
                    <ViewIcon />
                  </i>
                )}
              </div>
            )}
          </div>
        )}
      </div>,
      description && (
        <div key="description" className="form-item__desc">
          {description}
        </div>
      ),
    ]
  }
}

export default observer(Label)
