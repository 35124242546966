import React from 'react'

import Table from 'common/Table/Table'

import documentsApi from 'api/documents'

import './UserDocuments.scss'

class UserDocuments extends React.Component {
  uploadTable = {
    getData: documentsApi.adminListDocuments,
    columns: [
      {
        header: 'Filename',
        name: 'name',
        desc: 'contentType',
      },
      {
        header: 'Size',
        name: 'size',
      },
    ],
  }

  render() {
    return (
      <div className="document-list">
        <h1>User Documents</h1>
        <section>
          <article>
            <Table config={this.uploadTable} className="dark" />
          </article>
        </section>
      </div>
    )
  }
}

export default UserDocuments
