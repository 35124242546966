import { useState } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import DownIcon from 'common/Icons/MenuDown'

import './TopMenu.scss'

const TopMenu = (props) => {
  const groups = props.items

  return groups.map((items, key) => <GroupItems key={key} {...props} items={items} />)
}

const GroupItems = observer((props) => {
  const { items } = props

  return (
    <ul className="top-menu__group">
      {items.map((menuItem, k) => {
        const { title, icon, run, isActive, isHidden, isDisabled, items } = menuItem

        return items ? (
          <DropDownItems key={k} {...props} {...menuItem} />
        ) : (
          <li className={cn('top-menu__item', { isActive, isHidden, isDisabled })} key={k}>
            <button title={title} onClick={run}>
              {icon}
            </button>
          </li>
        )
      })}
    </ul>
  )
})

const labels = {
  paragraph: () => 'Paragraph',
  list_item: () => 'List item',
  ordered_list: () => 'Ordered list',
  bullet_list: () => 'Bullet list',
  dr_inline: () => 'Tag',
  heading: ({ attrs }) => 'Heading ' + attrs.level,
}

const getLabel = ({ name, node }) => {
  if (node) {
    return labels[name] ? labels[name](node) : 'N/A'
  }

  return 'Type...'
}

const DropDownItems = observer(({ items, activeNode, options }) => {
  const [ open, setOpen ] = useState()
  const label = (options && options.label) || getLabel(activeNode)

  return (
    <div className="top-menu__select">
      <div className="top-menu__select__label" onClick={() => setOpen(!open)}>
        <label>{label} </label>
        <DownIcon />
      </div>
      {open && (
        <div className="top-menu__select__options">
          <div className="top-menu__select__bg" onClick={() => setOpen(false)} />
          <ul className="top-menu__group">
            {items.map(({ title, label, className, run, isActive, isHidden, isDisabled }, k) => (
              <li className={cn('top-menu__item', className, { isActive, isHidden, isDisabled })} key={k}>
                <button
                  title={title}
                  disabled={isDisabled}
                  onClick={(e) => {
                    setOpen(false)
                    run(e)
                  }}
                >
                  {label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
})

export default TopMenu
