// Helper for creating a schema that supports tables.

function getCellAttrs(dom) {
  let widthAttr = dom.getAttribute('data-colwidth')
  let widths = widthAttr && /^\d+(,\d+)*$/.test(widthAttr) ? widthAttr.split(',').map((s) => Number(s)) : null
  let colspan = Number(dom.getAttribute('colspan') || 1)
  let result = {
    colspan,
    rowspan: Number(dom.getAttribute('rowspan') || 1),
    colwidth: widths && widths.length == colspan ? widths : null,
  }

  return result
}

function setCellAttrs(node) {
  let attrs = {}
  if (node.attrs.colspan != 1) attrs.colspan = node.attrs.colspan
  if (node.attrs.rowspan != 1) attrs.rowspan = node.attrs.rowspan
  if (node.attrs.colwidth) attrs['data-colwidth'] = node.attrs.colwidth.join(',')

  return attrs
}

//---------------------------------------------------------------------------------------------------------
//---------------------------------------------------------------------------------------------------------
export default function tableNodes(editor) {
  const cellAttrs = {
    colspan: { default: 1 },
    rowspan: { default: 1 },
    colwidth: { default: null },
    width: { default: null },
  }

  return {
    //---------------------------------------------------------------------------------------------------------
    table: {
      content: '(table_body | col_group)+',
      tableRole: 'table',
      isolating: true,
      attrs: {
        width: { default: null },
        className: { default: null },
        dataBorder: { default: null },
      },
      group: 'block',
      parseDOM: [
        {
          tag: 'table',
          getAttrs: (dom) => ({
            width: dom.getAttribute('width'),
            className: dom.getAttribute('class'),
            dataBorder: dom.getAttribute('data-border'),
          }),
        },
      ],
      toDOM(node) {
        const { width, className, dataBorder } = node.attrs
        const view = [ 'table', { width, class: className, 'data-border': dataBorder }, 0 ]
        return editor ? [ 'pm-block', [ 'pm-btn', { onclick: 'pmContextMenu(this)' } ], view ] : view
      },
    },

    //---------------------------------------------------------------------------------------------------------
    table_body: {
      label: 'Table body',
      content: 'table_row+',
      isolating: true,
      parseDOM: [ { tag: 'tbody' } ],
      toDOM() {
        return [ 'tbody', 0 ]
      },
    },

    //---------------------------------------------------------------------------------------------------------
    col_group: {
      content: 'col*',
      parseDOM: [ { tag: 'colgroup' } ],
      toDOM() {
        return [ 'colgroup', 0 ]
      },
    },

    //---------------------------------------------------------------------------------------------------------
    col: {
      attrs: { style: { default: null } },
      parseDOM: [
        {
          tag: 'col',
          getAttrs: (dom) => ({ style: dom.getAttribute('style') }),
        },
      ],
      toDOM({ attrs }) {
        return [ 'col', { style: attrs.style } ]
      },
    },

    //---------------------------------------------------------------------------------------------------------
    table_row: {
      label: 'Table row',
      attrs: { drEach: { default: null } },
      content: '(table_cell | table_header)*',
      tableRole: 'row',
      parseDOM: [ { tag: 'tr', getAttrs: (dom) => ({ drEach: dom.getAttribute('dr-each') }) } ],
      toDOM({ attrs }) {
        return [ 'tr', { 'dr-each': attrs.drEach }, 0 ]
      },
    },

    //---------------------------------------------------------------------------------------------------------
    table_cell: {
      label: 'Table cell',
      content: 'block*',
      attrs: cellAttrs,
      tableRole: 'cell',
      isolating: true,
      parseDOM: [ { tag: 'td', getAttrs: (dom) => getCellAttrs(dom) } ],
      toDOM(node) {
        return [ 'td', setCellAttrs(node), 0 ]
      },
    },

    //---------------------------------------------------------------------------------------------------------
    table_header: {
      label: 'Table header',
      content: 'block*',
      attrs: cellAttrs,
      tableRole: 'header_cell',
      isolating: true,
      parseDOM: [ { tag: 'th', getAttrs: (dom) => getCellAttrs(dom) } ],
      toDOM(node) {
        return [ 'th', setCellAttrs(node), 0 ]
      },
    },
  }
}

// const nodes = tableNodes()

// export { nodes as default, nodes }
