import { useState } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Avatar from 'common/UserCard/Avatar'

import PlusIcon from 'common/Icons/Plus'
import ArrowIcon from 'common/Icons/Up'
import TickIcon from 'common/Icons/Tick'

import _d from 'dictionary'
import negotiationsApi from 'api/negotiations'

import './AssignedUsers.scss'

const AssignedUsers = ({ item, readOnly }) => {
  const [ edit, setEdit ] = useState()
  const [ invite, setInvite ] = useState()
  const [ email, setEmail ] = useState('')

  const { myParty, me, _key } = negotiationsApi.current
  const { users } = myParty

  const { behalf } = item
  const canAssign = (me.permissions === 'admin' || (me.actions && me.actions.includes('canAssignUsers'))) && !readOnly

  const isAssigned = ({ perm }) =>
    behalf
      ? [].concat(perm.allowSignBehalf).includes(behalf)
      : perm.permissions === 'admin' || (perm.actions && perm.actions.includes('canSign'))

  const assignedUsers = users.filter((user) => {
    const assigned = isAssigned(user)
    user.assigned = assigned
    return assigned
  })

  const assign = (user) => {
    setEdit(false)
    const data = {
      _key: user._key,
      email: user.email,
      allow: !user.assigned,
      behalf,
    }
    return negotiationsApi.allowSign(_key, data)
  }

  const notAssigned = !assignedUsers.length

  if (notAssigned && !canAssign) {
    return (
      <div className="assigned-users__not-assigned">
        <label>{_d('signatures.noSignatoriesAssigned')}</label>
      </div>
    )
  }

  return (
    <div className="assigned-users">
      <ul className="assigned-users__preview">
        {assignedUsers.map((item) => (
          <li key={item._key}>
            <Avatar user={item} className="small" />
          </li>
        ))}

        {canAssign && (
          <li className={cn('--assign', { primary: notAssigned })}>
            {notAssigned && <span>Signatory required!</span>}
            <button onClick={() => setEdit(!edit)}>
              Assign signatories<ArrowIcon />
            </button>
          </li>
        )}
      </ul>

      {edit && (
        <div className="assigned-users__edit">
          <div
            className="assigned-users__edit__bg"
            onClick={() => {
              setInvite(false)
              setEdit(false)
            }}
          />
          <div className="assigned-users__edit__content">
            <h4>{_d('Other assigned signatories')}</h4>
            <ul>
              {users.map((item, key) => (
                <li key={key} className={cn('--user', { assigned: item.assigned })} onClick={() => assign(item)}>
                  <div className="--tick-icon">
                    <TickIcon />
                  </div>
                  <Avatar user={item} />
                  <div>
                    <div>{item.displayName}</div>
                    <div className="desc">{item.email}</div>
                  </div>
                </li>
              ))}

              {invite ? (
                <li className="--input">
                  <div>
                    <label>Email</label>
                  </div>
                  <input type="email" autoFocus value={email} onChange={({ target }) => setEmail(target.value)} />
                  <div className="--actions">
                    <button
                      className="btn cancel small"
                      onClick={() => {
                        setInvite(false)
                        setEmail('')
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn small"
                      onClick={() => {
                        setEmail('')
                        setInvite(false)
                        assign({ email })
                      }}
                    >
                      Invite
                    </button>
                  </div>
                </li>
              ) : (
                <li className="--invite" onClick={() => setInvite(true)}>
                  <PlusIcon /> Invite with Email
                </li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(AssignedUsers)
