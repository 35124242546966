const dic = {
  Home: 'Home',
  Documents: 'Documents',
  Negotiations: 'Negotiations',
  CompanyInfo: 'Company info',
  Profile: 'Profile',
  Help: 'Help',
  Login: 'Login',
  Logout: 'Logout',
  Users: 'Users',
  Version: 'Version',
  DocumentName: 'Document name',
  TemplateName: 'Template name',
  Category: 'Category',
  Presets: 'Presets',
  Counterparty: 'Counterparty',
  AddCounterparty: 'Add counterparty',
  MyParty: 'My Party',
  'My Party': 'My Party',
  StartNegotiation: 'Start new Negotiation',
  Elections: 'Elections',
  Preview: 'Preview',
  Updated: 'Updated',
  Visited: 'Visited',
  Created: 'Created',
  CreatedBy: 'Created by',
  Filename: 'Filename',
  'Created At': 'Created At',
  'Last update': 'Last update',
  'Last update by': 'Last update by',
  changes: (nr) => (nr === 1 ? 'change' : 'changes'),
  me: 'me',
  ago: 'ago',
  Send: 'Send',
  Agree: 'Agree',
  Private: 'Private',
  Language: 'Language',
  Messages: 'Messages',
  settings: 'settings',
  Settings: 'Settings',
  Templates: 'Templates',
  'How to use': 'How to use',
  homeScreen: {
    settingsDescription: 'Change your account preferences.',
    templatesDescription: 'Template collection for new contracts. Start new documents from here.',
    documentsDescription: 'Manage your documents, active negotiations and completed contracts.',
    messagesDescription: 'Manage the communication with your team.',
    howToUseDescription: 'Visual tutorial how to Start negotiation, Accept invitaion, Negotiate, use digital signature',
    docsDescription: 'Docs',
  },
  stateLabel: {
    draft: 'Draft',
    inviting: 'Inviting',
    active: 'Active',
    execution: 'Sign',
    completed: 'Completed',
    rejected: 'Rejected',
  },
  'My position': 'My position',
  'Counterparty position': 'Counterparty position',
  'Choose Preset': 'Choose Preset',
  'Are you sure ?': 'Are you sure ?',
  'Send invitation to CP?': 'Send invitation to CP?',
  'Delete this negotiation?': 'Delete this negotiation?',
  'E-mail is not valid!': 'E-mail is not valid!',
  'Negotiation permissions': 'Negotiation permissions',
  'Company permissions': 'Company permissions',
  'Last action': 'Last action',
  'Are you sure?': 'Are you sure?',
  'Document preview': 'Document preview',
  'Document name': 'Document name',
  //-------------------------------------------------
  'Minimum Characters': (nr) => `Minimum ${nr} characters!`,
  ca: {
    Description: 'Setup a new account for the email address you received this invitation.',
    'Individual desc': 'Creates an account just for yourself as an individual, to manage your personal documents.',
    'Company desc':
      "Enables collaboration and work between multiple people within a company. Best to organise your team and manage company's documents.",
  },
  //-------------------------------------------------
  'Signature disclaimer':
    "By signing, I confirm that I am the legal representative of the party on behalf of which I sign.\nI agree to use electronic signatures, records and to YumiDocs's Terms of Service.",
  'Sign as': 'Sign as',
  'Sign on behalf': 'Sign on behalf',
  'Sign behalf': (title) => `Sign on behalf of ${title}`,
  documents: {
    uploadTemplate: {
      headline: 'Upload your own template',
      descriptionL1:
        'Create your bespoke template from an existing document. Start by uploading a document in one of the compatible formats: DOC, DOCX or PDF format.',
      descriptionL2:
        'The new template is only going to be visible to your user and your company if you are part of one.',
    },
  },
  signatures: {
    signedBy: 'Signed by',
    signWithESignature: 'Sign with YumiDocs E-signature',
    onlineWithYumiDocs: 'Online signature',
    offlineQES: 'Qualified Signature',
    downloadSignUpload: 'Download execution PDF, sign and upload',
    signOffline: 'Offline signature with Qualified Digital Certificate',
    saveToDevice: 'Save the signed PDF file to your device.',
    uploadPdf: 'Upload PDF file',
    differentNegotiation: 'The document you uploaded is from a different negotiation!',
    tryAnotherDocument:
      'Try again by uploading a PDF file corresponding to the current negotiation signed for correct party.',
    notAllowedToSign: 'Not allowed to sign!',
    doNotMatch: 'Signature do not match!',
    signedForCounterparty: 'Incorrect signature. You signed on behalf of the counterparty!',
    signatureMissing: 'Signature already exists!',
    importSignatures: 'Import signatures',
    importSignatureConfirmation: 'I confirm and accept the signatures displayed above',
    numberOfMissingSignatures: (num) => `Found ${num} missing signatures.`,
    signaturesFound: (num) => `Found ${num} new signatures`,
    digitallySignedBy: 'Digitally signed by ',
    noSignatoriesAssigned: 'No signatories assigned',
    withSmartId: 'SmartID Signature',
    confirmButtonSignWithSmaryID: 'Sign with SmartID',
  },
  smartId: {
    NOT_FOUND: 'The user was not found. Check the SmartID details and try again.',
    WRONG_VC: 'The user selected an incorrect verification number!',
    USER_REFUSED_CONFIRMATIONMESSAGE_WITH_VC_CHOICE: 'The user refused to confirm!',
    USER_REFUSED_DISPLAYTEXTANDPIN: 'The user refused to key in the PIN!',
    USER_REFUSED: 'The registration was canceled!',
    VERIFICATION_CODE: 'Verification code',
    TIMEOUT: 'Timeout!',
    OK: 'Your Smart-ID device was successfully registered!',
    QUEUE: 'Please wait...',
  },
  signCodes: {
    STARTED: 'Signing in progress... please wait.',
    GET_CERT: 'Start signing with your device',
    VERIFICATION_CODE: 'Verification code',
    USER_REFUSED_DISPLAYTEXTANDPIN: 'The user refused to key in the PIN!',
    USER_REFUSED_CONFIRMATIONMESSAGE: 'The signing process was canceled by the user!',
    TIMEOUT: 'The session timeout!',
    ECONNABORTED: 'Connection lost!',
    USER_REFUSED: 'The signing process was canceled!',
    SIGNED: 'The document was signed!',
    QUEUE: 'Waiting for other signatories to finish. Please wait.',
    SIGNED_BY_OTHER: 'Already signed!',
    FAILED: 'Your Smart-ID details are expired or incorrect. Please reassign your SmartID to your profile.',
  },
  negotiationPermissions: {
    canAssignUsers: 'Manage access permission for negotiations',
    canHide: 'Set view only / hidden fields',
    canEdit: 'Edit negotiation',
    canSend: 'Send to Counterparty',
    canAgree: 'Agree negotiation',
    canSign: 'Sign negotiation',
    resetPermissionConfirmation: 'Are you sure to reset to default permissions ?',
    confirmRemove: 'Are you sure to Remove',
  },
  companyPermissions: {
    canAddUsers: 'Add new users',
    canUpdateUsers: 'Update users',
    canRemoveUsers: 'Remove users',
    canEdit: 'Edit company profile',
  },
  apiPermissions: {
    listDocuments: 'List documents',
    listNegotiations: 'List negotiations',
    viewNegotiation: 'View negotiation',
    startNegotiation: 'Start negotiation',
    editNegotiation: 'Edit negotiation',
    manageAlias: 'Manage alias',
    addCounterparty: 'Add counterparty',
    sendToCounterparty: 'Send to counterparty',
    getHistory: 'Get history',
    createAndExecuteViaAPIs: 'Create and execute contracts via APIs',    
  },
  notifications: {
    groupLabel: 'Receive notifications via E-mail',
    inviting: 'Invitation to negotiate received',
    accepted: 'Invitation to negotiate is accepted',
    rejected: 'Invitation to negotiate is rejected',
    addAlias: 'When you receive access to a company internal negotiation',
    receivedFromCounterparty: 'When the counterparty sent you an updated negotiation',
    execution: 'When a negotiation enters execution state',
    signed: 'When a negotiation was signed by one of the signatories',
    signedAndCompleted: 'When a negotiation was signed by all signatories',
    sendReminderNote:
      "Can only send a reminder email for this negotiation after 24h since the previous notification. Make sure it's counterparty's turn and not yours.",
  },
  editor: {
    publish: {
      formIsEmptyHeader: 'Error: The form is empty',
      formIsEmptyParagraph: 'All new templates need to have at least one form field that is connected to the document!',
    },
  },
}

export default dic
