import axios from 'axios'

const api = {
  //-------------------------------------------------------------------------------------------
  getGroups() {
    return axios.get('/fake/groups')
  },

  //-------------------------------------------------------------------------------------------
  getGroup(id) {
    return axios.get('/fake/groups/' + id)
  },

  //-------------------------------------------------------------------------------------------
  getFakeUsers() {
    return axios.get('/fake/users')
  },

  //-------------------------------------------------------------------------------------------
  createGroup(body) {
    return axios.post('/fake/groups', body)
  },

  //-------------------------------------------------------------------------------------------
  removeGroup(id) {
    return axios.delete('/fake/groups/' + id)
  },

  //-------------------------------------------------------------------------------------------
  createUser({ _key }, body) {
    return axios.post(`/fake/groups/${_key}/users`, body)
  },

  updateUser({ _key, status, role }) {
    return axios.put(`/admin/users/${_key}`, { status, role })
  },

  //-------------------------------------------------------------------------------------------
  createDummyUser(body) {
    return axios.post(`/fake/users`, body)
  },

  //-------------------------------------------------------------------------------------------
  assignUser({ _key }, body) {
    return axios.put(`/fake/groups/${_key}/users`, body)
  },

  //-------------------------------------------------------------------------------------------
  removeFromGroup(id) {
    return axios.delete('/fake/users/' + id + '/group')
  },
  //-------------------------------------------------------------------------------------------
  removeFromCompany(id) {
    return axios.delete('/fake/users/' + id + '/company')
  },

  //-------------------------------------------------------------------------------------------
  deleteUser(id) {
    return axios.delete('/fake/users/' + id + '/delete')
  },
  //-------------------------------------------------------------------------------------------

  //-------------------------------------------------------------------------------------------
}

export default api
