import React from 'react'
import { observer } from 'mobx-react'
import { format, timeDistance } from 'common/helper/date'
import FileSaver from 'file-saver'

import _d from 'dictionary'

import DocxTx from './DocxTranslator'
import PdfIcon from 'common/Icons/Pdf'
import DocxIcon from 'common/Icons/Docx'
import CodeIcon from 'common/Icons/DocumentVersion'
import DownloadIcon from 'common/Icons/Download'
import DownloadFileIcon from 'common/Icons/DownloadFile'

import AsyncButton from 'common/AsyncButton/AsyncButton'
import DownloadButton from './DownloadButton'
// import ExecutedDocuments from './ExecutedDocuments'

import printApi from 'api/print'

class DocumentInfo extends React.Component {
  downloadFile = async ({ name, fileName, label }) => {
    const { negotiation } = this.props
    const { state } = negotiation

    const fileData = await printApi.download(negotiation, { fileName: name.split('/').pop() })

    fileName = state === 'completed' ? label + '.signed.pdf' : fileName + '.pdf'

    FileSaver.saveAs(fileData, fileName)
  }

  print = async (fileType = 'pdf', type) => {
    const { negotiation } = this.props
    const { myPartyMeta, mainDoc, version } = negotiation

    const fileName = mainDoc.label + '-' + (myPartyMeta.version || version) + '.' + fileType

    let fileData

    if (negotiation._key) {
      fileData = await printApi.print(negotiation._key, fileType, type)
    } else {
      fileData = await printApi.printDoc(mainDoc._key, fileType, type)
    }

    FileSaver.saveAs(fileData, fileName)
  }

  printAudit = async () => {
    const { negotiation } = this.props
    const { mainDoc } = negotiation

    const fileName = mainDoc.label + '-audit.pdf'

    let fileData = await printApi.print(negotiation._key, 'pdf', 'auditTrail')
    FileSaver.saveAs(fileData, fileName)
  }

  printDocx = async () => {
    const { negotiation } = this.props
    const { myPartyMeta, mainDoc } = negotiation

    const docTx = await DocxTx.toDocx()

    const fileName = mainDoc.label + '-' + myPartyMeta.version + '.docx'
    const fileContent = await printApi.getDocx(docTx)
    FileSaver.saveAs(fileContent, fileName)
  }

  render() {
    window.DocumentInfo = this
    const { negotiation } = this.props
    const {
      doc,
      mainDoc,
      createdAt,
      createdBy,
      myParty = {},
      myPartyMeta = {},
      state,
      auditFile,
      lastActivity,
    } = negotiation
    const source = doc.tpls[0]

    const haveExecution = source.file && source.file.fileName
    const hideExecution = state === 'active' && myParty.isTurn
    const showExecution = haveExecution && !hideExecution

    return (
      <div>
        <section>
          <article>
            <h3>{mainDoc.label}</h3>
            <div>{mainDoc.description}</div>
            <ul className="info">
              <li className="info__item">
                <label>{_d('Category')}:</label>
                {mainDoc.category}
              </li>
              {myPartyMeta.version && (
                <li className="info__item">
                  <label>{_d('Version')}:</label>
                  {myPartyMeta.version}
                </li>
              )}
              <li className="info__item">
                <label>{_d('State')}:</label>
                {state}
              </li>

              {createdAt && (
                <li className="info__item">
                  <label>{_d('Created At')}:</label>
                  {format(createdAt, "do 'of' MMMM yyyy '@' HH:ii:ss")}
                </li>
              )}
              {createdBy && (
                <li className="info__item">
                  <label>{_d('Created By')}:</label>
                  {createdBy}
                </li>
              )}
              {lastActivity && (
                <li className="info__item">
                  <label>{_d('Last update')}:</label>
                  {timeDistance(lastActivity.createdAt, 'ago')}
                </li>
              )}
            </ul>
          </article>
          <article className="download">
            <h3 className="download__header">{_d('Download')}</h3>
            <div className="download__actions">
              {showExecution ? (
                <div>
                  <DownloadButton
                    label="Executed version"
                    icon={<PdfIcon />}
                    className="desc"
                    onClick={() => this.downloadFile(source.file)}
                  />
                  {auditFile && (
                    <AsyncButton
                      className="download__audit"
                      label="Audit trail"
                      onClick={() => this.downloadFile(auditFile)}
                      activeIcon={<DownloadFileIcon />}
                      icon={<DownloadIcon />}
                    />
                  )}
                </div>
              ) : (
                <DownloadButton label="PDF" icon={<PdfIcon />} onClick={() => this.print('pdf')} />
              )}

              {/* <DownloadButton label="DOCX" icon={<DocxIcon />} onClick={() => this.print('docx')} /> */}
              <DownloadButton label="DOCX" icon={<DocxIcon />} onClick={() => this.printDocx()} />

              <div className="download__icon" onClick={() => this.print('html')}>
                <div style={{ color: 'var(--dark2)' }}>
                  <CodeIcon version="</>" />
                </div>
                <div>HTML</div>
              </div>
            </div>
          </article>
          {/* <article className="download">
            <ExecutedDocuments {...this.props} />
          </article> */}
        </section>
      </div>
    )
  }
}

export default observer(DocumentInfo)
