import React from 'react'
import cn from 'classnames'

import PlusIcon from 'common/Icons/Plus'
import MinusIcon from 'common/Icons/MinusOnly'
import UpdateIcon from 'common/Icons/Repeat'
import JoinIcon from 'common/Icons/Archive'

import './Activities.expand.scss'

const ChangedItem = ({ id, value, orgValue, cpValue }) => {
  const action = {
    name: 'Updated',
    icon: <UpdateIcon />,
    type: 'update',
  }

  if (orgValue === undefined) {
    action.name = 'Added'
    action.type = 'add'
    action.icon = <PlusIcon />
  }

  if (value === undefined) {
    action.name = 'Removed'
    action.type = 'remove'
    action.icon = <MinusIcon />
  }

  return (
    <li className="activity-expand__changes__item">
      <div className="activity-expand__changes__header">{id}</div>
      <div className="activity-expand__changes__info">
        <div className={cn('--action', action.type)}>{action.icon}</div>
        <div className="--value">
          <ins>{value}</ins>
        </div>
        <div className="--orgValue">
          <del>{orgValue}</del>
        </div>
      </div>
      {cpValue !== undefined && (
        <div className="activity-expand__changes__info">
          <div className="--action --cp">cp</div>
          <div className="--cpValue">
            <span>{cpValue}</span>
          </div>
        </div>
      )}
    </li>
  )
}

const ActivityExpanded = (props) => {
  const { activity, history, model } = props
  const { total, completed, matched, changed, changedItems } = activity.stats || {}

  return (
    <div className="activity-expand">
      <div className="activity-expand__box">
        <div>
          <h4>Stats</h4>

          <ul className="activity-expand__stats">
            <li className="activity-expand__stats__item">
              <label>Total:</label>
              <div>{total}</div>
            </li>
            <li className="activity-expand__stats__item">
              <label>Completed:</label> <div>{completed}</div>
            </li>
            <li className="activity-expand__stats__item">
              <label>Matched:</label> <div>{matched}</div>
            </li>
            <li className="activity-expand__stats__item">
              <label>Changed:</label> <div>{changed}</div>
            </li>
          </ul>
        </div>

        {!!history.length && (
          <div>
            <button
              className="btn small clear"
              onClick={() => {
                const { myParty, counterparty } = history.reduce((acc, item) => {
                  acc[item.party] = item
                  return acc
                }, {})

                model.setCompare({
                  ...counterparty,
                  label: 'Counterparty position v.' + activity.version,
                  shortLabel: 'CP v.' + activity.version,
                })
                model.settings = { readOnly: true }
                model.answers = myParty.answers
              }}
            >
              <JoinIcon /> View history
            </button>
          </div>
        )}
      </div>

      {!!changed && (
        <div>
          <h4>Changes</h4>
          <ul className="activity-expand__changes">
            {changedItems.map((item, key) => <ChangedItem key={key} {...item} />)}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ActivityExpanded
