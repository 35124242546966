import axios from 'axios'
import { decorate, observable } from 'mobx'

import UserIcon from 'common/Icons/User'
import EmailIcon from 'common/Icons/EmailOutline'
import CreateAccount from 'screens/Settings/CreateAccount/CreateAccount'
import AccessViaEmail from 'screens/Settings/AccessViaEmail/AccessViaEmail'

import auth from './auth'
import app from './app'

const api = {
  current: {},

  //-------------------------------------------------------------------------------------------
  getList: (docId) => axios.get(`/negotiations${docId ? `?docId=${docId}` : ''}`),

  //-------------------------------------------------------------------------------------------
  async getOne(id, hash) {
    this.current = await axios.get(`/negotiations/${id}${hash ? '/hash/' + hash : ''}`)

    if (hash) {
      const { user } = this.current
      auth.user = user

      let modal = {
        header: '=Access via E-mail link',
        content: () => <AccessViaEmail hash={hash} negotiation={this.current} />,
        bgClick: false,
        icon: <EmailIcon />,
      }

      if (user.status === 'inviting') {
        modal.header = '=Create account'
        modal.content = () => <CreateAccount hash={hash} negotiation={this.current} />
        modal.icon = <UserIcon />
      }

      app.modal = modal
    }
    // if (!this.current.signatures) {
    //   this.current.signatures = this.current.signatories
    // }
    return this.current
  },

  async getOneDraft(id) {
    this.current = await axios.get(`/templates/${id}/draft`)
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  create: (data) => axios.post('/negotiations', data),

  //-------------------------------------------------------------------------------------------
  async share(negotiation, data) {
    this.current = await axios.post(`/negotiations/${negotiation._key}/alias`, data).catch(() => this.current)
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  //  Use this.current
  alias: {
    async add(data) {
      api.current = await axios.post(`/negotiations/${api.current._key}/alias`, data)
      return api.current
    },

    //-------------------------------------------------------------------------------------------
    async update(data) {
      api.current = await axios.put(`/negotiations/${api.current._key}/alias`, data)
      return api.current
    },

    //-------------------------------------------------------------------------------------------
    async remove({ perm }) {
      api.current = await axios.delete(`/negotiations/${api.current._key}/alias/${perm._key}`)
      return api.current
    },
  },

  async allowSign(negId, data) {
    api.current = await axios.put(`/negotiations/${negId}/allow-sign`, data)
    return api.current
  },

  //-------------------------------------------------------------------------------------------
  async updateAlias(negotiation, perm) {
    this.current = await axios.put(`/negotiations/${negotiation._key}/alias`, perm)
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  async removeAlias(negotiation, perm) {
    this.current = await axios.delete(`/negotiations/${negotiation._key}/alias/${perm._key}`)
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  async addCP(negotiation, body) {
    if (negotiation._key) {
      this.current = await axios
        .post(`/negotiations/${negotiation._key}/cp`, { ...body, myPosition: negotiation.myParty.position })
        .catch(() => this.current)
    } else {
      this.current = await api.createDraft(negotiation, body)
    }
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  async removeCP({ _key }) {
    this.current = await axios.delete(`/negotiations/${_key}/cp`)
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  async setSingleParty(negotiation, { singleParty }) {
    if (negotiation._key) {
      this.current = await axios.post(`/negotiations/${negotiation._key}/single-party`, { singleParty })
    } else {
      this.current = await api.createDraft(negotiation, { singleParty })
    }
    return this.current

    // this.current = await axios.post(`/negotiations/${_key}/single-party`, { singleParty })
    // return this.current
  },

  //-------------------------------------------------------------------------------------------
  async saveAnswers(negotiation, body) {
    if (negotiation._key) {
      this.current = await axios.put(`/negotiations/${negotiation._key}/answers`, body)
    } else {
      this.current = await api.createDraft(negotiation, body)
    }
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  createDraft(negotiation, { restrictions, answers, email, singleParty }) {
    const { myParty, mainDoc } = negotiation
    const data = {
      restrictions,
      counterparty: email,
      answers,
      singleParty,
      docId: mainDoc._key,
      asCompany: true,
      myPosition: myParty.position,
    }
    return api.create(data)
  },

  //-------------------------------------------------------------------------------------------
  async sendToCP(negotiation, body) {
    this.current = await axios.put(`/negotiations/${negotiation._key}/send`, body)
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  async accept(negotiation) {
    this.current = await axios.put(`/negotiations/${negotiation._key}/accept`)
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  async reject(negotiation) {
    this.current = await axios.put(`/negotiations/${negotiation._key}/reject`)
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  async agree(negotiation, answers) {
    this.current = await axios.post(`/negotiations/${negotiation._key}/agree`, answers)
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  delete: (negotiation) => axios.delete(`/negotiations/${negotiation._key}`),

  //-------------------------------------------------------------------------------------------
  getHistory: (negotiation) => axios.get(`/negotiations/${negotiation._key}/history`),

  //-------------------------------------------------------------------------------------------
  setRestriction: (negotiation, body) => axios.put(`/negotiations/${negotiation._key}/restriction`, body),

  //-------------------------------------------------------------------------------------------
  print: (id, fileType = 'pdf', type) =>
    axios({
      method: 'GET',
      url: `/negotiations/${id}/print/${type || fileType}`,
      responseType: 'blob',
    }),

  download: (negotiation, data = {}) =>
    axios({
      method: 'POST',
      url: `/negotiations/${negotiation._key}/download`,
      data,
      responseType: 'blob',
    }),

  //-------------------------------------------------------------------------------------------
  listDocuments: (id) => axios.get(`/negotiations/${id}/list-documents`),

  //-------------------------------------------------------------------------------------------
  async execute(id) {
    this.current = await axios.post(`/negotiations/${id}/execute`, {})
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  async executeSingle(negotiation, body) {
    if (!negotiation._key) {
      negotiation = await api.createDraft(negotiation, { singleParty: true })
    }
    this.current = await axios.post(`/negotiations/${negotiation._key}/execute-single`, body)
    return this.current
  },

  //-------------------------------------------------------------------------------------------
  getComments: (id) => axios.get(`/negotiations/${id}/comments`),

  //-------------------------------------------------------------------------------------------
  getActivities: (id) => axios.get(`/activities/negotiations/${id}`),

  //-------------------------------------------------------------------------------------------
  getActivity: (id) => axios.get(`/activities/${id}`),
  getSignatures: (id) => axios.get(`/negotiations/${id}/signatures`),

  //-------------------------------------------------------------------------------------------
  createComment: (id, body) => axios.post(`/negotiations/${id}/comments`, body),

  //-------------------------------------------------------------------------------------------
  signaturePreview: (body) => axios.post(`/negotiations/4998082/signature-preview`, body),

  //-------------------------------------------------------------------------------------------
  async sign(id, body) {
    await axios.post(`/negotiations/${id}/sign`, body)
    // this.current = await axios.post(`/negotiations/${id}/sign`, body)
    // return this.current
  },

  //-------------------------------------------------------------------------------------------
  async uploadSigned(id, formData, confirm) {
    const response = await axios.post(`/negotiations/${id}/upload-signed${confirm ? '/confirm' : ''}`, formData)
    if (confirm && response._key) {
      this.current = response
    }
    return response
  },
  //-------------------------------------------------------------------------------------------
  signWSmartId: (id, body) => axios.post(`/negotiations/${id}/signWSmartId`, body),
}

decorate(api, {
  current: observable.ref,
})

export default api
