import React from 'react'

const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
      <path
        d="M17.59,3.59 C17.21,3.21 16.7,3 16.17,3 L5,3 C3.89,3 3,3.9 3,5 L3,19 C3,20.1 3.9,21 5,21 L19,21 C20.1,21 21,20.1 21,19 L21,7.83 C21,7.3 20.79,6.79 20.41,6.42 L17.59,3.59 Z M12,19 C10.34,19 9,17.66 9,16 C9,14.34 10.34,13 12,13 C13.66,13 15,14.34 15,16 C15,17.66 13.66,19 12,19 Z M13,9 L7,9 C5.9,9 5,8.1 5,7 C5,5.9 5.9,5 7,5 L13,5 C14.1,5 15,5.9 15,7 C15,8.1 14.1,9 13,9 Z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </g>
  </svg>
)

export default Icon
