import React from 'react'

import TextIcon from 'common/Icons/TextIcon'
import TextAreaIcon from 'common/Icons/Text'
import RadioIcon from 'common/Icons/RadioIcon'
import CheckboxIcon from 'common/Icons/CheckboxIcon'
import SelectIcon from 'common/Icons/DropDownIcon'
import CalendarIcon from 'common/Icons/Calendar'
import NumbersIcon from 'common/Icons/Numbers'

import H2Icon from 'common/Icons/H2'
import ColumnsIcon from 'common/Icons/Columns'

import Form from 'common/Form/Form'

class Edit extends React.Component {
  form = [
    {
      type: 'text',
      name: 'label',
      label: 'Label',
      autoFocus: true,
      validate: {
        required: true,
      },
      hideIf: { type: 'columns' },
    },
    !this.isGroup && {
      type: 'dropDown',
      name: 'type',
      label: 'Type',
      options: [
        {
          content: <h3>Input</h3>,
        },
        {
          label: 'Text',
          value: 'text',
          icon: <TextIcon />,
        },
        {
          label: 'Text area',
          value: 'textarea',
          icon: <TextAreaIcon />,
        },
        // {
        //   label: 'Rich text',
        //   value: 'editor',
        //   icon: <TextAreaIcon />,
        // },
        {
          label: 'Number',
          value: 'numbers',
          icon: <NumbersIcon />,
        },
        {
          label: 'Radio',
          value: 'radio',
          icon: <RadioIcon />,
        },
        {
          label: 'Checkbox',
          value: 'checkbox',
          icon: <CheckboxIcon />,
        },
        {
          label: 'Select',
          value: 'select',
          icon: <SelectIcon />,
        },
        {
          label: 'DatePicker',
          value: 'datePicker',
          icon: <CalendarIcon />,
        },
        {
          content: <h3>Group</h3>,
        },
        {
          label: 'Section',
          value: 'section',
          icon: <H2Icon />,
        },
        {
          label: 'Columns',
          value: 'columns',
          icon: <ColumnsIcon />,
        },
      ],
    },
    {
      type: 'array',
      label: 'Options',
      name: 'options',
      showIf: { type: ['radio', 'select', 'checkbox'] },
      children: [{ type: 'text', name: 'label', autoFocus: true }],
    },
    {
      type: 'toggle',
      name: 'validate',
      label: 'Validation',
      showIf: { type: ['text', 'textarea', 'numbers'] },
      children: [
        {
          type: 'columns',
          showIf: { type: ['text', 'textarea'] },
          children: [
            {
              type: 'numbers',
              label: 'Min length',
              name: 'minLength',
              validate: {
                max: 10000,
                sync: 'validate.maxLength',
              },
            },
            {
              type: 'numbers',
              label: 'Max length',
              name: 'maxLength',
              validate: {
                min: 'validate.minLength + 1',
                max: 10000,
              },
            },
          ],
        },
        {
          type: 'columns',
          showIf: { type: ['numbers'] },
          children: [
            {
              type: 'numbers',
              label: 'Min',
              name: 'min',
              validate: {
                sync: 'validate.max',
              },
            },
            {
              type: 'numbers',
              label: 'Max',
              name: 'max',
              validate: {
                min: 'validate.min + 1',
              },
            },
          ],
        },
      ],
    },

    {
      type: 'actions',
      options: [
        {
          label: 'Cancel',
          className: 'cancel',
          onClick: () => {
            const { model } = this.props
            model.edit = false

            if (model._opt) {
              model.remove()
            }
          },
        },
        {
          label: 'Save',
          validate: true,
          type: 'submit',
        },
      ],
    },
  ]

  onSubmit = ({ values }) => {
    const { model } = this.props

    model.update(values)
    model._opt = null
    model.edit = null
    model.pm.getTagged()
  }

  get isGroup() {
    const { model } = this.props
    return model.type === 'group'
  }

  render() {
    const { model } = this.props
    const { values } = model

    return (
      <div className="elections">
        <Form config={this.form} values={values} onSubmit={this.onSubmit} />
      </div>
    )
  }
}

export default Edit
