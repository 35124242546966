import React from 'react'

import TickIcon from 'common/Icons/Tick'
import CloseIcon from 'common/Icons/Close'
import EmailOpenIcon from 'common/Icons/EmailOpen'
import WaitingIcon from 'common/Icons/Waiting'

import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import negotiations from 'api/negotiations'

const config = {
  myTurn: {
    icon: <EmailOpenIcon />,
    header: 'Invitation',
    showActivity: true,
    description: [
      'You are invited to participate in a negotiation with a third party.',
      'The details of your counter party and the content of their initial document are displayed below.',
      'After accepting the invitation you can sign or propose changes to the document.',
      'Rejecting the invitation halts the negotiation and cannot be undone.',
    ],
    cp: {
      label: 'Invitation sent',
      date: ({ negotiation: { cpParty } }) => cpParty.lastUpdated,
    },
    Action: ({ negotiation }) => (
      <div className="party-line__center">
        <div className="party-line__btn --right">
          <ConfirmButton
            confirm="Reject invitation?"
            className="error"
            data-id="reject"
            reverse
            onClick={() => negotiations.reject(negotiation)}
            icon={<CloseIcon />}
          >
            Reject
          </ConfirmButton>
        </div>

        <div className="party-line__btn">
          <ConfirmButton confirm="Accept invitation?" data-id="acceptInvitation" onClick={() => negotiations.accept(negotiation)} icon={<TickIcon />}>
            Accept
          </ConfirmButton>
        </div>
      </div>
    ),
  },

  cpTurn_2: {
    className: 'warning',
    icon: <WaitingIcon />,
    header: 'Waiting for Counterparty to accept Invitation',
    description: [
      'You submited your document to your counterparty.',
      'There is nothing required for you to do now. Waiting on your counter party to review the contract.',
    ],
    cp: {
      label: 'Sent',
      date: ({ negotiation: { myPartyMeta } }) => myPartyMeta.lastUpdated,
    },
  },
}

export default config
