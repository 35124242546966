import ListMy from './List/List.my'
import ListLibrary from './List/List.library'
import List from './List/List'

import Editor from './Editor/Editor'

const routes = [
  {
    path: '/documents',
    label: '=Templates',
    roles: [ 'user', 'admin' ],
    Wrapper: List,
    children: [
      {
        path: '/documents',
        name: 'my',
        Component: ListMy,
      },
      {
        path: '/documents/library',
        name: 'library',
        Component: ListLibrary,
      },
    ],
  },
  {
    path: '/documents',
    // label: '=Templates',
    // title: 'Template list',
    roles: [ 'user', 'admin' ],
    Component: ListMy,
    children: [
      {
        path: '/documents/edit',
        label: '=Edit',
        title: 'Editor - new template',
        Component: Editor,
      },
      {
        path: '/documents/:id/edit',
        label: '=Edit',
        title: 'Editor - existing template',
        Component: Editor,
      },
    ],
  },
]

export default routes
