import React from 'react'
import { format, formatDistance } from 'date-fns'

import LogoIcon from 'common/Icons/Logo'

import ShieldOutline from 'common/Icons/ShieldOutline'
import EmailIcon from 'common/Icons/Email'
import ServerIcon from 'common/Icons/Server'
import _d from 'dictionary'
import './Footer.scss'

class Footer extends React.Component {
  render() {
    const { APP_CONFIG: { version, environment = 'dev', buildTimestamp } } = window
    const date = new Date(parseInt(buildTimestamp + '000'))
    const buildDate = format(date, 'MMMM d, yyyy')
    const buildTime = format(date, 'h:iiaaaa')
    const timeDistance = formatDistance(date, new Date())

    return (
      <footer className="layout__footer">
        <div className="layout__footer__block">
          <div className="app__logo">
            {/* <img width="56px" src="/images/logo2.svg" alt="logo" /> */}
            <LogoIcon color="var(--lightest)" />
          </div>
        </div>
        <div className="layout__footer__block">
          <div>
            <ShieldOutline />
          </div>
          <div>
            <div>
              <a href="https://yumidocs.com/terms-of-service">{_d('Terms of service')}</a>
            </div>
          </div>
        </div>
        <div className="layout__footer__block">
          <div>
            <EmailIcon />
          </div>
          <div>
            <div>{_d('Help and info')}</div>
            <div>
              <i>info@yumidocs.com</i>
            </div>
          </div>
        </div>

        <div className="layout__footer__block">
          <div>
            <ServerIcon />
          </div>
          <div>
            <div>
              {environment} - {version}
            </div>
            <div>
              {buildDate} <i>@ {buildTime}</i>
            </div>
            <div className="date">{timeDistance} ago</div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
