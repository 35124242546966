import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Label from '../../input/Label'

class Actions extends React.Component {
  constructor(props) {
    super(props)
    const { model, options } = props.meta
    const cancel = options.find((item) => item.type === 'cancel')
    if (cancel) {
      model.onKeyDown = (e) => {
        if (e.key === 'Escape') {
          cancel.onClick()
        }
      }
    }
  }

  onChange = (e) => {
    const { meta } = this.props
    meta.setValue(e.target.value)
  }

  render() {
    const { meta = {}, readOnly } = this.props
    const { options = [] } = meta

    const { stats } = meta.model
    const { allValid } = stats || {}

    return (
      <div className={cn('form-item', { readOnly })}>
        <div className="form-item__content">
          <Label {...this.props} />
          <div className="form-input">
            <div className="form-item__actions">
              {options.map((item, key) => {
                const props = {
                  key,
                  disabled: readOnly,
                  type: item.type || 'button',
                  className: cn('btn', item.className),
                }

                if (typeof item.onClick === 'function') {
                  props.onClick = item.onClick
                }
                return (
                  <button {...props} disabled={item.validate && !allValid}>
                    {readOnly && item.loadingLabel ? item.loadingLabel : item.label}
                  </button>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default observer(Actions)
