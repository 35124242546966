import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Label from '../Label'
import Compare from '../Compare'
import ErrorMessage from '../ErrorMessage'

import Affix from '../Affix'
import AffixModel from './Text.affix'

class Text extends React.Component {
  onChange = (value) => {
    const { meta = {} } = this.props
    meta.setValue(value || undefined)
  }

  render() {
    const { meta = {}, readOnly } = this.props
    const {
      name,
      config = {},
      cpValue,
      disabled,
      cpOnly,
      opt = {},
      validation = {},
      isHidden,
      isRestricted,
      viewOnly,
      onBlur,
    } = meta

    const { isInvalid, isDirty, isMatched, isCompleted, isChanged } = validation

    const { flex, className } = config
    const valueModel = new AffixModel(meta)

    const { viewValue, prefix, suffix, value, viewMatched } = valueModel

    return (
      <div
        style={{ flex }}
        input-name={name || ''}
        className={cn(className, {
          isHidden,
          isRestricted,
          isMatched,
          isUnmatched: !isMatched,
          isIncompleted: !isCompleted,
          isCompleted,
          isChanged,
          isInvalid,
          viewOnly,
        })}
      >
        <div className={cn('form-item', { nested: opt.label, cpOnly, empty: !value, readOnly })}>
          <div className="form-item__content">
            <Label {...this.props} />
            {!cpOnly &&
              (viewOnly ? (
                <div className="form-input">
                  <div className={cn('form-input__view-only', { empty: !value, cpValue, value })}>{value}</div>
                </div>
              ) : (
                <div className="form-input">
                  <div className="flex">
                    {prefix && (
                      <Affix {...prefix} type="prefix" onChange={(val) => this.onChange(valueModel.setPrefix(val))} />
                    )}
                    <input
                      {...config.input}
                      autoFocus={config.autoFocus}
                      data-id={'text:' + name}
                      className={cn({
                        matched: viewMatched,
                        cpValue,
                        empty: !viewValue,
                        value: viewValue,
                        error: isInvalid && isDirty,
                        dirty: isDirty,
                        invalid: isInvalid,
                        blur: onBlur,
                      })}
                      onBlur={() => {
                        meta.onBlur = isInvalid
                        meta.onFocus = false
                      }}
                      onFocus={() => (meta.onFocus = true)}
                      value={viewValue}
                      onChange={({ target }) => this.onChange(valueModel.setValue(target.value))}
                      disabled={disabled || readOnly}
                    />
                    {suffix && (
                      <Affix {...suffix} type="suffix" onChange={(val) => this.onChange(valueModel.setSuffix(val))} />
                    )}
                  </div>

                  <ErrorMessage meta={meta} />
                </div>
              ))}
          </div>
          <Compare meta={meta} readOnly={readOnly || cpOnly} />
        </div>
      </div>
    )
  }
}

export default observer(Text)
