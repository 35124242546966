import React from 'react'
import { observer } from 'mobx-react'

import { timeDistance } from 'common/helper/date'
import Table from 'common/Table/Table'
import PreLoad from 'common/PreLoad/PreLoad'

import Avatar from 'common/UserCard/Avatar'
import TimeIcon from 'common/Icons/Revert'

import adminUsersApi from 'api/adminUsers'
import auth from 'api/auth'
import app from 'api/app'

import './SwitchFakeUsers.scss'

class SwitchFakeUsers extends React.Component {
  constructor(props) {
    super(props)
    this.preLoad = new PreLoad(this.getData)
  }

  getData = async () => {
    const group = await adminUsersApi.getFakeUsers()
    const { users } = group
    const companies = {}

    users.forEach((item) => {
      const company = item.company || { displayName: 'No company', _key: 1 }
      companies[company._key] = companies[company._key] || { ...company, users: [] }
      companies[company._key].users.push(item)
    })

    return { ...group, companies: Object.values(companies) }
  }

  tableConfig = {
    onClick: async (val) => {
      app.modal = { loading: true }
      await auth.autoLogin(val)
      window.location.reload()
    },
    active: ({ _key }) => _key === auth.user._key,

    columns: [
      {
        width: 50,
        content: (item) => <Avatar user={item} />,
      },
      {
        header: 'User',
        content: ({ displayName, email }) => (
          <div>
            <div>{displayName}</div>
            <div className="desc">{email}</div>
          </div>
        ),
      },
      {
        header: 'Last seen',
        width: 200,
        // align: 'center',
        content: ({ meta = {}, _key }) =>
          _key === auth.user._key ? (
            <div className="logged-in">Logged in</div>
          ) : (
            meta.lastAction && (
              <time>
                <TimeIcon />
                <div>{timeDistance(meta.lastAction)} ago</div>
              </time>
            )
          ),
      },
      // {
      //   width: 100,
      //   content: ({ dummy }) =>
      //     dummy && (
      //       <div className="fake-icon" title="Fake user">
      //         <IncognitoIcon />
      //       </div>
      //     ),
      // },
      {
        header: 'Company',
        align: 'center',
        width: 150,
        content: ({ company }) => company && company.displayName,
      },
      {
        header: 'Negotiations',
        align: 'center',
        width: 150,
        content: ({ negotiationsCount }) => <b>{negotiationsCount}</b>,
      },
    ],
  }

  render() {
    const { loading, result } = this.preLoad
    return (
      <div className="switch-fake-users">
        {loading || (
          <div>
            {/* <h1>{result.displayName}</h1> */}

            {result.companies.map((item, key) => (
              <div key={key} className="fake-users">
                <div className="fake-users__company">
                  <Avatar user={item} />
                  <div>{item.displayName}</div>
                </div>
                <Table config={{ ...this.tableConfig }} className="dark" list={item.users} />
              </div>
            ))}
          </div>
        )}
      </div>
    )
  }
}

export default observer(SwitchFakeUsers)
