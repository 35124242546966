import { observer } from 'mobx-react'

import { DropDown } from 'common/DropDown/DropDown'

const UnorderedList = ({ editorView, settings }) => {
  const { node } = settings

  const { attrs = {} } = node

  const update = (newAttrs = {}) => {
    const { state } = editorView.props
    const { selection } = state

    if (node.attrs) {
      const transaction = state.tr.setNodeMarkup(selection.from, undefined, { ...node.attrs, ...newAttrs })
      editorView.dispatch(transaction)
    }
  }

  const typeMarker = [
    { label: <label className="--parent">-- parent --</label>, value: null },
    { label: 'Disc', value: 'disc' },
    { label: 'Circle', value: 'circle' },
    { label: 'Square', value: 'square' },
  ]

  return settings.open ? (
    <div className="block-menu">
      <div className="block-menu__item">
        <div className="block-menu__label">Style:</div>
        <DropDown options={typeMarker} value={attrs.type} onChange={({ value }) => update({ type: value })} />
      </div>
    </div>
  ) : null
}

export default observer(UnorderedList)
