import React from 'react'
import { format, formatDistance } from 'date-fns'

import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import CompletedIcon from 'common/Icons/Completed'
import DoneIcon from 'common/Icons/Done2'
import WaitingIcon from 'common/Icons/Waiting'

import negotiations from 'api/negotiations'

const CurrentState = ({ state, lastUpdated }) => {
  const stateMap = {
    executed: (
      <b className="executed">
        Executed <DoneIcon />
      </b>
    ),
    execution: (
      <b className="completed">
        Waiting for action <WaitingIcon />
      </b>
    ),
  }

  let date
  let time
  if (lastUpdated) {
    date = format(new Date(lastUpdated), 'MMMM d, yyyy')
    time = format(new Date(lastUpdated), 'h:iiaaaa')
  }
  return (
    <div>
      <div className="current-state">
        <div>Current State:</div>
        {stateMap[state]}
      </div>
      {state === 'executed' &&
      lastUpdated && (
        <div>
          <div className="current-state date">
            {date} @ {time}
          </div>
          <div className="current-state date">{formatDistance(new Date(lastUpdated), new Date())} ago</div>
        </div>
      )}
    </div>
  )
}

const config = {
  // expand: true,
  className: 'success',
  icon: <CompletedIcon />,
  // header: 'Completed',
  download: 'Download executed version',
  description: [
    'The document is final and cannot be changed.',
    'Completed documents are always available for download and review.',
  ],
  cp: {
    Content: ({ negotiation: { cpParty } }) => <CurrentState {...cpParty} />,
  },
  me: {
    Content: ({ model, negotiation }) => {
      const { myParty } = negotiation
      return (
        <div>
          <CurrentState {...myParty} />
          {myParty.isTurn && (
            <div className="party-line__action">
              <ConfirmButton
                data-id="execute"
                confirm="Are you sure?"
                onClick={() => negotiations.sendToCP(negotiation, { answers: model.answers })}
              >
                Execute
              </ConfirmButton>
            </div>
          )}
        </div>
      )
    },
  },
}

export default config
