import { render } from 'react-dom'
import { decorate, observable } from 'mobx'

import { Plugin } from 'prosemirror-state'

import TopMenuComponent from './TopMenu.component'

//--------------------------------------------------------------------------------------------
class TopMenu {
  constructor(items, editorView, options) {
    this.items = items.map((item) => new MenuItem2(item, editorView))
    this.editorView = editorView
    this.options = options
    this.model = options && options.model

    decorate(this.activeNode, {
      name: observable,
    })
    this.updateNode()

    this.create()
  }

  activeNode = {}

  update(view, lastState) {
    const { state } = view
    if (lastState && lastState.doc.eq(state.doc) && lastState.selection.eq(state.selection)) return

    this.updateNode()
    this.updateItem(this.items)
  }

  updateItem = (item) => {
    if (Array.isArray(item)) {
      return item.map((itm) => this.updateItem(itm))
    }

    if (item.items) {
      return item.items.map((itm) => this.updateItem(itm))
    }

    if (item.update) {
      item.update()
    }
  }

  updateNode = () => {
    const node = this.getParentNode()
    const name = node && node.type.name

    this.activeNode.node = node
    this.activeNode.name = name
  }

  destroy() {
    this.dom.remove()
  }

  create = () => {
    this.dom = document.createElement('div')
    this.dom.className = 'top-menu'
    this.editorView.dom.parentNode.insertBefore(this.dom, this.editorView.dom)

    render(<TopMenuComponent {...this} />, this.dom)
  }

  getParentNode = () => {
    const { state } = this.editorView
    const { $from, to, node } = state.selection

    return node || (to <= $from.end() && $from.parent)
  }
}

//--------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------

//--------------------------------------------------------------------------------------------
//--------------------------------------------------------------------------------------------
class MenuItem2 {
  constructor(item, editorView) {
    this.editorView = editorView
    this.spec = item.spec
    this.item = item

    if (Array.isArray(item)) {
      return item.map((itm) => new MenuItem2(itm, editorView))
    }

    if (item.content) {
      item.items = item.content.map((itm) => new MenuItem2(itm, editorView))
    }

    if (!item.spec) {
      return item
    }

    this.update()

    decorate(this, {
      isHidden: observable,
      isActive: observable,
      isDisabled: observable,
    })
  }

  get icon() {
    const { icon, label } = this.spec
    return icon
      ? icon.text || (
          <svg width="16" height="16" viewBox={`0 0 ${icon.width} ${icon.height}`}>
            <path d={icon.path} fill="currentColor" />
          </svg>
        )
      : label
  }

  get title() {
    return this.spec.title
  }

  get label() {
    return this.spec.label
  }

  get className() {
    return this.spec.className
  }

  run = (e) => {
    const { editorView } = this
    e.preventDefault()
    editorView.focus()

    return this.spec.run(editorView.state, editorView.dispatch, editorView)
  }

  update = () => {
    const { editorView, spec } = this

    if (spec) {
      this.enable = spec.enable && spec.enable(editorView.state)
      this.available = spec.enable ? this.enable : spec.run(editorView.state, null, editorView)

      this.active = spec.active && spec.active(editorView.state)

      this.isHidden = this.enable === undefined && !this.available
      this.isActive = !!this.active
      this.isDisabled = this.enable !== undefined && !this.enable
    }
  }
}

//--------------------------------------------------------------------------------------------
const topMenuPlugin = (items, options) => new Plugin({ view: (editorView) => new TopMenu(items, editorView, options) })

export default topMenuPlugin
