// [Specs](#model.NodeSpec) for the nodes defined in this schema.
export const nodes = {
  // :: NodeSpec The top level document node.
  doc: {
    content: '(block | page_break)+',
  },

  // :: NodeSpec A plain paragraph textblock. Represented in the DOM
  // as a `<p>` element.

  paragraph: {
    attrs: {
      className: { default: null },
      marker: { default: null },
      align: { default: null },
      drWhen: { default: null },
      drValue: { default: null },
    },
    content: 'inline*',
    group: 'block',
    parseDOM: [
      {
        tag: 'p',
        getAttrs: (dom) => ({
          className: dom.getAttribute('class'),
          marker: dom.getAttribute('marker'),
          align: dom.getAttribute('align'),
          drWhen: dom.getAttribute('dr-when'),
          drValue: dom.getAttribute('dr-value'),
        }),
      },
    ],
    toDOM(node) {
      const { className, marker, align, drWhen, drValue } = node.attrs
      return ['p', { class: className, marker, align, 'dr-when': drWhen, 'dr-value': drValue }, 0]
    },
  },

  div: {
    attrs: {
      className: { default: null },
      marker: { default: null },
      align: { default: null },
      drWhen: { default: null },
      drValue: { default: null },
    },
    group: 'block',
    content: 'block*',
    marks: '',
    parseDOM: [
      {
        tag: 'div',
        priority: 51,
        getAttrs: (dom) => ({
          align: dom.getAttribute('align'),
          marker: dom.getAttribute('marker'),
          className: dom.getAttribute('class'),
          drWhen: dom.getAttribute('dr-when'),
          drValue: dom.getAttribute('dr-value'),
        }),
      },
    ],
    toDOM(node) {
      const { className, drWhen, drValue, align, marker } = node.attrs
      return ['div', { align, marker, class: className, 'dr-when': drWhen, 'dr-value': drValue }, 0]
    },
  },

  // :: NodeSpec A blockquote (`<blockquote>`) wrapping one or more blocks.
  blockquote: {
    content: 'block+',
    group: 'block',
    defining: true,
    parseDOM: [{ tag: 'blockquote' }],
    toDOM: () => ['blockquote', 0],
  },

  // :: NodeSpec A horizontal rule (`<hr>`).
  horizontal_rule: {
    group: 'block',
    parseDOM: [{ tag: 'hr' }],
    toDOM: () => ['hr'],
  },

  // :: NodeSpec A heading textblock, with a `level` attribute that
  // should hold the number 1 to 6. Parsed and serialized as `<h1>` to
  // `<h6>` elements.
  heading: {
    attrs: {
      level: { default: 1 },
      align: { default: null },
    },
    content: 'inline*',
    group: 'block',
    defining: true,
    parseDOM: [
      { tag: 'h1', getAttrs: (dom) => ({ level: 1, align: dom.getAttribute('align') }) },
      { tag: 'h2', getAttrs: (dom) => ({ level: 2, align: dom.getAttribute('align') }) },
      { tag: 'h3', getAttrs: (dom) => ({ level: 3, align: dom.getAttribute('align') }) },
      { tag: 'h4', getAttrs: (dom) => ({ level: 4, align: dom.getAttribute('align') }) },
      { tag: 'h5', getAttrs: (dom) => ({ level: 5, align: dom.getAttribute('align') }) },
      { tag: 'h6', getAttrs: (dom) => ({ level: 6, align: dom.getAttribute('align') }) },
    ],
    toDOM(node) {
      const { align, level } = node.attrs
      return ['h' + level, { align }, 0]
    },
  },

  // :: NodeSpec A code listing. Disallows marks or non-text inline
  // nodes by default. Represented as a `<pre>` element with a
  // `<code>` element inside of it.
  code_block: {
    content: 'text*',
    marks: '',
    group: 'block',
    code: true,
    defining: true,
    parseDOM: [{ tag: 'pre', preserveWhitespace: 'full' }],
    toDOM: () => ['pre', ['code', 0]],
  },

  // :: NodeSpec The text node.
  text: {
    group: 'inline',
    inline: true,
  },

  // :: NodeSpec An inline image (`<img>`) node. Supports `src`,
  // `alt`, and `href` attributes. The latter two default to the empty
  // string.
  image: {
    inline: true,
    attrs: {
      src: {},
      alt: { default: null },
      title: { default: null },
      style: { default: null },
    },
    group: 'inline',
    draggable: true,
    parseDOM: [
      {
        tag: 'img[src]',
        getAttrs(dom) {
          return {
            src: dom.getAttribute('src'),
            title: dom.getAttribute('title'),
            alt: dom.getAttribute('alt'),
            style: dom.getAttribute('style'),
          }
        },
      },
    ],
    toDOM(node) {
      const { src, alt, title, style } = node.attrs
      return ['img', { src, alt, title, style }]
    },
  },

  // :: NodeSpec A hard line break, represented in the DOM as `<br>`.
  hard_break: {
    inline: true,
    group: 'inline',
    selectable: false,
    parseDOM: [{ tag: 'br' }],
    toDOM: () => ['br'],
  },

  page_break: {
    // group: 'block',
    attrs: {
      pageBreak: { default: 'before' },
    },
    selectable: true,
    draggable: true,
    parseDOM: [
      {
        priority: 52,
        tag: '[data-page-break]',
        getAttrs: (dom) => ({ pageBreak: dom.getAttribute('data-page-break') }),
      },
    ],
    toDOM: ({ attrs }) => ['div', { 'data-page-break': attrs.pageBreak }],
  },
}

// :: Object [Specs](#model.MarkSpec) for the marks in the schema.
export const marks = {
  // :: MarkSpec A strong mark. Rendered as `<strong>`, parse rules
  // also match `<b>` and `font-weight: bold`.
  strong: {
    parseDOM: [
      { tag: 'strong' },
      { tag: 'b', getAttrs: (node) => node.style.fontWeight !== 'normal' && null },
      { style: 'font-weight', getAttrs: (value) => /^(bold(er)?|[5-9]\d{2,})$/.test(value) && null },
    ],
    toDOM: () => ['strong', 0],
  },

  // :: MarkSpec A link. Has `href` and `title` attributes. `title`
  // defaults to the empty string. Rendered and parsed as an `<a>`
  // element.
  link: {
    attrs: {
      href: {},
      title: { default: null },
    },
    inclusive: false,
    parseDOM: [
      {
        tag: 'a[href]',
        getAttrs(dom) {
          return { href: dom.getAttribute('href'), title: dom.getAttribute('title') }
        },
      },
    ],
    toDOM(node) {
      let { href, title } = node.attrs
      return ['a', { href, title }, 0]
    },
  },

  // :: MarkSpec An emphasis mark. Rendered as an `<em>` element.
  // Has parse rules that also match `<i>` and `font-style: italic`.
  em: {
    parseDOM: [{ tag: 'i' }, { tag: 'em' }, { style: 'font-style=italic' }],
    toDOM: () => ['em', 0],
  },

  underline: {
    parseDOM: [{ tag: 'u' }],
    toDOM: () => ['u', 0],
  },

  strike: {
    parseDOM: [{ tag: 's' }],
    toDOM: () => ['s', 0],
  },
}
