import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import './Aside.scss'

class AsideTabs extends React.Component {
  tabs = [
    {
      label: 'Form',
      name: 'form',
    },
    {
      label: 'Page style',
      name: 'pageSettings',
    },

    !!this.props.tpl._key && {
      label: 'Settings',
      name: 'settings',
    },
  ]

  render() {
    const { settings } = this.props
    const { active = 'form' } = settings

    return (
      <nav className="aside__tabs">
        <ul className="tabs">
          {this.tabs.map(({ label, name }, key) => (
            <li className="tabs__item" key={key}>
              <button className={cn('tab', { active: name === active })} onClick={() => (settings.active = name)}>
                {label}
              </button>
            </li>
          ))}
        </ul>
        <div>
          <button className="btn" disabled={active === 'publish'} onClick={() => (settings.active = 'publish')}>
            Publish
          </button>
        </div>
      </nav>
    )
  }
}

export default observer(AsideTabs)
