import React from 'react'
import { observer } from 'mobx-react'

import Actions from './actions/Actions/Actions'
import Submit from './actions/Submit/Submit'

import States from './view/States/States'
import Group from './view/Group/Group'
import Columns from './view/Columns/Columns'
import Section from './view/Section/Section'
import SubHeader from './view/SubHeader/SubHeader'

import Text from './input/Text/Text'
import TextArea from './input/TextArea/TextArea'
import Editor from './input/Editor/Editor'
import Numbers from './input/Numbers/Numbers'
import Radio from './input/Radio/Radio'
import Checkbox from './input/Checkbox/Checkbox'
import Select from './input/Select/Select'
import DropDown from './input/DropDown/DropDown'
import ArrayForm from './input/Array/Array'
import ArrayItem from './input/Array/Array.item'
import DatePicker from './input/DatePicker/DatePicker'
import Signature from './input/Signature/Signature'

import Toggle from './input/Toggle/Toggle'

import FormModel from './model/form'

import './Form.scss'
import './Form.elections.scss'

const input = {
  actions: Actions,
  text: Text,
  editor: Editor,
  textarea: TextArea,
  numbers: Numbers,
  radio: Radio,
  checkbox: Checkbox,
  select: Select,
  dropDown: DropDown,
  section: Section,
  selectGroup: Select,
  columns: Columns,
  group: Group,
  election: Group,
  array: ArrayForm,
  arrayItem: ArrayItem,
  datePicker: DatePicker,
  signature: Signature,
  states: States,
  submit: Submit,
  subHeader: SubHeader,
  toggle: Toggle,
}

class Form extends React.Component {
  constructor(props) {
    super(props)
    let { config, values = {}, compare, getModel, onChange, noCompare } = props

    if (config) {
      let cpPosition
      if (compare) {
        cpPosition = { answers: compare }
      }
      this.model = new FormModel(
        { children: config, validation: true, noCompare },
        { primary: { answers: values }, cpPosition },
        { simple: true }
      )

      this.list = this.model.children
      this.answers = this.model.answers
      if (onChange) {
        this.model.onChange = onChange
      }
    }

    if (getModel) {
      getModel(this.model)
    }
  }

  model = {}

  render() {
    window.Form = this

    let { items, model = this.model, tpls, readOnly, actions, wrapper, onSubmit, data } = this.props

    this.length = model.config && model.config.length
    const list = this.list || model.children || items

    if (!list) return null

    if (!list.find((item) => !Array.isArray(item))) {
      return (
        <div className="form-columns">
          {list.map((items, index) => <Form key={index} items={items} readOnly={readOnly} />)}
        </div>
      )
    }

    const form = list.map((meta = {}, index) => {
      if (meta.hide) return null

      if (Array.isArray(meta)) {
        return <Form key={index} items={meta} readOnly={readOnly} />
      }

      const { config = {}, name, onClick, label } = meta

      if (typeof config.content === 'function') {
        if (label) {
          return (
            <div className="form-item" key={index}>
              <div className="form-item__content">
                <label className="form-label" tabIndex="-1">
                  <span>{label}</span>
                </label>
                <div className="form-input">{config.content(meta, meta.model)}</div>
              </div>
            </div>
          )
        }
        return <React.Fragment key={index}>{config.content(meta, meta.model)}</React.Fragment>
      }

      const key = index + '|' + meta.unique // + '|' + config.name + '|' + config.type + '|' + config.label
      if (config.Component) {
        return (
          <config.Component
            key={key}
            meta={meta}
            readOnly={readOnly}
            model={model}
            tpls={tpls}
            values={model.answers}
            value={meta.value}
            stats={model.stats}
          />
        )
      }

      const Input = input[config.type]

      const content = Input && (
        <Input
          key={key}
          index={index}
          meta={meta}
          tpls={tpls}
          readOnly={readOnly || meta.readOnly}
          actions={actions}
          wrapper={wrapper}
          data={data}
        />
      )

      if (typeof onClick === 'function') {
        return (
          <div
            key={key}
            data-component={name}
            onClick={(e) => {
              e.stopPropagation()
              config.name && onClick(meta)
            }}
          >
            {content}
          </div>
        )
      }

      if (typeof wrapper === 'function') {
        return <React.Fragment key={key}>{wrapper(content, meta, this.props)}</React.Fragment>
      }

      return content
    })

    return onSubmit ? (
      <form
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit(model)
        }}
        onKeyDown={(e) => model.onKeyDown(e)}
      >
        {form}
      </form>
    ) : (
      form
    )
  }
}

export default observer(Form)
