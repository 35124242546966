import React from 'react'

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="207.627" viewBox="0 0 207.627 151.33">
    <g transform="translate(-21.016 -414.411)">
      <path
        d="M-.976,40.074c-.809,4.587,2.523,8.964,9.861,9.341L116.319,60.432c6.885.353,12.916-3.119,12.97-7.466l-2-44.489C126.57,3.218,122.9.718,116.946.407L18.763,0C11.091.159,7.6,1.638,6.417,6.051Z"
        transform="translate(218.928 545.516) rotate(171)"
        fill="rgba(237,239,242,0.55)"
      />
      <path
        d="M141.422,63.643c-1.263,6.975,3.938,13.631,15.393,14.2L322.7,106.192c10.749.537,20.164-4.742,20.248-11.353l-3.129-67.647c-1.117-8-6.849-11.8-16.141-12.272L170.4,14.3c-11.978.242-17.426,2.49-19.274,9.2Z"
        transform="matrix(-0.998, 0.07, -0.07, -0.998, 370.533, 510.529)"
        fill="rgba(30,47,65,0.8)"
      />
      <g transform="translate(-164 163.752)">
        <g transform="translate(296.049 362.071) rotate(-9)">
          <path
            d="M10.77,23.875A10.649,10.649,0,0,1,5.1,22.386a10.1,10.1,0,0,1-3.761-4.167A13.893,13.893,0,0,1,0,11.96,14.023,14.023,0,0,1,1.338,5.672,10.088,10.088,0,0,1,5.1,1.488,10.675,10.675,0,0,1,10.771,0a10.67,10.67,0,0,1,5.673,1.488A10.073,10.073,0,0,1,20.2,5.672a14.021,14.021,0,0,1,1.34,6.288,13.892,13.892,0,0,1-1.34,6.258,10.084,10.084,0,0,1-3.761,4.167A10.644,10.644,0,0,1,10.77,23.875Zm.017-20.114A5.424,5.424,0,0,0,7.274,4.889,6.668,6.668,0,0,0,5.213,7.874a12.061,12.061,0,0,0-.669,4.07,12.013,12.013,0,0,0,.669,4.077,6.567,6.567,0,0,0,2.061,2.964A5.464,5.464,0,0,0,10.787,20.1a5.4,5.4,0,0,0,3.488-1.113,6.589,6.589,0,0,0,2.056-2.964A11.976,11.976,0,0,0,17,11.945a12.023,12.023,0,0,0-.669-4.07,6.69,6.69,0,0,0-2.056-2.985A5.36,5.36,0,0,0,10.787,3.761Z"
            transform="translate(0 0)"
            fill="#e98400"
          />
          <path
            d="M10.772,23.875A10.575,10.575,0,0,1,5,22.349a10,10,0,0,1-3.709-4.22A14.106,14.106,0,0,1,0,11.959,13.956,13.956,0,0,1,1.325,5.738,10.158,10.158,0,0,1,5.056,1.517,10.409,10.409,0,0,1,10.726,0a10.907,10.907,0,0,1,4.695.969,8.539,8.539,0,0,1,3.333,2.716,8.218,8.218,0,0,1,1.511,4.077H15.888a5.259,5.259,0,0,0-1.647-2.8A4.9,4.9,0,0,0,10.8,3.79a5.583,5.583,0,0,0-3.285.986,6.41,6.41,0,0,0-2.19,2.8,10.729,10.729,0,0,0-.782,4.282,11.248,11.248,0,0,0,.768,4.361,6.448,6.448,0,0,0,2.173,2.844A5.552,5.552,0,0,0,10.8,20.068a5.548,5.548,0,0,0,2.324-.473,4.73,4.73,0,0,0,1.761-1.362,5.2,5.2,0,0,0,1-2.136h4.377a8.5,8.5,0,0,1-1.459,4,8.4,8.4,0,0,1-3.256,2.769,10.673,10.673,0,0,1-4.777,1.007"
            transform="translate(23.863 0)"
            fill="#e98400"
          />
          <path
            d="M18.338,5.942l-4.077.723a4.915,4.915,0,0,0-.806-1.491,4.071,4.071,0,0,0-1.481-1.159,5.346,5.346,0,0,0-2.331-.451,5.639,5.639,0,0,0-3.189.851A2.542,2.542,0,0,0,5.174,6.6a2.3,2.3,0,0,0,.859,1.865A6.922,6.922,0,0,0,8.8,9.626l3.671.843a9.856,9.856,0,0,1,4.755,2.273,5.34,5.34,0,0,1,1.565,3.986,6.052,6.052,0,0,1-1.2,3.694,7.921,7.921,0,0,1-3.325,2.536,12.371,12.371,0,0,1-4.928.917,11.063,11.063,0,0,1-6.334-1.661A6.971,6.971,0,0,1,0,17.483l4.347-.663a4.133,4.133,0,0,0,1.67,2.566,5.732,5.732,0,0,0,3.294.864,6.065,6.065,0,0,0,3.536-.926,2.705,2.705,0,0,0,1.324-2.264,2.376,2.376,0,0,0-.806-1.821,5.393,5.393,0,0,0-2.458-1.113l-3.913-.858a9.341,9.341,0,0,1-4.777-2.346A5.66,5.66,0,0,1,.677,6.844,5.827,5.827,0,0,1,1.82,3.264a7.531,7.531,0,0,1,3.16-2.4A11.619,11.619,0,0,1,9.6,0a9.616,9.616,0,0,1,5.9,1.615,7.6,7.6,0,0,1,2.843,4.327"
            transform="translate(46.445 0)"
            fill="#e98400"
          />
        </g>
        <path
          d="M.106,13.477l-.062-1c-.011-.118-.013-.461-.018-.733l-.012-.856c-.02-1.146-.019-2.32.012-3.52C.065,4.97.17,2.545.277.051L.776,0a5,5,0,0,1,.6.033L2.611.144,5.045.376a30.126,30.126,0,0,1,4.976.842c.847.217,1.7.492,2.546.8.424.141.839.33,1.258.493.212.083.413.171.614.262l.443.2a31.54,31.54,0,0,1,3.159,1.709,38.389,38.389,0,0,1,10.09,9.223,28.454,28.454,0,0,1,5,9.761,26.232,26.232,0,0,1,.636,11.612,27.029,27.029,0,0,1-.674,2.9c-.278.941-.639,1.928-.947,2.68l-.216.563-.273.66c-.188.439-.386.877-.6,1.312a28.077,28.077,0,0,1-1.408,2.558,26.1,26.1,0,0,1-8.176,8.237c-.234-.391.391-1.618-.738-1.152-2.2.975-3.406,1.042-4.086.9a1.4,1.4,0,0,1-1.05-.994c-.092-.208.631-.531,1.224-.831s1.057-.557.484-.506a18.274,18.274,0,0,0,4.087-2.931c.933-.962,1.179-1.431-.132-.561a24.865,24.865,0,0,0-2.632,1.632,5.789,5.789,0,0,1-2.86,1.107c-1.175-.188.267-1.653-.9-1.846a32.9,32.9,0,0,1-3.494,1.435c-1.035-.126.433-1.6-.448-1.293-2.738.487-6.384,1.572-10.3,2.3-.1-.447-1.139-.709.75-1.307,6.137-1.271,11.992-2.892,15.759-6.093a15.7,15.7,0,0,0,4.342-5.9c.13-.3.25-.593.37-.9l.413-1.078A17.293,17.293,0,0,0,22.81,34.4a15.921,15.921,0,0,0,.446-3.624,19.909,19.909,0,0,0-5.908-13.712,22.226,22.226,0,0,0-6.279-4.546l-.434-.2-.273-.117a.162.162,0,0,0-.033-.008l-.06-.01c-.033,0-.085-.017-.1,0l-.017.311c-.032,2.305-.03,4.629-.021,6.965.019,4.672.074,9.4.01,14.16-.218,4.1-1.125,1.013-1.84-2.023.237,1.014-.446,1.019-.211,2.034-.685,0-1.83-2.02-2.125-6.049l.044,3.028c-.215,1.013-.685.008-1.14.014-.011-1.009-.028-2.016-.256-2.012l-.912.013.049,4.023c-.007-1-.022-2.01-.249-2.007l-.912.012c-.252-2,.15-5.029-.114-7.042l-.683.011c-.194,2.023.09,5.035.113,7.039-.241-1-.469-1-.925-.99-.271-3,.351-6.035-.377-9.061L.65,23.639c-1.175-2-.345-7.083-.545-10.162"
          transform="translate(255.254 342.619) rotate(-9)"
          fill="#e98400"
        />
        <g transform="translate(199 290.006)">
          <path
            d="M7.736,41.6a13.264,13.264,0,0,1-5.746-5.856A20.749,20.749,0,0,1,0,26.206V1.263A1.116,1.116,0,0,1,1.263,0H8.209A1.116,1.116,0,0,1,9.472,1.263V25.7a16.788,16.788,0,0,0,.663,5.146,5.648,5.648,0,0,0,2.273,3.078A7.908,7.908,0,0,0,16.8,34.984a8.123,8.123,0,0,0,4.468-1.059,5.7,5.7,0,0,0,2.3-3.078,16.423,16.423,0,0,0,.679-5.146V13.2a17.346,17.346,0,0,1,1.2-6.726A10.746,10.746,0,0,1,28.8,1.957,9.775,9.775,0,0,1,33.721,0V25.7q0,8.839-4.4,13.355T16.8,43.571A19.256,19.256,0,0,1,7.736,41.6"
            transform="translate(51.146 0.737)"
            fill="#fff"
          />
          <path
            d="M54.5,41.551a1.5,1.5,0,0,1,.032.347,1,1,0,0,1-.3.759,1.22,1.22,0,0,1-.868.283h-7.2A1.249,1.249,0,0,1,44.772,41.8L39.4,16.007,34.668,31.194A37.566,37.566,0,0,1,31.8,38.315a13.058,13.058,0,0,1-3.189,3.962A10.948,10.948,0,0,1,24.628,44.2l-8.9-28.228L12.693,30.5Q11.3,37.256,7.957,40.731A10.681,10.681,0,0,1,0,44.2L10.293,1.072A1.308,1.308,0,0,1,11.682,0h6.5a1.41,1.41,0,0,1,.9.283,1.4,1.4,0,0,1,.49.789L27.5,26.838,35.553,1.072a1.4,1.4,0,0,1,.489-.789,1.411,1.411,0,0,1,.9-.283h6.5a1.309,1.309,0,0,1,1.389,1.072Z"
            transform="translate(88.466 0.737)"
            fill="#fff"
          />
          <path
            d="M15.6,8.588V34.352h4.861a1.117,1.117,0,0,1,1.263,1.264v6.061a1.117,1.117,0,0,1-1.263,1.264H1.263A1.116,1.116,0,0,1,0,41.677V35.616a1.117,1.117,0,0,1,1.263-1.264H6.125v-13.2a16.041,16.041,0,0,1,1.863-8.082,9.82,9.82,0,0,1,5.051-4.484H1.263A1.116,1.116,0,0,1,0,7.325V1.263A1.116,1.116,0,0,1,1.263,0h19.2a1.116,1.116,0,0,1,1.263,1.263V7.325a1.116,1.116,0,0,1-1.263,1.263Z"
            transform="translate(147.953 0.737)"
            fill="#fff"
          />
          <path
            d="M4.766,15.565,8.513,9.53a19.347,19.347,0,0,1,7.448-7.084A21.245,21.245,0,0,1,26.111,0L9.529,23.824V43.2a1.123,1.123,0,0,1-1.27,1.27H1.271A1.124,1.124,0,0,1,0,43.2V23.824"
            transform="translate(20.222)"
            fill="#fff"
          />
          <path
            d="M20.348,17.9a16.33,16.33,0,0,1-8.074-.931,14.971,14.971,0,0,1-5.806-5.306L.246,1.474A.934.934,0,0,1,.512,0h8.1c.857-.083,1.464.9,1.867,1.478L20.4,17.861"
            transform="translate(0 0.384)"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default Icon
