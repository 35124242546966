import { decorate, observable, toJS } from 'mobx'
import { observer } from 'mobx-react'
import { ReactSortable } from 'react-sortablejs'

import Group from './ElementTypes/Group'
import Columns from './ElementTypes/Columns'
import DefaultEl from './ElementTypes/DefaultEl'
import Radio from './ElementTypes/Radio'
import Checkbox from './ElementTypes/Checkbox'

const elements = {
  group: Group,
  columns: Columns,
  radio: Radio,
  checkbox: Checkbox,
}

class JsonEditor {
  //---------------------------------------------------------------------------------------------------------
  constructor(config, parent, opt) {
    this._config = config
    this._parent = parent
    this._opt = opt
    this.edit = (opt && opt.edit) || null

    this._root = (parent && parent._root) || this

    this.init()
    this.startWatch()

    window.JsonEditor = this
  }

  init() {
    this.initChildren()
  }

  startWatch() {
    decorate(this, {
      _children: observable.ref,
      _config: observable.ref,
      Component: observable.ref,
      edit: observable,
      showRemove: observable,
      isFocused: observable,
      _tags: observable.ref,
    })
  }

  id = Math.random()
  // edit = null
  //---------------------------------------------------------------------------------------------------------
  //  GETTERS
  //---------------------------------------------------------------------------------------------------------
  getComponent() {
    return elements[this.type] || DefaultEl
  }

  initChildren() {
    const { children } = this._config
    if (children && children.length) {
      this._children = children.filter(Boolean).map((item) => new JsonEditor(item, this))
    }
    return this._children
  }

  get config() {
    return toJS(this._config)
  }

  get name() {
    return this._config.name
  }

  get labelName() {
    return this.labelToName(this.label)
  }

  get fullName() {
    const { name, parent } = this
    const parentName = parent && parent.fullName

    return [parentName, name].filter(Boolean).join('.')
  }

  get label() {
    return this._config.label
  }

  get type() {
    return this._config.type
  }

  get options() {
    return this._config.options
  }

  get isInput() {
    return ['text', 'textarea', 'editor', 'datePicker', 'radio', 'checkbox', 'numbers', 'select', 'dropdown'].includes(
      this.type
    )
  }

  get parent() {
    return this._parent || {}
  }

  get index() {
    return this._parent && this._parent.children.indexOf(this)
  }

  get children() {
    const children = this._children || this.initChildren()
    if (['section', 'group', 'columns'].includes(this.type) && !children) {
      return []
    }

    // if ([ 'columns' ].includes(this.type)) {
    //   let arr = [ ...(children || []), null, null ]
    //   arr.length = 2

    //   console.log(arr)
    //   return arr
    // }

    return children
  }

  set children(value) {
    this._children = [...value]
  }

  get childrenValues() {
    const { children } = this
    if (children) {
      return children.map((item) => item.values)
    }
    return undefined
  }

  get childrenNames() {
    const { children } = this
    return [this.name && this.fullName, ...(children || []).map((item) => item.childrenNames).flat()].filter(Boolean)
  }

  get values() {
    const values = this.config
    values.children = this.childrenValues
    return values
  }

  get names() {
    return this._root.childrenNames
  }

  getItemsArr = (item = this, res = []) => {
    if (item.isInput) {
      res.push(item)
    }

    const { children } = item

    if (children) {
      children.forEach((item) => this.getItemsArr(item, res))
    }

    return res
  }

  get items() {
    const itemsArr = this.getItemsArr()

    return itemsArr.reduce((acc, item) => {
      if (item.isInput) {
        acc[item.fullName] = item
      }
      return acc
    }, {})
  }

  get pm() {
    return this._root && this._root.prose
  }

  get tags() {
    const { _tags } = this._root
    return _tags && _tags[this.fullName]
  }

  _attributes = {
    tabIndex: 0,
    onFocus: () => (this.isFocused = true),
    onBlur: () => (this.isFocused = false),
  }

  get attributes() {
    return this.isInput ? this._attributes : {}
  }

  //---------------------------------------------------------------------------------------------------------
  // Render
  //---------------------------------------------------------------------------------------------------------
  render = () => {
    return <Render model={this} />
  }

  //---------------------------------------------------------------------------------------------------------
  renderChildren = () => {
    let { children } = this

    if (children) {
      const currentIds = children.map((item) => item.id).join()

      let groupName = this.type ? 'item' : 'group'
      // if (this.type === 'columns') {
      //   groupName = 'section'
      // }

      return (
        <ReactSortable
          tag="ul"
          list={children}
          setList={(a, b, c) => {
            if (b && c.dragging) {
              const ids = a.map((item) => item.id).join()
              const changed = ids !== currentIds

              if (changed) {
                this.orderChildren(a)
              }
            }
          }}
          group={groupName}
          animation={200}
          selectedClass="selected"
          ghostClass="ghost"
          handle=".drag-handle"
          className="fel__children"
        >
          {children.map((item) => (
            <li className="fel__children__item" key={item.id}>
              {item.render && item.render()}
            </li>
          ))}
        </ReactSortable>
      )
    }
  }

  //---------------------------------------------------------------------------------------------------------
  // Utility functions
  //---------------------------------------------------------------------------------------------------------
  orderChildren = (list) => {
    const { children, childrenNames } = this

    if (list.length > children.length) {
      const newItem = list.find((item) => !children.includes(item))

      const { fullName } = newItem

      newItem._parent = this
      const newName = newItem.getValidName(newItem.labelName, 0, childrenNames)
      newItem._config.name = newName

      if (newName) {
        this.pm.replaceTags(fullName, newItem.fullName)
        this.pm.getTagged()
      }
    }

    this._config.children = list.map((item) => item.config)
    this._children = list
  }

  //---------------------------------------------------------------------------------------------------------
  updateChildren = (children) => {
    this._config.children = children
    this.initChildren()
  }

  //---------------------------------------------------------------------------------------------------------
  update = (values) => {
    let { label } = values
    const { fullName, parent } = this

    label = label && label.trim()
    let name = this.getAutoName(label)

    if (values.type === 'columns') {
      // values.children = [ { type: 'section', label, children: [] } ]
      name = null
      label = undefined
    }

    if (Array.isArray(values.options)) {
      values.options.forEach((item) => (item.value = item.label))
    }

    if (this.name && name) {
      const newName = [parent.fullName, name].filter(Boolean).join('.')
      this.pm.replaceTags(fullName, newName)
    }

    const config = { ...this._config, ...values, name, label }

    this._config = config

    // this.initChildren()
    // Object.assign(this._config, { ...values, name, label })
  }

  //---------------------------------------------------------------------------------------------------------
  getValidName = (name, count = 0, names = this.names) => {
    const newName = name + (count ? '_' + count : '')
    const { fullName } = this.parent

    const found = names.find((item) => item === fullName + '.' + newName)
    const valid = !found

    if (valid) {
      return newName
    }

    count++
    return this.getValidName(name, count, names)
  }

  //---------------------------------------------------------------------------------------------------------
  labelToName = (label) =>
    label &&
    label
      .replace(/[^a-z0-9]/gim, ' ')
      .split(' ')
      .map((e) => e.trim())
      .filter((e) => e)
      .map((e) => `${e[0].toUpperCase()}${e.slice(1).toLowerCase()}`)
      .join('')

  //---------------------------------------------------------------------------------------------------------
  //---------------------------------------------------------------------------------------------------------
  getAutoName(label) {
    if (label) {
      const name = this.labelToName(label)
      if (this.name !== name) return this.getValidName(name)
      return name
    }
  }

  //---------------------------------------------------------------------------------------------------------
  // Control functionality
  //---------------------------------------------------------------------------------------------------------
  append = (item = {}) => {
    const { children = [] } = this
    const name = this.getAutoName(item.label)

    const element = new JsonEditor({ ...item, name }, this, { edit: true })

    this.children = [...children, element]
    return element
  }

  //---------------------------------------------------------------------------------------------------------
  after = (item = {}) => {
    const { children } = this.parent
    const element = new JsonEditor({ type: this.type, ...item }, this.parent, { edit: true })
    const index = children.indexOf(this)

    children.splice(index + 1, 0, element)

    this.parent.children = children
  }

  //---------------------------------------------------------------------------------------------------------
  remove = () => {
    const children = this.parent.children.filter((item) => item !== this)
    this.parent.orderChildren(children)
    this.pm.replaceTags(this.fullName, '')
  }

  //---------------------------------------------------------------------------------------------------------
}

const Render = observer(({ model }) => {
  const Component = model.getComponent()
  return <Component model={model} />
})

export default JsonEditor
