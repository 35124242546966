const dic = {
  Romanian: 'Română',
  Lithuanian: 'Lituaniană',
  German: 'Germană',
  English: 'Engleză',
  Home: 'Home',
  Documents: 'Documente',
  Templates: 'Modele de contract',
  Document: 'Document',
  Negotiations: 'Negocieri',
  CompanyInfo: 'Informații companie',
  Profile: 'Profilul meu',
  Help: 'Ajutor',
  Login: 'Login',
  Logout: 'Logout',
  Remove: 'Elimină',
  Users: 'Utilizatori',
  User: 'Utilizator',
  DocumentName: 'Numele documentului',
  TemplateName: 'Numele contractului model',
  Category: 'Categorie',
  Presets: 'Pre-completare',
  Counterparty: 'Contra partidă',
  AddCounterparty: 'adaugă parte comercială',
  'Add counterparty': 'Adaugă contra parte',
  'Start new document': 'Crează un document nou',
  MyParty: 'Partea noastră',
  Elections: 'Opțiuni',
  Language: 'Limba',
  Private: 'Privat',
  Preview: 'Previzualizare',
  Updated: 'Modificat',
  Date: 'Data',
  Visited: 'Vizitat',
  Created: 'Creat',
  Draft: 'Draft',
  Inviting: 'Invitație',
  'In progress': 'În lucru',
  Execution: 'În semnare',
  Received: 'Recepționat',
  Rejected: 'Rejectat',
  CreatedBy: 'Creat de',
  Filename: 'Nume fișier',
  'Created At': 'Creat la',
  'Last update': 'Ultima modificare',
  'Last updated': 'Ultima modificare',
  'Last update by': 'Ultima modificare de',
  'Added at': 'Adăugat la',
  'Document summary': 'Sumarul documentului',
  'Compare against': 'Compară cu',
  'Turn off comparing': 'Oprește comparția',
  'Help and info': 'Info și ajutor',
  'Terms of service': 'Termeni și condiții',
  State: 'Stare',
  Size: 'Dimensiune',
  More: 'Mai multe',
  Messages: 'Mesaje',
  Highlight: 'Highlight',
  Timeline: 'Istoric',
  History: 'Istoric',
  optional: 'optional',
  Completed: 'Completat',
  Comment: 'Comentariu',
  Download: 'Descarcă',
  Matched: 'Potrivire',
  Custom: 'Particularizat',
  Empty: 'Gol',
  Name: 'Numele',
  Version: 'Versiunea',
  Password: 'Parola',
  Save: 'Salvează',
  Off: 'Oprit',
  Individual: 'Personal',
  Company: 'Companie',
  Settings: 'Setări',
  Cancel: 'Anulează',
  Send: 'Trimite',
  Agree: 'Agreează',
  Accepted: 'Acceptat',
  Progress: 'Progres',
  File: 'Fișier',
  me: 'eu',
  ago: 'în urmă',
  for: 'pentru',
  changes: 'schimbări',
  Close: 'Închide',
  Stats: 'Statistici',
  Next: 'Mai departe',
  'Is your name correct?': 'Numele este corect?',
  'Upload file': 'Încarcă un fișier',
  'You have': 'Ai',
  'Member of': 'Membru al',
  'Remove from list?': 'Elimină din listă?',
  'How to use': 'Ghid de folosire',
  'Change your logo...':'Schimbă logo-ul',
  homeScreen: {
    templatesDescription: 'Crează un nou contract pornind de la colecția de modele disponibile.',
    documentsDescription: 'Gestionează documentele, negocierile și contractele în vigoare.',
    messagesDescription: 'Gestionează ultimile notificări.',
    settingsDescription: 'Schimbă setările contului tău.',
    howToUseDescription: 'Ghid vizual pentru crearea noilor contracte, gestiunea negocierilor active și folosirea semnăturilor digitale.',
  },
  Skip: 'Continuă fară cont',
  'My position': 'Termenii mei',
  'Waiting for CP': 'Așteaptă contra partea',
  'Counterparty position': 'Termenii contra părții',
  'My party': 'Partea noastră',
  'My Party': 'Partea noastră',
  'Start new Negotiation': 'Crează o negociere nouă',
  'Document preview': 'Previzualizare document',
  'Document name': 'Numele documentului',
  'Last action': 'Ultima acțiune',
  'Last counterparty action': 'Ultima acțiune a contra părții',
  'Not selected': 'Neselectat',
  'Total users': 'Număr utilizatori',
  'Choose user': 'Alege un utilizator',
  'New user': 'Utilizator nou',
  'Assign a new user': 'Desemnează un semnatar nou',
  //-------------------------------------------------------
  // Create account
  'Choose account type': 'Alege tipul de cont',
  'Profile info': 'Informații profil',
  'Choose Preset': 'Alege Preset-ul',
  'E-mail is not valid!': 'Adresa email este invalidă!',
  'Are you sure?': 'Ești sigur?',
  'Send invitation to CP?': 'Confirmă și trimite invitația',
  'Send reminder to all Counterparty users': 'Trimite notificări tuturor utilizatorilor din echipa părții externe implicați în negociere.',
  'Send reminder': 'Trimite notificări',
  'Delete this negotiation?': 'Șterge această negocierea?',
  'Delete negotiation': 'Șterge negocierea',
  'Company permissions': 'Drepturile de acces pentru cont companie',
  'Reset password': 'Resetează parola',
  Reset: 'Resetează',
  'Confirm password': 'Confirmă parola',
  'Password have to match': 'Parolele trebuie să fie identice',
  'First name': 'Prenume',
  'Last name': 'Nume',
  'Your email': 'Adresa e-mail',
  'Minimum characters': (nr) => `Minimum ${nr} caractere!`,
  'Read our': 'Citeşte',
  'Invite user': 'Invită utilizatorul',
  'terms and conditions': 'termenii şi condiții',
  'Terms and conditions': 'Termenii şi condiții',
  'Accept terms and conditions': 'Acceptă termenii şi condițiile',
  'Create account': 'Crează un cont nou',
  'Fields to complete': 'Câmpuri necompletate',
  'Are you sure to remove?': 'Sigur dorești să elimini obiectul selectat?',
  'Receive E-mail when': 'Primește email când',
  'Download the execution version': 'Descarcă versiunea finală pentru semnare',
  'Company Info': 'Detalii companie',
  'Profile Info': 'Detalii profil',
  ca: {
    Description: 'Crează un cont nou în YumiDocs pentru adresa de email la care ai primit invitația.',
    'Individual desc': 'Crează un cont personal pentru gestiunea documentelor tale private.',
    'Company desc':
      'Contul pentru intreaga companie facilitează colaborarea şi lucrul in echipă. Cea mai bună opțiune pentru gestiunea documentelor companiei.',
  },
  'Signature disclaimer':
    'Sunt de acord și semnez. Confirm ca sunt reprezentatul legal al părții contractuale pe care o reprezint în acest contract. Sunt de acord să recunosc utilizarea semnăturii digitale și să respect termenii de utilizare YumiDocs.',
  'Sign as': 'Semnează ca',
  ' on behalf ': ' pentru ',
  'Choose signature style': 'Alege stilul semnăturii',
  'Other assigned signatories': 'Alți semnatari desemnați în contract',
  'Negotiation permissions': 'Drepturile de acces pentru negocieri',
  'Offline signature': 'Aplică o semnătura electronică calificată',
  'Can sign': 'Drept de semnatura',
  'Sign behalf': (title) => `Semnează pentru ${title}`,
  documents: {
    uploadTemplate: {
      headline: 'Încarcă un model de contract',
      descriptionL1:
        'Crează un model de contract personalizat pornind de la un document existent. Începi prin încărcarea unui document într-un format compatibil: DOC, DOCX sau PDF.',
      descriptionL2: 'Noul contract model va fi accesibil numai contului tau și companiei tale dacă există.',
    },
  },
  signatures: {
    signedBy: 'Semnat de',
    signWithESignature: 'Semnează cu Semnătură Electronică YumiDocs',
    onlineWithYumiDocs: 'Semnează online cu YumiDocs',
    downloadSignUpload: 'Descarcă PDF, semnează și încarcă',
    offlineQES: 'Semnatură calificată (offline)',
    signOffline: 'Aplică semnătura ta electronică calificată în dreptul numelui tău.',
    saveToDevice: 'Salvează fișierul PDF semnat pe dispozitivul tău sub un nume cunoscut.',
    uploadPdf: 'Incarcă fișier PDF semnat',
    differentNegotiation: 'Documentul încărcat nu corespunde negocierii curente. Încărcați documentul corect.',
    tryAnotherDocument: 'Încărcăți contractul selectat, semnat în dreptul persoanei corespunzătoare.',
    notAllowedToSign: 'Fară drept de semnătură.',
    doNotMatch: 'Semnătura încărcată nu corespunde semnatarului din document.',
    signedForCounterparty: 'Ai semnat din greșeală pentru un alt semnatar din document!',
    signatureMissing: 'Semnătura deja există!',
    importSignatureConfirmation: 'Confirm și accept noile semnături afișate mai sus',
    importSignatures: 'Importă semnăturile aplicate',
    numberOfMissingSignatures: (num) => `Am identificat ${num} semnături necesare pentru finalizarea contractului.`,
    signaturesFound: (num) => `Am identificat ${num} semnături noi.`,
    digitallySignedBy: 'Semnat electronic de ',
    noSignatoriesAssigned: 'Nici un semnatar nu a fost nominalizat',
  },
  Permissions: 'Drepturi de acces',
  'Sign on behalf': 'Semnează pentru',
  negotiationPermissions: {
    canAssignUsers: 'Administrează utilizatorii specifici contractului',
    canHide: 'Editează vizibilitatea câmpurilor de data negociabile',
    canEdit: 'Editează termenii contractelor',
    canEditDescription: 'Users can edit negotiation elections',
    canSend: 'Trimite contracte către contrapartide',
    canAgree: 'Agrează si acceptă termenii contractuali finali',
    canSign: 'Putere de semnătura contractuală',
    resetPermissionConfirmation: 'Confirmi că dorești să resetezi drepturile utilizatorului ?',
  },
  companyPermissions: {
    canAddUsers: 'Administrează utilizatori contului',
    canUpdateUsers: 'Modificare drepturilor utilizatorilor contului',
    canRemoveUsers: 'Sterge utilizatori',
    canEdit: 'Editează profilul companiei',
  },
  notifications: {
    groupLabel: 'Notificări pe email',
    inviting: 'Pentru invitațiile noi de negociere',
    accepted: 'Pentru invitațiile de negociere acceptate',
    rejected: 'Pentru invitațiile de negociere refuzate',
    addAlias: 'La primirea drepturilor de a acces pentru o negociere internă',
    receivedFromCounterparty: 'Când primesc o negociere este actualizată de contra parte',
    execution: 'Când contractul este gata de semnare',
    signed: 'Când un contract este semnat',
    signedAndCompleted: 'Când un contract este finalizat și semnat de toate parțile',
    sendReminderNote:
      'Notificările pentru această negociere se pot trimite după 24 de ore de la ultima acțiune sau notificare. Asigurați-vă că este randul contra părții să actioneze.',
  },
}
// ț  ă ş

export default dic
