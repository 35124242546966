import React from 'react'

import UploadButton from 'common/UploadButton/UploadButton'
import AsyncButton from 'common/AsyncButton/AsyncButton'

import authApi from 'api/auth'

import './LogoEditor.scss'

class LogoEditor extends React.Component {
  state = {
    scale: 1,
    zoom: 0,
    source: authApi.user.photoURL,
    x: 0,
    y: 0,
  }

  onUpload = ({ file }) => {
    this.setState({
      source: URL.createObjectURL(file),
      x: 0,
      y: 0,
      scale: 1,
      zoom: 0,
      xRatio: 0,
    })
  }

  onLoaded = ({ target }) => {
    const height = target.naturalHeight
    const width = target.naturalWidth

    let xRatio = 1
    let yRatio = 1

    if (height > width) {
      target.width = 300
      yRatio = height / width
    } else {
      target.height = 300
      xRatio = width / height
    }

    this.setState({ height, width, xRatio, yRatio })
  }

  clamp = (val, ratio, scale) => {
    const limit = (ratio * scale * 300 - 300) / 2
    const res = Math.min(Math.max(val, -limit), limit)
    return res
  }

  onScale = ({ target }) => {
    let { x, y, xRatio, yRatio } = this.state
    const zoom = target.value
    const scale = 1 + zoom / 10

    y = this.clamp(y, yRatio, scale)
    x = this.clamp(x, xRatio, scale)
    this.setState({ zoom, scale, x, y })
  }

  start = () => {
    this.setState({ drag: true })
  }

  stop = () => {
    this.setState({ drag: false })
  }

  onMove = (e) => {
    let { x, y, drag, xRatio, yRatio, scale } = this.state

    if (drag) {
      x += e.movementX
      y += e.movementY

      y = this.clamp(y, yRatio, scale)
      x = this.clamp(x, xRatio, scale)
      this.setState({ x, y })
    }
  }

  onSave = async () => {
    const { onSave = (f) => f, useBlob } = this.props
    let { x, y, xRatio, yRatio, scale } = this.state
    const height = 300 * scale * yRatio
    const width = 300 * scale * xRatio

    const el = document.createElement('canvas')
    el.height = 300
    el.width = 300
    const ctx = el.getContext('2d')

    x -= 150 * scale * xRatio - 150
    y -= 150 * scale * yRatio - 150

    ctx.drawImage(this.image, x, y, width, height)

    if (useBlob) {
      return new Promise((resolve) => {
        el.toBlob((file) => {
          const formData = new FormData()
          formData.append('file', file)

          resolve(onSave({ formData }))
        }, 'image/jpeg')
      })
    } else {
      const dataURL = el.toDataURL('image/jpeg')
      return onSave({ dataURL })
    }

    // this.setState({ outputImage: photoURL })
    // this.test.append(el)
  }

  render() {
    const { source, scale, zoom, x, y, xRatio } = this.state
    const { user } = authApi

    return (
      <div className="logo-editor">
        {source && (
          <div className="logo-editor__content">
            <div className="logo-editor__image">
              <img
                ref={(ref) => (this.image = ref)}
                src={source}
                className={xRatio && '--ready'}
                style={{ transform: `translate(${x}px, ${y}px)`, width: `${300 * scale * xRatio}px` }}
                draggable={false}
                onLoad={this.onLoaded}
                onMouseDown={this.start}
                onMouseUp={this.stop}
                onMouseMove={this.onMove}
                onMouseOut={this.stop}
                alt="User avatar"
              />
            </div>
            <div className="logo-editor__crop" />
          </div>
        )}

        {user.photoURL !== source && (
          <div>
            <input type="range" min="0" max="40" value={zoom} className="logo-editor__slider" onChange={this.onScale} />
          </div>
        )}
        <div className="logo-editor__actions">
          {user.photoURL !== source && (
            <AsyncButton className="btn" onClick={this.onSave} label="Save" useSpinner={true} />
          )}
          <UploadButton label="Upload" className="btn cancel" accept="image/*" onSelect={this.onUpload} />
        </div>

        {/* <div ref={(ref) => (this.test = ref)} /> */}
        {/* <div>
          <img src={outputImage} />
        </div> */}
        {/* <div>
          <canvas ref={(ref) => (this.canvas = ref)} width="300" height="300" />
        </div> */}
      </div>
    )
  }
}

export default LogoEditor
