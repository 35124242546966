const showIf = {
  name: 'if',

  render(el, model) {
    const obj = el && el.$obj

    const value = model.getValue(obj, 'if')
    const show = !!value

    if (obj.show !== show) {
      obj.el.remove()

      if (show) {
        obj.el = obj.copy.cloneNode(true)
        obj.el.$obj = obj
        obj.children = []

        model.parse(obj.el)
        obj.comment.after(obj.el)
      }
    }
  },
}

export default showIf
