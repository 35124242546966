import React from 'react'

const Icon = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
        <g transform="translate(6.000000, 5.000000)" fill="currentColor" fillRule="nonzero">
          <path d="M10.7621429,4.94117647 L9.28571429,4.94117647 L9.28571429,0.823529412 C9.28571429,0.370588235 8.86785714,0 8.35714286,0 L4.64285714,0 C4.13214286,0 3.71428571,0.370588235 3.71428571,0.823529412 L3.71428571,4.94117647 L2.23785714,4.94117647 C1.41142857,4.94117647 0.993571429,5.83058824 1.57857143,6.34941176 L5.84071429,10.1294118 C6.20285714,10.4505882 6.78785714,10.4505882 7.15,10.1294118 L11.4121429,6.34941176 C11.9971429,5.83058824 11.5885714,4.94117647 10.7621429,4.94117647 Z M0,13.1764706 C0,13.6294118 0.417857143,14 0.928571429,14 L12.0714286,14 C12.5821429,14 13,13.6294118 13,13.1764706 C13,12.7235294 12.5821429,12.3529412 12.0714286,12.3529412 L0.928571429,12.3529412 C0.417857143,12.3529412 0,12.7235294 0,13.1764706 Z" />
        </g>
      </g>
    </svg>
  )
}

export default Icon
