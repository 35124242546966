import React from 'react'
import Dropzone from 'react-dropzone'
import cn from 'classnames'

import app from 'api/app'

import UploadIcon from 'common/Icons/Upload'
import Spinner from 'common/Icons/spinner/Spinner'

import './UploadButton.scss'

class UploadButton extends React.Component {
  state = {}

  onDrop = async (files) => {
    const { onSubmit = (f) => f, onSelect, data } = this.props

    const [file] = files
    const form = new FormData()
    form.append('document', file)

    if (data) {
      form.append('data', data)
    }

    if (onSelect) {
      this.setState({ file })
      return onSelect({ file, form })
    }

    this.setState({ loading: true })
    const result = await onSubmit({ file, form, data })
    this.setState({ loading: false })

    if (result) {
      app.modal = { content: <pre>{JSON.stringify(result, 2, 2)}</pre> }
    }
    return result
  }

  render() {
    const { label = 'Upload', accept, className } = this.props
    const { file, loading } = this.state

    return (
      <div className={cn('upload-button', { loading })}>
        <Dropzone onDrop={this.onDrop} multiple={false} accept={accept}>
          {({ getRootProps, getInputProps }) => (
            <div className="btn-upload" {...getRootProps()}>
              <input {...getInputProps()} />
              <button className={className || 'btn'} disabled={loading}>
                {file ? 'Change' : label} {loading ? <Spinner /> : <UploadIcon />}
              </button>
            </div>
          )}
        </Dropzone>
        {file && (
          <div className="upload-button__preview">
            <h3>{file.name}</h3>
            <div className="desc">Size: {file.size}</div>
          </div>
        )}
      </div>
    )
  }
}
export default UploadButton
