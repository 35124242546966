import { observer } from 'mobx-react'
import cn from 'classnames'

import DragIcon from 'common/Icons/Drag2'
import EditIcon from 'common/Icons/Edit2'
import RemoveIcon from 'common/Icons/Delete'
import PlusIcon from 'common/Icons/PlusCircleOutline'
import SettingsIcon from 'common/Icons/Settings'

import TextIcon from 'common/Icons/TextIcon'
import TextAreaIcon from 'common/Icons/Text'
import NumbersIcon from 'common/Icons/Numbers'
import SelectIcon from 'common/Icons/DropDownIcon'
import CalendarIcon from 'common/Icons/Calendar'
import InfinityIcon from 'common/Icons/Infinity'
import H2Icon from 'common/Icons/H2'

import DropDown from 'common/DropDown/DropDown'
import Edit from '../Edit'

const icons = {
  text: TextIcon,
  textarea: TextAreaIcon,
  editor: TextAreaIcon,
  numbers: NumbersIcon,
  datePicker: CalendarIcon,
  select: SelectIcon,
  array: InfinityIcon,
  section: H2Icon,
}

const DefaultEl = ({ model }) => {
  const { label, type, renderChildren, edit, showRemove, isInput, tags = [], fullName, isFocused, attributes } = model

  const count = tags.length

  if (!type) {
    return renderChildren()
  }

  const Icon = icons[type] || SettingsIcon

  return (
    <div className={cn('fel', '--' + type)} data-name={fullName}>
      {isFocused && (
        <style>
          .document-edit__content [dr-text="{fullName}"] {`{ background: var(--dark); }`}
        </style>
      )}
      <div className="fel__content" {...attributes}>
        <div className="fel__header">
          <div className="drag-handle">
            <DragIcon />
          </div>
          {isInput && <div count={count} title={`Total ${count} tags!`} />}
          <h4 onDoubleClick={() => console.log(model)}>{label}</h4>

          <DropDown
            className="fel__settings"
            options={[
              { label: 'Edit', onClick: () => (model.edit = !edit), icon: <EditIcon /> },
              isInput
                ? {
                    label: 'New below',
                    onClick: () => model.after(),
                    icon: <PlusIcon />,
                  }
                : {
                    label: 'New child',
                    onClick: () => model.append({ type: 'text' }),
                    icon: <PlusIcon />,
                  },

              {
                label: 'Remove',
                onClick: () => (model.showRemove = !showRemove),
                icon: <RemoveIcon />,
                className: 'error2',
              },
            ]}
          >
            <span>{type}</span>
            <Icon />
          </DropDown>
        </div>
      </div>

      {edit && (
        <div className="fel__edit">
          <Edit model={model} />
        </div>
      )}

      {renderChildren()}

      {showRemove && (
        <div className="fel__remove">
          <h4>Sure?</h4>
          <div className="fel__remove__actions">
            <button className="btn cancel small" onClick={() => (model.showRemove = false)}>
              Cancel
            </button>
            <button className="btn small error" onClick={() => model.remove()}>
              Remove
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(DefaultEl)
