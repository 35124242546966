import React from 'react'
import cn from 'classnames'

import './Progress.sections.scss'

class ProgressSections extends React.Component {
  render() {
    const { done, completed, total, className } = this.props

    let percents = done === true ? 100 : done

    if (total) {
      percents = Math.round(((done || completed) / total) * 100)
    }

    let sections = []
    for (var i = 0; i < total; i++) {
      // let state = completed ? 'incomplete' : 'complete'
      let state = 'incomplete'

      if (i < completed) {
        state = 'complete'
      }

      if (i < done) {
        state = 'done'
      }

      sections.push({ state })
    }

    return (
      <div className={cn('progress-sections', className)}>
        <div className={cn('progress-sections__step', { 'multi-level': completed })}>
          {sections.map((item, key) => (
            <div key={key} className={item.state} />
          ))}
        </div>
        <div className="progress-sections__caption">
          {percents && <div className="--percents">{percents + '%'}</div>}
          {total && (
            <div className="--label">
              ( {done} / {total} )
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default ProgressSections
