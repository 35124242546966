import axios from 'axios'
import Cookies from 'js-cookie'

import app from 'api/app'

class Http {
  // baseURL: 'http://52.77.251.135:8529/_db/olesis/yumi',
  // baseURL: 'http://52.77.17.169:8529/_db/olesis/yumi',
  // baseURL: 'https://core.app.yumidocs.com/api',
  // baseURL: 'https://arangodb1.aws.nkts.lt:8530/_db/olesis/yumi',
  baseURL = (window.APP_CONFIG && window.APP_CONFIG.endpoint) || 'http://localhost:3000/api'
  axios = axios
  // baseURL: 'https://core.rc.masterorbit.com/api',

  constructor(baseURL) {
    if (baseURL) {
      this.baseURL = baseURL
      this.axios = axios.create()
    }

    this.init()
  }

  init() {
    this.axios.defaults.baseURL = this.baseURL
    delete this.axios.defaults.headers.common['authorization']

    const jwt = Cookies.get('JWT')

    this.setJwt(jwt)
    this.setInterceptors()
  }

  setJwt(jwt) {
    if (jwt) {
      try {
        Cookies.set('JWT', jwt, { sameSite: 'strict', secure: true })
        this.axios.defaults.headers.common['authorization'] = 'JWT ' + jwt
        // const apiKey = 'RoWqiCDajGqWGWcLGuNms.7rXNVmQf5VfEhAW3ROQqrK'
        // this.axios.defaults.headers.common['X-API-Key'] = apiKey

        this.validToken = true
        return true
      } catch (e) {
        this.logout()
      }
    }
    this.logout()
  }

  logout() {
    this.validToken = false
    delete this.axios.defaults.headers.common['authorization']
    Cookies.remove('JWT')
  }

  setInterceptors = () => {
    this.axios.interceptors.response.use(
      (response = {}) => {
        const { data } = response
        return data
      },
      async (error) => {
        const { response = {}, message, config = {} } = error
        let { status, data } = response
        const { url, method, baseURL } = config

        if (data && data.type === 'application/json') {
          try {
            const text = await data.text()
            data = JSON.parse(text)
          } catch (e) {
            console.error('Response is not JSON', response)
          }
        }

        const { baseUrl } = data || {}

        if (status === 401) {
          Cookies.remove('JWT')
          if (!['/login', '/token'].includes(global.location.pathname)) {
            global.location.replace(global.location.origin + '/login?target=' + global.location.pathname)
          }
          return
        }

        if (status === 403) {
          app.modal = {
            header: data.message || '403 Forbidden',
          }
        }

        if (data.message) {
          app.modal = {
            header: data.message,
          }
        }

        console.log(333, response)

        const { exception, stacktrace, errorMessage } = data
        const foxxError = data && stacktrace && ['Foxx: ' + baseUrl, 'Error: ' + exception, ...stacktrace]

        app.modal = app.modal || {
          header: message,
          text: errorMessage || ['URL: ' + url, 'Method: ' + method, 'Base URL: ' + baseURL],
          pre: foxxError,
        }
        return Promise.reject({ status, message, errorMessage, error, url, method, baseURL })
      }
    )
  }

  //-------------------------------------------------------------------------------------------
  // For API documentation
  //-------------------------------------------------------------------------------------------
  create(baseURL) {
    const instance = axios.create()
    // const jwt = Cookies.get('JWT')

    instance.defaults.baseURL = baseURL || this.baseURL
    // delete instance.defaults.headers.common['authorization']
    // const apiKey = '4rVYvNNkESytOvHBjiZACG.5rObqLLhjl4WdAxAea4UFh'
    // instance.defaults.headers.common['X-API-Key'] = apiKey

    this._instance = instance
    return instance
  }

  get instance() {
    return this._instance || this.create()
  }

  //-------------------------------------------------------------------------------------------
}

const http = new Http()
export { http as default, Http }
