import React from 'react'
import { observer } from 'mobx-react'

import Form from 'common/Form/Form'
import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import LogoIcon from 'common/Icons/Logo2'
import GmailIcon from 'common/Icons/Gmail'
import LinkedInIcon from 'common/Icons/LinkedIn'
import MicrosoftIcon from 'common/Icons/Microsoft'
import Loader from 'common/Icons/loadingBar4/LoadingBar'

import auth from 'api/auth'
import http from 'api/http'
import profileApi from 'api/profile'
import _d from 'dictionary'
import './Login.scss'

class Login extends React.Component {
  state = {}

  constructor(props) {
    super(props)
    this.checkAutoLogin()
  }

  checkAutoLogin = () => {
    const { location } = this.props
    const query = new URLSearchParams(location.search)
    const hash = query.get('hash')
    const target = query.get('target')

    if (hash) {
      this.state.autoLogin = true
      this.state.hash = hash
      this.state.target = target

      if (localStorage.autoLogin) {
        this.onAutoLogin()
      }
    }
  }

  onAutoLogin = async () => {
    const { history } = this.props
    const { hash, target } = this.state

    try {
      this.setState({ hash: false })
      await auth.autoLogin({ hash })

      history.push(target || './negotiations')
    } catch (e) {
      this.setState({ autoLogin: false })
    }
  }

  onLogin = async (model) => {
    this.setState({ loading: true })
    const { history } = this.props
    const { values } = model

    const res = await auth.login(values).catch(() => {
      model.setAnswers({ email: values.email })
      this.setState({ loading: false })
    })

    if (res) {
      const qParams = new URLSearchParams(global.location.search)
      const target = qParams.get('target')

      history.push(target || './')
    }
  }

  onAccessWithGoogle = () => {
    let nextUrl = `${http.baseURL}/auth/google/`
    window.location.replace(nextUrl)
  }

  onAccessWithLinkedin = () => {
    let nextUrl = `${http.baseURL}/auth/linkedin/`
    window.location.replace(nextUrl)
  }

  onAccessWithMicrosoft = () => {
    let nextUrl = `${http.baseURL}/auth/microsoft/`
    window.location.replace(nextUrl)
  }

  form = {
    login: {
      label: _d('Login'),
      onSubmit: this.onLogin,
      children: [
        {
          type: 'text',
          name: 'email',
          label: 'Email',
          autoFocus: true,
          input: {
            type: 'email',
          },
          validate: {
            required: true,
            type: 'email',
          },
        },
        {
          type: 'text',
          name: 'password',
          label: _d('Password'),
          input: {
            type: 'password',
            autoComplete: 'on',
          },
          validate: {
            required: true,
          },
        },
        {
          type: 'actions',
          name: 'login-actions',
          options: [
            {
              label: _d('Login'),
              loadingLabel: _d('Login...'),
              className: 'login-button',
              type: 'submit',
              validate: true,
            },
            {
              label: _d('Reset password'),
              className: 'link small',
              onClick: () => this.setState({ active: this.form.forget, message: null }),
            },
          ],
        },
      ],
    },
    forget: {
      label: _d('Reset password'),
      children: [
        {
          type: 'text',
          name: 'email',
          label: 'Email',
          autoFocus: true,
          input: {
            type: 'email',
          },
        },

        {
          content: ({ model }) => {
            const { stats, values } = model

            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            const { email } = values
            const valid = email && re.test(email.toLowerCase())
            return (
              <div className="login__actions">
                <ConfirmButton
                  confirm="Are you sure?"
                  data-id="save"
                  label="Send reset URL"
                  disabled={!stats.allCompleted || !valid}
                  onClick={async () => {
                    await profileApi.resetPassword(values)
                    this.setState({ active: this.form.login, message: 'Reset URL was send to your email!' })
                  }}
                />

                <button className="btn link" type="button" onClick={() => this.setState({ active: this.form.login })}>
                  Login
                </button>
              </div>
            )
          },
        },
      ],
    },
  }

  render() {
    const { active = this.form.login, loading, autoLogin, hash, message } = this.state

    return (
      <div className="login">
        <div className="login__aside">
          <div className="login__logo">
            <LogoIcon color="var(--lightest)" />
            <div className="login__logo__description">
              <h1>Legal contracts that grow your business.</h1>
              <p>YumiDocs automates the laborious work away.</p>
              <p>We use simple, clear and short legal language so everyone can understand it.</p>
              <p>
                The debate should be about the salient commercial terms and efficiency not commas, formatting, chasing
                emails and file management.
              </p>
            </div>
          </div>
        </div>

        <div className="login__main">
          {autoLogin && (
            <div className="login__box" style={{ justifyContent: 'space-between' }}>
              <p>You have a new pending contract. Click 'Open contract' to login</p>
              {hash ? (
                <div>
                  <button className="btn big" onClick={() => this.onAutoLogin()}>
                    Open contract
                  </button>
                </div>
              ) : (
                <div className="flex">
                  <h2>Log in... </h2>
                  <div>
                    <Loader />
                  </div>
                </div>
              )}
            </div>
          )}

          {!autoLogin && (
            <div className="login__box">
              <div className="login__tabs">
                <h1 className="active">{active.label}</h1>
              </div>

              <div>
                <Form
                  config={active.children}
                  readOnly={loading}
                  values={{}}
                  key={active.label}
                  onSubmit={active.onSubmit}
                />
              </div>
              {message && (
                <div className="form-item">
                  <div className="form-input login__actions">
                    <div className="login__message">{message}</div>
                  </div>
                </div>
              )}

              <div className="login__sso">
                <div className="login__sso__button">
                  <button className="gmail-button" onClick={this.onAccessWithGoogle}>
                    <GmailIcon />
                    <div>Continue with Google</div>
                  </button>
                </div>
                <div className="login__sso__button">
                  <button className="linkedin-button" onClick={this.onAccessWithLinkedin}>
                    <LinkedInIcon />
                    <div>Continue with LinkedIn</div>
                  </button>
                </div>
              </div>
              <div className="login__sso">
                <div className="login__sso__button">
                  <button className="microsoft-button" onClick={this.onAccessWithMicrosoft}>
                    <div>Continue with</div>
                    <div className="logo">
                      <MicrosoftIcon width="90" />
                    </div>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default observer(Login)
