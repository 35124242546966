import React from 'react'

import SignIcon from 'common/Icons/Signature'
import _d from 'dictionary'
import './OfflineSignature.scss'

const OfflineSignature = (props) => {
  const { verifiedSignature = {} } = props
  const { contact, signerCertificate = {}, date } = verifiedSignature
  const { subject = {} } = signerCertificate
  const { emailForUseInSignatures, commonName } = subject

  return (
    <div className="offline-signature">
      <div className="offline-signature__icon">
        <SignIcon />
      </div>
      <div className="offline-signature__content">
        <h3 className="offline-signature__title">{contact}</h3>
        <div>
          <label>{_d('signatures.digitallySignedBy')}</label> {commonName}
        </div>
        <div>
          <label>{_d('Email')}:</label> {emailForUseInSignatures}
        </div>
        <time>
          <label>{_d('Date')}:</label> {date}
        </time>
      </div>
    </div>
  )
}

export default OfflineSignature
