import React from 'react'
import { observer } from 'mobx-react'

import PreLoad from 'common/PreLoad/PreLoad'

import negotiationsApi from 'api/negotiations'

import Activity from './Activities.single'

import './Activities.scss'

class Activities extends React.Component {
  constructor(props) {
    super(props)
    this.preLoad = new PreLoad(this.getData)
  }

  getData = () => {
    const { negotiation } = this.props
    return negotiationsApi.getActivities(negotiation._key)
  }

  render() {
    const { model } = this.props
    const { loading, result } = this.preLoad

    return (
      loading || (
        <div>
          <ul className="activities">
            <li className="activities__item activities__today">
              <div className="activities__header">
                <div className="activities__user" />
                <div className="activities__icon">Now</div>
                <div className="activities__title" />
              </div>
            </li>
            {result.map((item, key) => <Activity key={key} item={item} model={model} />)}
          </ul>
        </div>
      )
    )
  }
}

export default observer(Activities)
