const Lithuanian = Object.freeze({
  dict: {
    units: ['nulis', 'vienas', 'du', 'trys', 'keturi', 'penki', 'šeši', 'septyni', 'aštuoni', 'devyni'],
    tens: [
      null,
      'dešimt',
      'dvidešimt',
      'trisdešimt',
      'keturiasdešimt',
      'penkiasdešimt',
      'šešiasdešimt',
      'septyniasdešimt',
      'aštuoniasdešimt',
      'devyniasdešimt',
    ],
    hundreds: ['šimtas', 'šimtai'],
    specialGroups: {
      11: 'vienuolika',
      12: 'dvylika',
      13: 'trylika',
      14: 'keturiolika',
      15: 'penkiolika',
      16: 'šešiolika',
      17: 'septyniolika',
      18: 'aštuoniolika',
      19: 'devyniolika',
    },
    scales: [
      null,
      ['tūkstantis', 'tūkstančiai', 'tūkstančių'],
      ['milijonas', 'milijonai', 'milijonų'],
      ['milijardas', 'milijardai', 'milijardų'],
    ],
  },
  options: {
    useOne: true,
    separator: ' ',
  },
})
export default Lithuanian
