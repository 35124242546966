import { useState } from 'react'
import FileSaver from 'file-saver'

import { format } from 'common/helper/date'
import DropDown from 'common/DropDown/DropDown'
import MoreIcon from 'common/Icons/More'
import EditIcon from 'common/Icons/Edit'
import ExportIcon from 'common/Icons/Download'
import DeleteIcon from 'common/Icons/Delete'

import Form from 'common/Form/Form'

import AsyncButton from 'common/AsyncButton/AsyncButton'
import UploadButton from 'common/UploadButton/UploadButton'

import documentsApi from 'api/documents'
import auth from 'api/auth'

import _d from 'dictionary'

const Settings = (props) => {
  const { mainDoc, data, preLoad } = props
  const { version, language, history } = data
  const [showDelete, setShowDelete] = useState()
  const [showEdit, setShowEdit] = useState()

  const { negotiations, presets } = history.reduce(
    (acc, item) => {
      acc.negotiations += item.totalNegotiations
      acc.presets += item.totalPresets
      return acc
    },
    { negotiations: 0, presets: 0 }
  )

  const onDelete = async () => {
    await documentsApi.deleteDocument(mainDoc._key)
    preLoad.setResult()
  }

  const config = [
    {
      type: 'text',
      label: 'Label',
      name: 'label',
    },
    {
      type: 'text',
      label: 'Category',
      name: 'category',
    },
    {
      type: 'select',
      label: 'Language',
      name: 'lang',
      options: [
        {
          label: 'English',
          value: 'en',
        },
        {
          label: 'German',
          value: 'de',
        },
        {
          label: 'Lithuanian',
          value: 'lt',
        },
        {
          label: 'Romanian',
          value: 'ro',
        },
      ],
    },
    {
      type: 'submit',
      label: 'Save',
      confirm: 'Are you sure?',
      onClose: () => setShowEdit(false),
      onSubmit: async (values) => {
        const rename = () => documentsApi.rename(mainDoc._key, values)
        await preLoad.update(rename)
      },
    },
  ]

  return (
    <div className="document-edit__box elections">
      <div className="document-edit__aside__actions">
        <DropDown
          options={[
            {
              label: 'Edit',
              icon: <EditIcon />,
              onClick: () => setShowEdit(!showEdit),
            },
            auth.isAdmin && {
              label: 'Export',
              icon: <ExportIcon />,
              onClick: () => {
                const { tpl, model, mainDoc, data } = props
                const { prose } = model
                const { label, category, lang, _id, _key } = mainDoc

                const body = {
                  label,
                  category,
                  lang,
                  _id,
                  _key,
                  pageStyle: tpl.pageStyle,
                  tpls: [
                    {
                      _id: tpl._id,
                      _key: tpl._key,
                      footer: tpl.footer,
                      body: prose.getValue(),
                      style: tpl.style && { _id: tpl.style._id },
                    },
                  ],
                  version: data.version,
                  schema: { ...model.values, validationOn: true },
                }

                const blob = new Blob([JSON.stringify(body, 2, 2)], { type: 'application/json;charset=utf-8' })
                FileSaver.saveAs(blob, label + '-v' + data.version + '.json')
              },
            },
            {
              label: 'Delete',
              className: 'error',
              icon: <DeleteIcon />,
              disabled: negotiations + presets,
              onClick: () => setShowDelete(!showDelete),
            },
          ]}
        >
          <MoreIcon />
        </DropDown>
      </div>

      <ul className="document-edit__aside__list">
        <li>
          <label>Version</label>
          <div>{version}</div>
        </li>
      </ul>

      {showEdit ? (
        <Form config={config} values={mainDoc} />
      ) : (
        <ul className="document-edit__aside__list">
          <li>
            <label>Label</label>
            <h2>{mainDoc.label}</h2>
          </li>
          <li>
            <label>Category</label>
            <div>{mainDoc.category}</div>
          </li>
          <li>
            <label>Language</label>
            <div>{language.label}</div>
          </li>
          <li>
            <label>Created</label>
            <time dateTime={mainDoc.createdAt}>{format(mainDoc.createdAt, "do 'of' MMMM yyyy @ hh:ii:ss")}</time>
          </li>
          <li>
            <label>Times changed</label>
            <div>{history.length}</div>
          </li>
          <li>
            <label>Total negotiations</label>
            <div>{negotiations}</div>
          </li>
          <li>
            <label>Total presets</label>
            <div>{presets}</div>
          </li>
          {auth.isAdmin && (
            <li>
              <label>Import</label>
              <div>
                <UploadButton
                  label={_d('Upload JSON file')}
                  onSubmit={({ file }) => {
                    const { data, preLoad } = props

                    const reader = new FileReader()

                    reader.readAsText(file)

                    reader.onload = () => {
                      const { schema, tpls } = JSON.parse(reader.result)
                      data.schema = schema
                      data.tpls[0].body = tpls[0].body

                      preLoad.loading = true
                      preLoad.setResult(data)
                    }

                    reader.onerror = () => console.log(reader.error)
                  }}
                  accept={['.json']}
                />
              </div>
            </li>
          )}
        </ul>
      )}

      {showDelete && (
        <div className="document-edit__aside__overlay">
          <h3>Are you sure?</h3>
          <div className="document-edit__aside__overlay__actions">
            <button className="btn cancel" onClick={() => setShowDelete(false)}>
              Cancel
            </button>
            <AsyncButton className="btn error" onClick={onDelete}>
              Delete
            </AsyncButton>
          </div>
        </div>
      )}
    </div>
  )
}

export default Settings
