import React from 'react'

import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import SentIcon from 'common/Icons/Sent'

import negotiations from 'api/negotiations'

const config = {
  icon: <SentIcon />,
  header: 'Send to Counterparty',
  // download: 'Download executed version',
  comment: true,
  description: [
    'Send the latest version of the document to your counter party.',
    'When there are no more objections to the document you will be able to execute the contract.',
  ],
  cp: {
    Content: () => {
      return <div />
    },
  },
  Action: ({ negotiation, model, settings, comment }) => (
    <div className="party-line__center">
      <div className="party-line__btn --right">
        <button className="btn link" onClick={() => (settings.confirmPage = null)}>
          Close
        </button>
      </div>

      <div className="party-line__btn">
        <ConfirmButton
          confirm="Send to counterparty?"
          data-id="sendToCounterparty"
          onClick={async () => {
            const { answers, form, myPartyMeta, isOwner } = model
            const body = { answers, comment }

            if (isOwner && form !== myPartyMeta.restrictions) {
              body.restrictions = form
            }

            if (negotiation.state === 'draft') {
              body.myPosition = negotiation.myParty.position
            }

            await negotiations.sendToCP(negotiation, body)
            settings.confirmPage = null
          }}
          icon={<SentIcon />}
        >
          Send to Counterparty
        </ConfirmButton>
      </div>
    </div>
  ),
}

export default config
