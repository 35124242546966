import React, { useState } from 'react'
import cn from 'classnames'

import _d from 'dictionary'

import Avatar from 'common/UserCard/Avatar'

import YesIcon from 'common/Icons/TickCircle'
import NoIcon from 'common/Icons/Cancel'

import './Settings.me.scss'

const Line = ({ value, allowed, label, hide }) =>
  !hide ? (
    <li className={cn({ 'not-allowed': !allowed })}>
      {allowed ? <YesIcon /> : <NoIcon />} {value ? _d('negotiationPermissions.' + value) : label}
    </li>
  ) : null

const Me = ({ user, signatories }) => {
  if (!user) return null

  const actionsList = [
    {
      label: _d('negotiationPermissions.canAssignUsers'),
      value: 'canAssignUsers',
    },
    {
      label: _d('negotiationPermissions.canHide'),
      value: 'canHide',
      hide: true,
    },
    {
      label: _d('negotiationPermissions.canEdit'),
      description: 'Edit negotiation',
      value: 'canEdit',
    },
    {
      label: _d('negotiationPermissions.canSend'),
      value: 'canSend',
    },
    {
      label: _d('negotiationPermissions.canAgree'),
      value: 'canAgree',
    },
    {
      label: _d('negotiationPermissions.canSign'),
      value: 'canSign',
    },
  ]

  const { displayName, email, perm = {} } = user
  const { permissions, actions, allowSignBehalf } = perm

  const isNegotiationAdmin = permissions === 'admin'

  return (
    <div className="me">
      <div className="me__avatar">
        <Avatar user={user} className="big" />
      </div>
      <div className="me__content">
        <div className="me__name">
          {displayName}
          <div className="me__email">{email}</div>
        </div>

        <div className="me__permissions">
          {isNegotiationAdmin && (
            <div className="me__permissions__list">
              <h4>
                {_d('Negotiation permissions')}: <i>Admin</i>
              </h4>
            </div>
          )}
          {actions && (
            <ul className="me__permissions__list">
              <h4>{_d('Negotiation permissions')}</h4>
              {actionsList.map((item) => (
                <Line key={item.value} {...item} allowed={actions.includes(item.value)} />
              ))}
              {allowSignBehalf &&
                allowSignBehalf.map((item) => (
                  <Line key={item.value} label={'Sign behalf of ' + item} allowed={true} />
                ))}
            </ul>
          )}
          {signatories && (
            <ul className="me__permissions__list">
              <h4>{_d('Sign on behalf')}</h4>
              {signatories.map((item) => (
                <Line key={item.name} {...item} allowed={(allowSignBehalf || []).includes(item.name)} />
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

export default Me
