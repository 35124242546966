import React from 'react'
import cn from 'classnames'

import { timeDistance, format } from 'common/helper/date'
import Avatar from 'common/UserCard/Avatar'
import ConfirmButton from 'common/ConfirmButton/ConfirmButton'
import WaitingIcon from 'common/Icons/Waiting'

import adminApi from 'api/admin'

import './Emails.scss'

class PartyUser extends React.Component {
  state = { lastMessage: this.lastMessage }

  get lastMessage() {
    const { messages } = this.props.user
    return messages && messages[0]
  }

  onResend = async () => {
    const { user } = this.props
    const message = this.lastMessage

    const id = message._from.split('/')[1]

    const { meta } = await adminApi.resend(id, user)
    user.messages.unshift(meta)
    this.setState({ lastMessage: meta })
  }

  onFixSignatures = () => {
    const { _key, user } = this.props
    return adminApi.fixSignatures(_key, user.email)
  }

  onReExecute = () => {
    const { _key, user } = this.props
    return adminApi.reExecute(_key, user)
  }

  render() {
    const { party, user, updatedAt, createdAt, timeBetweenReminders, state, signatures, notAscii } = this.props
    const { displayName, email, lastAction, messages } = user
    const { isTurn, isOwner } = party

    const { lastMessage } = this.state

    const lastUpdated = updatedAt || createdAt
    const lastEmailSent = (lastMessage && lastMessage.updatedAt) || lastUpdated

    const sentAgo = new Date().getTime() - new Date(lastEmailSent).getTime()

    const allowResend = sentAgo > timeBetweenReminders
    const showLastMessage = isTurn && lastMessage && !isOwner

    return (
      <li>
        <div className="user-card">
          <Avatar user={user} />
          <div className="user-card__name">
            {displayName}
            <div className="desc">{email}</div>
          </div>
          {[ 'execution', 'completed' ].includes(state) &&
          !signatures && (
            <div className="user-card__resend">
              <ConfirmButton className="small error" confirm="Fix?" onClick={this.onFixSignatures}>
                Fix signatures
              </ConfirmButton>
            </div>
          )}

          {notAscii && (
            <div className="user-card__resend">
              <ConfirmButton className="small error" confirm="Re-execute?" onClick={this.onReExecute}>
                Re-execute
              </ConfirmButton>
            </div>
          )}
          {showLastMessage && (
            <div className="user-card__resend">
              {allowResend ? (
                <ConfirmButton className="small" confirm="Resend?" onClick={this.onResend}>
                  Resend
                </ConfirmButton>
              ) : (
                <div>
                  <WaitingIcon />
                </div>
              )}
            </div>
          )}
          {lastEmailSent && (
            <div className="user-card__last-send">
              Last email send: <time>{timeDistance(lastEmailSent, 'ago')}</time>
            </div>
          )}
          {lastAction && (
            <div className="user-card__visited">
              <div>{lastAction.title}</div>
              <time>{timeDistance(lastAction.createdAt, 'ago')}</time>
            </div>
          )}
        </div>

        <ul className="list">
          {messages.map((item, k) => (
            <div key={k} className="message">
              <div className="--nr">{messages.length - k}</div>
              <div>
                <time>{format(item.updatedAt, "do MMMM yyyy '@' H:mm:ss")}</time>
              </div>
              <div className="--name">
                {item.subject || (
                  <div className="--warning">
                    {item.title}
                    <div className="desc">{item.message && item.message.type}</div>
                  </div>
                )}
                <div className="desc">{item.type}</div>
              </div>
              <div className={cn('--label', item.status)}>{item.status}</div>
            </div>
          ))}
        </ul>
      </li>
    )
  }
}

class PartyContent extends React.Component {
  notAscii = (str) => {
    return str && !!str.split('').find((char) => char.charCodeAt() > 127)
  }

  render() {
    const { party, signatures } = this.props

    const { displayName, users, position } = party

    const mySignatures = signatures && signatures.filter((item) => item.position === position)

    const notAscii = mySignatures && !!mySignatures.find((item) => this.notAscii(item.fieldName))

    return (
      <div className="party">
        <h3>{displayName}</h3>
        {mySignatures && <ul>{mySignatures.map((item, key) => <li key={key}>{item.fieldName}</li>)}</ul>}
        <ul>{users.map((user, key) => <PartyUser {...this.props} user={user} key={key} notAscii={notAscii} />)}</ul>
      </div>
    )
  }
}

class NegotiationContent extends React.Component {
  render() {
    const { parties } = this.props

    return (
      <div className="ani__parties">
        {parties.map((item, key) => <PartyContent {...this.props} party={item} key={key} />)}
      </div>
    )
  }
}

export default NegotiationContent
