import React from 'react'

import WaitingIcon from 'common/Icons/Waiting'

const config = {
  cpTurn_2: {
    className: 'warning',
    icon: <WaitingIcon />,
    header: 'Waiting for Counterparty',
    description: [
      'You submited your document to your counterparty.',
      'There is nothing required for you to do now. Waiting on your counter party to review the contract.',
    ],
    cp: {
      label: 'Sent',
      date: ({ negotiation: { myPartyMeta } }) => myPartyMeta.lastUpdated,
    },
  },
}

export default config
