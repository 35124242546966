import { useState } from 'react'
import Form from 'common/Form/Form'

import profileApi from 'api/profile'

import AuthPending from './Auth.pending'
import SmartIdIcon from 'common/Icons/SmartId'

const form = [
  {
    type: 'radio',
    name: 'smartIdDetails.identityType',
    label: 'Identity type',
    options: [
      {
        label: '(PAS) Passport number',
        value: 'PAS',
        children: [
          {
            type: 'columns',
            children: [
              {
                type: 'select',
                label: 'Country',
                name: 'smartIdDetails.countryCode',
                description: 'Select the country that issued your ID',
                options: [
                  {
                    label: 'Estonia',
                    value: 'EE',
                  },
                  {
                    label: 'Latvia',
                    value: 'LV',
                  },
                  {
                    label: 'Lithuania',
                    value: 'LT',
                  },
                ],
              },
              {
                type: 'numbers',
                label: 'Passport number',
                name: 'smartIdDetails.identifier',
                description: '9-14 digits',
                autoFocus: true,
                format: false,
                validate: {
                  required: true,
                  minLength: 9,
                  maxLength: 14,
                },
              },
            ],
          },
        ],
      },
      {
        label: '(IDC) National identity card number',
        value: 'IDC',
        children: [
          {
            type: 'columns',
            children: [
              {
                type: 'select',
                label: 'Country',
                name: 'smartIdDetails.countryCode',
                description: 'Select the country that issued your ID',
                options: [
                  {
                    label: 'Estonia',
                    value: 'EE',
                  },
                  {
                    label: 'Latvia',
                    value: 'LV',
                  },
                  {
                    label: 'Lithuania',
                    value: 'LT',
                  },
                ],
              },
              {
                type: 'numbers',
                label: 'National identity card number',
                name: 'smartIdDetails.identifier',
                description: '9-14 digits',
                autoFocus: true,
                format: false,
                validate: {
                  required: true,
                  minLength: 9,
                  maxLength: 14,
                },
              },
            ],
          },
        ],
      },
      {
        label: '(PNO) National personal number (national civic registration number)',
        value: 'PNO',
        children: [
          {
            type: 'columns',
            children: [
              {
                type: 'select',
                label: 'Country',
                name: 'smartIdDetails.countryCode',
                description: 'Select the country that issued your ID',
                options: [
                  {
                    label: 'Estonia',
                    value: 'EE',
                  },
                  {
                    label: 'Latvia',
                    value: 'LV',
                  },
                  {
                    label: 'Lithuania',
                    value: 'LT',
                  },
                ],
              },
              {
                type: 'numbers',
                label: 'National personal number',
                description: '9-14 digits',
                name: 'smartIdDetails.identifier',
                autoFocus: true,
                format: false,
                validate: {
                  required: true,
                  minLength: 9,
                  maxLength: 14,
                },
              },
            ],
          },
        ],
      },
    ],
    validate: {
      required: true,
    },
  },
]

const SmartIdRegister = () => {
  const [ pending, setPending ] = useState()

  const submitBtn = {
    type: 'submit',
    name: 'registerSmartId',
    onSubmit: ({ smartIdDetails }) => {
      profileApi.smartIdAuthenticate(smartIdDetails)
      setPending(true)
    },
  }

  if (pending) return <AuthPending tryAgain={() => setPending(null)} />

  return (
    <article className="smart-id__register">
      <h4 className="--label --smart-id">
        Register <SmartIdIcon />
      </h4>
      <div className="sign__content">
        <Form config={[ ...form, submitBtn ]} />
      </div>
    </article>
  )
}

export default SmartIdRegister
