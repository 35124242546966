import React from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react'

import _d from 'dictionary'

import Form from 'common/Form/Form'
import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

const TableAdd = (props) => {
  const { config, open, onClose, preLoad, data } = props
  const { columns, actions } = config

  const show = data && open

  if (!show) return null

  const { form, content, header, onSubmit, submitButton, sideContent, values, inline, left } = data

  return (
    <tbody>
      <tr>
        <td colSpan={columns.length + !!actions + 1} className="table__expand">
          <div className="table__add">
            {header && (
              <header className="table__add__header">
                <h2>{header}</h2>
              </header>
            )}
            <div className={cn('table__add__content elections', { 'form-inline': inline !== false })}>
              <div>
                {content && <div>{content(props)}</div>}
                <Form
                  values={values}
                  config={[
                    ...form,
                    {
                      Component: observer(({ model }) => {
                        const { values, stats } = model
                        const { allValid } = stats || {}

                        return (
                          <div className="form-item">
                            <div className="form-item__content">
                              {!left && <div className="form-label" />}
                              <div className="table__add__actions">
                                <button className="btn cancel" onClick={onClose}>
                                  {_d('Cancel')}
                                </button>
                                <ConfirmButton
                                  disabled={!allValid}
                                  data-id="apply"
                                  confirm={_d(submitButton || 'Are you sure?')}
                                  onClick={async () => {
                                    config.lastAdded = await onSubmit(values).catch((e) => null)
                                    config.lastAdded && preLoad.update()
                                    onClose()
                                  }}
                                >
                                  {_d(submitButton || 'Save')}
                                </ConfirmButton>
                              </div>
                            </div>
                          </div>
                        )
                      }),
                    },
                  ]}
                />
              </div>
              {sideContent && <div>{sideContent(this.props)}</div>}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  )
}

export default TableAdd
