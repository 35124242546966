import React from 'react'
import cn from 'classnames'
import { withRouter } from 'react-router-dom'

import DownIcon from 'common/Icons/Down'
import SpinnerIcon from 'common/Icons/spinner/Spinner'

import './DropDown.scss'

const Item = (props) => {
  let { item, active, onSelect, onClose } = props
  if (!item) {
    return null
  }

  if (typeof item !== 'object') {
    item = { label: item + '', value: item }
  }

  if (Array.isArray(item)) {
    const availableItems = item.filter(checkValid)
    return (
      !!availableItems.length && (
        <ul className="dropdown__group">
          {availableItems.map((child, key) => <Item key={key} {...props} item={child} />)}
        </ul>
      )
    )
  }

  if (item.Content) {
    return (
      <li className="dropdown__item" onClick={() => onSelect(item)}>
        <item.Content {...props} />
      </li>
    )
  }

  if (item.content) {
    return (
      <li className="dropdown__item" onClick={() => item.close && onClose()}>
        {item.content}
      </li>
    )
  }

  if (item.target) {
    return (
      <li className="dropdown__item">
        <div
          disabled={item.disabled}
          className={cn('dropdown__item__btn', item.className, {
            selected: !item.onClick && !item.link && item.value === active.value,
          })}
        >
          {item.icon}{' '}
          <a href={item.link} target={item.target}>
            {item.label}
          </a>
        </div>
      </li>
    )
  }

  return (
    <li className="dropdown__item">
      <div
        onClick={() => onSelect(item)}
        disabled={item.disabled}
        className={cn('dropdown__item__btn', item.className, {
          selected: (!item.onClick && !item.link && item.value === active.value) || item.selected,
        })}
      >
        {item.icon} <div>{item.label}</div>
      </div>
    </li>
  )
}

const checkValid = (item) => {
  let valid = !!item
  if (item && item.if) {
    valid = item.if(item)
  }

  return valid
}

class DropDown extends React.Component {
  state = {}

  constructor(props) {
    super(props)
    const { options } = props
    if (typeof options === 'function') {
      this.state.loading = true
      this.load()
    }
  }

  load = async () => {
    const { options } = this.props
    this._options = await options()
    this.setState({ loading: false })
  }

  get options() {
    return this._options || this.props.options || []
  }

  onSelect = (item) => {
    const { onChange, history } = this.props

    if (item.disabled) {
      return
    }

    if (item.onClick) {
      item.onClick(item)
      return this.setState({ open: false })
    }

    if (item.link) {
      history.push(item.link)
      return this.setState({ open: false })
    }

    if (onChange) {
      onChange(item)
      return this.setState({ open: false })
    }
  }

  render() {
    const { open, loading } = this.state
    const { children, value, label, className } = this.props
    const { options } = this

    if (loading) {
      return (
        <div className={cn('dropdown', className)} onClick={(e) => e.stopPropagation()}>
          {label && <label className="dropdown__label">{label}: </label>}

          <div className="dropdown__selected">
            <div className="dropdown__selected__label --loading">
              <label>
                <i>loading ...</i>
              </label>
              <SpinnerIcon />
            </div>
          </div>
        </div>
      )
    }

    let active = options.find((item) => item !== undefined && (item === value || (item && item.value === value))) || {}
    if (typeof active !== 'object') {
      active = { label: active, value: active }
    }

    return (
      <div className={cn('dropdown', className)} onClick={(e) => e.stopPropagation()}>
        {label && <label className="dropdown__label">{label}: </label>}
        <div className={cn('dropdown__selected', { open })} onClick={() => this.setState({ open: !open })}>
          {children || (
            <div className={cn('dropdown__selected__label', active.className)}>
              <div>
                {typeof active.label === 'object' ? (
                  active.label
                ) : (
                  <label>
                    {active.icon} {active.label || <i>... select ...</i>}
                  </label>
                )}
              </div>
              <DownIcon />
            </div>
          )}
        </div>

        {open && (
          <div className="dropdown__open">
            <ul className="dropdown__list">
              {options.map((item, key) => (
                <Item
                  key={key}
                  onSelect={this.onSelect}
                  onClose={() => this.setState({ open: false })}
                  item={item}
                  active={active}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }
}

const DropDownLink = withRouter(DropDown)

export { DropDown, DropDownLink as default }
