import React from 'react'
import { observer } from 'mobx-react'

import { timeDistance } from 'common/helper/date'
import Table from 'common/Table/Table'
import TickIcon from 'common/Icons/Tick'
import CloseIcon from 'common/Icons/Close'

import adminApi from 'api/admin'

class Migrations extends React.Component {
  tableConfig = {
    getData: adminApi.getMigrations,
    rowClass: ({ active }) => !active && 'cp-turn',
    columns: [
      {
        header: 'Is completed',
        content: ({ isCompleted }) => (isCompleted ? <TickIcon /> : <CloseIcon />),
      },
      {
        header: 'Migration',
        name: 'label',
        desc: 'description',
      },
      {
        header: 'Rows migrated',
        name: 'lastCompleted.rowsInserted',
      },
      {
        header: 'Last run',
        content: ({ lastRun }) => timeDistance(lastRun.createdAt, 'ago'),
      },
      {
        header: 'Status',
        content: ({ active }) => (active ? 'Active' : 'Turned off'),
      },
    ],
  }

  render() {
    return (
      <div className="page-inner">
        <h1>Migrations</h1>
        <Table config={this.tableConfig} className="dark" />
      </div>
    )
  }
}

export default observer(Migrations)
