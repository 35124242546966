import formatter from '../format/format'

const text = {
  name: 'text',

  render(el, model) {
    const obj = el && el.$obj

    const textValue = model.getValue(obj, 'text')
    const value = [ undefined, null ].includes(textValue) ? model.getValue(obj, 'secondary') : textValue

    const { format } = obj.attributes

    if (obj.value !== value) {
      obj.value = value

      let viewValue = value

      if (viewValue !== undefined) {
        if (format) {
          const { lang } = model
          const [ type, param, val ] = format.split('|')

          const options = { lang, useOne: val }

          if (type === 'toWords') {
            options.lang = param || lang
          }

          if (formatter[type]) {
            viewValue = formatter[type](value, param, options)
          }
        }

        obj.el.innerHTML = viewValue
        obj.children = []
      } else {
        obj.el.remove()
        obj.el = obj.copy.cloneNode(true)
        obj.el.$obj = obj
        obj.comment.after(obj.el)
      }
    }

    return true
  },
}

export default text
