import React from 'react'

const Icon = (props) => {
  const { value = false } = props
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24">
      {value ? (
        <path
          fill="currentColor"
          d="M10,17L5,12L6.41,10.58L10,14.17L17.59,6.58L19,8M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3Z"
        />
      ) : (
        <path
          fill="currentColor"
          d="M19,3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M19,5V19H5V5H19Z"
        />
      )}
    </svg>
  )
}

export default Icon
