import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'
import get from 'lodash.get'

import _d from 'dictionary'

import { timeDistance, format } from 'common/helper/date'
import InIcon from 'common/Icons/CornerUpLeft'
import OutIcon from 'common/Icons/CornerUpRight'
import SignIcon from 'common/Icons/Sign'
import Table from 'common/Table/Table'
import Avatar from 'common/UserCard/Avatar'

import DateTime from 'common/DateTime/DateTime'
import ProgressLine from 'common/ProgressLine/Progress.line'
import NegListItem from './ListItem/ListItem'

import negotiations from 'api/negotiations'
import getSummary from 'common/helper/summary'

import './NegotiationList.scss'
import './NegotiationList-2.scss'

class NegotiationList extends React.Component {
  total = 0
  count = []

  config = {
    async getData() {
      const res = await negotiations.getList()
      const group = {}

      res.forEach((neg) => {
        if (neg.summary) {
          neg.extra = {}
          neg.summary.forEach((item) => {
            getSummary(item, neg.myPartyMeta.answers)
            neg.extra[item.name] = item
            group[item.name] = item
          })
        }
      })

      const groupBy = Object.values(group).map(({ label, resultFormat, name }) => {
        return {
          closed: true,
          limit: 10,
          label,
          sortBy: (data) => get(data, `extra.${name}.result`),
          sortDirection: resultFormat === 'date' && 'DESC',
          value: (data) => {
            const value = get(data, `extra.${name}.result`)
            return value instanceof Date ? format(value, 'yyyy MMMM') : value === undefined ? '-- N/A --' : value + ''
          },
        }
      })

      this.groupBy = [ ...this.groupBy, ...groupBy ]
      return res
    },
    link: (item) => `/negotiations/${item._key}`,
    rowClass: ({ myParty }) => (myParty.isTurn ? 'my-turn' : 'cp-turn'),
    categories: {
      type: 'grid',
      value: 'state',
      content: (props) => <NegListItem {...props} />,
      manual: [
        { name: 'draft', label: 'Draft' },
        { name: 'inviting', label: 'Inviting' },
        { name: 'active', label: 'Active' },
        { name: 'execution', label: 'Sign' },
        { name: 'completed', label: 'Completed' },
      ],
    },

    groupBy: [
      {
        sortBy: ({ myParty, cpParty }) => (myParty.isTurn && 3) || (cpParty && cpParty.isTurn && 2) || 1,
        sortDirection: 'DESC',
        legend: true,
        label: _d('Turn'),
        value: ({ myParty, cpParty }) => {
          let label = 'Completed'
          label = (cpParty && cpParty.isTurn && 'Counterparty turn') || label
          label = (myParty.isTurn && 'My Turn') || label
          return label
        },
        view: ({ myParty, cpParty }) => {
          let label = 'Completed'
          label = (cpParty && cpParty.isTurn && 'Counterparty turn') || label
          label = (myParty.isTurn && 'My Turn') || label

          return (
            <div className="item">
              <div className={cn('title turn', { myTurn: myParty.isTurn, cpTurn: cpParty && cpParty.isTurn })}>
                {label}
              </div>
            </div>
          )
        },
      },
      {
        label: _d('Document'),
        limit: 5,
        value: 'mainDoc.label',
        view: ({ mainDoc }) => (
          <div className="item">
            <div className="title">{mainDoc.label}</div>
            <div className="desc">{mainDoc.category}</div>
          </div>
        ),
      },
      {
        label: _d('State'),
        value: 'state',
        view: ({ state }) => <div className={'state label-' + state}>{_d('stateLabel.' + state)}</div>,
      },
      {
        label: _d('Counterparty'),
        value: 'cpParty.owner._key',
        view: ({ cpParty }) =>
          cpParty ? (
            <div className="flex">
              <Avatar user={cpParty.owner} />
              <div>
                {cpParty.owner.displayName}
                <div className="desc">{cpParty.email}</div>
              </div>
            </div>
          ) : (
            <div className="item">
              {_d('-- None --')}
              <div className="desc">Counterparty not assigned</div>
            </div>
          ),
      },
      {
        label: _d('Sender'),
        value: 'myParty.isOwner',
        view: ({ myParty }) => (
          <div className="item">
            {myParty.isOwner ? (
              <div className="item__flex">
                <span className="sender-icon sender">
                  <OutIcon />
                </span>
                I am sender
              </div>
            ) : (
              <div className="item__flex">
                <span className="sender-icon">
                  <InIcon />
                </span>
                Counterparty sender
              </div>
            )}
          </div>
        ),
      },
      {
        closed: true,
        label: 'Created at',
        sortBy: ({ createdAt }) => new Date(createdAt),
        sortDirection: 'DESC',
        value: ({ createdAt }) => format(createdAt, 'yyyy MMMM'),
      },
    ],
    columns: [
      {
        header: _d('Updated'),
        width: 100,
        sortBy: ({ myPartyMeta = {}, updatedAt, createdAt }) =>
          new Date(updatedAt || myPartyMeta.updatedAt || createdAt),
        content: ({ myPartyMeta = {}, visited, updatedAt, createdAt }) => {
          const lastOpen = visited && new Date(visited.createdAt)
          const updated = new Date(updatedAt || myPartyMeta.updatedAt || createdAt)

          let flag

          if (lastOpen && updated > lastOpen) {
            flag = 'updated'
          }

          if (!lastOpen) {
            // flag = [ 'draft', 'inviting' ].includes(state) ? 'new' : 'updated'
            flag = 'new'
          }

          return (
            <div className="table__date">
              {flag && (
                <div className={cn('flag', flag)}>
                  <div className="flag__text">{flag}</div>
                </div>
              )}
              <div className={cn({ hasFlag: flag })}>
                {timeDistance(updatedAt || myPartyMeta.updatedAt || createdAt)}
                <div className="desc">{_d('ago')}</div>
              </div>
            </div>
          )
        },
      },
      {
        header: _d('Visited'),
        width: 100,
        sortBy: 'visited.createdAt',
        content: ({ visited }) => (
          <div className="table__date">
            {visited && (
              <div>
                {timeDistance(visited.createdAt)}
                <div className="desc">{_d('ago')}</div>
              </div>
            )}
          </div>
        ),
      },
      // {
      //   header: 'File',
      //   desc: 'files.0.name',
      // },
      {
        header: _d('Document'),
        width: '100%',
        desc: 'mainDoc.category',
        sortBy: 'mainDoc.label',
        content: ({ myParty, mainDoc }) => (
          <div className="negotiation-list__title">
            <h4>
              {mainDoc.label}
              <div className="version-label">v {myParty.version}</div>
            </h4>
          </div>
        ),
      },
      {
        header: _d('My Party'),
        align: 'right',
        sortBy: 'myParty.email',
        content: ({ myParty }) => (
          <div className="table__party">
            {myParty &&
            myParty.state === 'signed' && (
              <div className="table__party__icon">
                <SignIcon />
              </div>
            )}
            <div>
              <div className={cn('position', 'position-index-' + myParty.positionIndex)}>{myParty.displayName}</div>
              {myParty.owner.displayName}
              <div className="desc">{myParty.email}</div>
            </div>
          </div>
        ),
      },
      {
        content: ({ myParty }) => (
          <div className={cn('sender-icon', { sender: myParty.isOwner })}>
            {myParty.isOwner ? <OutIcon /> : <InIcon />}
          </div>
        ),
      },
      {
        header: _d('Counterparty'),
        // align: 'center',
        sortBy: 'cpParty.owner.displayName',
        content: ({ cpParty }) =>
          cpParty &&
          cpParty.owner && (
            <div className="table__party --cp">
              {cpParty.state === 'signed' && (
                <div className="table__party__icon">
                  <SignIcon />
                </div>
              )}
              <div className="table__party__user">
                <div className={cn('position', 'position-index-' + cpParty.positionIndex)}>{cpParty.displayName}</div>
                {cpParty.owner.displayName}
                <div className="desc">{cpParty.owner.email}</div>
              </div>
            </div>
          ),
      },

      {
        // header: 'Summary',
        width: 300,
        content: ({ summary }) =>
          summary &&
          summary.map(
            (item, key) =>
              item.viewValue && (
                <div className="summary-item" key={key}>
                  <label>{item.label}: </label>
                  {item.viewValue}
                </div>
              )
          ),
      },
      {
        header: _d('Created'),
        width: 100,
        align: 'center',
        sortBy: ({ createdAt }) => new Date(createdAt),
        content: ({ createdAt }) => <DateTime date={createdAt} />,
      },
      {
        header: _d('State'),
        width: 80,
        align: 'center',
        sortBy: 'state',
        content: ({ state, myPartyMeta: { stats } }) => (
          <div>
            <div className={'label-' + state}>{state}</div>
            {[ 'active', 'draft', 'inviting' ].includes(state) &&
            stats && (
              <ProgressLine done={stats.matched} total={stats.total} completed={stats.completed} className="in-table" />
            )}
          </div>
        ),
      },
    ],
  }
  render() {
    window.NegotiationList = this

    return (
      <div className="p1">
        <header className="p1__header">
          <h1>{_d('Documents')}</h1>

          {/* <div class="p1__actions">
            <button class="active">Active</button>
            <a href="/negotiations/completed">Completed</a>
          </div> */}
        </header>

        <div className="p1__content">
          <div>
            <Table config={this.config} />
          </div>
        </div>
      </div>
    )
  }
}

export default observer(NegotiationList)
