import { observer } from 'mobx-react'
import cn from 'classnames'

import ProgressCircle from 'common/ProgressCircle/ProgressCircle'

import TickIcon from 'common/Icons/TickCircle'

const Stats = ({ model }) => {
  const {
    state,
    stats: { allCompleted, allMatched, totalChanged, totalCompleted, total, totalMatched, totalInvalid } = {},
    highlight,
  } = model

  const isDraft = state === 'draft'

  const onSelect = (name) => {
    model.highlight = highlight === name ? null : name
  }

  return (
    <div className="toolbars__stats">
      {!allCompleted ? (
        <div
          onClick={() => onSelect('completed-only')}
          className={cn('toolbars__stats__tab', { active: highlight === 'completed-only' })}
        >
          <ProgressCircle done={totalCompleted} total={total} label="Completed" className="warning" />
        </div>
      ) : (
        <div className="toolbars__stats__done">
          <TickIcon /> All completed
        </div>
      )}
      {!isDraft &&
        (!allMatched ? (
          <div
            onClick={() => onSelect('matched-only')}
            className={cn('toolbars__stats__tab', { active: highlight === 'matched-only' })}
          >
            <ProgressCircle done={totalMatched} total={total} label="Matched" className="success" />
          </div>
        ) : (
          <div className="toolbars__stats__done">
            <TickIcon /> All matched
          </div>
        ))}
      {/* {!!totalChanged && (
        <div
          onClick={() => onSelect('changed-only')}
          className={cn('toolbars__stats__tab', { active: highlight === 'changed-only' })}
        >
          <ProgressCircle done={totalChanged} total={total} label="Changed" className="changed" />
        </div>
      )} */}
      {!!totalInvalid && (
        <div
          onClick={() => onSelect('error-only')}
          className={cn('toolbars__stats__tab', { active: highlight === 'error-only' })}
        >
          <ProgressCircle done={totalInvalid} total={total} label="Error" className="error" />
        </div>
      )}
    </div>
  )
}

export default observer(Stats)
