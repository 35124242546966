import React from 'react'
import { format } from 'date-fns'

import './DateTime.scss'

export class DateTime extends React.Component {
  render() {
    window.format = format
    const { date } = this.props

    if (!date) return null

    const d = new Date(date)

    const year = format(d, 'yyyy')
    const month = format(d, 'MMM')
    const day = format(d, 'd')
    // const weekDay = format(d, 'EEEE')
    const time = format(d, 'h:mmaaaa')

    return (
      <div className="date-time">
        <div className="date-time__date">
          <div className="date-time__day">{day}</div>
          <div>
            <div className="date-time__month">{month}</div>
            <div className="date-time__year">{year}</div>
          </div>

          {/* <div className="date-time__wd">{weekDay}</div> */}
        </div>

        <div className="date-time__time">{time}</div>
      </div>
    )
  }
}

export default DateTime
