import React from 'react'

const Icon = () => {
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
        <path
          d="M12,21 C7.02943725,21 3,16.9705627 3,12 C3,7.02943725 7.02943725,3 12,3 C16.9705627,3 21,7.02943725 21,12 C21,16.9705627 16.9705627,21 12,21 Z M12.5611222,11.3962264 L12.5611222,7.60621242 C12.5611222,7.27141055 12.2897117,7 11.9549098,7 C11.6201079,7 11.3486974,7.27141055 11.3486974,7.60621242 L11.3486974,11.3962264 L7.52830189,11.3962264 C7.23652881,11.3962264 7,11.6327552 7,11.9245283 C7,12.2163014 7.23652881,12.4528302 7.52830189,12.4528302 L11.3486974,12.4528302 L11.3486974,16.3937876 C11.3486974,16.7285895 11.6201079,17 11.9549098,17 C12.2897117,17 12.5611222,16.7285895 12.5611222,16.3937876 L12.5611222,12.4528302 L16.4716981,12.4528302 C16.7634712,12.4528302 17,12.2163014 17,11.9245283 C17,11.6327552 16.7634712,11.3962264 16.4716981,11.3962264 L12.5611222,11.3962264 Z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export default Icon
