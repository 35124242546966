import React from 'react'
import { observer } from 'mobx-react'

import './ErrorMessage.scss'

class ErrorMessage extends React.Component {
  render() {
    const { meta } = this.props
    const { model, validation } = meta
    const { isInvalid, errorMessage, isPristine } = validation

    return model.validationOn && isInvalid && !isPristine && meta.onBlur ? (
      <div className="error-message">
        <div>{errorMessage || 'Invalid field!'}</div>
      </div>
    ) : null
  }
}
export default observer(ErrorMessage)
