import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Form from '../../Form'
import Label from '../Label'
import Compare from '../Compare'

import DropDownSelect from 'common/DropDown/DropDown'

import './DropDown.scss'

class DropDown extends React.Component {
  onChange = ({ value }) => {
    const { meta } = this.props
    meta.setValue(value)
  }

  render() {
    let { meta = {}, readOnly } = this.props
    const {
      name,
      value,
      cpValue,
      children,
      config = {},
      changed,
      cpOnly,
      validation,
      options = [],
      isHidden,
      isRestricted,
      viewOnly,
      notEditable,
    } = meta
    const { flex = 1, className } = config

    const { isMatched, isCompleted, isChanged, isInvalid } = validation

    return (
      <div
        style={{ flex }}
        input-name={name}
        className={cn({
          isHidden,
          isRestricted,
          isMatched,
          isUnmatched: !isMatched,
          isIncompleted: !isCompleted,
          isCompleted,
          isChanged,
          isInvalid,
          viewOnly,
          notEditable,
        })}
      >
        <div className={cn('form-item form-select', { readOnly, changed, matched: isMatched, cpOnly, cpValue, value })}>
          <div className="form-item__content">
            <Label {...this.props} />
            {!cpOnly && (
              <DropDownSelect
                options={options}
                onChange={this.onChange}
                value={value}
                className={className}
                meta={meta}
              />
            )}
          </div>

          <Compare meta={meta} readOnly={readOnly || cpOnly} />
        </div>
        {children && (
          <ul>
            <li>
              <Form items={children} readOnly={readOnly} />
            </li>
          </ul>
        )}
      </div>
    )
  }
}

export default observer(DropDown)
