import React from 'react'

import auth from 'api/auth'

import Avatar from 'common/UserCard/Avatar'

import './AddComment.scss'

class AddComment extends React.Component {
  render() {
    const { user } = auth
    const { onChange } = this.props
    return (
      <div className="add-comment" data-id={'comment-to-cp'}>
        <div className="add-comment__user">
          <Avatar className="dark" user={user} />
          <div>
            <div className="label">{user.displayName || user.email}</div>
            <div className="desc">Comment</div>
          </div>
        </div>
        <div className="add-comment__content">
          <textarea defaultValue="" onChange={(e) => onChange(e.target.value)} autoFocus />
        </div>
      </div>
    )
  }
}

export default AddComment
