import axios from 'axios'

const api = {
  //-------------------------------------------------------------------------------------------
  print: (id, fileType = 'pdf', type) =>
    axios({
      method: 'GET',
      url: `/negotiations/${id}/print/${type || fileType}`,
      responseType: 'blob',
    }),

  //-------------------------------------------------------------------------------------------
  printDoc: (id, fileType = 'pdf', type) =>
    axios({
      method: 'GET',
      url: `/templates/${id}/print/${type || fileType}`,
      responseType: 'blob',
    }),

  //-------------------------------------------------------------------------------------------
  download: (id, data = {}) =>
    axios({
      method: 'POST',
      url: `/negotiations/${id}/download`,
      data,
      responseType: 'blob',
    }),

  //-------------------------------------------------------------------------------------------
  getDocx: (body) =>
    axios({
      method: 'POST',
      url: `/print/docx`,
      data: body,
      responseType: 'blob',
    }),

  //-------------------------------------------------------------------------------------------
}

export default api
