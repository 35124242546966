import React from 'react'
import cn from 'classnames'

import CloseIcon from 'common/Icons/CloseCircle'
import RightIcon from 'common/Icons/Right'
import SpinnerIcon from 'common/Icons/spinner/Spinner'
import Segment from '../SegmentIO/SegmentIO'

import './ConfirmButton.scss'

class ConfirmButton extends React.Component {
  state = { loading: false }

  onClick = async (e) => {
    const { onClick = (f) => f } = this.props

    this.setState({ loading: true })

    const promise = onClick(e)

    if (promise === Promise.resolve(promise)) {
      await promise.catch((err) => err)
      Segment.track(this.props['data-id'] || this.props.children || this.props.confirm)
    }

    if (!this.unmounted) {
      this.setState({ open: false, loading: false })
    }
  }

  componentWillUnmount() {
    this.unmounted = true
  }

  render() {
    const { open, loading } = this.state

    const { label, icon, children, className, confirm, reverse, disabled } = this.props

    return (
      <div className={cn('confirm-btn', className, { open, reverse })}>
        {loading || disabled ? (
          <button className={cn('btn', 'cancel')} disabled={true}>
            {label || children}
            {loading && [ '...', <SpinnerIcon key="spinner" /> ]}
          </button>
        ) : (
          <div className="container" onClick={() => !open && this.setState({ open: true })}>
            {open && (
              <div className="close" onClick={() => this.setState({ open: false })}>
                <CloseIcon />
              </div>
            )}
            {!open && (
              <div className="primary">
                <button
                  className={cn('btn', className)}
                  onClick={() => this.setState({ open: true })}
                  data-id={this.props['data-id']}
                  type="button"
                >
                  {label || children}
                </button>
              </div>
            )}
            {!open && (
              <div className={cn('btn-icon', className, { 'custom-icon': icon })}>{icon || <RightIcon className={className} />}</div>
            )}
            {open && (
              <div className="text" onClick={() => this.setState({ open: false })}>
                <div className="btn-icon">{icon}</div>
                {confirm}
              </div>
            )}
            {open && (
              <div className="secondary slide-in">
                <button className="btn" type="button" onClick={this.onClick} data-id={this.props['data-id'] + ':confirm'}>
                  Confirm
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }
}

export default ConfirmButton
