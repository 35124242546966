import React, { useEffect, useRef } from 'react'
import cn from 'classnames'

import './Dragon.preview.scss'
import './Dragon.document.scss'
// import './DocStyle0.scss'

const DocumentPreview = ({ result, active, negotiation }) => {
  const htmlRef = useRef()
  const footerRef = useRef()

  useEffect(
    () => {
      htmlRef.current.append(active.html)

      if (active.footer) {
        footerRef.current.append(active.footer)
      }
    },
    [ active ]
  )

  useEffect(() => active.update(result), [ result ])

  useEffect(() => active.renderSignature(negotiation), [ negotiation ])

  return (
    <div className="document-preview__document doc-style">
      {active.css && <style key="style" dangerouslySetInnerHTML={{ __html: active.css }} />}
      <div className={cn({ '--default': !active.css })} ref={htmlRef} />
      <div className="page-footer" ref={footerRef} />
    </div>
  )
}

export default DocumentPreview
