import { EditorState } from 'prosemirror-state'
import { EditorView } from 'prosemirror-view'
import { DOMParser, DOMSerializer } from 'prosemirror-model'

// import applyDevTools from 'prosemirror-dev-tools'

import { schema, editorSchema } from './EditorSchema'
import initPlugins from './Plugins'

import './ProseMirror.menu.scss'
import './ProseMirror.content.scss'

class ProseMirrorService {
  //---------------------------------------------------------------------------------------------------------
  constructor(editorRef, { value, model, isEditor, onChange }) {
    window.Mirror = this
    this.editorRef = editorRef
    this.value = value
    this.model = model
    this.isEditor = isEditor
    this.onChange = onChange

    this.init()
    this.getTagged()
  }

  //---------------------------------------------------------------------------------------------------------
  init() {
    const state = this.createState()

    const interceptTransaction = (transaction) => {
      const newState = view.state.apply(transaction)

      const value = this.getValue(newState)
      this.onChange && this.onChange(value)

      view.updateState(newState)
    }

    const view = new EditorView(this.editorRef, {
      state,
      dispatchTransaction: this.onChange && interceptTransaction,
    })

    // applyDevTools(view)

    this.view = view
  }

  get state() {
    const { state } = this.view.props
    return state
  }

  get selection() {
    return this.state.selection
  }

  get nodes() {
    return this.state.schema.nodes
  }

  //---------------------------------------------------------------------------------------------------------
  createState = (value = this.value) => {
    const { model, isEditor } = this
    const content = document.createElement('div')
    content.innerHTML = value || ''

    const currentSchema = isEditor ? editorSchema : schema

    const doc = DOMParser.fromSchema(currentSchema).parse(content)
    const plugins = initPlugins({ schema: currentSchema, model, isEditor, service: this })

    return EditorState.create({ doc, plugins })
  }

  //---------------------------------------------------------------------------------------------------------
  getValue = (state = this.state) => {
    const fragment = DOMSerializer.fromSchema(schema).serializeFragment(state.doc.content)
    let tmp = document.createElement('div')
    tmp.appendChild(fragment)
    if (tmp.childElementCount === 1 && tmp.firstChild.tagName === 'P') {
      tmp = tmp.firstChild
    }
    return tmp.innerHTML
  }

  //---------------------------------------------------------------------------------------------------------
  // getJSON = () => {
  //   const json = this.state.doc.toJSON()
  //   this.clearJSON(json)
  //   return json
  // }

  // clearJSON = (json) => {
  //   if (json.attrs) {
  //     for (const name in json.attrs) {
  //       if (json.attrs[name] === null) {
  //         delete json.attrs[name]
  //       }
  //     }
  //   }

  //   if (json.content) {
  //     json.content.forEach(this.clearJSON)
  //   }
  // }

  //---------------------------------------------------------------------------------------------------------
  update = () => {
    const { state } = this
    const DOMfrom = DOMSerializer.fromSchema(state.schema)
    const fragment = DOMfrom.serializeFragment(state.doc.content)

    let tmp = document.createElement('div')
    tmp.appendChild(fragment)

    const body = DOMParser.fromSchema(state.schema).parse(tmp)

    console.log({ fragment, DOMfrom, tmp, body, EditorState })
  }

  //---------------------------------------------------------------------------------------------------------
  updateNode = ({ posBefore, node }, attrs) => {
    const transaction = this.state.tr.setNodeMarkup(posBefore, undefined, { ...node.attrs, ...attrs })

    this.view.dispatch(transaction)
  }

  //---------------------------------------------------------------------------------------------------------
  blankNode = ({ attrs, type }, newAttrs = { drText: '' }, text) => {
    const json = {
      type: type.name,
      attrs: {
        ...attrs,
        ...newAttrs,
      },
      content: [
        {
          type: 'text',
          text,
        },
      ],
    }

    return this.state.schema.nodeFromJSON(json)
  }

  //---------------------------------------------------------------------------------------------------------
  replaceNode = ({ posBefore, posAfter, node }, attrs, text) => {
    const newNode = this.blankNode(node, attrs, text)

    const transaction = this.state.tr.replaceRangeWith(posBefore, posAfter, newNode)

    this.view.dispatch(transaction)
  }

  //---------------------------------------------------------------------------------------------------------
  replaceTags = (name, newName) => {
    const tags = this.view.dom.querySelectorAll(`[dr-text^="${name}"]`)

    tags.forEach((item) => {
      const { pmViewDesc } = item
      if (pmViewDesc) {
        const { attrs } = pmViewDesc.node
        const drText = attrs.drText.replace(name, newName)

        this.updateNode(pmViewDesc, { drText })
      }
    })
  }

  //---------------------------------------------------------------------------------------------------------
  replaceSignatures = (name, party) => {
    const drSignature = party.name

    if (name !== drSignature) {
      const tags = this.view.dom.querySelectorAll(`[dr-signature="${name}"]`)

      tags.forEach((item) => {
        const { pmViewDesc } = item
        const { node } = pmViewDesc

        let content = party.label
        if (node.attrs.drBehalf) {
          content += ' - ' + node.attrs.drBehalf
        }

        this.replaceNode(pmViewDesc, { drSignature }, '[' + content + ']')
      })
    }
  }

  //---------------------------------------------------------------------------------------------------------
  setValue = (value) => {
    const state = this.createState(value)
    this.view.updateState(state)
    const newValue = this.getValue(state)

    if (value !== newValue) {
      this.onChange(newValue)
    }
  }

  //---------------------------------------------------------------------------------------------------------
  getTagged = () => {
    if (this.model) {
      const textEl = this.view.dom.querySelectorAll(`[dr-text]`)
      const { items } = this.model

      let tags = Array.from(textEl).reduce((acc, el) => {
        const drText = el.getAttribute('dr-text')
        const drFormat = el.getAttribute('dr-format')
        const item = items[drText]

        if (!drText || drText[0] === '.' || drText[0] === '$') {
          return acc
        }

        if (!item) {
          const { pmViewDesc } = el
          if (pmViewDesc) {
            this.updateNode(pmViewDesc, { drText: '' })
          }

          return acc
        }

        const tag = { el, drText, drFormat }

        acc[tag.drText] = acc[drText] || []
        acc[tag.drText].push(tag)
        return acc
      }, {})

      const valueEl = this.view.dom.querySelectorAll(`[dr-value]`)
      tags = Array.from(valueEl).reduce((acc, el) => {
        const drValue = el.getAttribute('dr-value')
        const tag = { el, drValue }

        acc[drValue] = acc[drValue] || []
        acc[drValue].push(tag)
        return acc
      }, tags)

      this.model._tags = tags
      return tags
    }
  }

  //---------------------------------------------------------------------------------------------------------
}

export default ProseMirrorService
