import React from 'react'

import ProgressLine from './Progress.line'
import ProgressSections from './Progress.sections'

class Progress extends React.Component {
  render() {
    const { total } = this.props

    if (total < 15) {
      return <ProgressSections {...this.props} />
    }

    return <ProgressLine {...this.props} />
  }
}

export default Progress
