import React from 'react'

const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
      <g transform="translate(2.000000, 4.000000)" fill="currentColor">
        <path d="M13.3913043,7.75249713 L11.5621482,9.39130435 L9.13685188,7.17155098 C8.45389316,6.54890549 6.69054156,6.57201429 5.85038928,7.34632174 C5.01023701,8.12062918 4.7826087,9.39130435 5.54864477,10.3796891 L10.0567087,14.9619386 C10.3884576,15.2643902 11.1050183,15.5203568 11.7358775,15.4225995 C12.4037438,15.3191076 12.9869094,14.8437936 13.3381192,14.5235998 C13.3558692,14.5075341 13.3735976,14.4914875 13.3913043,14.4754599 L13.3913043,14.5043478 C13.3913043,15.8298312 12.3167877,16.9043478 10.9913043,16.9043478 L2.4,16.9043478 C1.0745166,16.9043478 1.1428709e-12,15.8298312 1.14153131e-12,14.5043478 L1.14153131e-12,2.4 C1.14136899e-12,1.0745166 1.0745166,2.2312076e-13 2.4,2.22877272e-13 L10.9913043,2.22433183e-13 C12.3167877,2.22189696e-13 13.3913043,1.0745166 13.3913043,2.4 L13.3913043,7.75249713 Z" />
        <path d="M11.4301071,11.6547825 L8.23885198,8.62011298 C7.88325498,8.28196409 7.31794692,8.28196409 6.96234992,8.62011298 C6.60675292,8.95826187 6.60675292,9.4958319 6.96234992,9.8339808 L10.7827382,13.4669138 C11.1383352,13.8050626 11.7127611,13.8050626 12.0683581,13.4669138 L21.7333022,4.28487079 C22.0888993,3.9467219 22.0888993,3.40915186 21.7333022,3.07100297 C21.3777052,2.73285408 20.8123972,2.73285408 20.4568002,3.07100297 L11.4301071,11.6547825 Z" />
      </g>
    </g>
  </svg>
)

export default Icon
