import { lazy } from 'react'

import Login from './Login/Login'
import Register from './Login/Register'
import Token from './Login/Token'
import ResetPassword from './Login/ResetPassword'

import Home from './Home/Home'
import Negotiation from './Negotiation/Negotiation'
import NegotiationList from './NegotianionList/NegotiationList'
import Dashboard from './Dashboard/Dashboard'
import Inbox from './Inbox/Inbox'

import AdminRoutes from './Admin'

import DocumentsRoutes from './Documents'
import SettingsRoutes from './Settings'
import TemplateEditorRoutes from './TemplateEditor'

import DevelopmentRoutes from './Development'

const Docs = lazy(() => import('./Docs/Docs'))
const SignPdf = lazy(() => import('./SignPdf/SignPdf'))

const routes = [
  {
    path: '/how-to-use',
    Component: Home,
  },
  {
    path: '/',
    label: 'Home',
    title: 'Dashboard',
    roles: ['user', 'admin'],
    status: ['inviting'],
    Component: Dashboard,
  },
  {
    path: '/login',
    className: 'wind-bg',
    title: 'Login',
    Component: Login,
  },
  {
    path: '/register/:code',
    Component: Register,
  },
  {
    path: '/reset-password/:code',
    Component: ResetPassword,
  },
  {
    path: '/token',
    Component: Token,
  },
  ...DocumentsRoutes,
  {
    path: '/negotiations',
    label: 'Documents',
    title: 'Negotiation List',
    // count: 'isNew',
    roles: ['user', 'admin'],
    status: ['inviting'],
    Component: NegotiationList,
    children: [
      {
        path: '/negotiations/:id/:d?',
        label: 'Document',
        analytics: false,
        Component: Negotiation,
      },
    ],
  },
  {
    path: '/negotiations/:id',
    Component: Negotiation,
  },
  {
    // label: 'Sign PDF (Admin)',
    path: '/sign-pdf',
    Component: SignPdf,
    roles: ['api'],
  },
  {
    // label: 'Inbox',
    path: '/inbox',
    roles: ['user', 'admin'],
    Component: Inbox,
  },
  TemplateEditorRoutes,
  SettingsRoutes,
  DevelopmentRoutes,
  AdminRoutes,
  {
    path: '/docs/:cat?',
    Component: Docs,
    roles: ['api'],
  },
]

export default routes
