import React from 'react'
import { observer } from 'mobx-react'

import Form from 'common/Form/Form'
import PreLoad from 'common/PreLoad/PreLoad'

import LogoIcon from 'common/Icons/Logo2'
import GmailIcon from 'common/Icons/Gmail'
import LinkedInIcon from 'common/Icons/LinkedIn'
import WarningIcon from 'common/Icons/Warning'

import auth from 'api/auth'

import './Login.scss'

class Register extends React.Component {
  constructor(props) {
    super(props)
    this.preLoad = new PreLoad(this.getData)
  }

  getData = async () => {
    const { params } = this.props.match
    return auth.getUserByCode(params.code)
  }

  onRegister = async (values) => {
    const { history, match: { params } } = this.props
    await auth.registerWithCode(params.code, values)
    history.push('/')
  }

  form = [
    {
      type: 'text',
      name: 'firstName',
      label: 'First Name',
    },
    {
      type: 'text',
      name: 'lastName',
      label: 'Last Name',
    },
    {
      name: 'email',
      Component: ({ value }) => {
        return (
          <div className="form-item">
            <div className="form-item__content">
              <label className="form-label">Email</label>
              <div className="form-input">
                <h2 className="form-input__value">{value}</h2>
              </div>
            </div>
          </div>
        )
      },
    },
    {
      type: 'text',
      name: 'password',
      label: 'Password',
      input: {
        type: 'password',
      },
    },
    {
      type: 'text',
      name: 'confirmPassword',
      label: 'Confirm Password',
      input: {
        type: 'password',
      },
    },

    {
      Component: ({ values }) => {
        return (
          <div className="form-item">
            <div className="form-input login__actions">
              <button className="btn link" onClick={() => this.props.history.push('/login')}>
                Login
              </button>
              <button className="btn" onClick={() => this.onRegister(values)}>
                Register
              </button>
            </div>
          </div>
        )
      },
    },
  ]

  render() {
    window.Register = this
    const { loading, result } = this.preLoad

    return (
      loading || (
        <div className="login">
          <div className="login__aside">
            <div className="login__logo">
              <LogoIcon color="var(--lightest)" />
              <p>
                We use simple general legal language so everyone can understand it. The debate should be about the
                salient terms not commas, formatting and spaces.
              </p>
            </div>
          </div>

          <div className="login__main">
            {result ? (
              <div className="login__box">
                <div className="login__tabs">
                  <h1 className="active">Register</h1>
                  {/* <h1 onClick={() => this.setState({ tab: 'login' })}>Login</h1> */}
                </div>

                <div>
                  <Form config={this.form} values={result} />
                </div>

                <div className="login__sso">
                  <div className="login__sso__button">
                    <button className="gmail-button" onClick={() => auth.gmailLogin()}>
                      <GmailIcon /> Login with Gmail
                    </button>
                  </div>
                  <div className="login__sso__button">
                    <button className="linkedin-button" onClick={() => auth.linkedinLogin()}>
                      <LinkedInIcon /> Login with LinkedIn
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="login__box">
                <div className="form-item">
                  <div className="form-input login__actions">
                    <div className="login__warning">
                      <WarningIcon /> Code is not valid!
                    </div>
                    <button className="btn" onClick={() => this.props.history.push('/login')}>
                      Login
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )
    )
  }
}

export default observer(Register)
