import { useState } from 'react'
import cn from 'classnames'

import HelpIcon from 'common/Icons/Help'

import '../Toolbar/Toolbar.scss'
import './Toolbars.scss'

const info = {
  draft: [
    'The parties of the agreement are defined below. Which side of the negotiation are you?',
    `The elections and all signature blocks rely on the correct assignment for parties' positions.`,
    `Assign your party position below.`,
    'Once sent to the counterparty, the parties for this document cannot be changed.',
    'If you made a mistake, delete the current document using the Settings. Then you can send a new document with the correct information.',
  ],
  active: [
    'Once sent to the counterparty, the parties for this document cannot be changed.',
    'If you made a mistake, delete the current document using the Settings. Then you can send a new document with the correct information.',
  ],
}

const Ul = ({ list }) => <ul className="help-box__list">{list.map((text, key) => <li key={key}>{text}</li>)}</ul>

const PositionsToolbar = ({ nr, negotiation = {} }) => {
  const { state, doc } = negotiation

  const singleParty = doc.schema.positions.length === 1

  const completed = state !== 'draft' || singleParty
  const [ showHelp, setShowHelp ] = useState(!completed)

  const help = info[state] || info.active
  const isDraft = state === 'draft'

  return [
    <div className={cn('toolbars', '--nr-' + nr, { completed })} key="toolbar">
      {/* {nr && <div className="toolbars__nr">{nr}</div>} */}

      <div className="toolbars__content">
        {singleParty ? (
          <div>
            <h3>Position</h3>
            <div className="desc">Single party</div>
          </div>
        ) : (
          <div>
            <h3>Position and Counterparty</h3>
            <div className={cn('desc', { isDraft })}>{isDraft ? 'Chose counterparty and position' : 'Completed'}</div>
          </div>
        )}
      </div>
      <button className="btn help-btn" onClick={() => setShowHelp(!showHelp)}>
        <HelpIcon />
      </button>
    </div>,

    !singleParty && (
      <div key="help-box">
        {showHelp && (
          <div className="help-box">
            <div>
              <Ul list={help} />
              <div className="help-box__actions">
                <button className="btn link" onClick={() => setShowHelp(false)}>
                  close
                </button>
              </div>
            </div>
            {/* <HelpIcon /> */}
          </div>
        )}
      </div>
    ),
  ]
}

export default PositionsToolbar
