import React from 'react'
import { observer } from 'mobx-react'

import Tiles from './Tiles/Tiles'

import CreateNewIcon from 'common/Icons/CreateNew'
import NegotiateIcon from 'common/Icons/Negotiate'
import ESignIcon from 'common/Icons/ESign'
import AnalyseIcon from 'common/Icons/Analyse'
import './Dashboard.scss'

class Dashboard extends React.Component {
  render() {
    return (
      <div className="dashboard">
        <div className="intro">
          <div className="intro__container">
            <h1>Steps to create a new contract</h1>
            <div className="intro__step">
              <div className="intro__nr">1.</div>
              <div className="intro__text">
                <h3>Select a contract template from the Templates Library</h3>
                <ul>
                  <li>Add your counterparty.</li>
                  <li>Fill in the blank sections.</li>
                  <li>Reutilise existing precedents.</li>
                  <li>Add drafting notes and comments.</li>
                  <li>Send to your counterparty.</li>
                </ul>
              </div>

              <CreateNewIcon />
            </div>

            <div className="intro__step">
              <div className="intro__nr">2.</div>
              <div className="intro__text">
                <h3>Negotiate, agree</h3>
                <ul>
                  <li>If any, mark appropriate fields as non-negotiable.</li>
                  <li>Sign right away if terms are agreed by all parties.</li>
                  <li>
                    Negotiate with your counterparty.
                    <ul>
                      <li>Automatic term comparison and blacklining.</li>
                      <li>Track changes.</li>
                      <li>Automatic versioning and history tracking.</li>
                      <li>Provide drafting notes and comments during negotiation.</li>
                      <li>Download any time PDF or DOCX version of the latest version.</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <NegotiateIcon />
            </div>

            <div className="intro__step">
              <div className="intro__nr">3.</div>
              <div className="intro__text">
                <h3>Sign</h3>
                <ul>
                  <li>Execute immediately using YumiDocs E-Signature.</li>
                  <li>
                    Sign on your device with a Qualified Electronic Signature.
                    <ul>
                      <li>Download and sign offline, on any device, using your Qualified Electronic Signature.</li>
                      <li>Upload the signed contract back into YumiDocs.</li>
                    </ul>
                  </li>
                </ul>
              </div>
              <ESignIcon />
            </div>

            <div className="intro__step">
              <div className="intro__nr">4.</div>
              <div className="intro__text">
                <h3>Manage, analyse, review</h3>
                <ul>
                  <li>Sort, filter, group contracts based on the salient terms.</li>
                  <li>Download the final versions and audit trails.</li>
                  <li>Manage expiration dates and T&C without opening any PDF files.</li>
                </ul>
              </div>
              <AnalyseIcon />
            </div>
          </div>
        </div>

        <div className="dashboard__actions">
          <h1>Menu</h1>
          <Tiles />
        </div>
      </div>
    )
  }
}

export default observer(Dashboard)
