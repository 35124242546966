import React from 'react'
import { observer } from 'mobx-react'

import AsyncButton from 'common/AsyncButton/AsyncButton'
import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

class Submit extends React.Component {
  render() {
    const { meta = {} } = this.props
    const { config, model } = meta
    const { onClose, onSubmit, confirm, label } = config

    const { allValid } = model.stats

    const Button = confirm ? ConfirmButton : AsyncButton

    return (
      <div className="form-item__content">
        <div className="table__add__actions">
          {!!onClose && (
            <button className="btn cancel" onClick={onClose}>
              Cancel
            </button>
          )}
          <Button
            disabled={!allValid}
            data-id="submit-btn"
            confirm={confirm}
            className={!confirm && 'btn'}
            onClick={async () => {
              config.lastAdded = await onSubmit(model.values, model, meta)
              onClose && onClose()
            }}
          >
            {label || 'Save'}
          </Button>
        </div>
      </div>
    )
  }
}

export default observer(Submit)
