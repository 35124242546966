import { observer } from 'mobx-react'
import cn from 'classnames'

import RemoveIcon from 'common/Icons/Delete'
// import SettingsIcon from 'common/Icons/Edit'
// import CreateIcon from 'common/Icons/CreatePreset'

import InlineTags from './Inline.tags'
import TagSettings from './Inline.settings'
import InlineIf from './Inline.if'

import './Inline.scss'

const InlineMenu = (props) => {
  const { actions } = props
  const { node, open, isTagged } = props.settings
  const { isBlock, attrs = {} } = node || {}

  const isSignature = !!attrs.drSignature
  const drText = attrs.drText

  const name = node && node.type && (node.type.spec.label || node.type.name)

  return open ? (
    <div className={cn('inline-menu', { isBlock })}>
      {/* <ul className="inline-menu__list">
        {items.map(({ icon, title, isActive, isDisabled, run }, key) => (
          <li
            key={key}
            title={title}
            className={cn('inline-menu__item', { active: isActive, disabled: isDisabled })}
            onClick={run}
          >
            {icon ? (
              icon.text || (
                <svg width="16" height="16" viewBox={`0 0 ${icon.width} ${icon.height}`}>
                  <path d={icon.path} fill="currentColor" />
                </svg>
              )
            ) : (
              <div>{title[0]}</div>
            )}
          </li>
        ))}
      </ul> */}
      <div className="inline-menu__node-name">{name}</div>
      <ul className="inline-menu__list inline-menu__dr">
        {isTagged && (
          <li className="inline-menu__item" onClick={() => actions.replaceToText()}>
            <RemoveIcon />
          </li>
        )}
        {drText && !isSignature && <TagSettings {...props} />}

        {isBlock ? <InlineIf label="IF" {...props} /> : <InlineTags label="Tag" {...props} />}
      </ul>
    </div>
  ) : null
}

export default observer(InlineMenu)
