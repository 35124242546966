import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import cn from 'classnames'

import SmartIdIcon from 'common/Icons/SmartId'
import SpinnerIcon from 'common/Icons/spinner/Spinner'

import negotiationApi from 'api/negotiations'
import sse from 'api/sse'

import _d from 'dictionary'

const SignPending = ({ behalf, behalfLabel, style, displayName, tryAgain }) => {
  const [ notification, setNotification ] = useState({})
  const { id } = useParams()

  useEffect(
    () =>
      sse.on('sign', ({ data, id: negId }) => {
        if (id === negId && data && data.body && data.body.signBehalf === behalf) {
          setNotification(data)

          if ([ 'SIGNED', 'SIGNED_BY_OTHER' ].includes(data.status)) {
            setTimeout(() => negotiationApi.getOne(id), 2000)
          }
        }
      }),
    [ behalf, id ]
  )

  const { status, result, verificationCode, message } = notification
  const state = (result && result.endResult) || status

  return (
    <article className="sign__pending">
      <div className="my-signature__actions">
        <div className="--label --smart-id">
          Sign with <SmartIdIcon /> {behalf ? _d(' on behalf ') + (behalfLabel || behalf) : ''}
        </div>

        <div className={cn('--preview', style)}>{displayName}</div>
      </div>
      <div className={cn('sign__content', state)}>
        {message && <div className="--message">{message}</div>}
        <h4>
          {state ? _d('signCodes.' + state) : 'Connecting...'}
          <button className="btn" onClick={tryAgain}>
            Try again
          </button>
        </h4>
        {verificationCode && (
          <div className="flex">
            <div className="--code">{verificationCode}</div>
            <SpinnerIcon />
          </div>
        )}
      </div>
    </article>
  )
}

export default SignPending
