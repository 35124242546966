import axios from 'axios'

import authApi from './auth'
import http from './http'

const api = {
  update: async (body) => {
    const res = await axios.put(`/profile`, body)
    // Object.assign(authApi.user, res)
    authApi.user = res
    return res
  },
  createAccount: (body) => (body.hash ? axios.post(`/create-account`, body) : axios.put(`/create-account`, body)),
  resetPassword: (user) => {
    return user ? axios.post(`/reset-password`, user) : axios.get(`/reset-password`)
  },

  saveImage: (body) => axios.post(`/images`, { ...body, baseURL: http.baseURL }),
  saveImageFile: (body) => axios.post(`/images`, body),

  smartId: () => axios.get(`/smartid`),
  smartIdAuthenticate: (data) => axios.post(`/smartid/authenticate`, data),
  smartIdRemove: () => axios.delete(`/smartid`),
}

export default api
