import axios from 'axios'

const api = {
  getList: (docId) => axios.get(`/documents/${docId}/presets`),
  getMainList: (docId) => axios.get(`/documents/main/${docId}/presets`),
  getOne: (id) => axios.get(`/presets/${id}`),
  create: (docId, body) => axios.post(`/documents/${docId}/presets`, body),
  update: (id, body) => axios.put(`/presets/${id}`, body),
  remove: (id) => axios.delete(`/presets/${id}`),
}

export default api
