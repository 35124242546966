import axios from 'axios'

const api = {
  getList: (library) => axios.get(`/templates${library ? '/public' : ''}`),
  getOne: (id) => axios.get(`/templates/${id}`),
  uploadPersonal: (formData) => axios.post('/documents/personal', formData),
  listPersonal: () => axios.get('/documents/personal'),
  adminListDocuments: () => axios.get('/documents/admin'),

  updateTemplate: (id, data) => {
    console.log(data)
    // axios.put(`/templates/${id}`, data)
  },
  template: ({
    content,
    footer,
    label,
    category,
    lang,
    style,
    pageStyle,
    permissions,
    schema: { children, positions, validationOn } = {},
  }) => ({
    publish: (id) =>
      axios.put(`/templates/${id}`, { content, pageStyle, schema: { children, positions, validationOn } }),

    saveAs: () =>
      axios.post(`/templates`, {
        content,
        footer,
        schema: { children, positions, validationOn },
        label,
        category,
        lang,
        style,
        pageStyle,
        permissions,
      }),
  }),

  deleteDocument: (id) => axios.delete(`/templates/${id}`),
  rename: (id, body) => axios.patch(`/templates/${id}`, body),
}

export default api
