import { decorate, observable, toJS } from '../mobx'

import FormItem from './form.item'

class Model {
  constructor(schema, answersSet = {}, options = {}) {
    global.Model = this
    // console.time('Form Model #3')

    const { state, myParty, cpParty, myPartyMeta = {}, restrictions } = options

    this.schema = schema
    this.config = schema.children

    this.options = options
    this.rootData = options

    this.form = myPartyMeta.restrictions || restrictions

    this._state = state
    this.myParty = myParty
    this.myPartyMeta = myPartyMeta
    this.cpParty = cpParty

    const { primary = {}, original = {} } = answersSet
    const { answers = {} } = primary

    this.answersSet = answersSet
    this.primary = primary

    this.original = JSON.parse(JSON.stringify(original.answers || answers))
    this.answers = answers

    this.setCompare()
    this.init()

    decorate(this, {
      config: observable.ref,
      answers: observable,
      highlight: observable,
      settings: observable,
      editor: observable.ref,
      form: observable.ref,
      _state: observable,
    })

    // console.timeEnd('Form Model #3')
  }

  restrictedStats = {}

  isAllowed(name) {
    const { me } = this.rootData
    if (me.permissions === 'write') {
      me.actions = [ 'canEdit', 'canSend', 'canAgree' ]
    }
    return !me._key || (me.actions && me.actions.includes(name)) || me.permissions === 'admin'
  }

  get noCompare() {
    return this.schema.noCompare || this.state === 'draft'
  }

  get validationOn() {
    return this.schema.validation || this.schema.validationOn
  }

  get validation() {
    return this.root && this.root.validation
  }

  get stats() {
    return this.root && this.root.stats
  }

  //---------------------------------------------------------------------------------------------------------
  init(config = this.config) {
    this.root = new FormItem({ children: config, root: true, type: '__ROOT__' }, { model: this })
  }

  //---------------------------------------------------------------------------------------------------------
  _lastValues = {}
  _compareBy = 'cpPosition'

  conditions = []
  checkConditions = () => {
    this.conditions.forEach((item) => item.checkConditions())
  }

  onKeyDown = () => {}
  //---------------------------------------------------------------------------------------------------------

  //---------------------------------------------------------------------------------------------------------
  setAnswers(answers) {
    this.answers = JSON.parse(JSON.stringify(answers || '{}'))
    this.root.update()
    // this.getStats()
  }

  setValues = (values) => this.setAnswers(values)

  setState(state) {
    this._state = state
  }

  get children() {
    return this.root && this.root.children
  }

  get values() {
    return toJS(this.answers)
  }

  get openAll() {
    return !this.root.children.find((item) => !item.open)
  }

  get state() {
    return this._state
  }

  get isOwner() {
    return this.myParty && this.myParty.isOwner
  }

  get haveSignature() {
    const { signatures, myParty = {} } = this.rootData
    return signatures && myParty.position && !!signatures.find((item) => item.position === myParty.position)
  }

  //---------------------------------------------------------------------------------------------------------
  setCompare(name = this._compareBy) {
    const compare = name && name.answers ? name : this.answersSet[name] || {}

    this.compare = compare
    this.compareAswers = compare.answers
  }

  //---------------------------------------------------------------------------------------------------------
  copyOriginal() {
    this.original = toJS(this.values)
    this.getStats()
  }

  //---------------------------------------------------------------------------------------------------------
  getStats() {
    if (this.root && this.root.info) {
      this.root.info.updateDown()
    }
  }

  setForm = (form) => {
    this.form = form && { ...form }
  }

  //---------------------------------------------------------------------------------------------------------
  toggle(state) {
    this.root.children.forEach((item) => item.setOpen(state))
  }

  //---------------------------------------------------------------------------------------------------------
  //  Template Editor
  //---------------------------------------------------------------------------------------------------------

  getRestricted() {
    const { items } = this
    this._restricted = Object.values(items).filter((item) => !item.isEditable)
    return this._restricted
  }

  get items() {
    this._items = {}
    return this.getItems(this.children) || []
  }

  _items = {}
  getItems = (children) => {
    if (children) {
      children.forEach((item) => {
        if (item.name) {
          this._items[item.name] = item
        }

        if (item.children) {
          this.getItems(item.children)
        }
      })
      return this._items
    }
  }

  //---------------------------------------------------------------------------------------------------------
}

export default Model
