import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import ToggleButton from 'common/ToggleButton/ToggleButton'

import Form from '../../Form'
import Label from '../Label'
import Compare from '../Compare'

class Toggle extends React.Component {
  onChange = (value) => {
    const { meta } = this.props
    const { children } = meta.config

    if (children) {
      if (value) {
        value = this.val
      } else {
        this.val = meta.value
      }
    }

    meta.setValue(value || undefined)
  }

  val = {}

  render() {
    const { meta = {}, readOnly } = this.props
    const { changed, unmatched, opt = { myPosition: true }, value, children } = meta

    return (
      <div>
        <div className={cn('form-item', { readOnly, changed, unmatched, cpOnly: !opt.myPosition })}>
          <div className="form-item__content">
            <Label {...this.props} />
            <ToggleButton value={value} onChange={this.onChange} />
          </div>
          <Compare meta={meta} readOnly={readOnly || !opt.myPosition} />
        </div>
        {value &&
        children && (
          <ul>
            <li>
              <Form items={children} />
            </li>
          </ul>
        )}
      </div>
    )
  }
}

export default observer(Toggle)
