const Icon = () => (
  <svg width="72" height="22" viewBox="0 0 72 22" fill="none" xmlns="http://www.w3.org/2000/svg" role="figure">
    <path
      d="M13.0412 7.68712C13.4793 7.68712 13.8344 7.33199 13.8344 6.89392C13.8344 6.45584 13.4793 6.10071 13.0412 6.10071C12.6031 6.10071 12.248 6.45584 12.248 6.89392C12.248 7.33199 12.6031 7.68712 13.0412 7.68712Z"
      fill="currentColor"
    />
    <path
      d="M11 0C10.2651 0 9.5419 0.0699887 8.83034 0.209966C8.53872 0.26829 8.32876 0.524915 8.32876 0.8282V15.2459C8.32876 15.5958 8.60871 15.8758 8.95866 15.8758C9.3086 15.8758 9.58856 15.5958 9.58856 15.2459V1.35312C10.0551 1.28313 10.5334 1.24813 11 1.24813C16.3658 1.24813 20.7401 5.61076 20.7401 10.9882C20.7401 14.0677 19.2587 17.0073 16.7624 18.8386C16.4824 19.0486 16.4241 19.4335 16.6224 19.7252C16.7507 19.8885 16.9374 19.9818 17.1357 19.9818C17.264 19.9818 17.3923 19.9468 17.509 19.8535C20.3202 17.7888 21.9999 14.476 21.9999 10.9882C21.9882 4.9342 17.0657 0 11 0Z"
      fill="currentColor"
    />
    <path
      d="M13.0412 10.9652C12.6913 10.9652 12.4113 11.2451 12.4113 11.5951V20.6236C11.9447 20.6936 11.4665 20.7286 10.9999 20.7286C5.63409 20.7286 1.2598 16.366 1.2598 10.9885C1.2598 7.90899 2.74122 4.96947 5.23749 3.1381C5.51744 2.92813 5.57577 2.53153 5.37747 2.25157C5.1675 1.97162 4.7709 1.91329 4.49094 2.1116C1.67973 4.19959 0 7.51239 0 11.0002C0 17.0659 4.9342 22.0001 10.9999 22.0001C11.7348 22.0001 12.458 21.9301 13.1695 21.7901C13.4612 21.7318 13.6711 21.4751 13.6711 21.1719V11.6067C13.6711 11.2568 13.3912 10.9652 13.0412 10.9652Z"
      fill="currentColor"
    />
    <path
      d="M28.1939 10.4164C27.1324 10.2648 26.9224 10.0198 26.9224 9.51822C26.9224 9.05162 27.284 8.71335 27.9722 8.71335C28.6721 8.71335 29.0454 8.98164 29.1504 9.63486H30.2002C30.0836 8.45672 29.2904 7.86182 27.9722 7.86182C26.7008 7.86182 25.8376 8.62003 25.8376 9.61153C25.8376 10.7547 26.5258 11.2096 27.9956 11.3962C28.9871 11.5596 29.267 11.7695 29.267 12.3411C29.267 12.901 28.7888 13.2859 28.1122 13.2859C27.0741 13.2859 26.8291 12.7727 26.7474 12.1545H25.6626C25.7443 13.3093 26.4558 14.1375 28.1239 14.1375C29.477 14.1375 30.3752 13.3093 30.3752 12.2478C30.3752 11.023 29.6286 10.6147 28.1939 10.4164Z"
      fill="currentColor"
    />
    <path
      d="M35.6476 12.108L34.0379 7.93201H32.5098V14.056H33.5363V9.4251L35.4027 14.056H35.7643L37.5957 9.4251V14.056H38.7271V7.93201H37.2224L35.6476 12.108Z"
      fill="currentColor"
    />
    <path
      d="M42.7398 7.93201L40.6867 14.056H41.7599L43.4163 8.86519L44.2562 11.5014H43.5213L43.2297 12.388H44.5361L45.0727 14.056H46.2509L44.2329 7.93201H42.7398Z"
      fill="currentColor"
    />
    <path d="M58.8022 7.93201H54.2413V8.8302H55.9444V14.056H57.0992V8.8302H58.8022V7.93201Z" fill="currentColor" />
    <path d="M64.0163 7.93201H62.8731V14.056H64.0163V7.93201Z" fill="currentColor" />
    <path d="M60.9834 10.813H58.6854V11.6996H60.9834V10.813Z" fill="currentColor" />
    <path
      d="M68.3556 7.93201H66.5009V14.056H67.6441V13.1578V8.8302H68.309C69.7438 8.8302 70.3503 9.6234 70.3503 10.9415V11.0115C70.3503 12.1547 69.8721 12.8779 68.9156 13.0878V14.021C70.6886 13.8111 71.5401 12.6213 71.5401 11.0115V10.9299C71.5285 9.14514 70.4903 7.93201 68.3556 7.93201Z"
      fill="currentColor"
    />
    <path
      d="M52.5498 9.76338V9.72838C52.5498 8.48025 51.605 7.93201 50.2635 7.93201H48.2105V14.056H49.3537V8.81853H50.2752C50.9984 8.81853 51.4417 9.06349 51.4417 9.76338V9.79837C51.4417 10.4516 51.045 10.7782 50.2752 10.7782H50.0885V11.5948L51.6866 14.0677H52.9231L51.1967 11.4198C51.9549 11.2098 52.5498 10.7199 52.5498 9.76338Z"
      fill="currentColor"
    />
  </svg>
)

export default Icon
