import { observer } from 'mobx-react'
import { createParagraphNear } from 'prosemirror-commands'

import { DropDown } from 'common/DropDown/DropDown'

const TableMenu = ({ editorView, settings }) => {
  const { node } = settings

  const { attrs = {} } = node

  const update = (newAttrs = {}) => {
    const { state } = editorView.props
    const { selection } = state

    if (node.attrs) {
      const transaction = state.tr.setNodeMarkup(selection.from, undefined, { ...node.attrs, ...newAttrs })
      editorView.dispatch(transaction)
    }
  }

  const dataBorder = [
    { label: 'Solid', value: null },
    { label: 'Dashed', value: 'dashed' },
    { label: 'Dotted', value: 'dotted' },
    { label: 'None', value: 'none' },
  ]

  return settings.open ? (
    <div className="block-menu">
      <div className="block-menu__item">
        <div className="block-menu__label">Border:</div>
        <DropDown
          options={dataBorder}
          value={attrs.dataBorder}
          onChange={({ value }) => update({ dataBorder: value })}
        />
      </div>

      <button
        className="block-menu__btn"
        onClick={() => {
          const { state } = editorView.props
          createParagraphNear(state, editorView.dispatch)
        }}
      >
        New line below
      </button>
    </div>
  ) : null
}

export default observer(TableMenu)
