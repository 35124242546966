import React from 'react'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { timeDistance, format } from 'common/helper/date'

import PdfIcon from 'common/Icons/Pdf'
import DocxIcon from 'common/Icons/Docx'
import DownIcon from 'common/Icons/Down'
import DocumentLogo from './DocumentLogo/DocumentLogo'

import PartyLine from './PartyLine/PartyLine'
import AddComment from './AddComment/AddComment'
import Stats from './Stats/Stats'

import pages from './pages'

import './ConfirmBox.scss'

class ConfirmBox extends React.Component {
  state = { open: true }

  toggle = () => {
    const { open } = this.state
    this.setState({ open: !open })
  }

  getContent = ({ state, myParty, myPartyMeta, cpParty }) => {
    const { settings: { confirmPage } } = this.props

    const data = pages[confirmPage || state] || {}

    if (myPartyMeta.deletedAt) {
      return pages.deleted
    }

    if (myParty && myParty.isTurn) {
      return data.myTurn || data
    }
    if (cpParty && cpParty.isTurn) {
      return data.cpTurn || data
    }

    return data
  }

  render() {
    const { open } = this.state
    const { children, negotiation } = this.props
    const { cpParty = {}, lastActivity } = negotiation

    const data = this.getContent(negotiation)
    const { expand, className, cp, icon, header, description, Action, download, comment, showActivity } = data

    const showComment = showActivity && lastActivity && lastActivity.comment

    return (
      <div className="confirm-box__wrapper">
        {header && (
          <div className="split-page">
            <div className={cn('confirm-box', className)}>
              <div className="confirm-box__main">
                <div className="confirm-box__left">
                  <header>
                    <div className="confirm-box__header">
                      {icon}
                      <h1>{header}</h1>
                    </div>
                    <div className="confirm-box__desc">{description.map((item, key) => <p key={key}>{item}</p>)}</div>
                  </header>

                  {cp && (
                    <PartyLine {...this.props} party={cpParty} label="Counterparty" info={cp}>
                      {cp.Content && <cp.Content {...this.props} />}
                    </PartyLine>
                  )}

                  {lastActivity && (
                    <div className="party-line">
                      <div className="party-line__label">{lastActivity.title}</div>
                      <div className="party-line__date">
                        <div>
                          {format(lastActivity.createdAt, 'MMMM d, yyyy')} @{' '}
                          {format(lastActivity.createdAt, 'h:iiaaaa')}
                        </div>
                        <div className="date">{timeDistance(lastActivity.createdAt, 'ago')}</div>
                      </div>
                    </div>
                  )}

                  {(comment || showComment) && <Stats {...this.props} label="Stats" />}
                </div>
                <div className="confirm-box__right">
                  <DocumentLogo {...this.props} />
                  {comment && (
                    <article>
                      <AddComment {...this.props} onChange={(comment) => this.setState({ comment })} />
                    </article>
                  )}
                  {showComment && (
                    <article>
                      <div className="last-activity" data-id={'comment-from-cp'}>
                        <h3>Comment</h3>
                        <div className="last-activity__comment">{lastActivity.comment}</div>
                      </div>
                    </article>
                  )}

                  {!comment && !showComment && <Stats {...this.props} />}

                  {download && (
                    <article className="download">
                      <h3 className="download__header">{download}</h3>
                      <div className="download__actions">
                        <div className="download__icon">
                          <PdfIcon />
                          <div>PDF</div>
                        </div>
                        <div className="download__icon">
                          <DocxIcon />
                          <div>DOCX</div>
                        </div>
                      </div>
                    </article>
                  )}
                </div>
              </div>

              <div className="confirm-box__actions">{Action && <Action {...this.props} {...this.state} />}</div>
            </div>
          </div>
        )}

        {expand ? (
          <div className="split-page">
            <div className={cn('confirm-box__content', { open })}>
              <div className="confirm-box__toggle" onClick={this.toggle}>
                <DownIcon /> Preview negotiation
              </div>
              {open && children}
            </div>
          </div>
        ) : (
          children
        )}
      </div>
    )
  }
}

export default observer(ConfirmBox)
