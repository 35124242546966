import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import WaitingIcon from 'common/Icons/Waiting'

const Avatar = (props) => {
  const { user, className } = props
  if (!user) return null

  const { displayName = '', photoURL, logoURL, status } = user
  const nameLetters = displayName.split(' ').map((item) => item[0] + '.')

  let logo = <div className="user-card__letters">{nameLetters}</div>

  if (logoURL) {
    logo = <img src={logoURL} alt={displayName} className="--company-logo" />
  }

  if (photoURL) {
    logo = <img height="100px" alt={displayName} src={photoURL} />
  }

  if (status === 'inviting') {
    logo = (
      <div className="waiting-logo">
        <WaitingIcon />
      </div>
    )
  }

  return <div className={cn('user-card__avatar', className, { photo: photoURL, logo: logoURL })}>{logo}</div>
}

export default observer(Avatar)
