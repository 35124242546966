import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'
import { Link } from 'react-router-dom'

import LogoIcon from 'common/Icons/Logo'
// import BellOutlineIcon from 'common/Icons/BellOutline'

import PublicNav from './Public/Public'
import UserProfile from './UserProfile/UserProfile'

import betaInfo from 'data/betaInfo.json'

import app from 'api/app'
// import auth from 'api/auth'

import './Header.scss'

class Header extends React.Component {
  render() {
    // const { messageCount = {} } = auth.user || {}
    return (
      <div className={cn('app__header', app.header)}>
        {app.showEnvironmentLabel && (
          <div className="app__logo">
            <div className="beta-testing" onClick={() => (app.modal = betaInfo)}>
              {app.environmentLabel || 'Preview'}
            </div>
          </div>
        )}
        <div className="app__logo">
          <Link to="">
            <LogoIcon color="var(--lightest)" />
          </Link>
        </div>
        <nav className="app__menu">
          <PublicNav {...this.props} />
        </nav>

        {/* {auth.isLoggedIn && (
          <div>
            <Link to="/inbox">
              <div className="app__notifications" total={messageCount.unread} key={messageCount.unread}>
                <BellOutlineIcon />
              </div>
            </Link>
          </div>
        )} */}
        <div className="app__user">
          <UserProfile />
        </div>
      </div>
    )
  }
}

export default observer(Header)
