import React from 'react'
import { observer } from 'mobx-react'

import SignatureIcon from 'common/Icons/Signature'

import SignatureActive from './Active/Signature.active'
import SignatureCompleted from './Completed/Signature.completed'
import SignatureNoForMe from './NotForMe/Signature.notForMe'

import negotiationsApi from 'api/negotiations'

import './Signature.scss'

const Signature = ({ meta = {}, data }) => {
  const { current = {} } = negotiationsApi
  const { myParty, cpParty, signatures, me } = current

  const mySignatures = (signatures && signatures.filter((item) => item.position === myParty.position)) || []
  const cpSignatures = cpParty && signatures && signatures.filter((item) => item.position === cpParty.position)

  return (
    <div className="group-signature__wrapper">
      {cpSignatures && (
        <div className="group-signature">
          <div className="label --cp">Counterparty</div>

          {cpSignatures.map((item, key) => (
            <CpSignatureOne item={item} key={key} meta={meta} signatures={signatures} data={data} />
          ))}
        </div>
      )}
      <div className="group-signature">
        <div className="label --me">My party</div>

        {mySignatures.map((item, key) => (
          <MySignatureOne item={item} key={key} meta={meta} me={me} signatures={signatures} data={data} />
        ))}
      </div>
    </div>
  )
}

const CpSignatureOne = ({ item }) => {
  const { positionLabel, behalfLabel, behalf, searchStr, signed, fieldName } = item

  if (signed) {
    return <SignatureCompleted item={item} />
  }

  return (
    <div data-signature={searchStr} tabIndex="0" className="form-signature">
      <div className="form-signature__header cp-unsigned">
        <div className="--header">
          <div className="--icon">
            <SignatureIcon />
          </div>
          <div>
            <h3>{fieldName}</h3>
          </div>
        </div>

        <div className="--waiting">Waiting for counterparty to sign</div>
      </div>
    </div>
  )
}

const MySignatureOne = ({ item, meta, data, signatures, me }) => {
  const { signed, behalf } = item

  const allowSign = behalf
    ? [].concat(me.allowSignBehalf).includes(behalf)
    : me.permissions === 'admin' || (me.actions && me.actions.includes('canSign'))

  if (signed) {
    return <SignatureCompleted item={item} />
  }

  if (!allowSign) {
    return <SignatureNoForMe item={item} meta={meta} data={data} />
  }

  if (!signed) {
    return <SignatureActive item={item} meta={meta} signatures={signatures} data={data} />
  }
  return null
}

export default observer(Signature)
