import { observer } from 'mobx-react'

import { DropDown } from 'common/DropDown/DropDown'

import './OrderedList.scss'

const OrderedList = ({ editorView, settings }) => {
  const { node } = settings

  const { attrs = {} } = node

  const update = (newAttrs = {}) => {
    const { state } = editorView.props
    const { selection } = state

    if (node.attrs) {
      const transaction = state.tr.setNodeMarkup(selection.from, undefined, { ...node.attrs, ...newAttrs })
      editorView.dispatch(transaction)
    }
  }

  const type = [
    { label: <label className="--parent">-- parent --</label>, value: null },
    { label: 'Number', value: '1' },
    { label: 'Upper aplha', value: 'A' },
    { label: 'Lower aplha', value: 'lower-alpha' },
    { label: 'Upper roman', value: 'I' },
    { label: 'Lower roman', value: 'lower-roman' },
  ]

  const dataStyle = [
    { label: <label className="--parent">-- parent --</label>, value: null },
    { label: 'x', value: 'x' },
    { label: 'x.', value: 'x.' },
    { label: 'x)', value: 'x)' },
    { label: '(x)', value: '(x)' },
    { label: 'x.x', value: 'x.x' },
    { label: 'x.x.', value: 'x.x.' },
  ]

  const dataIndent = [
    { label: <label className="--parent">-- parent --</label>, value: null },
    { label: '-4', value: '-4' },
    { label: '-3', value: '-3' },
    { label: '-2', value: '-2' },
    { label: '-1', value: '-1' },
    { label: '0', value: '0' },
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
  ]

  const dataMarker = [
    { label: <label className="--parent">-- parent --</label>, value: null },
    { label: 'Left', value: 'left' },
    { label: 'Right', value: 'right' },
  ]

  const dataStart = [
    { label: <label className="--parent">-- auto --</label>, value: null },
    1,
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
  ]

  return settings.open ? (
    <div className="block-menu">
      <div className="block-menu__item">
        <div className="block-menu__label">Align:</div>
        <DropDown
          options={dataMarker}
          value={attrs.dataMarker}
          onChange={({ value }) => update({ dataMarker: value })}
        />
      </div>
      <div className="block-menu__item">
        <div className="block-menu__label">Type:</div>
        <DropDown options={type} value={attrs.type} onChange={({ value }) => update({ type: value })} />
      </div>
      <div className="block-menu__item">
        <div className="block-menu__label">Style:</div>
        <DropDown options={dataStyle} value={attrs.dataStyle} onChange={({ value }) => update({ dataStyle: value })} />
      </div>

      <div className="block-menu__item">
        <div className="block-menu__label">Indent:</div>
        <DropDown
          options={dataIndent}
          value={attrs.dataIndent}
          onChange={({ value }) => update({ dataIndent: value })}
        />
      </div>

      <div className="block-menu__item">
        <div className="block-menu__label">Start:</div>
        <DropDown options={dataStart} value={attrs.start} onChange={({ value }) => update({ start: value })} />
      </div>
    </div>
  ) : null
}

export default observer(OrderedList)
