import React from 'react'
import { observer } from 'mobx-react'

import Form from 'common/Form/Form'
import PreLoad from 'common/PreLoad/PreLoad'
import ConfirmButton from 'common/ConfirmButton/ConfirmButton'

import LogoIcon from 'common/Icons/Logo2'
import WarningIcon from 'common/Icons/Warning'

import auth from 'api/auth'

import './Login.scss'

const ViewInput = ({ value, meta }) => {
  return (
    <div className="form-item">
      <div className="form-item__content">
        <label className="form-label">{meta.label}</label>
        <div className="form-input">
          <h2 className="form-input__value">{value}</h2>
        </div>
      </div>
    </div>
  )
}

class ResetPassword extends React.Component {
  constructor(props) {
    super(props)
    this.preLoad = new PreLoad(this.getData)
  }

  getData = async () => {
    const { params } = this.props.match
    return auth.getByResetCode(params.code)
  }

  onReset = async (values) => {
    const { history, match: { params } } = this.props
    await auth.resetPassword(params.code, values)
    history.replace('/')
  }

  form = [
    {
      Component: ViewInput,
      name: 'firstName',
      label: 'First Name',
    },
    {
      Component: ViewInput,
      name: 'lastName',
      label: 'Last Name',
    },
    {
      name: 'email',
      label: 'Email',
      Component: ViewInput,
    },
    {
      type: 'text',
      name: 'password',
      label: 'Password',
      input: {
        type: 'password',
      },
    },
    {
      type: 'text',
      name: 'confirmPassword',
      label: 'Confirm Password',
      input: {
        type: 'password',
      },
    },

    {
      Component: ({ values, model }) => {
        return (
          <div className="form-item">
            <div className="form-input login__actions">
              <ConfirmButton
                confirm="Are you sure?"
                onClick={() => this.onReset(values)}
                disabled={!model.stats.allCompleted}
              >
                Reset
              </ConfirmButton>
              <button className="btn link" onClick={() => this.props.history.push('/login')}>
                Login
              </button>
            </div>
          </div>
        )
      },
    },
  ]

  render() {
    const { loading, result } = this.preLoad

    return (
      loading || (
        <div className="login">
          <div className="login__aside">
            <div className="login__logo">
              <LogoIcon color="var(--lightest)" />
              <p>
                We use simple general legal language so everyone can understand it. The debate should be about the
                salient terms not commas, formatting and spaces.
              </p>
            </div>
          </div>

          <div className="login__main">
            {result ? (
              <div className="login__box">
                <div className="login__tabs">
                  <h1 className="active">Reset Password</h1>
                </div>

                <div>
                  <Form config={this.form} values={result} />
                </div>
              </div>
            ) : (
              <div className="login__box">
                <div className="form-item">
                  <div className="form-input login__actions">
                    <div className="login__warning">
                      <WarningIcon /> Code is not valid!
                    </div>
                    <button className="btn" onClick={() => this.props.history.push('/login')}>
                      Login
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )
    )
  }
}

export default observer(ResetPassword)
