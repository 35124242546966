const Romanian = Object.freeze({
  dict: {
    units: [
      'zero',
      [ 'unu', null, 'o' ],
      [ 'doi', null, 'două' ],
      'trei',
      'patru',
      'cinci',
      'şase',
      'şapte',
      'opt',
      'nouă',
    ],
    tens: [
      null,
      'zece',
      'douăzeci',
      'treizeci',
      'patruzeci',
      'cincizeci',
      'şaizeci',
      'şaptezeci',
      'optzeci',
      'nouăzeci',
    ],
    hundreds: [ 'sută', 'sute' ],
    specialGroups: {
      1: [ 'unu', [ 'o', 'unu' ], [ 'un', 'unu' ] ],
      2: [ 'doi', 'două', 'două' ],
      11: 'unsprezece',
      12: 'doisprezece',
      13: 'treisprezece',
      14: 'paisprezece',
      15: 'cincisprezece',
      16: 'şaisprezece',
      17: 'şaptesprezece',
      18: 'optisprezece',
      19: 'nouăsprezece',
      22: [ 'douăzeci şi doi', 'douăzeci şi două' ],
    },
    scales: [ null, [ 'mie', 'mii' ], [ 'milion', 'milioane' ], [ 'miliard', 'miliarde' ], [ 'bilion', 'bilioane' ] ],
  },
  options: {
    useOne: true,
    bindingWord: 'şi',
    usePluralForm: true,
    separator: ' ',

    and: (twoDigits, one, ten, hundred, value) => {
      if (value % 10 !== 0 && value % 100 > 20) {
        return Romanian.options.bindingWord
      }
    },
  },
})
export default Romanian
