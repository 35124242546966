import React from 'react'
import { observer } from 'mobx-react'

import StateOne from './States.completed'

import negotiationsApi from 'api/negotiations'

import './States.scss'

class States extends React.Component {
  get haveSignature() {
    const { signatures, myParty = {} } = negotiationsApi.current
    return signatures && myParty.position && !!signatures.find((item) => item.position === myParty.position)
  }

  getGroups = () => {
    const { children } = this.props.meta

    const states = {
      active: {
        label: 'Negotiation',
        items: [],
      },
      execution: {
        label: 'Execution',
        show: () => false, //this.haveSignature,
        items: [],
      },
    }

    const groups = children.reduce((acc, item) => {
      acc[item.state] = acc[item.state] || { label: item.state, items: [] }
      acc[item.state].items.push(item)
      return acc
    }, states)

    return Object.entries(groups)
      .filter(([ _, data ]) => data.items.length && (!data.show || data.show()))
      .map(([ state, data ]) => ({
        ...data,
        state,
        active: data.items[0] && data.items[0].active,
      }))
  }

  render() {
    const { meta = {}, readOnly, tpls } = this.props
    const { model } = meta

    const groups = this.getGroups()

    return (
      <div>
        {groups.map((item, key) => <StateOne key={key} {...item} tpls={tpls} model={model} readOnly={readOnly} />)}
      </div>
    )
  }
}
export default observer(States)
