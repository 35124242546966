import { observer } from 'mobx-react'
import cn from 'classnames'

import DragIcon from 'common/Icons/Drag2'
import RemoveIcon from 'common/Icons/Delete'
import PlusIcon from 'common/Icons/PlusCircleOutline'

import ColumnsIcon from 'common/Icons/Columns'

import DropDown from 'common/DropDown/DropDown'

const Columns = ({ model }) => {
  const { type, renderChildren, children, showRemove } = model

  return (
    <div className={cn('fel', 'fel__columns', 'cols-' + children.length)}>
      <div className="fel__content">
        <div className="fel__header">
          <div className="drag-handle">
            <DragIcon />
          </div>

          <DropDown
            className="fel__settings"
            options={[
              {
                label: 'New column',
                disabled: children && children.length > 1,
                onClick: () => model.append({ type: 'section' }),
                icon: <PlusIcon />,
              },
              {
                label: 'Remove',
                onClick: () => (model.showRemove = !showRemove),
                className: 'error2',
                icon: <RemoveIcon />,
              },
            ]}
          >
            {type}
            <ColumnsIcon />
          </DropDown>
        </div>
      </div>

      {renderChildren()}

      {showRemove && (
        <div className="fel__remove">
          <h4>Sure?</h4>
          <div className="fel__remove__actions">
            <button className="btn cancel small" onClick={() => (model.showRemove = false)}>
              Cancel
            </button>
            <button className="btn small error" onClick={() => model.remove()}>
              Remove
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(Columns)
