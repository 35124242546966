import React from 'react'

import Dragon from '../Dragon'
import AuditTrailTpl from './AuditTrailTpl'

import './AuditTrail.scss'

const AuditTrail = ({ model }) => {
  const { rootData } = model

  if (!rootData || rootData.state !== 'completed') return null

  const setRef = (ref) => {
    const tpl = new Dragon(AuditTrailTpl, rootData, { debug: true })

    if (ref) {
      ref.innerHTML = ''
      ref.append(tpl.html)
    }
  }

  return <div className="document-preview__document audit-trail" ref={setRef} />
}

export default AuditTrail
