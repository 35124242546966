const each = {
  name: 'each',

  onInit(el) {
    el.remove()
  },

  render(el, model) {
    const obj = el && el.$obj

    const value = model.getValue(obj, 'each')
    const rows = value && value.length

    if (obj.rows !== rows) {
      obj.rows = rows

      obj.children.forEach((item) => item.el.remove())

      if (rows) {
        obj.children = value.map((v, key) => {
          const one = obj.copy.cloneNode(true)
          one.$obj = { parent: obj, children: [], name: key + '', $index: key + 1 }
          one.$obj.el = one
          model.parse(one)
          obj.comment.before(one)

          return one.$obj
        })
      }
    }
    return true
  },
}

export default each
