import React from 'react'
import { withRouter } from 'react-router-dom'

import Form from 'common/Form/Form'
import Avatar from 'common/UserCard/Avatar'

import documentsApi from 'api/documents'
import auth from 'api/auth'

import _d from 'dictionary'

class Publish extends React.Component {
  config = [
    {
      type: 'radio',
      name: 'actionType',
      validate: { required: true },
      options: [
        this.props.tpl._rev && {
          label: 'Publish on existing',
          value: 'publish',
        },
        {
          label: 'Save as...',
          value: 'saveAs',
        },
      ],
    },
    {
      type: 'section',
      showIf: { actionType: 'saveAs' },
      children: [
        {
          type: 'text',
          label: 'Template name',
          name: 'label',
          validate: { required: true },
        },
        {
          type: 'text',
          label: 'Category',
          name: 'category',
          validate: { required: true },
        },
        {
          type: 'text',
          label: 'Description',
          name: 'description',
        },
        {
          type: 'dropDown',
          label: 'Language',
          name: 'lang',
          validate: { required: true },
          options: [
            { label: 'English', value: 'en' },
            { label: 'German', value: 'de' },
            { label: 'Romanian', value: 'ro' },
            { label: 'Lithuanian', value: 'lt' },
          ],
        },
        auth.user.company && {
          type: 'radio',
          label: 'Permissions',
          name: 'permissions',
          className: 'user-radio',
          defaultValue: 'company',
          options: [
            {
              label: (
                <div className="flex">
                  <Avatar user={auth.user} />
                  <div>
                    Individual
                    <h4>{auth.user.displayName}</h4>
                  </div>
                </div>
              ),
              value: 'personal',
            },
            {
              label: (
                <div className="flex">
                  <Avatar user={auth.user.company} className="circle" />
                  <div>
                    Company
                    <h4>{auth.user.company.displayName}</h4>
                  </div>
                </div>
              ),
              value: 'company',
            },
          ],
        },
      ],
    },

    {
      type: 'submit',
      label: 'Submit',
      confirm: 'Are you sure?',
      onClose: this.props.onClose,
      onSubmit: async (data) => {
        const { tpl, history, preLoad, model, mainDoc } = this.props
        const { prose } = model

        const { style: { _id } = {}, footer, pageStyle } = tpl

        const templateAction = documentsApi.template({
          ...data,
          pageStyle: pageStyle,
          footer: footer,
          style: _id && { _id },
          content: prose.getValue(),
          schema: { ...model.values, validationOn: true },
        })

        const fn = templateAction[data.actionType](tpl._key)

        const res = await preLoad.load(fn)
        prose.page.update()

        if (res.mainDoc._key !== mainDoc._key || !mainDoc._rev) {
          history.push(`/documents/${res.mainDoc._key}/edit`)
        }
      },
    },
  ]

  render() {
    const { tpl, mainDoc, model, settings } = this.props

    const values = tpl._key ? mainDoc : tpl
    values.actionType = tpl._rev ? 'publish' : 'saveAs'

    const itemsArr = model.getItemsArr()
    const countItems = itemsArr.length

    return (
      <div className="document-edit__box">
        <header>
          <div>
            <h2>Publish</h2>
          </div>
        </header>
        <div className="elections">
          {countItems ? (
            <Form config={this.config} values={values} />
          ) : (
            <div className="notification">
              <h1>{_d('editor.publish.formIsEmptyHeader')}</h1>
              <div className="notification__content">
                <p>{_d('editor.publish.formIsEmptyParagraph')}</p>
                <div>
                  <button className="btn" onClick={() => (settings.active = 'form')}>
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }
}

export default withRouter(Publish)
