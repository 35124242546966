import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import cn from 'classnames'

import _d from 'dictionary'

import auth from 'api/auth'
import app from 'api/app'

import './Settings.scss'

class Settings extends React.Component {
  render() {
    const { user } = auth
    const { currentPage } = app

    const { children, tabs = [] } = this.props

    const list = tabs.filter((item) => (!item.showWhen || item.showWhen(user)) && item.label)

    return (
      <div className="settings">
        <aside className="settings__nav">
          <ul>
            {list.map((item, key) => (
              <li
                key={key}
                className={cn('settings__nav__item', {
                  active: currentPage && (item === currentPage || item === currentPage.$parent),
                })}
              >
                <Link to={item.path}>
                  {item.Icon && <item.Icon />}
                  {_d(item.label)}
                </Link>
              </li>
            ))}
          </ul>
          <div className="settings__nav__footer" />
        </aside>
        <div className="layout__content">{children}</div>
      </div>
    )
  }
}

export default observer(Settings)
