import React from 'react'

import DocumentVersionIcon from 'common/Icons/DocumentVersion'
import ShieldIcon from 'common/Icons/ShieldOutline'
import UploadButton from 'common/UploadButton/UploadButton'
import Table from 'common/Table/Table'

import documentsApi from 'api/documents'
import auth from 'api/auth'
import editorApi from 'api/editor'

import _d from 'dictionary'

import './DocumentsList.scss'

class DocumentsList extends React.Component {
  canEdit = (perm) => {
    const docAdmin = perm && [].concat(perm.role).includes('admin')
    const docEditor = perm && [].concat(perm.role).includes('editor')
    return docAdmin || docEditor
  }

  config = {
    async getData() {
      return documentsApi.getList()
    },
    onClick: ({ mainDoc }) => {
      const { history } = this.props
      history.push(`/negotiations/${mainDoc._key}/draft`)
    },
    actions: ({ mainDoc, perm }) => {
      const canEdit = this.canEdit(perm)
      const { isAdmin } = auth

      return [
        (canEdit || isAdmin) && {
          label: 'Edit template' + (!canEdit && isAdmin ? ' as Admin' : ''),
          className: !canEdit && isAdmin && 'warning',
          onClick: () => {
            const { history } = this.props
            history.push(`/documents/${mainDoc._key}/edit`)
          },
        },
      ]
    },

    columns: [
      {
        width: 60,
        content: (item) => (
          <div>
            <DocumentVersionIcon className="doc-version" version={item.language.code.toUpperCase()} />
          </div>
        ),
      },
      {
        name: 'version',
      },
      {
        header: _d('TemplateName'),
        sortBy: 'mainDoc.label',
        content: (item) => (
          <div>
            <h4>{item.mainDoc.label}</h4>
            <div className="desc">{item.mainDoc.description}</div>
          </div>
        ),
      },
      {
        content: ({ isRestricted, perm }) =>
          isRestricted && (
            <div className="restricted">
              <div className="flex">
                <ShieldIcon />
                <div>
                  {_d('Private')}
                  {this.canEdit(perm) && <div className="desc">Editable</div>}
                </div>
              </div>
            </div>
          ),
      },
      {
        header: _d('Category'),
        name: 'mainDoc.category',
      },
      {
        header: _d('Language'),
        name: 'language.label',
      },
      {
        header: _d('Presets'),
        align: 'center',
        // width: 100,
        sortBy: 'countPresets',
        content: (item) => <div number={item.countPresets} />,
      },
      {
        header: _d('Negotiations'),
        align: 'center',
        // width: 100,
        sortBy: 'countNegotiations',
        content: (item) => <div number={item.countNegotiations} />,
      },
    ],
  }

  import = (file) => {
    return documentsApi.uploadPersonal(file.form)
  }

  get isAdmin() {
    const { role } = auth.user
    return [].concat(role).includes('admin')
  }

  onSubmit = async ({ file, form }) => {
    const { history } = this.props

    if (file.type === 'application/json') {
      const reader = new FileReader()

      reader.readAsText(file)

      reader.onload = () => {
        sessionStorage.setItem('documentJSON', reader.result)

        history.push('/documents/edit')
      }

      reader.onerror = () => {
        console.log(reader.error)
      }

      return
    }

    const { name: label, templateHtml: body } = await editorApi.toHtml(form)

    sessionStorage.setItem('documentJSON', JSON.stringify({ tpls: [{ body, label }], label }))

    setTimeout(() => history.push('/documents/edit'))
  }

  render() {
    return (
      <div className="p1__content">
        <header>
          <UploadButton
            label={_d('Upload new document')}
            onSubmit={this.onSubmit}
            accept={auth.isAdmin ? ['.docx', '.pdf', '.json'] : ['.docx', '.pdf']}
          />
        </header>

        <Table config={this.config} />
      </div>
    )
  }
}

export default DocumentsList
