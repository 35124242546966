import React from 'react'

const Icon = () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
      <g transform="translate(0.000000, 7.000000)" fill="currentColor">
        <path
          d="M22.6378378,-6.21724894e-15 L11.7405405,-6.21724894e-15 C10.9913514,-6.21724894e-15 10.3851892,0.569391026 10.3851892,1.26531339 L10.3783784,8.85719373 C10.3783784,9.5531161 10.9913514,10.1225071 11.7405405,10.1225071 L22.6378378,10.1225071 C23.387027,10.1225071 24,9.5531161 24,8.85719373 L24,1.26531339 C24,0.569391026 23.387027,-6.21724894e-15 22.6378378,-6.21724894e-15 Z M22.3389961,2.55253876 L17.5483205,5.35545903 C17.3314865,5.482288 17.0468919,5.482288 16.8300579,5.35545903 L12.0393822,2.55253876 C11.8699807,2.45107559 11.7683398,2.27985648 11.7683398,2.09595447 C11.7683398,1.67107741 12.2629923,1.41741947 12.6492278,1.63937017 L17.1891892,4.29643712 L21.7291506,1.63937017 C22.1153861,1.41741947 22.6100386,1.67107741 22.6100386,2.09595447 C22.6100386,2.27985648 22.5083977,2.45107559 22.3389961,2.55253876 Z"
          fillRule="nonzero"
        />
        <rect x="-1.02140518e-14" y="-1.14352972e-14" width="9.08108108" height="1.84045584" rx="0.92022792" />
        <rect x="3.24324324" y="3.0674264" width="5.83783784" height="1.84045584" rx="0.92022792" />
      </g>
    </g>
  </svg>
)

export default Icon
