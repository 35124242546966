import React from 'react'
import cn from 'classnames'

import RightIcon from 'common/Icons/Right'
import CheckbodIcon from 'common/Icons/Checkbox'

import './Filter.scss'

class Filter extends React.Component {
  state = { open: !this.props.closed }

  render() {
    const { sortGroup, list, label, limit, activeLimit, originalList = [], selected, onSelect, hidden } = this.props
    const { open } = this.state

    if (hidden) {
      return null
    }

    const active = selected[label] || {}

    return (
      <div className="filter__group">
        <h3 className={cn({ open })} onClick={() => this.setState({ open: !open })}>
          <RightIcon /> {label}
        </h3>
        {open && (
          <ul>
            {list.map((item, i) => {
              const { length } = item.filtered

              return (
                <li
                  className={cn('filter__item', { active: active[item.value], empty: !length })}
                  key={i}
                  onClick={() => length && onSelect(label, item.value)}
                >
                  <div className="filter__item__checkbox">
                    <CheckbodIcon value={active[item.value]} />
                  </div>
                  <div className="filter__item__view">{item.viewValue}</div>
                  <div className="filter__item__count">
                    {length !== item.list.length && (
                      <div className={cn('--available', { empty: !length })}>{length}</div>
                    )}
                    <div className="--total">{item.list.length}</div>
                  </div>
                </li>
              )
            })}
            {limit &&
            limit < list.length && (
              <div className="filter__actions">
                <div className="--total">
                  {list.length} of {originalList.length}
                </div>
                <div className="flex">
                  {activeLimit !== limit && (
                    <button
                      onClick={() => {
                        sortGroup(limit)
                        onSelect()
                      }}
                    >
                      Show less
                    </button>
                  )}

                  {list.length < originalList.length && (
                    <button
                      onClick={() => {
                        sortGroup(activeLimit + 10)
                        onSelect()
                      }}
                    >
                      Show more
                    </button>
                  )}
                </div>
              </div>
            )}
          </ul>
        )}
      </div>
    )
  }
}

export default Filter
