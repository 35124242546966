import get from 'lodash.get'

class Interpolate {
  constructor() {
    return this.parse
  }

  operations = {
    '||': (a, b) => a || b,
    '&&': (a, b) => a && b,
    '===': (a, b) => a === b,
    '==': (a, b) => a == b,
    '!=': (a, b) => a != b,
    '!==': (a, b) => a !== b,
    '-': (a, b) => this.getNumber(a) - this.getNumber(b),
    '+': (a, b) => this.getNumber(a) + this.getNumber(b),
    '*': (a, b) => this.getNumber(a) * this.getNumber(b),
    '/': (a, b) => this.getNumber(a) / this.getNumber(b),
  }

  eval = (str, data) => {
    str = str.split(' ').filter((v) => v)

    let result = this.getValue(data, str[0])

    str.forEach((val, i) => {
      const operation = this.operations[val]
      if (operation) {
        result = operation(result, this.getValue(data, str[i + 1]))
      }
    })
    return result
  }

  getNumber = (val) => {
    const number = parseFloat(val)
    if (!isNaN(number) && number.toString() === val.toString()) {
      return number
    }

    return 0
  }

  getValue = (data, param) => {
    const invert = param[0] === '!'

    if (invert) {
      param = param.replace('!', '')
    }

    let finalRes = get(data, param)

    const number = parseFloat(param)
    if (!isNaN(number) && number.toString() === param) {
      finalRes = number
    }

    return invert ? !finalRes : finalRes
  }

  parse = (str = '', data = {}) => {
    data = { ...data }
    let varNr = 0

    if (typeof str === 'number') {
      return str
    }

    str = str.replace(/\([^)(]*?\)/g, (found) => {
      varNr++
      const name = '__OPERATION__' + varNr
      found = found.replace('(', '').replace(')', '')

      data[name] = this.eval(found, data)
      return name
    })

    if (str.includes('(') && str.includes(')')) {
      str = this.parse(str, data)
    } else {
      str = this.eval(str, data)
    }

    return str
  }
}

export default new Interpolate()
