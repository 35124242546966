import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import cn from 'classnames'

import _d from 'dictionary'

import app from 'api/app'
import auth from 'api/auth'

import './Public.scss'

class Public extends React.Component {
  getActive(item) {
    return item.$parent ? this.getActive(item.$parent) : item
  }

  render() {
    const { currentPage } = app
    const { negCount = {}, status } = auth.user || {}

    const { pages } = this.props
    const activeTab = (currentPage && this.getActive(currentPage)) || {}

    document.querySelector('title').innerHTML = [ 'YumiDocs', _d(activeTab.label) ].filter((i) => i).join(' - ')

    return (
      <ul className="main-nav">
        {pages &&
          pages.filter((item) => !item.$parent && item.label).map((item, key) => (
            <li key={key} className={cn({ active: item === activeTab })}>
              <Link
                to={item.path}
                data-id={'mainNav:' + item.label}
                key={item.count && negCount[item.count]}
                total={item.count && negCount[item.count]}
              >
                {_d(item.label)}
              </Link>
            </li>
          ))}

        {status === 'inviting' && (
          <li>
            <div className="main-nav__create-account" onClick={auth.createAccount}>
              {_d('Create account')}
            </div>
          </li>
        )}
      </ul>
    )
  }
}

export default observer(Public)
