import axios from 'axios'

const api = {
  //-------------------------------------------------------------------------------------------
  getInbox() {
    return axios.get('/messages/inbox')
  },

  //-------------------------------------------------------------------------------------------
  getMessage({ message }) {
    return axios.get(`/messages/${message._key}`)
  },

  //-------------------------------------------------------------------------------------------
  getActivityMessage(_key) {
    return axios.get(`/messages/event/${_key}`)
  },

  //-------------------------------------------------------------------------------------------
  sendReminder(_key, body) {
    return axios.post(`/messages/negotiation/${_key}/reminder`, body)
  },
}

export default api
