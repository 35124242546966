export default `

<div id="timeline-content">
  <style>
    #timeline-content {
      padding: 4em;
      padding-top: 0;
    }

    #timeline-content table,
    #timeline-content tr,
    #timeline-content td {
      border: none;
      margin: 0;
      padding: 0;
      vertical-align: top;
      border-spacing: 0;
    }

    #timeline-content time {
      display: block;
      color: #999;
      font-size: 12px;
      font-style: italic;
      margin: .5em 0;
    }

    #timeline-content .client {
      color: #999;
      padding: .5em 0 0;
      margin: 0;
    }

    #timeline-content .client li {
      list-style: none;
      margin: .25em;
    }

    #timeline-content .header {
      text-align: center;
      margin: 1em;
    }

    #timeline-content .summary {
      padding: 0;
      color: #666;
    }

    #timeline-content .summary strong {
      color: black;
    }

    #timeline-content .summary li {
      list-style: none;
      margin: .5em 0;
    }

    #timeline-content .timeline {
      width: 100%;
      margin-top: 2em;
    }

    #timeline-content .event__time {
      padding: 1em 1.5em 1em 0;
      white-space: nowrap;
    }

    #timeline-content .event__content {
      padding: 1em 0;
      border-top: solid 1px #ccc;
    }

    #timeline-content .event__comment {
      padding: .5em 1em;
      background: #eee;
      border-radius: 5px;
      margin: 0 3px;
      line-height: 1.5;
      font-size: 12px;
      white-space: pre-wrap;
      text-align: justify;
    }

    #timeline-content .event__comment__label {
      margin: .5em 5px 3px;
    }

  </style>
  <p style="text-align:right;"><img src="https://yumidocs.com/yumidocs.png" width="200"></p>
  <h1 class="header">Audit trail</h1>

  <ul class="summary">
    <li><h3 dr-text="mainDoc.label">[Template Name]</h3></li>
    <li>YumiDocs Negotiation Id: <span dr-text="_key">[YumiDocs NegotiationID]</span></li>
    <li>Status: <span>Completed</span></li>
    <li>Between:
      <ul>
        <li><strong dr-text="myParty.owner.displayName">[my party]</strong> as&nbsp;<strong dr-text="myParty.displayName">[position]</strong>; and</li>
        <li><strong dr-text="cpParty.owner.displayName">[cp party]</strong> as&nbsp;<strong dr-text="cpParty.displayName">[position]</strong></li>
      </ul>
    </li>
    <li>Created at: <span dr-text="createdAt" dr-format="date|d MMMM, yyyy H:ii:ss 'GMT'">[Created at]</span></li>
    <li>Completed at: <span dr-text="lastActivity.createdAt" dr-format="date|d MMMM, yyyy H:ii:ss 'GMT'">[Completed at]</span></li>
  </ul>

  <table class="timeline">

    <tr class="event" dr-each="auditTrail">
      <td>
        <div class="event__time">
          <strong dr-text=".partyName">[party position]</strong><br />
          <time dr-text=".createdAt" dr-format="date|d MMMM, yyyy H:ii:ss 'GMT'">[date]</time>
        </div>
      </td>

      <td>
        <div class="event__content">
          <div class="title"><span dr-text=".createdBy.displayName">[Name]</span> &lt;<span dr-text=".createdBy.email">[email]</span>&gt 
            <span dr-value=".type">
              <span dr-when="inviting">Invitation sent</span>
              <span dr-when="accept">Invitation accepted</span>
              <span dr-when="sendToCounterparty">Send to counterparty</span>
              <span dr-when="execution">Negotiation agreed</span>
              <span dr-when="signed">Signed</span>
              <span dr-when="signedAndCompleted">Signed and completed</span>
              <span dr-when="completed">Completed</span>
            </span>
          </div>
          <ul dr-if=".client.ip" class="client">
            <li>Negotiation version: <span dr-text=".version">[version]</span></li>
            <div dr-value=".type" dr-when-not="signedAndCompleted,signed">
              <ul>
                <li>All elections matching: <span dr-text=".stats.isMatched">[IsMatched]</span></li>
                <li>Filled in elections by party: <span dr-text=".stats.completed">[Count filled in items]</span></li>
                <li>Changed elections: <span dr-text=".stats.changed">[Count changed items]</span></li>
                <li>Matched elections: <span dr-text=".stats.matched">[Count matched items]</span></li>
              </ul>
            </div>
            <li>IP addres: <span dr-text=".client.ip">[ip]</span></li>
            <li>OS: <span dr-text=".client.userAgent" dr-format="userAgent|os">[os]</span></li>
            <li>Browser: <span dr-text=".client.userAgent" dr-format="userAgent|browser">[browser]</span></li>
          </ul>

          <div dr-if=".comment">
            <div class="event__comment__label">Comment:</div>
            <div class="event__comment" dr-text=".comment">Comment</div>
          </div>
        </div>
      </td>
    </tr>

  </table>
</div>

`
