import React from 'react'

const Icon = (props) => (
  <svg width="24px" height="24" viewBox="0 0 24 24" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(3.000000, 2.000000)" fill="currentColor" fillRule="nonzero">
        <path d="M16.8205128,5.1379021 C16.7966434,4.61277389 16.7966434,4.11151515 16.7966434,3.61025641 C16.7966434,3.20447552 16.4863403,2.89417249 16.0805594,2.89417249 C13.0968765,2.89417249 10.8292774,2.03487179 8.94358974,0.196923077 C8.65715618,-0.0656410256 8.22750583,-0.0656410256 7.94107226,0.196923077 C6.05538462,2.03487179 3.78778555,2.89417249 0.804102564,2.89417249 C0.398321678,2.89417249 0.088018648,3.20447552 0.088018648,3.61025641 C0.088018648,4.11151515 0.088018648,4.61277389 0.0641491841,5.1379021 C-0.0313286713,10.1504895 -0.174545455,17.0248951 8.20363636,19.9131002 L8.442331,19.9608392 L8.68102564,19.9131002 C17.035338,17.0248951 16.9159907,10.174359 16.8205128,5.1379021 Z" />
      </g>
    </g>
  </svg>
)

export default Icon
