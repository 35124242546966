import React from 'react'

const Icon = ({ version = '0.1', category }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="107.536" height="144.341" viewBox="0 0 107.536 144.341">
    <g transform="translate(-978.859 -144.5)">
      <g>
        <g transform="translate(940.818 -29)">
          <g transform="translate(39.54 175)">
            <path
              d="M29.644,28.561h16.8c.625,0,1.116-2.965,1.116-3.592v2.472c0-1.993-.87-6.346-2.388-7.6L31.652,5.6a7.154,7.154,0,0,0-4.6-1.657H24.324A1.694,1.694,0,0,0,22.6,5.58V21.813C22.6,24.164,27.3,28.561,29.644,28.561Z"
              transform="translate(56.975 -3.929)"
              fill="#adc0d5"
              stroke="#2e435d"
              strokeWidth="3"
            />
            <path
              d="M70.411,30.092V3.938H18.316c-6.052,0-11,4.708-11,10.462V134.817c0,5.754,4.952,10.462,11,10.462h82.529c6.052,0,11-4.708,11-10.462V44.8H85.885C77.357,44.8,70.411,38.2,70.411,30.092Z"
              transform="translate(-7.313 -3.938)"
              fill="#adc0d5"
              stroke="#2e435d"
              strokeWidth="3"
            />
          </g>
          <rect width="92.913" height="44.466" rx="5" transform="translate(45.217 264.796)" fill="#fff" />
          <text
            transform="translate(74 299)"
            fill="#6284a8"
            fontSize="28"
            fontFamily="ArialNarrow-Bold, Arial"
            fontWeight="700"
            letterSpacing="0.025em"
          >
            <tspan x="0" y="0">
              {category || 'DOC'}
            </tspan>
          </text>
          <line
            x2="15.784"
            transform="translate(61.67 182.892)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="3"
          />
          <line
            x2="15.784"
            transform="translate(61.67 192.937)"
            fill="none"
            stroke="#fff"
            strokeLinecap="round"
            strokeWidth="3"
          />
        </g>
      </g>
      <g transform="translate(-152 45)">
        <g transform="translate(933 106)">
          <path
            d="M7,0H22a7,7,0,0,1,7,7V8a7,7,0,0,1-7,7H7A7,7,0,0,1,0,8V7A7,7,0,0,1,7,0Z"
            transform="translate(263 59)"
            fill="#2e435d"
          />
          <text
            transform="translate(271 71)"
            fill="#fff"
            fontSize="11"
            fontFamily="Arial-BoldMT, Arial"
            fontWeight="700"
            letterSpacing="-0.054em"
          >
            <tspan x="0" y="0">
              {version}
            </tspan>
          </text>
        </g>
        <text transform="translate(1144 177)" fontSize="13" fontFamily="ArialMT, Arial">
          <tspan x="0" y="0">
            Version:
          </tspan>
        </text>
      </g>
    </g>
  </svg>
)

export default Icon
