import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import AsyncButton from 'common/AsyncButton/AsyncButton'

const RowsExpanded = (props) => {
  const { config, item, open, confirm, edit } = props
  const { columns } = config

  let actions = typeof config.actions === 'function' ? config.actions(item) : config.actions
  actions = actions && actions.filter((v) => v)

  const expandable = !!config.content
  const body = expandable && open && config.content(item)

  return (
    <div className="table__row">
      <td colSpan={columns.length + expandable + !!actions} className="table__expand">
        <div className={cn('table__content', edit && edit.className)}>
          <div>{body}</div>
          {edit && <div className="table__edit">{edit.body(item, edit)}</div>}
          {confirm && (
            <div className="table__confirm" onClick={confirm.onClose}>
              <div>{confirm.confirm}</div>
              <div className="table__confirm__buttons">
                <button className="btn cancel small" onClick={confirm.onClose}>
                  Cancel
                </button>
                <AsyncButton className={cn('btn small', confirm.className)} onClick={() => confirm.onSubmit(item)}>
                  {confirm.confirmLabel || 'Confirm'}
                </AsyncButton>
              </div>
            </div>
          )}
        </div>
      </td>
    </div>
  )
}

export default observer(RowsExpanded)
