import { decorate, observable } from '../mobx'

import helper from './helper'

import ValidateRules from './validate.rules'

class Stats {
  constructor(meta) {
    this.meta = meta
    this.countSelf()
    this.countChildren()

    if (this.self) {
      this.self.isPristine = true
      this.self.isDirty = false
    }

    decorate(this, {
      results: observable.ref,
      self: observable.ref,
    })
  }

  get children() {
    const { meta } = this

    let children = meta.amend || meta.children

    if (children && !children.length && meta.cpValue) {
      children = meta.amendForm
    }

    return children
  }

  //---------------------------------------------------------------------------------------------------------
  countChildren() {
    const { children } = this
    const { hide, cpOnly, active } = this.meta
    this.list = null
    this.results = null

    if (children && children.length && !hide && !cpOnly && active) {
      this.list = children.reduce((acc, { info, hide, cpOnly, cpValue, config }) => {
        if (!hide && (!cpOnly || config.name === '__amend') && info) {
          if (info.self) {
            acc.push(info.self)
          }

          if (info.list) {
            acc = acc.concat(info.list)
          }
        }
        return acc
      }, [])

      const res = {
        listChanged: [],
        listCompleted: [],
        listIncompleted: [],
        listMatched: [],
        listValid: [],
        listInvalid: [],
        restricted: {
          listValid: [],
          listInvalid: [],
          listCompleted: [],
          listIncompleted: [],
          listMatched: [],
          listChanged: [],
          list: [],
        },
      }

      this.list.forEach((input) => {
        const { isChanged, isCompleted, isMatched, isValid, isInvalid, item } = input

        if (isChanged) res.listChanged.push(input)
        if (isCompleted) res.listCompleted.push(input)
        if (!isCompleted) res.listIncompleted.push(input)
        if (isMatched) res.listMatched.push(input)
        if (isValid) res.listValid.push(input)
        if (isInvalid) res.listInvalid.push(input)

        if (item.isRestricted) {
          res.restricted.list.push(input)
          if (isChanged) res.restricted.listChanged.push(input)
          if (isCompleted) res.restricted.listCompleted.push(input)
          if (!isCompleted) res.restricted.listIncompleted.push(input)
          if (isMatched) res.restricted.listMatched.push(input)
          if (isValid) res.restricted.listValid.push(input)
          if (isInvalid) res.restricted.listInvalid.push(input)
        }
      })

      res.list = this.list
      res.total = this.list.length
      res.totalChanged = res.listChanged.length
      res.totalCompleted = res.listCompleted.length
      res.totalIncompleted = res.listIncompleted.length
      res.totalMatched = res.listMatched.length
      res.totalValid = res.listValid.length
      res.totalInvalid = res.listInvalid.length

      res.allChanged = res.total === res.totalChanged
      res.allCompleted = res.total === res.totalCompleted
      res.allMatched = res.total === res.totalMatched
      res.allValid = res.total === res.totalValid
      res.allInvalid = res.total === res.totalInvalid

      const { restricted } = res

      restricted.total = restricted.list.length
      if (restricted.total) {
        restricted.totalCompleted = restricted.listCompleted.length
        restricted.totalMatched = restricted.listMatched.length

        restricted.allCompleted = restricted.total === restricted.totalCompleted
        restricted.allMatched = restricted.total === restricted.totalMatched
      }

      this.results = res
    }
  }

  //---------------------------------------------------------------------------------------------------------
  countSelf() {
    this.self = null

    const { isInput, hide, cpOnly, config } = this.meta

    if (isInput && !hide && (!cpOnly || config.name === '__amend')) {
      const validation = this.validate() || { isValid: true }
      const { isValid } = validation

      const isChanged = this.isChanged()
      const isCompleted = isValid && this.isCompleted()
      const isMatched = isValid && this.isMatched()
      const haveChildren = !!this.children

      this.self = {
        item: this.meta,
        isChanged,
        isCompleted,
        isMatched,
        ...validation,
        isPristine: false,
        isDirty: true,
        haveChildren,
      }
    }
  }

  haveChanges(old) {
    const { self } = this

    if (!self || !old || self.haveChildren) {
      return true
    }

    const params = [ 'isCompleted', 'isChanged', 'isMatched', 'isValid', 'errorType', 'errorMessage', 'haveChildren' ]

    return !!params.find((name) => old[name] !== self[name])
  }

  //---------------------------------------------------------------------------------------------------------
  updateUp(haveChanges) {
    const { parent } = this.meta
    const old = this.self

    this.countSelf()
    this.countChildren()

    haveChanges = haveChanges || this.haveChanges(old)

    if (haveChanges && parent && parent.info) {
      parent.info.updateUp(true)
    }

    return this.self
  }

  //---------------------------------------------------------------------------------------------------------
  updateDown() {
    const { children } = this

    // console.log('...', children)
    if (children && children.length) {
      children.forEach(({ info }) => {
        info.countSelf()
        info.updateDown()
        info.countChildren()
      })
    }

    this.countSelf()
    this.countChildren()
  }

  //---------------------------------------------------------------------------------------------------------
  isTotal() {
    const { name } = this.meta
    return !!name
  }

  isChanged() {
    const { value, orgValue } = this.meta
    return !helper.isEqual(value, orgValue)
  }

  isCompleted() {
    const { value } = this.meta
    return value !== undefined
  }

  isMatched() {
    const { value, cpValue } = this.meta
    return value !== undefined && helper.isEqual(value, cpValue)
  }

  //---------------------------------------------------------------------------------------------------------
  // Validation
  //---------------------------------------------------------------------------------------------------------
  get validateRules() {
    if (!this._validateRules) {
      this._validateRules = new ValidateRules(this.meta.config.validate)
    }

    return this._validateRules
  }

  //---------------------------------------------------------------------------------------------------------
  validateSelf = (name, res = { listValidators: [], summary: {}, listSuccess: [], listError: [] }, includeEmpty) => {
    const { validateRules, meta } = this
    const { value } = meta

    if (validateRules[name] && (value !== undefined || includeEmpty)) {
      const item = validateRules[name](value, meta)

      if (item) {
        res.listValidators.push(item)
        res.summary[item.type] = item.valid

        if (item.valid) {
          res.listSuccess.push(item)
        } else {
          res.listError.push(item)
        }
      }

      res.isValid = !res.listError.length
      res.isInvalid = !res.isValid
      res.error = res.listError[0] || {}
      res.errorMessage = res.error.message
      res.errorType = res.error.type
    }

    return res
  }

  //---------------------------------------------------------------------------------------------------------
  validate() {
    const { validate } = this.meta.config

    const res = { listValidators: [], summary: {}, listSuccess: [], listError: [], isValid: true }

    if (validate) {
      if (validate.required) {
        this.validateSelf('required', res, true)
      }

      Object.entries(validate).forEach(([ name ]) => {
        if ([ 'min', 'max', 'minLength', 'maxLength', 'match', 'sync' ].includes(name)) {
          this.validateSelf(name, res)
        }
      })

      if (validate.type) {
        this.validateSelf(validate.type, res)
      }

      return res
    }
  }

  //---------------------------------------------------------------------------------------------------------
}

export default Stats
