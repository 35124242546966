import { Schema } from 'prosemirror-model'

import { nodes, marks } from './schema-basic'
import listNodes from './schema-list'
import tableNodes from './schema-table'
import dragonNodes from './schema-dragon'

const getNodes = (editor) => ({ ...nodes, ...dragonNodes, ...listNodes(editor), ...tableNodes(editor) })

const getSchema = (editor) => new Schema({ nodes: getNodes(editor), marks })

const schema = getSchema()
const editorSchema = getSchema(true)

export { schema, editorSchema }
