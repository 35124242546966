import React from 'react'
import { formatDistance, format } from 'date-fns'

import UserCard from 'common/UserCard/UserCard'

import './PartyLine.scss'

class PartyLine extends React.Component {
  render() {
    const { children, party, label } = this.props

    return (
      <div className="party-line">
        <div className="party-line__label">{label}</div>
        <div className="party-line__party">
          <UserCard user={party.owner} className="blank" email={party.email} />

          {children}
        </div>
      </div>
    )
  }
}

export default PartyLine
