import { useState } from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import HelpIcon from 'common/Icons/Help'

import AsyncButton from 'common/AsyncButton/AsyncButton'
import Form from 'common/Form/Form'

import negotiationsApi from 'api/negotiations'

import SignatureToolbar from '../Toolbars/SignatureToolbar'

import './Signature.scss'

const info = {
  noAction: 'This section becomes active during the Execution stage. Nothing to do here for now.',
  canSign: [
    'All signature blocks below need to be signed.',
    'The date of signature is captured automatically when signing.',
    'You can assign other people to sign on behalf of your party.',
    `Depending on how your company's account is set up, you may not be able to sign this document because you don't have signature rights. You will see a read-only Signature section here.`,
  ],
}

const Ul = ({ list }) => <ul className="help-box__list">{list.map((text, key) => <li key={key}>{text}</li>)}</ul>

const Signature = ({ templates, neg }) => {
  const { doc, myParty, state, _key } = negotiationsApi.current

  const allExecuted = [ 'execution', 'completed' ].includes(state)
  const completed = state === 'completed'

  const [ showHelp, setShowHelp ] = useState()

  const [ tpl ] = templates
  const haveSignatures = !!tpl.signatures.find((item) => item.signature.position === myParty.position)

  const { file = {} } = doc.tpls[0]

  const { isTurn, stats } = myParty

  const canSign = haveSignatures && stats && stats.isCompleted && (allExecuted || !isTurn)
  const signatureAvailable = !!file.name && canSign
  const visible = canSign

  const help = canSign ? info.canSign : info.noAction

  const text = Array.isArray(help) ? <Ul list={help} /> : help

  return [
    <SignatureToolbar key="toolbar" nr="3" canSign={canSign} className={cn({ completed, disabled: !visible })} />,
    // (!visible || signatureAvailable) &&
    //   (showHelp ? (
    //     <div className={cn('help-box', { disabled: !visible })} key="help-box">
    //       <div>
    //         {text}
    //         <div className="help-box__actions">
    //           <button className="btn link" onClick={() => setShowHelp(false)}>
    //             close
    //           </button>
    //         </div>
    //       </div>
    //       <HelpIcon />
    //     </div>
    //   ) : (
    //     <div className="help-link" onClick={() => setShowHelp(true)} key="help-link">
    //       Guide <HelpIcon />
    //     </div>
    //   )),
    visible && (
      <div key="signature" className={cn('signature-block', { executed: signatureAvailable })}>
        {signatureAvailable ? (
          <Form
            tpls={templates}
            data={neg}
            config={[
              {
                type: 'signature',
                label: 'Digital signature',
              },
            ]}
          />
        ) : (
          <div className="execute-doc">
            <h2>Fast Sign</h2>
            <p>If your changes are final, you can sign the document you sent to the counterparty.</p>
            <h3>After you sign</h3>
            <ul>
              <li>
                <p>
                  If the counterparty agrees with your changes, they sign the document and it becomes binding. You don't
                  need to do anything else.
                </p>
              </li>
              <li>
                <p>
                  However, if the counterparty suggests further changes, you are prompted to review the changes and sign
                  the updated version.
                </p>
              </li>
            </ul>
            <p>
              <strong>Note:</strong> A document cannot be changed or modified after all parties agreed and signed it.
            </p>
            <div className="execute-doc__actions">
              <AsyncButton className="btn" onClick={() => negotiationsApi.execute(_key)}>
                Start signing now
              </AsyncButton>
            </div>
          </div>
        )}
      </div>
    ),
  ]
}

export default observer(Signature)
