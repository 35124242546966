import React, { Suspense } from 'react'
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom'
import { observer } from 'mobx-react'
import cn from 'classnames'

import auth from 'api/auth'
import app from 'api/app'
import Segment from 'common/SegmentIO/SegmentIO'

import Header from './Header/Header'
import Footer from './Footer/Footer'
import Modal from './Modal/Modal'

import Loader from 'common/Icons/loadingBar4/LoadingBar'

import './Layout.root.scss'
import './Layout.input.scss'
import './Layout.scss'
import './Animations.scss'

const Loading = () => (
  <div className="pre-load">
    <div>
      <Loader />
    </div>
  </div>
)

class Layout extends React.Component {
  getValid = (list, parent) => {
    const { user } = auth

    return list.filter((item) => {
      if (!item) return false

      let role
      if (user) role = user.role || 'waiting'

      this.role = role

      const valid =
        !item.roles ||
        (role &&
          item.roles.find((name) => role.includes(name)) &&
          ['active', ...(item.status || [])].includes(user.status))

      if (valid) {
        if (parent) {
          item.$parent = parent
        }

        if (!parent || !parent.Wrapper) {
          this.routes.push(item)
          this.routesMap[item.path] = item
        }

        if (item.children) {
          item.tabs = this.getValid(item.children, item)
        }
      }

      return valid
    })
  }

  checkPermissions = (pages = this.props.pages) => {
    this.routes = []
    this.routesMap = {}

    this.pages = this.getValid(pages)
    return this.routes
  }

  render() {
    window.Layout = this
    const { user } = auth
    const pages = user !== undefined && this.checkPermissions()

    const layoutPages = [
      {
        path: '',
        Wrapper: MainLayout,
        tabs: pages || [],
      },
    ]

    return (
      <BrowserRouter>
        <Switch>
          {user === undefined || !app.fontsLoaded ? (
            <Route
              render={() => (
                <MainLayout>
                  <Loading />
                </MainLayout>
              )}
            />
          ) : (
            <NestedRouter pages={layoutPages} />
          )}
        </Switch>
        <Modal />
      </BrowserRouter>
    )
  }
}

//-----------------------------------------------------------------------------
const MainLayout = observer((props) => {
  const { children, tabs = [] } = props
  return (
    <div
      className={cn('layout', { 'fonts-loaded': app.fontsLoaded, 'no-footer': !app.footer })}
      style={{ background: 'url(/images/light-texture-bg.png) repeat fixed' }}
    >
      <Header pages={tabs} />
      <div className="layout__main">{children}</div>
      <Footer />
    </div>
  )
})

//-----------------------------------------------------------------------------
const SingleRoute = (props) => {
  const { item } = props
  window.scrollTo(0, 0)
  const { Wrapper, tabs } = item

  if (Wrapper) {
    return (
      <Suspense fallback={<Loading />}>
        <Wrapper {...props} tabs={tabs} currentPage={item}>
          <NestedRouter pages={tabs} />
        </Wrapper>
      </Suspense>
    )
  }

  if (item.analytics !== false) {
    Segment.page('App', item.title || item.label)
  }

  return [
    <Suspense fallback={<Loading />} key="page">
      <item.Component {...props} currentPage={item} />
    </Suspense>,
    <span
      key="currentPageInfo"
      className="current-page"
      ref={(ref) => {
        if (ref) app.currentPage = item
      }}
    />,
  ]
}

//-----------------------------------------------------------------------------
const NestedRouter = (props) => {
  const { pages = [] } = props

  const list = [...pages].sort((a, b) => b.path.length - a.path.length)

  return (
    <Switch>
      {list.map((item, key) => (
        <Route
          exact={!item.children}
          key={key}
          path={item.path}
          render={(props) => <SingleRoute {...props} item={item} />}
        />
      ))}
      <Route render={() => <Redirect to="/login" />} />
    </Switch>
  )
}

export default observer(Layout)
