class Actions {
  //--------------------------------------------------------------------------------------------
  constructor({ editorView }) {
    this.editorView = editorView
  }

  get state() {
    return this.editorView.props.state
  }

  get schema() {
    return this.state.schema
  }

  get tr() {
    return this.state.tr
  }

  get selection() {
    return this.state.selection
  }

  get node() {
    return this.selection.node
  }

  get attrs() {
    return this.node && this.node.attrs
  }

  get textContent() {
    const { textContent } = this.selection.content().content.content[0]
    return textContent
  }

  //--------------------------------------------------------------------------------------------
  blankNode = (text, attrs = { drText: '' }, type = 'dr_inline') => {
    const json = {
      type,
      attrs: {
        tagName: 'span',
        ...attrs,
      },
      content: [
        {
          type: 'text',
          text,
        },
      ],
    }

    return this.schema.nodeFromJSON(json)
  }

  //--------------------------------------------------------------------------------------------
  createInputNode = ({ fullName, label, type }) => {
    const node = this.blankNode('[' + label + ']')

    node.attrs.drText = fullName
    node.attrs.drFormat = type === 'datePicker' ? 'date' : null

    return node
  }

  //--------------------------------------------------------------------------------------------
  createSignatureNode = ({ drSignature, drBehalf }) => {
    const attrs = {
      drSignature: drSignature.name,
      drBehalf,
    }

    return this.blankNode('[' + drSignature.label + (drBehalf ? ' - ' + drBehalf : '') + ']', attrs, 'dr_signature')
  }

  //--------------------------------------------------------------------------------------------
  replaceToNode = (node) => {
    this.editorView.focus()
    const transaction = this.tr.replaceSelectionWith(node)

    this.editorView.dispatch(transaction)
  }

  //--------------------------------------------------------------------------------------------
  replaceSelection = (item) => {
    const node = item.drSignature ? this.createSignatureNode(item) : this.createInputNode(item)

    this.replaceToNode(node)
  }

  //--------------------------------------------------------------------------------------------
  replaceToText = () => {
    const json = { type: 'text', text: this.textContent }
    const node = this.schema.nodeFromJSON(json)
    this.replaceToNode(node)
  }

  //--------------------------------------------------------------------------------------------
}

export default Actions
