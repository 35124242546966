import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import MoreIcon from 'common/Icons/More'
import DropDown from 'common/DropDown/DropDown'

import Avatar from './Avatar'
import './UserCard.scss'

class UserCard extends React.Component {
  state = { edit: false }

  permissions = {}

  options = [
    {
      label: 'Remove',
      name: 'remove',
      confirm: 'Are you sure?',
      onClick: (item) => {
        const { onRemove = () => null } = this.props
        onRemove(item)
      },
    },
    {
      label: 'Edit',
      name: 'edit',
      innerContent: ({ user, onClose }) => {
        const { perm } = user
        let { permissions = perm.permissions } = this.state
        return (
          <div className="user-card__edit">
            <div>
              <button
                className="btn small"
                onClick={async () => {
                  const { onUpdate = () => null } = this.props
                  const { permissions } = this.state
                  await onUpdate({ ...perm, permissions })
                  onClose()
                }}
              >
                Confirm
              </button>
              <button className="btn cancel small" onClick={onClose}>
                Cancel
              </button>
            </div>
            <div>
              <DropDown
                label="Change permission"
                value={permissions}
                options={[
                  { label: 'Admin', value: 'admin' },
                  { label: 'Write', value: 'write' },
                  { label: 'Read', value: 'read' },
                ]}
                onChange={({ value }) => this.setState({ permissions: value })}
              />
            </div>
          </div>
        )
      },
    },
  ]

  wrapOptions = () => {
    const { options = this.options } = this.props
    return options.map((item) => {
      const res = { ...item }

      if (item.confirm) {
        res.onClick = () => {
          this.setState({ confirm: item })
        }
      }

      if (item.innerContent) {
        res.onClick = () => {
          this.setState({ content: item })
        }
      }

      if (typeof item.link === 'function') {
        res.link = item.link(this.props, item)
      }

      if (item.onClick && !item.confirm) {
        res.onClick = (val) => item.onClick(this.props, val)
      }

      return res
    })
  }

  render() {
    const { content, confirm } = this.state
    const { user, me = {}, className, children, label, tagType = 'cp', email, onClick = (f) => f } = this.props
    if (!user) return null

    const { displayName = '', perm = {}, company, type, status } = user

    return (
      <div
        data-id={this.props['data-id']}
        className={cn('user-card', className, { '--company': type === 'company' || company })}
      >
        {label && (
          <div className="tag">
            <div className={cn('tag__item', tagType)}>{label}</div>
          </div>
        )}

        <div className="user-card__view" onClick={onClick}>
          <Avatar user={user} className={className} />
          <div className="user-card__title">
            {displayName || <i>{status}</i>}
            <div className="email">{user.email || email}</div>
          </div>
          {perm.permissions && <div className="user-card__perm">{perm.permissions}</div>}
          {me.permissions === 'admin' && (
            <div className="user-card__more-btn">
              <DropDown options={this.wrapOptions()}>
                <MoreIcon />
              </DropDown>
            </div>
          )}
          {children && (
            <div className="user-card__children" onClick={(e) => e.stopPropagation()}>
              {children}
            </div>
          )}
        </div>

        {confirm && (
          <div className="user-card__delete" onClick={() => this.setState({ confirm: false })}>
            <h3>{confirm.confirm}</h3>
            <button className="btn small error" onClick={() => confirm.onClick({ user, me })}>
              Confirm
            </button>
            <button className="btn small cancel">Cancel</button>
          </div>
        )}
        {content && content.innerContent({ user, me, onClose: () => this.setState({ content: false }) })}
      </div>
    )
  }
}

export default observer(UserCard)
