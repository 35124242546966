import axios from 'axios'

const api = {
  //-------------------------------------------------------------------------------------------
  getAllNegotiations() {
    return axios.get('/admin/negotiations')
  },

  //-------------------------------------------------------------------------------------------
  getUserNegotiations({ _key }) {
    return axios.get(`/admin/negotiations/user/${_key}`)
  },

  //-------------------------------------------------------------------------------------------
  resend(messageId, { _key, email, displayName }) {
    return axios.post(`/message/resend/${messageId}`, { _key, email, displayName })
  },

  //-------------------------------------------------------------------------------------------
  createAuditTrail(id, email) {
    return axios.post(`/admin/negotiations/${id}/auditTrail`, { email })
  },

  //-------------------------------------------------------------------------------------------
  getMigrations() {
    return axios.get(`/admin/migrations`)
  },

  //-------------------------------------------------------------------------------------------
  updateCompany({ _key, displayName, logoURL }) {
    return axios.put(`/admin/company/${_key}`, { displayName, logoURL })
  },

  //-------------------------------------------------------------------------------------------
  //-------------------------------------------------------------------------------------------
  fixSignatures(id, email) {
    return axios.put(`/admin/negotiations/${id}/fix-signature`, { email })
  },

  //-------------------------------------------------------------------------------------------
  reExecute(id, user) {
    return axios.post(`/admin/negotiations/${id}/re-execute`, user)
  },

  //-------------------------------------------------------------------------------------------
  getHistory(id) {
    return axios.get(`/admin/negotiations/${id}/history`)
  },

  //-------------------------------------------------------------------------------------------
  revert(id, { updatedAt, createdAt }) {
    return axios.patch(`/negotiations/${id}/revert`, { updatedAt, createdAt })
  },

  //-------------------------------------------------------------------------------------------
  getQueue: () => axios.get('/admin/queue'),

  //-------------------------------------------------------------------------------------------
}

export default api
