import React from 'react'
import { observer } from 'mobx-react'

import Form from '../../Form'
import CloseIcon from 'common/Icons/Close'

class ArrayItem extends React.Component {
  onRemove = () => {
    const { meta } = this.props
    const { data = [ {} ] } = meta.parent

    const values = data.filter((v) => v !== meta.data)
    meta.parent.setValue(values)
    meta.parent.getChildren()
  }

  render() {
    const { meta = {}, readOnly, wrapper } = this.props
    const { children, config: { limit = 1, index }, parent } = meta

    const { data = [ {} ] } = parent
    const canRemove = !readOnly && data.length > limit && data.length > index

    return (
      <div className="array__item">
        <div className="--nr">{index + 1}</div>
        <div className="--form">
          <Form items={children} wrapper={wrapper} readOnly={readOnly} />
        </div>
        {canRemove && (
          <button type="button" className="array__btn" onClick={this.onRemove}>
            <CloseIcon />
          </button>
        )}
      </div>
    )
  }
}

export default observer(ArrayItem)
