import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import cn from 'classnames'
import get from 'lodash.get'

import './Grid.scss'

const Grid = ({ list, config }) => {
  //-----------------------------------------------------------------------------------------
  const { link = () => null, categories } = config
  const { value, manual, content } = categories
  const cols = {}

  list.forEach((item) => {
    const val = get(item, value)
    cols[val] = cols[val] || { label: val, items: [] }
    cols[val].items.push(item)
  })

  const orderedCols = manual
    ? manual.map((v) => ({ ...v, items: (cols[v.name] && cols[v.name].items) || [] }))
    : Object.values(cols)

  return (
    <div className="grid">
      {orderedCols.map(({ items = [], name, label }) => (
        <div key={name} className="grid__column">
          <div className="grid__header">
            <h3>{label}</h3>
            {items.length ? (
              <div className="desc">
                Total: <span>{items.length}</span>
              </div>
            ) : (
              <div className="desc">Empty</div>
            )}
          </div>
          <ul className={cn('table')}>
            {items.map((item, key) => (
              <Link
                to={link(item)}
                key={key}
                className={cn('grid__row', config.rowClass && config.rowClass(item), {
                  active: config.active && config.active(item),
                })}
              >
                {content && content(item)}
              </Link>
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}

export default observer(Grid)
