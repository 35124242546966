import React from 'react'
import { format, parse } from 'date-fns'
import cn from 'classnames'

import LeftIcon from 'common/Icons/Left'
import RightIcon from 'common/Icons/Right'
import CalendarIcon from 'common/Icons/Calendar'
import MenuDownIcon from 'common/Icons/MenuDown'

import Days from './Days/Days'
import Months from './Months/Months'

import './DatePicker.scss'

class DatePicker extends React.Component {
  state = { view: 'days' }

  dateFormat = 'yyyy-MM-dd'
  viewFormat = 'do MMMM yyyy'

  constructor(props) {
    super(props)
    let selected = parse(props.value, props.format || this.dateFormat, new Date())
    let active = selected

    if (selected.toString() === 'Invalid Date') {
      selected = null
      active = new Date()
    }

    this.state.active = active
    this.state.selected = selected
  }

  onChange = ({ year, month, day }) => {
    const { onChange = (f) => f } = this.props
    const dateFormat = this.props.format || this.dateFormat
    const selected = new Date(year, month, day)

    this.setState({ selected, show: false })
    onChange(format(selected, dateFormat))
  }

  getDays = () => {
    const { active } = this.state
    const year = active.getFullYear()
    const month = active.getMonth()

    const startDate = new Date(year, month, 1)
    const monthName = format(startDate, 'MMMM')
    const yearName = startDate.getFullYear()

    return { monthName, yearName }
  }

  render() {
    window.DatePicker = this

    const { viewFormat, className, disabled } = this.props
    const { active, show, selected, view } = this.state

    const year = active.getFullYear()
    const month = active.getMonth()

    const { monthName, yearName } = this.getDays()

    let viewDate
    if (selected) {
      viewDate = format(selected, viewFormat || this.viewFormat)
    }
    return (
      <div className={cn('date-picker', className)}>
        <div className="date-picker__input" onClick={() => !disabled && this.setState({ show: true })}>
          <input readOnly disabled={disabled} value={viewDate} onChange={() => null} />
          <CalendarIcon />
        </div>
        {show && (
          <div>
            <div className="date-picker__bg" onClick={() => this.setState({ show: false, view: 'days' })} />
            <div className="date-picker__calendar">
              <header className="date-picker__header">
                <div>
                  <button className={cn('icon', view)} onClick={() => this.setState({ active: new Date(year, month - 1, 1) })}>
                    <LeftIcon />
                  </button>
                </div>
                <div
                  className={cn('date-picker__header__center', { active: view === 'month' })}
                  onClick={() => this.setState({ view: view === 'month' ? 'days' : 'month' })}
                >
                  {monthName} {yearName} <MenuDownIcon />
                </div>
                <div>
                  <button className={cn('icon', view)} onClick={() => this.setState({ active: new Date(year, month + 1, 1) })}>
                    <RightIcon />
                  </button>
                </div>
              </header>

              {view === 'month' && (
                <Months
                  onChange={({ year, month }) => this.setState({ active: new Date(year, month, 1) })}
                  onClose={() => this.setState({ view: 'days' })}
                  active={active}
                  selected={selected}
                />
              )}

              {view === 'days' && <Days onChange={this.onChange} active={active} selected={selected} />}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default DatePicker
