import React from 'react'
import { observer } from 'mobx-react'

import PreLoad from 'common/PreLoad/PreLoad'

import LogoIcon from 'common/Icons/Logo2'
import WarningIcon from 'common/Icons/Warning'

import auth from 'api/auth'

import './Login.scss'

class Token extends React.Component {
  constructor(props) {
    super(props)
    this.preLoad = new PreLoad(this.getData)
  }

  getData = async () => {
    const { history, location } = this.props
    const params = new URLSearchParams(location.search)
    const jwt = params.get('jwt')
    if (jwt) {
      const success = await auth.setToken(jwt)
      if (success) {
        history.push('./')
      }
      return success
    }
  }

  render() {
    const { loading, result } = this.preLoad

    return (
      <div className="login">
        <div className="login__aside">
          <div className="login__logo">
            <LogoIcon color="var(--lightest)" />
            <p>
              We use simple general legal language so everyone can understand it. The debate should be about the salient
              terms not commas, formatting and spaces.
            </p>
          </div>
        </div>

        <div className="login__main">
          {loading ||
            (result ? (
              <div className="login__box">{result && result.displayName}</div>
            ) : (
              <div className="login__box">
                <div className="form-item">
                  <div className="form-input login__actions">
                    <div className="login__warning">
                      <WarningIcon /> Token is not valid!
                    </div>
                    <button className="btn" onClick={() => this.props.history.push('/login')}>
                      Login
                    </button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    )
  }
}

export default observer(Token)
