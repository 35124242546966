import React from 'react'

const Icon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-0.8 -2 23.286 28">
    <path
      d="M25.1,11.775c-.214-.214-8.48-8.646-8.48-8.646a2.943,2.943,0,0,0-4.2.005s-8.229,8.384-8.48,8.646a2.153,2.153,0,0,0-.562,1.4V24.536A1.719,1.719,0,0,0,5.089,26.25H23.946a1.719,1.719,0,0,0,1.714-1.714V13.125A2.062,2.062,0,0,0,25.1,11.775Zm-1.039,2.266-4.345,4.543a.1.1,0,0,0,0,.15l4.034,4.3a.7.7,0,0,1,0,.986.7.7,0,0,1-.986,0L14.673,15.38a.211.211,0,0,0-.311,0l-8.084,8.63a.7.7,0,0,1-.986,0,.7.7,0,0,1,0-.986l4.034-4.3a.114.114,0,0,0,0-.15L4.966,14.036a1.222,1.222,0,0,1,.862-1.918H23.207A1.265,1.265,0,0,1,24.059,14.041Z"
      transform="translate(-3.375 -2.25)"
      fill="currentColor"
    />
  </svg>
)

export default Icon
