import React from 'react'

import Form from '../../Form'

class Columns extends React.Component {
  render() {
    const { meta = {}, readOnly, wrapper } = this.props
    const { children } = meta

    const props = { items: children, readOnly, wrapper }
    return <div className="form-columns">{children && <Form {...props} />}</div>
  }
}

export default Columns
