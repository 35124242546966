import axios from 'axios'

const api = {
  //-------------------------------------------------------------------------------------------
  createCompany(body) {
    return axios.post('/company', body)
  },

  //-------------------------------------------------------------------------------------------
  normalize: ({ perm }) => {
    const { permissions } = perm
    const current = permissions === 'admin' ? 'admin' : null
    perm.companyPermissions = perm.companyPermissions === undefined ? current : perm.companyPermissions
    if (permissions === 'write') {
      perm.actions = [ 'canEdit', 'canSend', 'canAgree' ]
      perm.permissions = null
    }
  },

  async getCompany() {
    const res = await axios.get('/company')

    this.normalize(res.me)
    res.users.forEach(this.normalize)

    return res
  },

  //-------------------------------------------------------------------------------------------
  getCompanies() {
    return axios.get('/companies')
  },

  //-------------------------------------------------------------------------------------------
  adminAddUser(id, body) {
    return axios.post(`/company/${id}/user`, body)
  },

  //-------------------------------------------------------------------------------------------
  addUser(body) {
    return axios.post('/company/user', body)
  },

  //-------------------------------------------------------------------------------------------
  removeUser(id) {
    return axios.delete('/company/user/' + id)
  },

  //-------------------------------------------------------------------------------------------
  updateUser(id, body) {
    return axios.put('/company/user/' + id, body)
  },

  //-------------------------------------------------------------------------------------------
  createApiKey(body) {
    return axios.post('/company/api-key/', body)
  },

  //-------------------------------------------------------------------------------------------
  updateApiKey(id, body) {
    return axios.put('/company/api-key/' + id, body)
  },

  //-------------------------------------------------------------------------------------------
}

export default api
