import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import PlayIcon from 'common/Icons/Play'
import WaitingIcon from 'common/Icons/Waiting'
import ShieldIcon from 'common/Icons/Restricted'

import './Positions.scss'

import Party from './Party/Party'
import negotiationsApi from 'api/negotiations'

const defaultPositions = [
  {
    label: 'Sender',
    name: 'sender',
  },
  {
    label: 'Receiver',
    name: 'receiver',
  },
]

const mapPositions = ({ positions = defaultPositions, myParty = {}, cpParty = {}, lastActivity }) => {
  const action = (lastActivity && { [lastActivity.position]: lastActivity }) || {}

  const firstPOsition = positions[0]
  const partyMap = {
    [myParty.position || firstPOsition.name]: {
      party: myParty,
      title: 'My Party',
      type: 'my',
      isMe: true,
      lastActivity: action.myParty || (myParty.updatedAt && { title: 'Last action', createdAt: myParty.updatedAt }),
    },
  }

  if (cpParty) {
    partyMap[cpParty.position] = {
      party: cpParty,
      title: 'Counterparty',
      type: 'cp',
      isCp: true,
      lastActivity:
        action.counterparty || (cpParty.updatedAt && { title: 'Last action', createdAt: cpParty.updatedAt }),
    }
  }

  return positions.map((item) => ({ ...item, ...(partyMap[item.name] || {}) }))
}

const Positions = (props) => {
  const negotiation = negotiationsApi.current

  const { doc = {}, myParty = {}, state } = negotiation
  let { positions } = doc.schema
  const isCompleted = state === 'completed'

  positions = mapPositions({ ...negotiation, positions })

  const info = {
    message: myParty.isTurn ? 'My turn' : 'Waiting for CP...',
    icon: myParty.isTurn ? <PlayIcon /> : <WaitingIcon />,
    status: myParty.isTurn ? 'active' : 'waiting',
    isCompleted,
  }

  if (isCompleted) {
    info.message = 'Completed'
    info.status = 'completed'
    info.icon = <ShieldIcon />
  }

  const onSwitch = () => {
    const { current } = negotiationsApi
    const { position } = current.myParty

    const myPosition = positions.find((item) => item.name === position) || positions[0]
    const cpPosition = positions.find((item) => item.name !== myPosition.name)

    current.myParty.position = cpPosition.name

    if (current.cpParty) {
      current.cpParty.position = myPosition.name
    }

    negotiationsApi.current = { ...current }
  }

  return (
    <div className={cn('info-box', state, { warning: !myParty.isTurn })}>
      <div className="positions">
        {positions.map((position, key) => (
          <Party key={key} {...props} position={position} info={info} index={key} onSwitch={onSwitch} />
        ))}
      </div>
    </div>
  )
}

export default observer(Positions)
