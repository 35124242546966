import React from 'react'
import cn from 'classnames'

import './ToggleButton.scss'

class ToggleButton extends React.Component {
  render() {
    const { label, value, className, disabled, onChange = (f) => f } = this.props

    return (
      <div
        className={cn('toggle-btn', className, { disabled, on: value })}
        onClick={() => !disabled && onChange(!value)}
      >
        {label}
        <div className="toggle-btn__btn" />
      </div>
    )
  }
}

export default ToggleButton
