import React from 'react'
import cn from 'classnames'

import { timeDistance } from 'common/helper/date'

const PartyUsers = (props) => {
  const { parties = [], nr, timeBetweenReminders } = props
  const party = parties[nr]
  const { users, isTurn } = party || {}

  const side = nr ? 'receiver' : 'sender'

  return users ? (
    <div className={side}>
      <ul>
        {users.map((user, key) => (
          <PartySingleUser
            key={key}
            user={user}
            isTurn={isTurn}
            negotiation={props}
            party={party}
            timeBetweenReminders={timeBetweenReminders}
          />
        ))}
      </ul>
    </div>
  ) : null
}

class PartySingleUser extends React.Component {
  get lastMessage() {
    const { messages } = this.props.user
    return messages && messages[0]
  }

  render() {
    const { user, isTurn, negotiation, party, timeBetweenReminders } = this.props
    const { lastMessage } = this
    const { displayName, email, lastAction, status, messages } = user
    const { updatedAt, createdAt, state } = negotiation

    const lastVisited = (lastAction && lastAction.createdAt) || new Date()
    const lastUpdated = updatedAt || createdAt
    const lastEmailSent = (lastMessage && lastMessage.updatedAt) || lastUpdated

    const visitedAgo = new Date(lastVisited).getTime() - new Date(lastUpdated).getTime()
    const sentAgo = new Date().getTime() - new Date(lastEmailSent).getTime()
    const isVisited = lastAction && visitedAgo > 0

    const isInvitation = state === 'inviting'
    const showLastMessage = isTurn && lastMessage && !party.isOwner
    const allowResend = showLastMessage && sentAgo > timeBetweenReminders

    const totalMessages = messages && messages.length
    return (
      <li className={cn('party__user', status, { isVisited, isInvitation, showLastMessage })}>
        <div className={cn('flex', { allowResend })}>
          {!isVisited && <div className="warning-icon">!</div>}
          <div>
            {displayName.trim() || email} {!!totalMessages && <span>({totalMessages})</span>}
            {status === 'inviting' && displayName.trim() && <div className="desc">{email}</div>}
          </div>
        </div>

        <div>
          {lastAction && (
            <div className="desc">
              {lastAction.title}
              <i> {timeDistance(lastAction.createdAt)}</i>
            </div>
          )}
        </div>
      </li>
    )
  }
}

export default PartyUsers
