import React from 'react'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import cn from 'classnames'

import _d from 'dictionary'

import app from 'api/app'

const Settings = ({ children }) => {
  const { currentPage } = app
  const activeName = currentPage && currentPage.name

  return (
    <div className="p1 document-list small">
      <header className="p1__header">
        <h1>{_d('Templates')}</h1>
        <div className="p1__actions">
          <Link className={cn({ active: activeName === 'my' })} to="/documents">
            My templates
          </Link>
          <Link className={cn({ active: activeName === 'library' })} to="/documents/library">
            Library
          </Link>
        </div>
      </header>

      {children}
    </div>
  )
}

export default observer(Settings)
