import { useState } from 'react'

import EditIcon from 'common/Icons/Edit2'

import Form from 'common/Form/Form'

const config = [
  {
    type: 'select',
    label: 'Font family',
    name: 'fontFamily',
    options: [
      'Arial',
      'Times New roman',
      'Roboto',
      'Poppins',
      'Open Sans',
      'Verdana',
      'Trebuchet MS',
      'Georgia',
      'Courier',
    ],
  },
  {
    type: 'select',
    name: 'lineHeight',
    label: 'Line height',
    options: [
      '1',
      '1.1',
      '1.2',
      '1.3',
      '1.4',
      '1.5',
      '1.6',
      '1.7',
      '1.8',
      '1.9',
      '2',
      '2.1',
      '2.2',
      '2.3',
      '2.4',
      '2.5',
    ],
  },
]

const PageSettings = ({ tpl }) => {
  const [ edit, setEdit ] = useState(true)

  const { fontFamily, lineHeight } = tpl.pageStyle

  return (
    <div className="document-edit__box elections">
      {!edit && (
        <div className="document-edit__aside__actions">
          <button className="btn link" onClick={() => setEdit(!edit)}>
            <EditIcon /> Edit
          </button>
        </div>
      )}
      <h2>Page style</h2>

      {edit ? (
        <Form config={config} values={tpl.pageStyle} />
      ) : (
        <ul className="document-edit__aside__list">
          <li>
            <label>Font family</label>
            <div>{fontFamily}</div>
          </li>
          <li>
            <label>Line height</label>
            <div>{lineHeight}</div>
          </li>
        </ul>
      )}
    </div>
  )
}

export default PageSettings
