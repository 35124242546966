import React from 'react'

const Icon = (props) => {
  const { value = false } = props
  return (
    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect fill="#FFFFFF" opacity="0" x="0" y="0" width="24" height="24" />
        <circle stroke="currentColor" strokeWidth="2" cx="12" cy="12" r="8" />
        {value && <circle fill="currentColor" cx="12" cy="12" r="6" />}
      </g>
    </svg>
  )
}

export default Icon
