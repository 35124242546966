import React from 'react'
import cn from 'classnames'

import './SvgLine.scss'

const SvgLine = ({ w, h, top, left }) => {
  const x1 = 0
  const y1 = 2

  const x2 = w
  const y2 = h - 4

  const middleX = w / 2
  const middleY = h / 2

  let start = `M ${x1} ${y1}`
  let c1 = `C ${middleX - middleX / 2} ${y1}, ${middleX} ${middleY}, ${middleX} ${middleY}`
  let c2 = `C ${middleX + middleX / 2} ${y2}, ${x2} ${y2}, ${x2} ${y2}`

  if (!(top && left) && (top || left)) {
    start = `M ${x2} ${y1}`
    c1 = `C ${middleX + middleX / 2} ${y1}, ${middleX} ${middleY}, ${middleX} ${middleY}`
    c2 = `C ${middleX - middleX / 2} ${y2}, ${x1} ${y2}, ${x1} ${y2}`
  }

  return (
    <div className={cn({ top, left })} style={{ border: 'solid 0px red', width: w + 'px', height: h + 'px' }}>
      <svg viewBox={`0 0 ${w} ${h}`}>
        <path d={`${start} ${c1} ${c2}`} />
      </svg>
    </div>
  )
}

class LineBetween extends React.Component {
  state = {}

  setRef = (ref) => {
    const { to } = this.props

    if (ref) {
      const from = ref.parentElement
      const fromPos = this.findCenter(from)
      const toPos = this.findCenter(to)

      if (fromPos && toPos) {
        const size = this.findSize(fromPos, toPos)

        this.setState({ fromPos, toPos, size })
      }
    }
  }

  findCenter = (el) => {
    if (typeof el === 'string') {
      el = document.getElementById(el)
    }
    if (el) {
      let { x, y, height, width } = el.getBoundingClientRect()
      x = x + width / 2
      y = y + height / 2

      return { x, y }
    }
  }

  findSize = (b, a) => {
    const width = a.x - b.x
    const height = a.y - b.y
    return { width, height }
  }

  render() {
    const { size } = this.state
    const { className, to } = this.props

    let style
    let w
    let h
    let top
    let left

    if (size) {
      w = Math.abs(size.width)
      h = Math.abs(size.height) + 6

      style = { height: h, width: w }

      top = size.height < 0
      left = size.width < 0

      const transform = []
      if (top) {
        transform.push('translateY(-100%)')
        style.marginTop = '9px'
      }

      if (left) {
        transform.push('translateX(-100%)')
      }

      if (transform.length) {
        style.transform = transform.join(' ')
      }
    }

    return (
      <div className="svg-line" data-to={to} ref={this.setRef}>
        {size && (
          <div className={cn('svg-line__box', className)} style={style}>
            <SvgLine w={w} h={h} top={top} left={left} />
          </div>
        )}
      </div>
    )
  }
}

export default LineBetween
