import profileApi from 'api/profile'
import auth from 'api/auth'

import AsyncButton from 'common/AsyncButton/AsyncButton'

import Pending from './SmartId.pending'
import Preview from './SmartId.preview'

import './SmartId.scss'

const SmartID = (props) => {
  const { value, setValue, parent, model } = props
  const { smartId } = props.values

  const { allValid, totalValid } = parent.stats

  const onSubmit = async () => {
    const { smartIdDetails } = props.values
    profileApi.smartIdAuthenticate(smartIdDetails)
    setValue('pending')
  }

  const onRemove = async () => {
    await profileApi.smartIdRemove()
    const user = await auth.getMe()
    model.setValues(user)
  }

  if (smartId) {
    return <Preview {...smartId} onRemove={onRemove} />
  }

  const views = {
    active: () => (
      <div className="smart-id__actions">
        <button className="btn cancel" onClick={() => setValue(null)}>
          Cancel
        </button>

        <AsyncButton className="btn" onClick={onSubmit} disabled={!allValid || !totalValid}>
          Confirm Code
        </AsyncButton>
      </div>
    ),
    pending: () => <Pending tryAgain={() => setValue('active')} />,
  }

  return (
    <div className="smart-id">
      {(value && views[value] && views[value]()) || (
        <button className="btn" onClick={() => setValue('active')}>
          Register
        </button>
      )}
    </div>
  )
}

export default SmartID
