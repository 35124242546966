import React from 'react'
import { observer } from 'mobx-react'
import cn from 'classnames'

import Label from '../Label'
import Compare from '../Compare'
import ErrorMessage from '../ErrorMessage'

class Numbers extends React.Component {
  onChange = ({ target }) => {
    const { meta } = this.props
    const { value } = target

    const num = this.parse(value)
    const length = value && value.length

    this.lastChar = value[length - 1]

    meta.setValue((!isNaN(num) && num) || undefined)
  }

  format(val, [ thausand, comma ] = [ ',', '.' ]) {
    const nr = parseFloat(val)
    if (!isNaN(nr)) {
      const arr = nr.toString().split('.')

      const big = arr[0]
      arr[0] = arr[0].split('').map((nr, i) => ((big.length - i) % 3 === 1 ? nr + thausand : nr)).join('').slice(0, -1)

      let res = arr.join(comma)
      if (Number.isInteger(nr) && this.lastChar === '.') {
        res += '.'
      }
      return res
    }
  }

  parse(val) {
    val = val.replaceAll(',', '')
    return parseFloat(val)
  }

  render() {
    const { meta = {}, readOnly } = this.props
    const {
      name,
      value,
      cpValue,
      disabled,
      unmatched,
      opt = { myPosition: true },
      isHidden,
      isRestricted,
      notEditable,
      viewOnly,
      onBlur,
      validation = {},
      format,
      config,
    } = meta

    const { isInvalid, isDirty, isMatched, isCompleted, isChanged } = validation

    const viewValue = format === false ? value : this.format(value)

    return (
      <div
        input-name={name}
        className={cn({
          isHidden,
          isRestricted,
          isMatched,
          isUnmatched: !isMatched,
          isIncompleted: !isCompleted,
          isCompleted,
          isChanged,
          isInvalid,
          viewOnly,
          notEditable,
        })}
      >
        <div className={cn('form-item', { readOnly, unmatched, cpOnly: !opt.myPosition })}>
          <div className="form-item__content">
            <Label {...this.props} />
            {opt.myPosition && (
              <div className="form-input">
                {viewOnly ? (
                  <pre className={cn('form-input__view-only')}>{viewValue}</pre>
                ) : (
                  <input
                    data-id={'numbers:' + name}
                    autoFocus={config.autoFocus}
                    className={cn({
                      matched: isMatched,
                      value,
                      cpValue,
                      error: isInvalid && isDirty,
                      dirty: isDirty,
                      invalid: isInvalid,
                      blur: onBlur,
                    })}
                    onBlur={() => {
                      meta.onBlur = isInvalid
                      meta.onFocus = false
                    }}
                    type="tel"
                    value={viewValue || ''}
                    onChange={this.onChange}
                    disabled={disabled}
                    maxLength="19"
                  />
                )}

                <ErrorMessage validation={validation} meta={meta} />
              </div>
            )}
          </div>
          <Compare meta={meta} readOnly={readOnly || !opt.myPosition} />
        </div>
      </div>
    )
  }
}

export default observer(Numbers)
