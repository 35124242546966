import React from 'react'
import get from 'lodash.get'

import app from 'api/app'

import ro from './ro'
import en from './en'
import lt from './lt'

const langs = { en, lt, ro }
const selectedDictionary = langs[app.lang] || en

const dictionary = (str, val) => {
  if (str) {
    if (str[0] === '=') {
      str = str.slice(1)
    }

    const [ name, ...params ] = str.split('|')

    const current = langs[app.lang] || en
    let lang = langs[app.lang] ? app.lang : 'en'

    let value = get(current, name)

    if (value === undefined) {
      value = get(en, name)
      lang = 'en'
    }

    let result = typeof value === 'function' ? value(val || params, name) : value
    result = result === undefined ? name : result

    if (app.debug) {
      return (
        <i data-lang={lang} data-name={name} title={lang}>
          {result}
        </i>
      )
    }
    return result
  }
}

export default Object.assign(dictionary, selectedDictionary)
