import React from 'react'
import cn from 'classnames'

import './SubHeader.scss'

class SubHeader extends React.Component {
  render() {
    const { label } = this.props.meta

    return <div className={cn('form-section')}>{label && <h4>{label}</h4>}</div>
  }
}

export default SubHeader
