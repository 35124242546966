import get from 'lodash.get'

import interpolate from './interpolate'

class ValidateRules {
  constructor(rules) {
    this.rules = rules
  }
  //---------------------------------------------------------------------------------------------------------
  required = (value) => {
    const { required } = this.rules

    const isEmptyString = typeof value === 'string' && !value.trim()

    if (required && (value === undefined || isEmptyString)) {
      return { valid: false, message: 'Required field!', type: 'required' }
    }
    return { valid: true, type: 'required' }
  }

  //---------------------------------------------------------------------------------------------------------
  string = (value) => {
    const { minLength } = this.rules

    if (typeof value === 'string' && value.length < minLength) {
      return { valid: false, type: 'string', message: `Minimum ${minLength} characters!` }
    }
    return { valid: true, type: 'string' }
  }

  //---------------------------------------------------------------------------------------------------------
  minLength = (value) => {
    const { minLength } = this.rules
    const length = (value + '').length

    if (length < minLength) {
      return { valid: false, type: 'minLength', message: `Minimum length ${minLength} characters!` }
    }
    return { valid: true, type: 'minLength' }
  }

  //---------------------------------------------------------------------------------------------------------
  maxLength = (value) => {
    const { maxLength } = this.rules
    const length = (value + '').length

    if (length > maxLength) {
      return { valid: false, type: 'maxLength', message: `Maximum length ${maxLength} characters!` }
    }
    return { valid: true, type: 'maxLength' }
  }

  //---------------------------------------------------------------------------------------------------------
  min = (value, { values }) => {
    let { min } = this.rules
    min = interpolate(min, values)
    const num = parseFloat(value)

    if (isNaN(num) || num < min) {
      return { valid: false, type: 'min', message: `Minimum ${min}!` }
    }
    return { valid: true, type: 'min' }
  }

  //---------------------------------------------------------------------------------------------------------
  max = (value) => {
    const { max } = this.rules
    const num = parseFloat(value)

    if (isNaN(num) || num > max) {
      return { valid: false, type: 'max', message: `Maximum ${max}!` }
    }
    return { valid: true, type: 'max' }
  }

  //---------------------------------------------------------------------------------------------------------
  email = (value) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    if (value && !re.test(value)) {
      return { valid: false, type: 'email', message: `E-mail is not valid!` }
    }
    return { valid: true, type: 'email' }
  }

  //---------------------------------------------------------------------------------------------------------
  match = (value, { model }) => {
    const { match } = this.rules

    const matchValue = get(model.values, match)

    if (value !== undefined && value !== matchValue) {
      return { valid: false, type: 'match', message: `Password should match!` }
    }

    return { valid: true, type: 'match' }
  }

  //---------------------------------------------------------------------------------------------------------
  sync = (_, { model }) => {
    const { sync } = this.rules
    const syncItem = model.items[sync]

    if (syncItem) {
      const res = syncItem.info && syncItem.info.updateUp()
      syncItem.onBlur = res.isInvalid
    }
  }

  //---------------------------------------------------------------------------------------------------------
}

export default ValidateRules
